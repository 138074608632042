import React, { useState } from "react";

import Toggle from "react-toggle";

import Button from "../Button";

import FormInput from "../FormInput";
import SelectCheckbox from "../SelectCheckbox";
import ActionLoader from "../ActionLoader";

import CaretIcon from "../../icons/caret.png";
import ChooseImageIcon from "../../icons/pos_choose_image.png";

import ImageUploading from "./ImageUpload";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import AskExpertIcon from "../../icons/pos_ask_expert.png";

const AskExpert = ({ onClose, onSave, onBack, isLoading }) => {
  const [message, setMessage] = useState("");
  const [oem, setOem] = useState("");
  const [make, setMake] = useState("");
  const [model, setModel] = useState("");
  const [image, setImage] = useState(null);

  return (
    // <Modal>
    <div className="mt-8">
      <div style={{alignItems: 'baseline'}} className="flex c-pointer mb-20" onClick={onBack}>
        <div className="wt-20">
          <img src={CaretIcon} className="wt-100p rotate-90 op-5" />
        </div>
        <span  className="fs-12 c-table-header fw-bolder">
          BACK TO CONVERTERS
        </span>
      </div>
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div
          style={{ textAlign: "center", justifyContent: "center" }}
          className="btn ht-40  wt-20p text-center flex flex-middle fs-14 fw-bolder mb-20"
        >
          <span>ASK EXPERT</span>
        </div>
        <div className="flex flex-between flex-middle mb-40 flex-wrap wt-100p">
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder="OEM"
              onChange={(e) => {
                setOem(e.target.value)
              }}
              className="mr-40 wt-33p"
              value={oem}
              // hasError={hasSubmitted && !validations.businessLicense}
            />
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder="Make"
              onChange={(e) => {
                setMake(e.target.value)
              }}
              className="mr-40 wt-33p"
              value={make}
              // hasError={hasSubmitted && !validations.ein}
            />
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder="Model"
              onChange={(e) => {
                setModel(e.target.value)
              }}
              className="mr-40 wt-33p"
              value={model}
              // hasError={hasSubmitted && !validations.ein}
            />
          </div>
        <div className="flex flex-between">
          <div className="c-red fw-bolder fs-16 wt-48p mr-40">Upload Photo</div>
          <div className="c-red fw-bolder fs-16 wt-48p ml-8">Description</div>
        </div>
        <div className="flex flex-between  mb-40 flex-wrap">
          <div className="wt-48p mr-40 bg-white p-relative">
            <div style={{ width: "50%", paddingBottom: "50%" }}>
              <div className="container-center">
                <ImageUploading onChange={setImage} />
              </div>
            </div>
          </div>
          <textarea
            className="pl-16 pt-8 wt-48p bg-blue"
            placeholder="Enter text here"
            value={message}
            onChange={(e) => {
              setMessage(e.target.value);
            }}
          />
          {/* <div  className="wt-48p">
                        
                        
                    </div> */}
        </div>
        {isLoading && (
          <div className="ht-80 p-relative">
            <ActionLoader />
          </div>
        )}
        <div className="flex flex-middle flex-right">
          <Button
            className="flex ht-40 flex-middle mr-20 btn btn-red"
            type="modalCancel"
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            className="btn ht-40"
            type="modalSave"
            onClick={() => {
              onSave(image ? image[0] : null, message, oem, make, model);
            }}
          >
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            <span>SUBMIT</span>
          </Button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default AskExpert;
