import React from "react";
import HeaderIcon from "../../icons/newicons/cart-fill.svg";
import HeaderIconWhite from "../../icons/newicons/cart-fill.svg";
import { postData, CREATE_NEW_SESSION, CREATE_LOAD_URL } from "../../utils/api";

const Loads = ({ customerId, history, companyId, setToastParams }) => {
  const createNewGradingSassion = async () => {
    try {
      const data = await postData(CREATE_NEW_SESSION, {
        isRequestFromGradingSession: false,
      });
      history.push("/loads/active-load");
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  const createNewLoadCart = async () => {
    try {
      const data = await postData(CREATE_LOAD_URL, {
        customerId: companyId,
      });
      history.push(`/load/${data.loadId}`);
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  return (
    <>
      <div className="flex flex-middle mb-24">
        <img src={HeaderIcon} className="ht-30 wt-30 mr-12" />
        <h1 className="mr-24 fs-24 c-gray">SELL</h1>
      </div>
      <div
        className="flex flex-middle bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer wt-50p"
        onClick={createNewGradingSassion}
      >
        <img src={HeaderIconWhite} className="ht-30 wt-30 mr-24" />
        <span>REQUEST A GRADING SESSION</span>
      </div>
      <div
        className="flex flex-middle mt-24 bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer wt-50p"
        onClick={createNewLoadCart}
      >
        <img src={HeaderIconWhite} className="ht-30 wt-30 mr-24" />
        <span>REQUEST A QUOTE</span>
      </div>
    </>
  );
};

export default Loads;
