import * as firebase from 'firebase';

const config = {
  apiKey: "AIzaSyCsvbvIzzoUEt7nO6z7sSsvpwlwc1cEkLA",
  authDomain: "cat-traders.firebaseapp.com",
  databaseURL: "https://cat-traders.firebaseio.com",
  projectId: "cat-traders",
  storageBucket: "cat-traders.appspot.com",
  messagingSenderId: "46805139653",
  appId: "1:46805139653:web:82e05ce6e21d2f9c6330ac",
  measurementId: "G-1F29JQ26BJ"
};;

firebase.initializeApp(config);

const database = firebase.database();

export {
  database,
};