import React from 'react'
import UnderConstruction from '../../components/UnderConstruction';

const LoadsReport = () => {
    return (
        <UnderConstruction />
    )
}

export default LoadsReport
