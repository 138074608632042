import React, { useState, useEffect, Fragment } from "react";
import Toggle from "react-toggle";

import Checkbox from "../Checkbox";
// import FormInput from '../FormInput';

import HeaderIcon from "../../icons/newicons/cart-fill.svg";

import { Link } from "react-router-dom";
import moment from "moment";
import { RiCheckboxBlankCircleFill } from "react-icons/ri";
import OutsideClickHandler from "react-outside-click-handler";
import Modal from "../../components/Modal";
import Button from '../../components/Button';

import { IconContext } from "react-icons";
// import CaretIcon from '../../icons/caret.png';
import CaretIcon from "../../icons/rev_carat.png";
import Edit from "../../icons/pos_edit_green.png";
import Clone from "../../icons/red_contacts.png";
import Delete from "../../icons/pos_delete-small.png";
import PlusIcon from "../../icons/pos_plus.png";
import ViewIcon from "../../icons/pos_explore.png";
import Cross from "../../icons/cross.svg";
import Pencil from "../../icons/pencil.svg";
import Tick from "../../icons/tick.svg";
import InvoiceIconActive from "../../icons/pos_invoice.png";

import ActionLoader from "../ActionLoader";

let TIMER = null;

const Table = ({
  type,
  columns,
  items,
  keys,
  widthClasses = [],
  itemtemClasses = [],
  actions,
  className,
  customClassName = "",
  isCart,
  counterDropdown,
  viewIconImg,
  switchIds = [],
  modalColumn = [],
  scrollTable,

  offset,
  fetchMore,
  fetchLess,
  areMoreItemsAvailable,
  hideCheckBox,
  limit,

  headerBgWhite,
  isLoading,
  viewLinkKey = "",
  addCartLabel = "",
  hasAddToCart = false,
  isSingleSelect = false,
  clearSelection = false,
  hideTableCounter = false,
  highlight = false,
  showSelectAll=false,
  onIsActiveChange = () => {},
  onChange = () => {},
  onEdit = () => {},
  onClone = () => {},
  onAddFranchise = () => {},
  onDelete = () => {},
  onMessage = () => {},
  showGraph = () => {},
  onRupeeView = () => {},
  onItemClick = () => {},
  onCountChange = () => {},
  handleAddToCart = () => {},
  onModify = () => {},
  onApprove = () => {},
  onReject = () => {},
  onSelection = () => {},
  onClearSelection = () => {},
  onPrint = () => {},
  onSelectAll = () => {},
}) => {
  const [selected, setSelected] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [isImgModalShown, setIsImgModalShown] = useState(false)
  let timer = null;
  useEffect(() => {
    if (clearSelection === true) {
      setSelected([]);
      onClearSelection(false);
    }
  }, [clearSelection]);

  const selectAll = () => {
    if (selected.length === items.length) {
      setSelected([]);
      onSelectAll(false)
      return;
    }
    const selectedIndices = Array.from({ length: items.length }).map(
      (item, index) => {
        return index;
      }
    );
    setSelected(selectedIndices);
    onSelectAll(items);
  };

  const selectDeSelectItem = (index, item) => {
    let updatedItems = [];
    if (isSingleSelect) {
      updatedItems = selected.includes(index) ? [] : [index];

      if (onSelection) {
        onSelection(updatedItems.includes(index) ? item : null);
      } else {
        onItemClick(updatedItems.includes(index) ? item : showSelectAll?item:null);
      }
    } else {
      updatedItems = selected.filter((itemIndex) => {
        return itemIndex !== index;
      });
      if (updatedItems.length === selected.length) {
        updatedItems.push(index);
      }
      onSelection(updatedItems.includes(index) ? item : showSelectAll?item:null);
    }
    setSelected(updatedItems);
  };

  const isKeyADate = (key) => {
    if ((key || "").startsWith("dd-date-")) {
      return true;
    }
    return false;
  };

  const isDiffField = (key) => {
    if ((key || "").startsWith("diff-")) {
      return true;
    }
    return false;
  };
  const isSemiocolonSeparatedLine = (val) => {
    try {
      if (val.startsWith("semicolon-separated-inline-")) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };
  const isPhoto = (val) => {
    try {
      if (val.startsWith("is-photo-")) {
        return true;
      }
      return false;
    } catch (e) {
      return false;
    }
  };

  const getDiff = (key, item) => {
    const keys = key.split("-");
    const val = ((item[keys[1]] - item[keys[2]]) / item[keys[2]]) * 100;
    return val.toFixed(3);
  };
  const formatPagination = () => {
    return (
      items.length > 0 &&
      (offset >= 10 || areMoreItemsAvailable) && (
        <div className="ht-20 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
          <div className="ht-20 rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4">
            <img
              src={CaretIcon}
              alt=""
              className={`wt-20 rotate-90 c-pointer ${
                offset > 0 ? "op-7" : "op-2"
              }`}
              onClick={offset > 0 ? fetchLess : () => {}}
            />
          </div>
          <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
            Showing {offset + 1} - {offset + items.length}
          </span>
          <div className=" ht-20 rounded-circle  flex flex-center flex-middle pl-8 pb-4 pt-4">
            <img
              src={CaretIcon}
              alt=""
              className={`wt-20 rotate--90 c-pointer ${
                areMoreItemsAvailable ? "op-7" : "op-2"
              }`}
              onClick={areMoreItemsAvailable ? fetchMore : () => {}}
            />
          </div>
        </div>
      )
    );
  };

  const renderRolesData = (keys, item) => {
    return (keys || []).map((key, index) => {
      return (
        <div
          className={"pl-16 pr-16 " + (widthClasses[index] || "flex-1")}
          key={index}
        >
          {key != "permissionDisplay"
            ? item[key]
            : Object.keys(item[key]).map((type) => {
                return (
                  <div className="mt-20">
                    {
                      <span
                        style={{
                          fontWeight: "500",
                          color: "#272727",
                          fontSize: 16,
                          lineHeight: "20px",
                        }}
                      >
                        {type}
                      </span>
                    }
                    <div className="flex flex-middle flex-wrap">
                      {item[key][type]
                        ? item[key][type].map((el) => {
                            return (
                              <div className="mt-10 mr-20">
                                <IconContext.Provider
                                  value={{
                                    size: "1em",
                                    color: "#0B5FFF",
                                    className: className || "",
                                  }}
                                >
                                  <div>
                                    <RiCheckboxBlankCircleFill />
                                    <span style={{marginLeft: 5, color: '#494949', fontSize: 12, fontWeight: '500', lineHeight: '30px'}}>{el.permissionName}</span>
                                  </div>
                                </IconContext.Provider>
                              </div>
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })}
        </div>
      );
    });
  };

  console.log(items);

  return (
    <div className={`p-relative`}>
      {/* <div className="ht-80 flex flex-middle flex-between flex-row-reverse pl-16 pr-16">
                    <div>
                    {(items.length >0 && (offset >=10 || areMoreItemsAvailable)) &&<div className="flex flex-middle">
                        <span className="fs-12 mr-20">Showing {offset + 1} - {offset + items.length}</span>
                        <img src={CaretIcon} alt="" className={`wt-20 rotate-90 c-pointer ${offset > 0?'op-7':'op-2'}`} onClick={offset > 0?fetchLess:()=>{}} />
                        <img src={CaretIcon} alt="" className={`wt-20 rotate--90 c-pointer ${areMoreItemsAvailable?'op-7':'op-2'}`}  onClick={areMoreItemsAvailable?fetchMore:()=>{}} />
                    </div>}</div>
                    <div>
                        <FormInput 
                            onChange={(e)=> {
                                const val = e.target.value;
                                if(TIMER) {
                                    clearTimeout(TIMER);
                                }
                                TIMER = setTimeout(()=> {
                                    onChange(val); 
                                }, 500)
                                setSearchValue(val)
                            }}
                            placeholder={"Search for items"}
                            value = {searchValue}
                        />
                    </div>
                </div> */}
      {/* {(items.length >0 && (offset >=10 || areMoreItemsAvailable)) && <div className="p-relative mt--74" >
                    {formatPagination()}
                </div>} */}
      <div className="table-wrapper p-relative loads-listing">
        <div className={`p-relative ${className || ""} `}>
          <div
            className={`flex flex-middle flex-between pl-16 pr-16 tbl-header ${
              headerBgWhite ? "bg-white pt-8" : "mb-8 mt-24"
            }`}
          >
            {
              showSelectAll && <Checkbox onClick={selectAll} isSelected={selected.length === items.length} />
            }
            {!hideCheckBox && (
              <div className={hasAddToCart ? "" : "mr-52"} style={{marginRight: showSelectAll?35:52}}>
                {" "}
                {hasAddToCart && (
                  <span className={`c-gray fs-14 ${customClassName}`}>{addCartLabel}</span>
                )}{" "}
              </div>
            )}
            {(columns || []).map((column, index) => {
              return (
                <span
                  className={
                    "c-gray fs-14 pl-16 pr-16 " +
                    (widthClasses[index] || "flex-1")
                  }
                  key={index}
                >
                  {typeof column === "function" ? column() : column}
                </span>
              );
            })}
            {actions && (
              <div
                className={
                  actions.length > 4
                    ? "wt-100 c-gray fs-14 t-right"
                    : "wt-90 c-gray fs-14 t-right"
                }
              >
                Actions
              </div>
            )}
          </div>
          <div
            class={scrollTable ? "scrollTable" : ""}
            style={scrollTable ? { height: "auto", overflow: "scroll" } : {}}
          >
            {(items || []).map((item, index) => {
              return (
                <div
                  style={{borderTop: `${item && item.showTopBorder ? '1px solid #000':''}`}}
                  key={index}
                  className={`bg-white flex flex-middle flex-between pb-12 pt-12 pl-16 pr-16  c-black fs-13 fw-bold table-item ${highlight?'hoverRow':''}`}
                  onClick={() => {
                    onItemClick(item);
                  }}
                >
                  {!hideCheckBox && !isCart && (
                    <Checkbox
                      id={index}
                      onClick={(id) => {
                        selectDeSelectItem(id, item);
                      }}
                      isSelected={selected.includes(index)}
                      className="mr-40 mt-12 mb-12"
                    />
                  )}
                  {!hideCheckBox && isCart && (
                    <div className="mr-32 flex flex-middle">
                      <img
                        alt=""
                        className="wt-20 ht-20"
                        src={HeaderIcon}
                        onClick={() => {
                          if (hasAddToCart) {
                            handleAddToCart(item);
                          }
                        }}
                      />
                    </div>
                  )}
                  {type === "rolesgrid"
                    ? renderRolesData(keys, item)
                    : (keys || []).map((key, index) => {
                        // console.log('item, ', isSemiocolonSeparatedLine(item[key]), key, item);
                        if (index === 0 && type === "entity") {
                          if (isSemiocolonSeparatedLine(item[key])) {
                            return (
                              <div
                                className={
                                  "pl-16 pr-16 " +
                                  (widthClasses[index] || "flex-1")
                                }
                              >
                                {(
                                  item[key].split(
                                    "semicolon-separated-inline-"
                                  )[1] || ""
                                )
                                  .split(";")
                                  .map((val) => {
                                    const hasColor = val.startsWith(
                                      "text-color-"
                                    );
                                    if (hasColor) {
                                      const dataItem = (
                                        val.split("text-color-")[1] || ""
                                      ).split("_");
                                      return (
                                        <div
                                          className={`mb-4 fs-12 ${dataItem[0]}`}
                                          key={index}
                                        >
                                          {dataItem[1]}
                                        </div>
                                      );
                                    } else {
                                      return <div key={index}>{val}</div>;
                                    }
                                  })}
                              </div>
                            );
                          } else {
                            return (
                              <div
                                className={
                                  "pl-16 pr-16 " +
                                  (widthClasses[index] || "flex-1")
                                }
                                key={index}
                              >
                                {item[key]}
                              </div>
                            );
                          }
                        }
                        if (
                          ((switchIds.length > 0
                            ? switchIds.includes(index)
                            : index === 5) &&
                            type === "entity" &&
                            key !== undefined) ||
                          (index === 5) & (type === "user")
                        ) {
                          return (
                            <div className="pl-16 pr-16 wt-100">
                              <Toggle
                                onChange={(e) => {
                                  onIsActiveChange(item, e.target.checked);
                                }}
                                className="bg-theme"
                                checked={item.isActive}
                              />
                            </div>
                          );
                        }
                        if (isSemiocolonSeparatedLine(item[key])) {
                          return (
                            <div
                              className={
                                "pl-16 pr-16 " +
                                (widthClasses[index] || "flex-1")
                              }
                            >
                              {(
                                item[key].split(
                                  "semicolon-separated-inline-"
                                )[1] || ""
                              )
                                .split(";")
                                .map((val) => {
                                  const hasColor = val.startsWith(
                                    "text-color-"
                                  );
                                  if (hasColor) {
                                    const dataItem = (
                                      val.split("text-color-")[1] || ""
                                    ).split("_");
                                    return (
                                      <div
                                        className={`mb-4 fs-12 ${dataItem[0]}`}
                                        key={index}
                                      >
                                        {dataItem[1]}
                                      </div>
                                    );
                                  } else {
                                    return <div key={index}>{val}</div>;
                                  }
                                })}
                            </div>
                          );
                        }
                        if (isPhoto(item[key])) {
                          return (
                            <div
                              className={
                                "pl-16 pr-16 " +
                                (widthClasses[index] || "flex-1")
                              }
                            >
                              <img
                                alt=""
                                className="wt-30"
                                src={item[key].split("is-photo-")[1]}
                              />
                            </div>
                          );
                        }
                        if((modalColumn || []).includes(key)) {
                          return <span
                          className={
                            "pl-16 pr-16 cursorhand " + (widthClasses[index] || "flex-1")
                          }
                          onClick={(e) => {
                            setIsImgModalShown(item[key])
                            e.stopPropagation();
                            e.preventDefault();
                          }}
                          key={index}
                        >
                          Show Message
                          </span>
                        }
                        return (
                          <span
                            className={
                              "pl-16 pr-16 " + (widthClasses[index] || "flex-1")
                            }
                            key={index}
                          >
                            {isKeyADate(key) || isDiffField(key) ? (
                              <Fragment>
                                {isKeyADate(key) ? (
                                  <Fragment>
                                    {moment(
                                      item[key.replace("dd-date-", "")]
                                    ).format("Do MMM, YYYY")}
                                  </Fragment>
                                ) : null}
                                {isDiffField(key) ? (
                                  <span
                                    className={
                                      getDiff(key, item) >= 0
                                        ? "success-color"
                                        : "error-color"
                                    }
                                  >
                                    {getDiff(key, item)}
                                  </span>
                                ) : null}
                              </Fragment>
                            ) : (
                              <Fragment>{item[key]}</Fragment>
                            )}
                          </span>
                        );
                      })}
                  {actions && (
                    <div
                      className={
                        actions.length > 4
                          ? "flex flex-right flex-middle wt-100"
                          : "flex flex-right flex-middle wt-90"
                      }
                    >
                      {/* {actions.includes('GRAPH') && <StrategyIcon className="wt-25 ht-20" onClick={()=>{showGraph(item)}}/>}
                                            {actions.includes('RUPEE') && <MoneyIcon className="wt-25 ht-20" onClick={()=>{onRupeeView(item)}}/>} */}
                      {actions.includes("MODIFY") && (
                        <div className="wt-20 ht-25 flex flex-middle flex-center mr-4 ml-4">
                          <img
                            alt=""
                            src={Pencil}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onModify(item);
                            }}
                          />
                        </div>
                      )}
                      {actions.includes("APPROVE") && (
                        <div className="wt-20 ht-25 flex flex-middle flex-center mr-4 ml-4">
                          <img
                            alt=""
                            src={Tick}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onApprove(item);
                            }}
                          />
                        </div>
                      )}
                      {actions.includes("REJECT") && (
                        <div className="wt-20 ht-25 flex flex-middle flex-center mr-4 ml-4">
                          <img
                            alt=""
                            src={Cross}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onReject(item);
                            }}
                          />
                        </div>
                      )}
                      {actions.includes("VIEW") && (
                        <Link to={item[viewLinkKey]}>
                          {" "}
                          <img
                            alt="View"
                            src={viewIconImg ? viewIconImg : ViewIcon}
                            className="ht-30 p-relative t-2"
                          />
                        </Link>
                      )}
                      {actions.includes("PRINT") && (
                        <div className="wt-20 ht-25 flex flex-middle flex-center mr-4 ml-4">
                          <img
                            alt="view"
                            src={InvoiceIconActive}
                            className="wt-20 ht-20"
                            onClick={(e) => {
                              e.stopPropagation();
                              onPrint(item);
                            }}
                          />
                        </div>
                      )}
                      {actions.includes("EDIT") && (
                        <div
                          className={
                            actions.length > 4
                              ? "wt-25 ht-25 flex flex-middle flex-center mr-4 ml-4"
                              : "wt-30 ht-25 flex flex-middle flex-center"
                          }
                        >
                          <img
                            alt=""
                            src={Edit}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onEdit(item);
                            }}
                          />
                        </div>
                      )}
                        {actions.includes("MESSAGE") && (
                        <div
                          className={
                            actions.length > 4
                              ? "wt-25 ht-25 flex flex-middle flex-center mr-4 ml-4"
                              : "wt-30 ht-25 flex flex-middle flex-center"
                          }
                          onClick={(e) => {
                            e.stopPropagation();
                            onMessage(item);
                          }}
                          style={{cursor:"pointer"}}
                        >
                         <svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="currentColor" class="bi bi-chat-right-text" viewBox="0 0 16 16">
                          <path d="M2 1a1 1 0 0 0-1 1v8a1 1 0 0 0 1 1h9.586a2 2 0 0 1 1.414.586l2 2V2a1 1 0 0 0-1-1H2zm12-1a2 2 0 0 1 2 2v12.793a.5.5 0 0 1-.854.353l-2.853-2.853a1 1 0 0 0-.707-.293H2a2 2 0 0 1-2-2V2a2 2 0 0 1 2-2h12z"/>
                          <path d="M3 3.5a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9a.5.5 0 0 1-.5-.5zM3 6a.5.5 0 0 1 .5-.5h9a.5.5 0 0 1 0 1h-9A.5.5 0 0 1 3 6zm0 2.5a.5.5 0 0 1 .5-.5h5a.5.5 0 0 1 0 1h-5a.5.5 0 0 1-.5-.5z"/>
                        </svg>
                        </div>
                      )}
                      {actions.includes("CLONE") && (
                        <div
                          className={
                            actions.length > 4
                              ? "wt-25 ht-25 flex flex-middle flex-center mr-4 ml-4"
                              : "wt-30 ht-25 flex flex-middle flex-center"
                          }
                        >
                          <img
                            alt=""
                            src={Clone}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onClone(item);
                            }}
                          />
                        </div>
                      )}
                         {actions.includes("ADD_FRANCHISE") && (item.isFranchise && (item.isFranchise === 'false')) && (
                        <div
                          className={
                            actions.length > 4
                              ? "wt-25 ht-25 flex flex-middle flex-center mr-4 ml-4"
                              : "wt-30 ht-25 flex flex-middle flex-center"
                          }
                        >
                          <div
                           onClick={(e) => {
                            e.stopPropagation();
                            onAddFranchise(item)
                               }
                           }
                           style={{
                             cursor:'pointer'
                           }}
                          
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-plus-fill mr-8"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              <path
                                fill-rule="evenodd"
                                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                              />
                            </svg>
                            </div>
                        </div>
                      )}
                      {actions.includes("DELETE") && (
                        <div
                          className={"wt-30 ht-25 flex flex-middle flex-center"}
                        >
                          <img
                            alt=""
                            src={Delete}
                            className="wt-20 ht-20 "
                            onClick={(e) => {
                              e.stopPropagation();
                              onDelete(item);
                            }}
                          />
                        </div>
                      )}
                      {/* <EditIcon className="wt-25 ht-20" onClick={()=>{onEdit(item)}}/> */}
                      {/* <DeleteIcon className="wt-25 ht-20" onClick={()=>{onDelete(item)}}/> */}
                    </div>
                  )}
                </div>
              );
            })}
          </div>
        </div>
      </div>
      <div className="flex flex-between flex-middle pagination-select ">
        {!hideTableCounter && (
          <div className="ht-40 wt-150 p-relative t-8" style={{ zIndex: 1 }}>
            <select
              class="form-select rounded-pill"
              name=""
              id=""
              value={limit}
              onChange={(e) => {
                onCountChange(parseInt(e.target.value));
              }}
            >
              {counterDropdown ? (
                <Fragment>
                  {(counterDropdown || []).map((dcount) => (
                    <option value={dcount} key={`drop-${dcount}`}>
                      {dcount}
                    </option>
                  ))}
                </Fragment>
              ) : (
                <Fragment>
                  <option value={10}>10</option>
                  <option value={20}>20</option>
                  <option value={30}>30</option>
                  <option value={40}>40</option>
                </Fragment>
              )}
            </select>
          </div>
        )}
        {items.length > 0 && (offset >= 10 || areMoreItemsAvailable) && (
          <div className="flex-1">
            <div
              style={{ position: "absolute", width: "100%", left: "0" }}
              className=" flex flex-middle flex-center pl-16 pr-16 pagination-wrapper bottom"
            >
              <div className="ht-20 rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate-90 c-pointer ${
                    offset > 0 ? "op-7" : "op-2"
                  }`}
                  onClick={offset > 0 ? fetchLess : () => {}}
                />
              </div>
              <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                Showing {offset + 1} - {offset + items.length}
              </span>
              <div className="ht-20 rounded-circle  flex flex-center flex-middle pl-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate--90 c-pointer ${
                    areMoreItemsAvailable ? "op-7" : "op-2"
                  }`}
                  onClick={areMoreItemsAvailable ? fetchMore : () => {}}
                />
              </div>
            </div>
          </div>
        )}
        <div></div>
      </div>

      {isLoading && (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      )}
      {isImgModalShown && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => setIsImgModalShown(false)}>
            <span>
                {isImgModalShown}
            </span>
            <div className="flex flex-row-reverse flex-middle mt-20">
                            <Button type="saveButton" className="flex flex-middle" onClick={() => setIsImgModalShown(false)}>
                                <span>Close</span>
                            </Button>
                        </div>
          </OutsideClickHandler>
        </Modal>
      )}
    </div>
  );
};

export default Table;
