import React, { useState } from "react";

import Modal from "../Modal";
import Button from "../Button";

import FormInput from "../FormInput";

import Checkbox from "../Checkbox";

// import ROLES from '../../constants/roles'
import SelectCheckbox from "../SelectCheckbox";

import CaretIcon from "../../icons/caret.png";

import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import { useEffect } from "react";
import { getLoginDetails } from "../../utils/storage";

import { GET_FACILITIES_URL, getData } from "../../utils/api";
import Toggle from "react-toggle";

const RolesAddEdit = ({
  item,
  permissions,
  onClose,
  onSave,
  type,
  selected,
  onBack,
  isLoading = false,
  types = [],
}) => {
  const [name, setName] = useState(item.name);
  const [selectedPermissions, setSelectedPermissions] = useState(
    selected || []
  );
  const [facilities, setFaciltiesItems] = useState([]);
  const [selectedFacilities, setSelectedFacilities] = useState([]);
  const [roles, setRoles] = useState({});
  const [hasSubmitted, setHasSubmitted] = useState(false);
  const [isFranchise, setIsFranchise] = useState(item.isFranchise || false);
  const [selectedType, setSelectedType] = useState(item.type || null);
  const [validations, setValidations] = useState({
    nameValidation: false,
    valueValidation: false,
  });

  useEffect(() => {
    getFacilities();
  }, []);

  useEffect(() => {
    let data = {};
    permissions.map((el) => {
      if (!el || !el.type) {
        return;
      }
      if (!data[el.type]) {
        data[el.type] = [];
      }
      data[el.type].push(el);
    });
    setRoles(data);
  }, [permissions]);

  const validateFields = () => {
    const updatedValidations = { ...validations };

    updatedValidations.name = name;
    updatedValidations.type = selectedType;
    updatedValidations.selectedPermissions = selectedPermissions.length;
    setValidations(updatedValidations);
    return updatedValidations;
  };

  const toggleItem = (item) => {
    const updatedItems = selectedPermissions.filter((selectedItem) => {
      return item.id !== selectedItem.id;
    });
    if (updatedItems.length === selectedPermissions.length) {
      updatedItems.push({ ...item });
    }
    setSelectedPermissions(updatedItems);
  };

  useEffect(() => {
    const invPermission = (selected || []).find(
      (el) => el.id === "canViewAllFacilityInvoices"
    );
    const select = facilities.filter(
      (el) =>
        invPermission &&
        invPermission.facilities &&
        invPermission.facilities.includes(el.name)
    );
    setSelectedFacilities(
      select.map((el) => {
        return { id: el.facilityId, value: el.name };
      })
    );
  }, [permissions, facilities]);

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId: getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(
        (data.list && data.list.filter((el) => el.isActive)) || []
      );
    } catch (e) {}
  };

  return (
    // <Modal>
    <div className="mt-8">
      <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
        <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
        <span className="fs-12 c-table-header fw-bolder">BACT TO ROLES</span>
      </div>

      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="fs-24 fw-bolder mb-40">
          {type === "add" ? "ADD" : type == "clone" ? "CLONE" : "EDIT"} ROLE
        </div>
        <div className="flex mb-40">
          <div className="wt-200">
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder="Name"
              onChange={(e) => {
                setName(e.target.value);
              }}
              className="mr-40"
              value={name}
              hasError={hasSubmitted && !validations.name}
            />
          </div>
          {/* <div className="p-relative t-24 flex-1 add-role-select">
            <SelectCheckbox
              className="t-24 mt-20 p-relative"
              selectPlaceholder={"Select Permissions"}
              selected={selectedPermissions || []}
              items={permissions}
              setSelectedItems={setSelectedPermissions}
              hasError={hasSubmitted && !validations.selectedPermissions}
            />
          </div> */}
          <div className="wt-20p mr-40 p-relative t-12" style={{ border: 0, marginTop: 30 }}>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
              value={selectedType}
              onChange={(e) => setSelectedType(e.target.value)}
            >
              <option value={""}>Select Type</option>
              {types.map((fac, idx) => (
                <option key={idx} value={fac.id}>
                  {fac.value}
                </option>
              ))}
            </select>
              {hasSubmitted && !validations.type && (
              <div  className="fs-10 c-error fw-bold p-absolute">
                Please select valid Type
              </div>
            )}
          </div>
          {/* <div
            className="pl-16 pr-16 flex"
            style={{ border: 0, marginTop: 40 }}
          >
            <Toggle
              onChange={(e) => {
                setIsFranchise(e.target.checked);
              }}
              className="bg-theme"
              checked={isFranchise}
            />
            <span className="pl-16">Is Franchise</span>
          </div> */}
        </div>
        <div className="flex flex-between mb-40">
          <div className="mr-40">
            <span
              className="fw-bolder"
              style={{ fontSize: 16, color: "#272727" }}
            >
              Permissions
            </span>
            <div className="mt-20">
              {Object.keys(roles).map((type) => {
                return (
                  <div className="mt-20">
                    {
                      <span
                        style={{
                          fontWeight: "400",
                          color: "#272727",
                          fontSize: 16,
                        }}
                      >
                        {type}
                      </span>
                    }
                    <div className="flex flex-middle flex-wrap">
                      {roles[type]
                        ? roles[type].map((el) => {
                            return (
                              <>
                                <div className="mt-20 mr-40">
                                  <Checkbox
                                    className="p-relative"
                                    isSelected={
                                      selectedPermissions &&
                                      selectedPermissions.find(
                                        (temp) => temp.id == el.id
                                      )
                                    }
                                    label={
                                      <span
                                        style={{
                                          color: "#949494",
                                          fontSize: 14,
                                        }}
                                      >
                                        {el.value}
                                      </span>
                                    }
                                    id={el}
                                    labelClassName="fs-12"
                                    onClick={(e) => toggleItem(e)}
                                  />
                                </div>
                                {el.id === "canViewAllFacilityInvoices" &&
                                selectedPermissions &&
                                selectedPermissions.find(
                                  (temp) => temp.id == el.id
                                ) ? (
                                  <div
                                    className="p-relative t-24 flex-1 add-role-select"
                                    style={{ marginTop: 0, zIndex: 1 }}
                                  >
                                    <SelectCheckbox
                                      id={"selectedFacilities-dropdowns"}
                                      selectPlaceholder={"Facilities"}
                                      selected={selectedFacilities}
                                      items={facilities.map((facility) => {
                                        return {
                                          id: facility.facilityId,
                                          value: facility.name,
                                        };
                                      })}
                                      setSelectedItems={(items) => {
                                        setSelectedFacilities(items);
                                      }}
                                    />
                                  </div>
                                ) : null}
                              </>
                            );
                          })
                        : null}
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className="flex flex-middle flex-right">
          <Button
            className="btn ht-40 btn-redflex flex-middle mr-20 bg-red"
            type="modalCancel"
            style={{ padding: "10px 30px" }}
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            type="modalSave"
            style={{ padding: "10px 30px" }}
            className="btn  ht-40"
            onClick={() => {
              setHasSubmitted(true);
              const updatedValidations = validateFields();
              if (
                !updatedValidations.name ||
                !updatedValidations.type ||
                !updatedValidations.selectedPermissions
              ) {
                return;
              }
              onSave({
                roleId: item.roleId,
                name,
                isFranchise,
                type: parseInt(selectedType),
                permissions: selectedPermissions.map((item) => {
                  return item.id !== "canViewAllFacilityInvoices"
                    ? {
                        permissionName: item.value,
                        permissionId: item.id,
                        type: item.type,
                      }
                    : {
                        permissionName: item.value,
                        permissionId: item.id,
                        type: item.type,
                        facilities:
                          selectedFacilities.map((el) => el.value) || [],
                      };
                }),
              });
            }}
          >
            {/* {type === 'add' ? 'Add' : 'Edit'} item */}
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            <span>{isLoading ? "Loading..." : "SAVE"}</span>
          </Button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default RolesAddEdit;
