import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import EntityAddEdit from "../../components/AddEditItems/EntityAddEdit";

import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/people-fill.svg";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_ENTITIES_URL,
  GET_FACILITIES_URL,
  ADD_ENTITIES_URL,
  EDIT_ENTITIES_URL,
  DELETE_ENTITIES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const entityModel = {
  companyName: "",
  companyLogo: "",
  companyType: "",
  isPartner: false,
  address: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  firstName: "",
  lastName: "",
  companyId: undefined,
  isActive: false,
  businessLicense: "",
  ein: "",
  metalLicense: "",
  facilities: [],
};

// const entityModel={
//     "companyName": "First Organisation",
//     "companyLogo": "http://a.b",
//     "companyType": "BUYER",
//     "isPartner": true,
//     "address": "company address",
//     "city": "company city",
//     "state": "company state",
//     "zipcode": "560043",
//     "email": "company email",
//     "phone": "company phone",
//     "firstName": "first name",
//     "lastName": "last name"
// }

export const Entities = (props) => {
  const { canDeleteGroup, canEditGroup } = getLoginDetails();
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [facilitiesItems, setFaciltiesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isAddPopupShown, setIsAddPopupShown] = useState(true);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getEntities = async () => {
    try {
      setIsLoading(true);
      // setEntitiesItems([]);

      const data = await getData(GET_ENTITIES_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      });
      setIsLoading(false);

      setEntitiesItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getFacilities();
  }, []);
  useEffect(() => {
    getEntities();
  }, [params.offset, params.text, params.limit]);

  useEffect(() => {
    props.searchVal && onSearchChange(props.searchVal);
  }, [props.searchVal]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const addItem = async (data, image, metalLicenseImg) => {
    let { companyLogo, metalLicense } = data;
    try {
      console.log("metalLicenseImg", metalLicenseImg);
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      if (metalLicenseImg) {
        const metalResponse = await uploadFile(
          FILE_UPLOAD_URL,
          metalLicenseImg.file
        );
        metalLicense = metalResponse.imageUrl;
      }
      const response = await postData(ADD_ENTITIES_URL, {
        ...data,
        companyLogo,
        metalLicense,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      });
      // setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Partner",
        id: new Date().getTime(),
      });
      if(props.location.state && props.location.state.isFromCart) {
        goBack();
      } else {
        getEntities();
      }
    } catch (e) {
      console.log("ERROR", e);
      if (e && e.response && e.response.data && e.response.data.error) {
        setToastParams({
          type: "ERROR",
          message:
            e && e.response.data.error
              ? e.response.data.error
              : "Oops! Something went wrong. Please try again later",
          id: new Date().getTime(),
        });
      }
    }
  };
  const editItem = async (data, image) => {
    console.log(data);
    let { companyLogo } = data;
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      const response = await putData(EDIT_ENTITIES_URL, {
        ...data,
        companyLogo,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the Partner",
        id: new Date().getTime(),
      });
      
      
      if(props.location.state && props.location.state.isFromCart) {
        goBack();
      } else {
        getEntities();
      }
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
      if(props.location.state && props.location.state.isFromCart) {
        goBack();
      }
    }
  };
  const onIsActiveChange = (item, isActive) => {
    editItem({
      ...item,
      isActive,
    });
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_ENTITIES_URL, {
        priceModifierId: itemForDelete.priceModifierId,
      });
      setIsDeletePopupShown(false);
      getEntities();
    } catch (e) {
      console.log("ERROR");
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    props.setRemoveTabs && props.setRemoveTabs(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  const goBack = () => {
    console.log('back')
    props.history.goBack();
  }

  return (
    <PageLayout icon={HeaderIconSvg} heading="Customers" hideSearchBar={true}>
      {!isAddPopupShown && !isEditPopupShown && (
        <div className="flex flex-middle flex-between create-group-button">
          <div className="flex flex-between flex-middle"></div>
          {entitiesItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + entitiesItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          <div
            className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 mb-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
              props.setRemoveTabs(true);
            }}
          >
            <img src={IconAdd} className="ht-30 wt-30 mr-12" />
            <span>CREATE CUSTOMER</span>
          </div>
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && (
        <Table
          scrollTable={true}
          type="entity"
          columns={[
            "Name",
            "Type",
            "Is Partner?",
            "User Name",
            "Address",
            "Is Active?",
          ]}
          // className="wt-600"
          items={entitiesItems.map((entity) => {
            return {
              ...entity,
              displayAddress: `${entity.address || ""} ${entity.city} ${
                entity.state
              } ${entity.zipcode}`,
              name: `${entity.firstName || ""} ${entity.lastName}`,
              displayIsPartner: entity.isPartner ? "YES" : "NO",
              displayIsActive: entity.isActive ? "YES" : "NO",
              link: `/entities/viewGroupId/${entity.companyId}`,
            };
          })}
          keys={[
            "companyName",
            "companyType",
            "displayIsPartner",
            "name",
            "displayAddress",
            "displayIsActive",
          ]}
          widthClasses={[
            "flex-1",
            "flex-1",
            "flex-1",
            "flex-1",
            "flex-1",
            "wt-100",
          ]}
          actions={[canEditGroup && "EDIT", canDeleteGroup && "DELETE", "VIEW"]}
          onEdit={setEdit}
          onDelete={setDelete}
          onChange={onSearchChange}
          onIsActiveChange={onIsActiveChange}
          headerBgWhite={true}
          viewLinkKey="link"
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
          limit={params.limit}

          // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
        />
      )}
      {isAddPopupShown && (
        <EntityAddEdit
          item={{ ...entityModel }}
          isFromCart={props.location.state && props.location.state.isFromCart?true:false}
          onClose={() => {
            if(props.location.state && props.location.state.isFromCart) {
              goBack();
            } else {
              setIsAddPopupShown.bind(null, false)
            }
          }}
          onBack={() => {
            if(props.location.state.isFromCart) {
              goBack();
            } else {
              setIsAddPopupShown.bind(null, false)
            }
          }}
          onSave={addItem}
          type="add"
          shouldShowPriceMod={getLoginDetails().hierarchy === 1}
          facilities={facilitiesItems}
          setRemoveTabs={() => {
            // props.history.push("/entities/viewGroup");
          }}
        />
      )}
      {isEditPopupShown && (
        <EntityAddEdit
          item={{ ...itemForEdit }}
          isFromCart={props.location.state && props.location.state.isFromCart?true:false}
          onClose={() => {
            if(props.location.state && props.location.state.isFromCart) {
              goBack();
            } else {
              setIsEditPopupShown.bind(null, false)
            }
          }}
          onBack={() => {
            console.log('sdvsd1')
            if(props.location.state && props.location.state.isFromCart) {
              goBack();
            } else {
              setIsEditPopupShown.bind(null, false)
            }
          }}
          onSave={editItem}
          type="edit"
          shouldShowPriceMod={getLoginDetails().hierarchy === 1}
          facilities={facilitiesItems}
          setRemoveTabs={props.setRemoveTabs?props.setRemoveTabs:() => {}}
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="company"
          name={itemForDelete.companyName}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Entities;
