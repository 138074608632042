import React from "react";
import Modal from "../Modal";
import Button from "../Button";
const DeleteConfirmation = ({ type, name, onClose, onConfirm }) => {
  return (
    <Modal>
      <div className="pl-16 pr-16">
        <div className="fs-18 fw-bolder t-center mb-20">
          Are you sure you want to delete the {type || ""} {name || ""} ?
        </div>
        <div className="flex flex-middle flex-center">
          <Button className="mr-20 btn  ht-40 btn-red" onClick={onClose}>
            Cancel
          </Button>
          <Button type="modalSave" className="btn  ht-40" onClick={onConfirm}>
            Confirm
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default DeleteConfirmation;
