import React, {Component} from 'react'
import {Launcher} from './react-chat-window'
import { database } from './Firebaseconfig';
import { getLoginDetails } from '../../utils/storage';

import  Logo from '../../icons/logo.png';
 
const USERID = getLoginDetails() && getLoginDetails().userId;
const PROFILEURL =getLoginDetails() &&  getLoginDetails().profileUrl;
const USERNAME = getLoginDetails() && `${getLoginDetails().firstName} ${getLoginDetails().lastName}` ;

export default class Chat extends Component {
 
  constructor() {
    super();
    this.state = {
      messages: []
      // messageList: [{
      //   author: 'them',
      //   type: 'text',
      //   data: { text:"Already existing" }
      // }]
    };
  }
  componentDidMount = ()=> {
    const messagesRef = database.ref(this.props.channel)
      .orderByKey()
      .limitToLast(100);

      messagesRef.on('child_added', snapshot => {
        const message = { 
          text: snapshot.val().text, 
          id: snapshot.key, 
          userId: snapshot.val().userId, 
          userName: snapshot.val().userName,
          profileUrl: snapshot.val().profileUrl 
        };
        this.setState(prevState => ({
          messages: [...prevState.messages, message ],
        }));
      });
  }
  _onMessageWasSent = (message)=> {
    // this.setState({
    //   messages: [...this.state.messages, message]
    // })
    database.ref(this.props.channel).push().set({
      'userId': USERID,
      'userName': USERNAME,
      'profileUrl': PROFILEURL,
      'text': message.data.text
    });
  }
  onFilesSelected = (files)=> {
    console.log("FILES");
  }
  _sendMessage = (text)=> {
    if (text.length > 0) {
      this.setState({
        messages: [...this.state.messages, {
          author: 'me',
          type: 'text',
          data: { text }
        }]
      })
    }
  }
 
  render() {
    return (<div>
      <Launcher
        agentProfile={{
          teamName: 'CHAT',
          imageUrl: {Logo}
        }}
        onMessageWasSent={this._onMessageWasSent.bind(this)}
        messageList={this.state.messages.map((message)=> {
          // console.log("MESSAGE", message);
          return {
            author: (message.userId === USERID)?'me':'them',
            data: {
              text: message.text,
              userName: message.userName,
              profileUrl: message.profileUrl,
            },
            type: 'text'
          }
        })}
        showEmoji
      />
    </div>)
  }
}