import React, { useState } from "react";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

import ImageUploading from "./ImageUpload";
import IconSearch from "../../icons/pos_find.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import CaretIcon from "../../icons/caret.png";
import PlusIcon from "../../icons/pos_plus.png";
import { useEffect } from "react";

const ExpertAdviceAddEdit = ({ item, onClose, onSave, type, onBack }) => {
  const [message, setMessage] = useState(item.message);
  const [marketPrice, setMarketPrice] = useState(item.marketPrice);
  const [basePrice, setBasePrice] = useState(item.basePrice);
  const [comments, setComments] = useState(item.comments);
  const [status, setStatus] = useState(item.status);
  const [image, setImage] = useState(null);
  const [oem, setOEM] = useState(item.oem);
  const [make, setMake] = useState(item.make);
  const [model, setModel] = useState(item.model);

  useEffect(() => {
    setMarketPrice((marketPrice || "").toString().replace(/\D/g,''))
  }, [marketPrice])

  return (
    <div className="mt-8" style={{ overflow: "scroll" }}>
      <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
        <img src={CaretIcon} className="wt-20 rotate-90 op-5" alt="" />
        <span className="fs-12 c-table-header fw-bolder">Back To Advices</span>
      </div>
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="flex flex-middle mr-12">
          <img src={IconSearch} className="ht-30 wt-30 mr-12" alt="" />
          <h1 className="mr-24 fs-20 c-gray">
            {type === "add" ? "ADD" : "EDIT"} EXPERT ADVICE
          </h1>
        </div>
        <div className="flex mb-40 mt-20">
          <FormInput
            inputClassName="bg-blue rounded-pill"
            isReadonly={true}
            placeholder="Company Name"
            className="mr-40  wt-25p"
            value={item.companyName}
            onChange={(e) => {
              setMarketPrice(e.target.value);
            }}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            isReadonly={true}
            placeholder="User Name"
            className="mr-40  wt-25p"
            value={`${item.firstName} ${item.lastName}`}
            onChange={(e) => {
              setBasePrice(e.target.value);
            }}
          />
        </div>
        <div className="flex  flex-middle mb-40 ">
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="Offer Price"
            className="mr-40  wt-25p"
            value={marketPrice}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }
              setMarketPrice(e.target.value);
            }}
          />
          {/*<FormInput placeholder="Base Price" className="mr-40  wt-25p" value={basePrice} onChange={(e)=> { setBasePrice(e.target.value) }} />*/}
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="Comments"
            className="mr-40  wt-25p"
            value={comments}
            onChange={(e) => {
              setComments(e.target.value);
            }}
          />
          {/* <FormInput placeholder="Description" className="mr-40  wt-25p" value={description} onChange={(e)=> { setDescription(e.target.value) }} /> */}
          {/* <div className="c-gray fs-14">Upload Images</div> */}
          <div className="wt-25p">
            <div className="c-gray fs-14 mb-4">Status</div>
            <select
              name="qty"
              className="select font-primary fs-14 fRegular rounded-pill bg-blue"
              value={status}
              onChange={(e) => {
                const id = e.target.value;
                setStatus(parseInt(id));
              }}
            >
              <option value={1}>PENDING</option>
              <option value={2}>RESOLVED</option>
              <option value={3}>REJECTED</option>
            </select>
          </div>
        </div>
        <div className="flex mb-40 mt-20">
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="OEM"
            className="mr-40  wt-25p"
            value={oem}
            onChange={(e) => {
              setOEM(e.target.value);
            }}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="Make"
            className="mr-40  wt-25p"
            value={make}
            onChange={(e) => {
              setMake(e.target.value);
            }}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="Model"
            className="mr-40  wt-25p"
            value={model}
            onChange={(e) => {
              setModel(e.target.value);
            }}
          />
        </div>
        <div className="flex wt-75p" style={{ width: "82%" }}>
          <div>
        <div className="c-gray fs-14 mb-4">Picture</div><b/>
          <div className="mr-8" style={{ width: "30%", marginRight: "50px" }}>
            <ImageUploading onChange={setImage} initialImage={item.url || ""} />
          </div>
          </div>
          <div className="mr-40" style={{ width: "70%" }}>
            <div className="c-gray fs-14 mb-4">Description</div>

            <textarea
              style={{ borderRadius: "10px", width: "100%" }}
              rows="8"
              placeholder="Message"
              className="mr-40 wt-100p pl-8 pt-8 bg-blue"
              value={message}
              onChange={(e) => {
                setMessage(e.target.value);
              }}
            />
          </div>
        </div>
        <div
          className="flex flex-middle flex-right"
          style={{ width: "82%", marginTop: "20px" }}
        >
          {/* <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button> */}
          <Button
            type="modalCancel"
            className="flex ht-40 flex-middle mr-20 btn btn-red"
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            type="modalSave"
            className="flex  ht-40 flex-middle btn"
            onClick={() => {
              onSave({
                message,
                marketPrice,
                basePrice,
                comments,
                status,
                ticketId: item.ticketId,
                make,
                oem,
                model,
              });
            }}
          >
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            {/* <span>{type === 'add'?'Add': 'Edit'} Item</span> */}
            <span>SAVE</span>
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ExpertAdviceAddEdit;
