import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import ExpertAdviceAddEdit from "../../components/AddEditItems/ExpertAdviceAddEdit";

import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import ExpertIcon from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_EXPERT_ADVICES_URL,
  EDIT_EXPERT_ADVICES_URL,
  DELETE_EXPERT_ADVICES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const makeSample = {
  make: "",
  model: [],
  description: "",
  imglUrls: [],
  videoUrls: [],
  documentUrls: [],
};

export const ExpertAdvice = (props) => {
  const [expertAdviceList, setExpertAdviceList] = useState([]);
  const [activeView, setActiveView] = useState(1);
  const [isLoading, setIsLoading] = useState(false);

  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);

  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getExpertAdvices = async () => {
    try {
      const data = await getData(GET_EXPERT_ADVICES_URL, {
        status: activeView,
        count: params.limit,
        offset: params.offset,
        text: `${params.text || ""}`,
      });
      setIsLoading(false);
      setExpertAdviceList(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getExpertAdvices();
  }, [params.offset, params.limit, params.text, activeView]);

  // useEffect(()=> {
  //     getEntities();
  // }, [params.offset]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const editItem = async (data, image) => {
    let url = data.url;
    if (image) {
      const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      url = response.imageUrl;
    }
    try {
      setIsLoading(true);
      const response = await putData(EDIT_EXPERT_ADVICES_URL, { ...data, url });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Make",
        id: new Date().getTime(),
      });
      getExpertAdvices();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_EXPERT_ADVICES_URL, {
        ticketId: itemForDelete.ticketId,
      });
      setIsDeletePopupShown(false);
      getExpertAdvices();
    } catch (e) {
      console.log("ERROR");
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  const getTime = (date) => {
    const createdDate = new Date(date);
    const dd = String(createdDate.getDate()).padStart(2, "0");
    const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
    const yyyy = createdDate.getFullYear();
    let hours = createdDate.getHours();
    let minutes = createdDate.getMinutes();
    const ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    return `${mm}-${dd}-${yyyy}  ${hours}:${minutes}${ampm}`;
  }

  return (
    <PageLayout
      icon={ExpertIcon}
      onSearchChange={onSearchChange}
      heading="Identification Queue"
      searchPlaceholder="Search"
    >
      {!isAddPopupShown && !isEditPopupShown && (
        <div className=" flex flex-middle mt-8 flex-between">
          <div className=" tabs flex">
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 1
                        ? "bg-lt-gray-1 c-gray fw-bolder bg-white"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(1);
              }}
            >
              <span>Pending</span>
            </div>
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 2
                        ? "bg-lt-gray-1 c-gray fw-bolder bg-white"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(2);
              }}
            >
              <span>Resolved</span>
            </div>
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 3
                        ? "bg-lt-gray-1 c-gray fw-bolder bg-white"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(3);
              }}
            >
              <span>Rejected</span>
            </div>
          </div>
          {expertAdviceList.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + expertAdviceList.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          {/* <div className="flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer" onClick={()=> {setIsAddPopupShown(true)}}>
                    <img src={IconAdd} className="ht-30 wt-30 mr-12" />
                    <span>ADD MAKE</span>
                </div> */}
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && (
        <Table
          className="identifyConvertersTable"
          // type="entity"
          columns={[
            "Company",
            "Message",
            "Name",
            // 'Base Price',
            "Offer Price",
            "Created",
            "Resolved"
          ]}
          // className="wt-600"
          items={expertAdviceList.map((expertAdvice) => {
            return {
              ...expertAdvice,
              createdDateDisplay: expertAdvice.createdDate ? getTime(expertAdvice.createdDate) : "",
              ticketResTime: expertAdvice.ticketResTime ? getTime(expertAdvice.ticketResTime) : "",
              userName: `${expertAdvice.firstName} ${expertAdvice.lastName}`,
            };
          })}
          keys={["companyName", "message", "userName", "marketPrice", "createdDateDisplay", "ticketResTime"]}
          widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]}
          actions={["EDIT", "DELETE"]}
          onEdit={setEdit}
          onDelete={setDelete}
          onChange={onSearchChange}
          headerBgWhite={true}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          hideCheckBox={true}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
          limit={params.limit}

          // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
        />
      )}

      {/* {isAddPopupShown &&
                <MakeAddEdit
                    item={{...makeSample}}
                    onClose = {setIsAddPopupShown.bind(null, false)}
                    onBack = {setIsAddPopupShown.bind(null, false)}
                    onSave = {addItem}
                    type="add"
                />
            }*/}
      {isEditPopupShown && (
        <ExpertAdviceAddEdit
          item={{ ...itemForEdit }}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="ticket"
          name={itemForDelete.ticketId}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default ExpertAdvice;
