import React, { useState, useEffect, useRef } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import FormInput from "../../components/FormInput";

import OutsideClickHandler from "react-outside-click-handler";

import ConvertersAddEdit from "../../components/AddEditItems/ConvertersAddEdit";
import GraphDisplay from "../../components/AddEditItems/GraphDisplay";
import RupeeView from "../../components/AddEditItems/RupeeView";
import moment from "moment";
import IconAddConverter from "../../icons/rev_add_converter.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";

import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import { getLoginDetails } from "../../utils/storage";

import {
  GET_CONVERTERS_URL,
  GET_MAKE_MODEL_URL,
  DELETE_CONVERTERS_URL,
  ADD_CONVERTERS_URL,
  EDIT_CONVERTERS_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_PRICE_HISTORY_URL,
  GET_CONVERTER_CART_URL,
  ADD_ITEMS_TO_CONV_CART_URL,
  FILE_UPLOAD_URL,
  DOWNLOAD_CONVERTER_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

const converterAddModel = {
  serialNo: "",
  abCode: "",
  abSubcode: "",
  manufacturer: "",
  description: "",
  oem: "",
  subweight: "",
  ptAssay: "",
  pdAssay: "",
  rhAssay: "",
  comment: "",
  urls: [],
  ptDisc: "",
  pdDisc: "",
  rhDisc: "",
  schemeDisc: "",
  price2Modifier: "",
  ptDiscIsPercentage: true,
  pdDiscIsPercentage: true,
  rhDiscIsPercentage: true,
  schemeDiscIsPercentage: true,
  price2ModifierIsPercentage: true,
};

const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};

const Converters = () => {
  const { canAddConverter, canDeleteConverter, isSA } = getLoginDetails();
  const [priceModifierItems, setPriceModifierItems] = useState([]);
  const [makeAndModelList, setMakeAndModelList] = useState([]);
  const [historyGraphItems, setHistoryGraphItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(false);
  const [isAddPopupShown, setIsAddPopupShown] = useState(true);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [isGraphPopupShown, setIsGraphPopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [itemForGraph, setItemForGraph] = useState(null);
  const [points, setPoints] = useState([]);
  const [rupeeView, setRupeeView] = useState(false);
  const [productDetail, setProductDetail] = useState({});

  const [showCartItems, setShowCartItems] = useState(false);
  const [cartItemsList, setCartItemsList] = useState([]);
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const [box, setBox] = useState(0);
  const [quantity, setQuatity] = useState(1);
  const [unit, setUnit] = useState(1);

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    text: "",
    areMoreItemsAvailable: false,
  });

  const [cartItemParams, setCartItemParams] = useState({
    limit: 10,
    offset: 0,
    text: null,
    areMoreItemsAvailable: false,
  });

  const [graphParams, setGraphParams] = useState({
    limit: 10,
    offset: 0,
  });

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getProductDetail = async (item) => {
    try {
      setIsLoading(true);
      const data = await getData(GET_PRODUCT_DETAILS_URL, {
        productId: item.productId,
      });
      setProductDetail(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, { count: 100 });
      setIsLoading(false);
      setMakeAndModelList(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getConverters = async () => {
    try {
      setIsLoading(true);
      setPriceModifierItems([]);
      const data = await getData(GET_CONVERTERS_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
      });
      setIsLoading(false);
      setPriceModifierItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const isFirstRun = useRef(true);
  useEffect(() => {
    if (isFirstRun.current) {
      // isFirstRun.current = false;
      return;
    }
    getCartItems();
  }, [cartItemParams.offset, cartItemParams.text, cartItemParams.limit]);
  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    getConverters();
    // getMakeAndModel();
  }, [params.offset, params.text, params.limit]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const fetchMoreCartItems = () => {
    setCartItemParams({
      ...cartItemParams,
      offset: cartItemParams.offset + cartItemParams.offset,
    });
  };
  const fetchLessCartItems = () => {
    setCartItemParams({
      ...cartItemParams,
      offset: cartItemParams.offset - cartItemParams.offset,
    });
  };

  const getHistoryForGraph = async (productId) => {
    try {
      const data = await getData(GET_PRICE_HISTORY_URL, {
        productId: productId,
        count: graphParams.limit,
        offset: graphParams.offset,
      });
      // const pts = {abListPrice: [], abPublishPrice: []};
      const pts = [["Date", "List Price", "Publish Price"]];

      (data.list || []).map((obj, index) => {
        // pts.abListPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abListPrice)])
        // pts.abPublishPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abPublishPrice)])
        pts.push([
          moment(obj.date).format("Do, MMM YY, hh:mm A"),
          parseFloat(obj.abListPrice),
          parseFloat(obj.abPublishPrice),
        ]);
      });
      setPoints(pts);
      setHistoryGraphItems(data.list);
      setIsGraphPopupShown(true);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const addItem = async (data, images) => {
    let urls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        return uploadFile(FILE_UPLOAD_URL, image.file);
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      urls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await postData(ADD_CONVERTERS_URL, { ...data, urls });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Converter",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      getConverters();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data, images) => {
    let urls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      urls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await putData(EDIT_CONVERTERS_URL, { ...data, urls });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Conveter",
        id: new Date().getTime(),
      });
      getConverters();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const getCartItems = async (item) => {
    try {
      // setIsLoading(true);
      const data = await getData(GET_CONVERTER_CART_URL, {
        count: cartItemParams.limit,
        offset: cartItemParams.offset,
        type: 1,
      });
      // setIsLoading(false);
      setCartItemsList(data.list);
      setShowCartItems(item ? item : showCartItems);
      setCartItemParams({
        ...cartItemParams,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const downloadConerterDetailDump = async () => {
    try {
      // setIsLoading(true);
      const data = await getData(DOWNLOAD_CONVERTER_URL);
      if (data && data.link) {
        const element = document.createElement("a");
        element.setAttribute("href", data.link);
        element.setAttribute("download", true);

        element.click();
      }
      // setIsLoading(false);
      console.log("data", data);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const updateSelectCartItem = async () => {
    try {
      if (!quantity || !unit) {
        setToastParams({
          type: "ERROR",
          message: "Please enter valid count for unit and quantity",
          id: new Date().getTime(),
        });
        return;
      }
      setIsLoading(true);
      const data = await postData(ADD_ITEMS_TO_CONV_CART_URL, {
        productId: showCartItems.productId,
        oem: showCartItems.oem,
        description: showCartItems.description,
        url:
          (showCartItems.urls || []).length > 0 ? showCartItems.urls[0] : " ",
        type: showCartItems.type,
        loadId: selectedCartItem.loadId,
        basePrice: showCartItems.basePrice,
        adjustedPrice: showCartItems.basePrice,
        isAddToLoad: true,
        box,
        quantity,
        unit,
      });
      setIsLoading(false);
      setToastParams({
        type: "SUCCESS",
        message:
          data && data.message ? data.message : "Successfully added item",
        id: new Date().getTime(),
      });
      setShowCartItems(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error ? e.error : "Couldn't add to cart, please try again.",
        id: new Date().getTime(),
      });
    }
  };
  const handleCartItemSelect = (item) => {
    setSelectedCartItem(item);
  };
  const deleteItem = async () => {
    try {
      setIsLoading(true);
      const response = await deleteData(DELETE_CONVERTERS_URL, {
        productId: itemForDelete.productId,
      });
      setIsDeletePopupShown(false);
      getConverters();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const setView = (item) => {
    setRupeeView(true);
    getProductDetail(item);
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };
  const showGraph = (item) => {
    setItemForGraph(item);
    getHistoryForGraph(item.productId);
  };
  const currentUrl = window.location.pathname;
  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Converters"
      searchPlaceholder="Search Converters"
      hideSearchBar={true}
    >
      {!isAddPopupShown && !isEditPopupShown && (
        <div className="flex flex-middle mb-16 mt-8 flex-between">
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW CONVERTERS</h1> */}
          </div>
          {priceModifierItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) &&
            (params.text || "").length > 0 && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div
                  style={{
                    backgroundColor: "#4862f1",
                    width: "40px",
                    transform: "scale(0.9)",
                    marginRight: "-10px",
                  }}
                  className="ht-20 rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4"
                >
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + priceModifierItems.length}
                </span>
                <div
                  style={{
                    backgroundColor: "#4862f1",
                    width: "40px",
                    transform: "scale(0.9)",
                    marginLeft: "-10px",
                  }}
                  className="ht-20 rounded-circle flex flex-center flex-middle pl-8 pb-4 pt-4"
                >
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          <div className="flex">
            {isSA && (
              <div
                className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mr-12"
                onClick={() => {
                  downloadConerterDetailDump();
                }}
              >
                Download
              </div>
            )}
            {canAddConverter ? (
              <div
                className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
                onClick={() => {
                  setIsAddPopupShown(true);
                }}
              >
                <img src={IconAddConverter} className="ht-30 wt-30 mr-12" />
                <span>Add Converter</span>
              </div>
            ) : (
              <div></div>
            )}
          </div>
        </div>
      )}
      {!isAddPopupShown &&
        !isEditPopupShown &&
        (priceModifierItems || []).length > 0 &&
        (params.text || "").length > 0 && (
          <Table
            scrollTable={true}
            type="converters"
            columns={[
              "Manufacturer",
              "Description",
              "OEM",
              "OFFER Price",
              "Type",
            ]}
            items={priceModifierItems.map((converter) => {
              return {
                ...converter,
                link: `/converter/${converter.productId}/1`,
                markPrice: `$${converter.marketPrice}`,
                basePriceWithDollar: `$${converter.basePrice}`,
              };
            })}
            keys={[
              "manufacturer",
              "description",
              "oem",
              "basePriceWithDollar",
              "type",
            ]}
            actions={[canDeleteConverter && "DELETE", "VIEW"]}
            viewLinkKey="link"
            isCart={true}
            // widthClasses={['wt-100']}
            onEdit={setEdit}
            onDelete={setDelete}
            onChange={onSearchChange}
            onRupeeView={setView}
            showGraph={showGraph}
            offset={params.offset}
            areMoreItemsAvailable={params.areMoreItemsAvailable}
            fetchMore={fetchMore}
            fetchLess={fetchLess}
            isLoading={isLoading}
            className="min-wt-1000"
            onCountChange={(value) => {
              setParams({
                ...params,
                limit: value,
              });
            }}
            limit={params.limit}
            addCartLabel={"Buy/Sell"}
            hasAddToCart={true}
            handleAddToCart={getCartItems}
          />
        )}
      {isAddPopupShown && (
        <ConvertersAddEdit
          addItem={{ ...converterAddModel }}
          makeAndModelList={makeAndModelList}
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          type="add"
          hideBack={true}
        />
      )}
      {isGraphPopupShown && (
        <GraphDisplay
          item={{
            ...itemForGraph,
            name: itemForGraph.serialNo,
            s1value: "abListPrice",
            s2Value: "abPublishPrice",
          }}
          priceModifierGraphItems={[...points]}
          onClose={setIsGraphPopupShown.bind(null, false)}
          graphType={"Bar"}
          dataItems={[...historyGraphItems]}
          listHeaders={["Publish Price", "List Price", "Date"]}
          listDataKeys={["abPublishPrice", "abListPrice", "date"]}
          showList={true}
          type="graph"
        />
      )}
      {isEditPopupShown && (
        <ConvertersAddEdit
          addItem={{ ...itemForEdit }}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          makeAndModelList={makeAndModelList}
          type="edit"
          hideBack={true}
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="converter"
          name={itemForDelete.name}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      {rupeeView && (
        <RupeeView
          rupeeItems={{ ...productDetail }}
          onClose={setRupeeView.bind(null, false)}
          type="rupee"
        />
      )}

      {showCartItems && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => setShowCartItems(false)}>
            <h2> CARTS </h2>
            <Table
              scrollTable={true}
              columns={[
                "Customer Name",
                "CUSTOMER ",
                "LOCATION",
                "Date",
                "CART NAME",
                "STATUS",
                "ASSISTANT",
                "GRADER",
              ]} //, "Status"]}
              items={cartItemsList.map((load, index) => {
                const createdDate = new Date(
                  load.updatedDate || load.createdDate
                );
                const dd = String(createdDate.getDate()).padStart(2, "0");
                const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yyyy = createdDate.getFullYear();
                let hours = createdDate.getHours();
                let minutes = createdDate.getMinutes();
                const ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                return {
                  ...load,
                  custName: load.firstName + " " + load.lastName,
                  group: load.companyName,
                  location: load.city + ", " + load.state,
                  date: `${mm}-${dd}-${yyyy} ${hours}:${minutes}${ampm}`,
                  // statusVal: STATUS_MAPPING[load.status],
                  statusVal: STATUS_MAPPING[load.status],
                  assistantFullName:
                    (load.assistantFirstName || "") +
                    " " +
                    (load.assistantLastName || ""),
                  videoGraderFullName:
                    (load.videoGraderFirstName || "") +
                    " " +
                    (load.videoGraderLastName || ""),
                };
              })}
              keys={[
                "custName",
                "group",
                "location",
                "date",
                "loadName",
                "statusVal",
                "assistantFullName",
                "videoGraderFullName",
              ]} //, "statusVal"]}
              widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]} //, "flex-1"]}
              isCart={false}
              hideCheckBox={false}
              isSingleSelect={true}
              onItemClick={handleCartItemSelect}
              offset={cartItemParams.offset}
              areMoreItemsAvailable={cartItemParams.areMoreItemsAvailable}
              fetchMore={fetchMoreCartItems}
              fetchLess={fetchLessCartItems}
              isLoading={isLoading}
              onCountChange={(value) => {
                setCartItemParams({
                  ...cartItemParams,
                  limit: value,
                });
              }}
            />
            <div className="flex flex-middle flex-right">
              <FormInput
                placeholder="Box"
                type="number"
                className="mr-20"
                value={box}
                onChange={(e) => {
                  setBox(e.target.value);
                }}
              />
              <FormInput
                placeholder="Unit"
                type="number"
                className="mr-20"
                value={unit}
                onChange={(e) => {
                  setUnit(e.target.value);
                }}
              />
              <FormInput
                placeholder="Quantity"
                type="number"
                className="mr-40"
                value={quantity}
                onChange={(e) => {
                  setQuatity(e.target.value);
                }}
              />
              <Button
                className="mr-20"
                type="secondary"
                onClick={updateSelectCartItem}
              >
                Add to Cart
              </Button>
              <Button
                className="mr-20"
                type="secondary"
                onClick={() => {
                  setShowCartItems(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </OutsideClickHandler>
        </Modal>
      )}

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Converters;
