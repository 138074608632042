import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";

import Toast from "../../components/Toast";

import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import EntityAddEdit from "../../components/AddEditItems/EntityAddEdit";

import {
  GET_ENTITY_DETAIL_URL,
  ADD_USERS_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  uploadFile,
  putData,
  EDIT_ENTITIES_URL,
  GET_FACILITIES_URL
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

export const SettingsConfig = (props) => {
  const { companyId } = getLoginDetails();

  const [facilitiesItems, setFaciltiesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [entity, setEntity] = useState(null);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getEntityDetail = async () => {
    try {
      const data = await getData(GET_ENTITY_DETAIL_URL, {
        companyId: companyId,
      });
      setEntity(data);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    getEntityDetail();
    getFacilities();
  }, []);

  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const editItem = async (data, image, metalLicenseImg) => {
    let { companyLogo, metalLicense } = data;
    setIsLoading(true);
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      if (metalLicenseImg && metalLicenseImg.file) {
        const metalResponse = await uploadFile(
          FILE_UPLOAD_URL,
          metalLicenseImg.file
        );
        metalLicense = metalResponse.imageUrl;
      }
      const response = await putData(EDIT_ENTITIES_URL, {
        ...data,
        companyLogo,
        metalLicense,
        companyId,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the Partner",
        id: new Date().getTime(),
      });
      setIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: e && e.error ? e.error : e.response.data.error,
        id: new Date().getTime(),
      });
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="SETTINGS"
      searchPlaceholder="Search Users"
    >
      {
        entity && <EntityAddEdit
        isLoading={isLoading}
        item={{ ...entity }}
        onClose={() => props.history.push('/entities/viewGroup')}
        onBack={() => props.history.push('/entities/viewGroup')}
        onSave={editItem}
        type="add"
        shouldShowPriceMod={getLoginDetails().hierarchy === 10}
        facilities={facilitiesItems}
        setRemoveTabs={() => {}}
        isFromCart={true}
        history={props.history}
        hideCustomers={true}
      />
      }
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default SettingsConfig;
