import React, {useState} from 'react';

import Toggle from 'react-toggle'


import Modal from '../Modal';
import Button from '../Button';

import FormInput from '../FormInput';
import SelectCheckbox from '../SelectCheckbox';

import CaretIcon from '../../icons/caret.png';




const EntityPricingAddEdit = ({item, onClose, onSave, type, onBack, facilities}) => {
    
    const [groupDiscount, setGroupDiscount] = useState(item.groupDiscount);
    const [facilityDiscounts, setFacilityDiscounts] = useState(facilities.map((facility)=> {
        return {
            ...facility,
            facilityDiscount:((item.facilityDiscount || []).find((item)=> {
                return item.facilityId === facility.facilityId
            }) || {}).discount || 0
        }
    }))

    return (
        // <Modal>
            <div className="mt-8">
                <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
                    <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
                    <span className="fs-12 c-table-header fw-bolder">Back To Customers</span>
                </div>
                <div className="fs-24 fw-bolder mb-40">{type === 'add'?'Add': 'Edit'} Customer Pricing</div>
                {/* <div className="flex flex-middle flex-center">
                    <ImageUploading onChange={setImage} initialImage={item.companyLogo}/>
                </div> */}

                <div className="flex  mb-40 flex-wrap">
                    <FormInput 
                        placeholder="Customer Discount"
                        onChange={(e)=> {
                            setGroupDiscount(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={groupDiscount}

                    />
                    <div className="flex-1">
                        <div className="mb-30">Facility Discounts</div>
                        {
                            (facilityDiscounts || []).map((facility, index)=> {
                                return (
                                    <FormInput 
                                        placeholder={facility.name}
                                        onChange={(e)=> {
                                            const updatedFacilityDiscount = [...facilityDiscounts];
                                            updatedFacilityDiscount[index].facilityDiscount = e.target.value;
                                            setFacilityDiscounts(updatedFacilityDiscount)
                                            // setGroupDiscount(e.target.value);
                                        }}
                                        className="mr-40 wt-33p"
                                        value={facility.facilityDiscount}

                                    />
                                )
                            })
                        }
                    </div>
                </div>
                <div className="flex flex-middle flex-right">
                    <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button>
                    <Button type="modalSave" onClick={()=> {
                        onSave({
                            companyId: item.companyId,
                            groupDiscount: Number(groupDiscount),
                            facilityDiscount: facilityDiscounts.map((facilityDiscount)=> {
                                return {
                                    facilityId: facilityDiscount.facilityId,
                                    discount: Number(facilityDiscount.facilityDiscount)
                                }
                            })
                        })
                    }}
                    >{type === 'add'?'Add': 'Edit'} item</Button>
                </div>
            </div>
        // </Modal>
    )
}

export default EntityPricingAddEdit
