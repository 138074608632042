import React from 'react'
import PageLayout from '../PageLayout';

const UnderConstruction = () => {
    return (
        <PageLayout>
            <div className="page-center ml-100">
                <h1 className="t-center">Under construction</h1>
                <h2 className="t-center">Coming soon</h2>
            </div>
        </PageLayout>
    )
}

export default UnderConstruction
