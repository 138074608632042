import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import UserAddEdit from "../../components/AddEditItems/UserAddEdit";
import EntityPricingAddEdit from "../../components/AddEditItems/EntityPricingAddEdit";

import EntityDetail from "../../components/EntityDetail";
import Toast from "../../components/Toast";

import CaretIcon from "../../icons/caret.png";
import HeaderIcon from "../../icons/newicons/people-fill.svg";

import IconAdd from "../../icons/rev_add_user.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIconPagination from "../../icons/rev_carat.png";

import IconUsersInactive from "../../icons/rev_user.png";
import IconGroupsInactive from "../../icons/rev_user-group.png";
import IconPricingInactive from "../../icons/rev_pricing.png";

import IconUsersActive from "../../icons/pos_user.png";
import IconGroupsActive from "../../icons/pos_user-group.png";
import IconPricingActive from "../../icons/pos_pricing.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_USERS_URL,
  GET_FACILITIES_URL,
  GET_ENTITY_DETAIL_URL,
  EDIT_ENTITIES_URL,
  ADD_USERS_URL,
  EDIT_USERS_URL,
  DELETE_USERS_URL,
  GET_ROLES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

import PriceModifier from "../PriceModifiers";
import Entities from "../Entities";

const userModel = {
  email: "",
  companyId: "",
  roleId: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  profileUrl: "",
  smsNumber: "",
  notes: "",
  isActive: true,
  driversLicenseUrl: "",
};

// const userModel = {
//     "email": "ABC Organisation",
//     "companyId": "CE079521DDFD49F1B24A5F40202587D8",
//     "roleId": "7ADF59D7D3F1452BBB74DA7C10307D5E",
//     "password": "pass",
//     "firstName": "first name",
//     "lastName": "last name",
//     "phone": "company phone",
//     "address": "company address",
//     "city": "company city",
//     "state": "company state",
//     "zipcode": "560043",
//     "profileUrl": "http://a.b",
//     "smsNumber": "sms number of user",
//     "notes": "some notes - text area"
// }

const nameMapping = {};

export const Users = (props) => {
  const { canCreateFranchise, canDeleteUser } = getLoginDetails();

  const companyId = props.match.params.entityId;
  const [facilitiesItems, setFaciltiesItems] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [rolesItems, setRolesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [entity, setEntity] = useState(null);
  const [activeView, setActiveView] = useState(1);
  const [breadcrumbs, setBreadcrumbs] = useState([companyId]);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [isEntityEditPopupShown, setIsEntityEditPopupShown] = useState(false);
  const [removeTabs, setRemoveTabs] = useState(false);
  const hidePricing = true;

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getRolesAndPermissions = async () => {
    try {
      const data = await getData(GET_ROLES_URL, {
        count: params.limit,
        offset: params.offset,
        companyId: props.match.params.entityId,
      });
      setRolesItems(
        data.list.map((role, index) => {
          return {
            id: role.roleId,
            value: role.name,
          };
        })
      );
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getEntityDetail = async () => {
    try {
      const data = await getData(GET_ENTITY_DETAIL_URL, {
        companyId: props.match.params.entityId,
      });
      nameMapping[props.match.params.entityId] = data.companyName;
      setEntity(data);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getUsers = async () => {
    try {
      setIsLoading(true);
      setUserItems([]);
      const data = await getData(GET_USERS_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        companyId: props.match.params.entityId,
        isEmbed: true,
      });
      setIsLoading(false);
      setUserItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getUsers();
  }, [params.offset, params.text, params.limit, props.match.params.entityId]);

  useEffect(() => {
    getRolesAndPermissions();
    getFacilities();
  }, []);
  useEffect(() => {
    getEntityDetail();
    console.log("BREAD CRUMBS", breadcrumbs, props.match.params.entityId);
    const entityIndex = breadcrumbs.findIndex((breadcrumb) => {
      return breadcrumb === props.match.params.entityId;
    });
    if (entityIndex === -1) {
      setBreadcrumbs([...breadcrumbs, props.match.params.entityId]);
    } else {
      setBreadcrumbs(breadcrumbs.slice(0, entityIndex + 1));
    }
  }, [props.match.params.entityId]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const addItem = async (data, image, driversLicenseImg) => {
    let { profileUrl, driversLicenseUrl } = data;
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        profileUrl = response.imageUrl;
      }
      if (driversLicenseImg) {
        const response = await uploadFile(
          FILE_UPLOAD_URL,
          driversLicenseImg.file
        );
        driversLicenseUrl = response.imageUrl;
      }
      const response = await postData(ADD_USERS_URL, {
        ...data,
        profileUrl,
        driversLicenseUrl,
        companyId: props.match.params.entityId,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the User",
        id: new Date().getTime(),
      });
      getUsers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data, image, driversLicenseImg) => {
    let { profileUrl, driversLicenseUrl } = data;
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        profileUrl = response.imageUrl;
      }
      if (driversLicenseImg) {
        const response = await uploadFile(
          FILE_UPLOAD_URL,
          driversLicenseImg.file
        );
        driversLicenseUrl = response.imageUrl;
      }
      const response = await putData(EDIT_USERS_URL, {
        ...data,
        profileUrl,
        driversLicenseUrl,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the User",
        id: new Date().getTime(),
      });
      getUsers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const onIsActiveChange = (item, isActive) => {
    editItem({
      ...item,
      isActive,
    });
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_USERS_URL, {
        priceModifierId: itemForDelete.priceModifierId,
        userId: itemForDelete.userId,
        companyId: itemForDelete.companyId,
      });
      setIsDeletePopupShown(false);
      getUsers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    console.log("item", item);
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };
  const editEntity = async (data) => {
    try {
      const response = await putData(EDIT_ENTITIES_URL, {
        ...data,
      });
      setIsEditPopupShown(false);
      setIsEntityEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the Pricing",
        id: new Date().getTime(),
      });
      getEntityDetail();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const formatUsersView = () => {
    return (
      <>
        {!isAddPopupShown && !isEditPopupShown && (
          <div className="f-Right">
            <div
              className="btn ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={() => {
                setIsAddPopupShown(true);
              }}
              style={{ marginTop: "-40px" }}
            >
              <img src={IconAdd} className="ht-20 wt-20 mr-8" />
              <span>Create user</span>
            </div>
          </div>
        )}
        {false && !isAddPopupShown && !isEditPopupShown && (
          <div className="flex flex-middle mb-8 mt-8 flex-between">
            <div className="flex flex-between flex-middle">
              {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                        <h1 className="mr-24 fs-20 c-gray">VIEW USERS</h1> */}
            </div>
            {userItems.length > 0 &&
              (params.offset >= 10 || params.areMoreItemsAvailable) && (
                <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                  <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                    <img
                      src={CaretIconPagination}
                      alt=""
                      className={`wt-20 rotate-90 c-pointer ${
                        params.offset > 0 ? "op-7" : "op-2"
                      }`}
                      onClick={params.offset > 0 ? fetchLess : () => {}}
                    />
                  </div>
                  <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                    Showing {params.offset + 1} -{" "}
                    {params.offset + userItems.length}
                  </span>
                  <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                    <img
                      src={CaretIconPagination}
                      alt=""
                      className={`wt-20 rotate--90 c-pointer ${
                        params.areMoreItemsAvailable ? "op-7" : "op-2"
                      }`}
                      onClick={
                        params.areMoreItemsAvailable ? fetchMore : () => {}
                      }
                    />
                  </div>
                </div>
              )}
            <div className="flex flex-between flex-middle" />
            {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          </div>
        )}
        {!isAddPopupShown && !isEditPopupShown && (
          <Table
            scrollTable={true}
            type="user"
            columns={[
              "Name",
              "Email",
              "Address?",
              "Role",
              "Is Admin",
              "Is Active?",
            ]}
            // className="wt-600"
            items={userItems.map((entity) => {
              return {
                ...entity,
                displayAddress: `${entity.address || ""} ${entity.city || ""} ${
                  entity.state || ""
                } ${entity.zipcode || ""}`,
                name: `${entity.firstName || ""} ${entity.lastName || ""}`,
                displayIsActive: entity.isActive ? "YES" : "NO",
                displayIsAdmin: entity.isAdmin ? "YES" : "NO",
              };
            })}
            keys={[
              "name",
              "email",
              "displayAddress",
              "type",
              "displayIsAdmin",
              "displayIsActive",
            ]}
            widthClasses={[
              "flex-1",
              "flex-1",
              "flex-1",
              "flex-1",
              "wt-100",
              "wt-100",
            ]}
            headerBgWhite={true}
            actions={["EDIT", canDeleteUser && "DELETE"]}
            onEdit={setEdit}
            onDelete={setDelete}
            onChange={onSearchChange}
            onIsActiveChange={onIsActiveChange}
            onCountChange={(value) => {
              setParams({
                ...params,
                limit: value,
                offset: 0,
              });
            }}
            offset={params.offset}
            areMoreItemsAvailable={params.areMoreItemsAvailable}
            fetchMore={fetchMore}
            fetchLess={fetchLess}
            isLoading={isLoading}

            // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
          />
        )}

        {isAddPopupShown && (
          <UserAddEdit
            item={{ ...userModel, phone: entity.phone, address: entity.address, city: entity.city, state: entity.state, zipcode: entity.zipcode }}
            onClose={setIsAddPopupShown.bind(null, false)}
            onBack={setIsAddPopupShown.bind(null, false)}
            onSave={addItem}
            type="add"
            roles={rolesItems}
            selected={[]}
          />
        )}
        {isEditPopupShown && (
          <UserAddEdit
            item={{ ...itemForEdit }}
            onClose={setIsEditPopupShown.bind(null, false)}
            onBack={setIsEditPopupShown.bind(null, false)}
            onSave={editItem}
            type="edit"
            roles={rolesItems}
            selected={[
              {
                id: itemForEdit.roleId,
                value: (
                  rolesItems.find((role) => {
                    return role.id === itemForEdit.roleId;
                  }) || {}
                ).value,
              },
            ]}
          />
        )}
        {isDeletePopupShown && (
          <DeleteConfirmation
            type="user"
            name={itemForDelete.name}
            onClose={setIsDeletePopupShown.bind(null, false)}
            onConfirm={deleteItem}
          />
        )}
        <Toast
          type={toastParams.type}
          message={toastParams.message}
          id={toastParams.id}
        />
      </>
    );
  };

  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Users"
      searchPlaceholder="Search Users"
    >
      <div
        className="flex flex-middle c-pointer"
        onClick={() => {
          // try {
          //     props.history.goBack();
          // } catch(e) {
          // }
        }}
      >
        {/* <img src={CaretIcon} className="wt-20 rotate-90 op-5 pr-8" /> */}
        {/* <span className="fs-12 c-table-header fw-bolder">BACK TO CUSTOMERS</span> */}
        {/* <Link to={`/entities`} className="fs-12 c-table-header fw-bolder pr-8 decoration-none border-right">Back to Customer</Link> */}
        {/*
                    breadcrumbs.map((companyId)=> {
                        return (<Link to={`/users/${companyId}`} className="fs-12 c-table-header fw-bolder pl-8 pr-8 border-right decoration-none">{nameMapping[companyId] || companyId}</Link>);
                    })
                */}
      </div>
      {entity && !isAddPopupShown && !isEditPopupShown && (
        <div>
          {/* <h1 className="mr-24 fs-20">Partner Detail</h1> */}
          {entity && (
            <EntityDetail
              entity={entity}
              editEntity={editEntity}
              match={props.match}
            />
          )}
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && !removeTabs && (
        <div className={`flex p-absolute`}>
          {false && (
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 1
                        ? "bg-white c-gray fw-bolder"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(1);
              }}
            >
              {activeView !== 1 && (
                <img src={IconUsersInactive} className="wt-30 mr-8" />
              )}
              {activeView === 1 && (
                <img src={IconUsersActive} className="wt-30 mr-8" />
              )}
              <span>USERS</span>
            </div>
          )}
          {!hidePricing && (
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 2
                        ? "bg-white c-gray fw-bolder"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(2);
              }}
            >
              {activeView !== 2 && (
                <img src={IconPricingInactive} className="wt-30 mr-8" />
              )}
              {activeView === 2 && (
                <img src={IconPricingActive} className="wt-30 mr-8" />
              )}
              <span>PRICING</span>
            </div>
          )}
          {false && canCreateFranchise && (
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer
                    ${
                      activeView === 3
                        ? "bg-white c-gray fw-bolder"
                        : "bg-lt-gray-2 c-white border-gray"
                    }
                `}
              onClick={() => {
                setActiveView(3);
              }}
            >
              {activeView !== 3 && (
                <img src={IconGroupsInactive} className="wt-30 mr-8" />
              )}
              {activeView === 3 && (
                <img src={IconGroupsActive} className="wt-30 mr-8" />
              )}
              <span>FRANCHISE</span>
            </div>
          )}
        </div>
      )}
      {activeView === 1 && formatUsersView()}
      {activeView === 2 && !isEntityEditPopupShown && (
        <div className="">
          {/* <PriceModifier {...props} parentCompanyId={companyId}/> */}
          <Table
            scrollTable={true}
            // type="entityDetailEmbed"
            columns={["Customer Discount", "Facilities", "Facilities Discount"]}
            // className="wt-600"
            items={[
              {
                groupDiscount: entity.groupDiscount || 0,
                facilities: `semicolon-separated-inline-${facilitiesItems
                  .filter((facility) => {
                    return (entity.facilities || []).includes(
                      facility.facilityId
                    );
                  })
                  .map((facility) => {
                    return facility.name;
                  })
                  .join(";")}`,
                facilityDiscount: `semicolon-separated-inline-${facilitiesItems
                  .filter((facility) => {
                    return (entity.facilities || []).includes(
                      facility.facilityId
                    );
                  })
                  .map((refFacility) => {
                    const facility = (entity.facilityDiscount || []).find(
                      (facility) => {
                        return refFacility.facilityId === facility.facilityId;
                      }
                    );
                    return (facility || {}).discount || 0;
                  })
                  .join(";")}`,
              },
            ]}
            keys={["groupDiscount", "facilities", "facilityDiscount"]}
            // widthClasses={['flex-1', 'flex-1', 'flex-1']}

            actions={["EDIT", "DELETE"]}
            onEdit={setIsEntityEditPopupShown.bind(null, true)}
            onDelete={setDelete}
            onChange={onSearchChange}
            onIsActiveChange={onIsActiveChange}
            offset={params.offset}
            areMoreItemsAvailable={params.areMoreItemsAvailable}
            fetchMore={fetchMore}
            fetchLess={fetchLess}
            isLoading={isLoading}

            // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
          />
        </div>
      )}
      {activeView === 3 && (
        <div className="t-ml--75 d-ml--275 mt--80">
          <Entities
            {...props}
            parentCompanyId={companyId}
            isEmbed={true}
            setRemoveTabs={setRemoveTabs}
          />
        </div>
      )}
      {isEntityEditPopupShown && activeView === 2 && (
        <EntityPricingAddEdit
          item={{ ...entity }}
          onClose={setIsEntityEditPopupShown.bind(null, false)}
          onBack={setIsEntityEditPopupShown.bind(null, false)}
          onSave={editEntity}
          // onSave = {(item)=> {
          //     console.log("ITEMMMMMMMMM", item);
          // }}
          type="edit"
          facilities={facilitiesItems.filter((facility) => {
            return (entity.facilities || []).includes(facility.facilityId);
          })}
          editType="pricing"
        />
      )}
    </PageLayout>
  );
};

export default Users;
