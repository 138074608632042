import React, { useState, useEffect } from "react";
import Toggle from "react-toggle";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

import ImageUploading from "./ImageUpload";
import IconSearch from "../../icons/pos_find.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import CaretIcon from "../../icons/caret.png";

import {
  MULTIPLE_FILE_UPLOAD_URL,
  GET_CONVERTER_TYPES_URL,
  getData,
  uploadFile,
} from "../../utils/api";

const ConvertersAddEdit = ({
  addItem,
  makeAndModelList,
  onClose,
  onSave,
  type,
  onBack,
  hideBack=false
}) => {
  const [item, setItem] = useState(addItem);
  const [image, setImage] = useState(null);
  const [convoTypes, setConvoTypes] = useState([]);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  useEffect(() => {
    getConverterTypes();
  }, []);

  const getConverterTypes = async () => {
    try {
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };

  const uploadFiles = async (e) => {
    try {
      const response = await uploadFile(
        MULTIPLE_FILE_UPLOAD_URL,
        e.target.files
      );
      if (response && response.url) {
        item.urls = response.url;
        setItem(item);
      }
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Partner",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  return (
    // <Modal>
    <div className="mt-8" style={{ overflow: "scroll" }}>
      {
        !hideBack?<div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
        <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
        <span className="fs-12 c-table-header fw-bolder">
        Back To Converters
        </span>
      </div>:null
      }
      
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="flex flex-middle mr-12">
          <img src={IconSearch} className="ht-30 wt-30 mr-12" />
          <h1 className="mr-24 fs-20 c-gray">
            {type === "add" ? "ADD" : "EDIT"} CONVERTER
          </h1>
        </div>
        <div className="flex flex-between flex-middle mb-40 mt-20 flex-wrap">
          {/* <FormInput   inputClassName="rounded-pill bg-blue" placeholder="Name" className="mr-40 wt-33p" value={item.name} onChange={(e)=> { setItem({...item, name: e.target.value}) }} />
                    <FormInput   inputClassName="rounded-pill bg-blue" placeholder="Serial No." className="mr-40 wt-33p" value={item.serialNo} onChange={(e)=> { setItem({...item, serialNo: e.target.value}) }} />
                    <FormInput   inputClassName="rounded-pill bg-blue" placeholder="AB Code" className="mr-40 wt-33p" value={item.abCode} onChange={(e)=> { setItem({...item, abCode: e.target.value}) }} />
                    <FormInput   inputClassName="rounded-pill bg-blue" placeholder="AB Subcode" className="mr-40 wt-33p" value={item.abSubcode} onChange={(e)=> { setItem({...item, abSubcode: e.target.value}) }} /> */}
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Manufacturer"
            className="wt-33p mr-40"
            value={item.manufacturer}
            onChange={(e) => {
              setItem({ ...item, manufacturer: e.target.value });
            }}
          />

          {/* <div className="wt-33p mr-40">
                        <div className="c-gray fs-14 mb-4">Make</div>
                        <select name="qty" className="select font-primary fs-14 fw-bolder"
                            value={item.makeId}
                            onChange={
                                (e)=> {
                                    const makeId = e.target.value;
                                    const make = makeAndModelList.find((make)=> {
                                        return make.makeId === makeId;
                                    })
                                    setItem({...item, manufacturer: make.make, makeId})
                                }
                            }
                        >
                            <option value={""}>Select the make</option>
                            {
                                (makeAndModelList || []).map((make)=> {
                                    return (
                                        <option value={make.makeId}>{make.make}</option>
                                    )
                                })
                            }
                        </select>
                        
                    </div> */}
          {/* <div className="wt-33p mr-40">
                        <div className="c-gray fs-14 mb-4">Model</div>
                        <select name="qty" className="select font-primary fs-14 fw-bolder"
                            value={item.model}
                            onChange={
                                (e)=> {
                                    const model = e.target.value;
                                    setItem({...item, model})
                                }
                            }
                        >
                            <option value={""}>Select the model</option>
                            {
                                (((makeAndModelList || []).find((make)=> {
                                    return make.makeId === item.makeId
                                }) || {model:[]}) || []).model.map((model)=> {
                                    return (
                                        <option value={model}>{model}</option>
                                    )
                                })
                            }
                        </select>
                        
                    </div> */}
          {/* <FormInput   inputClassName="rounded-pill bg-blue" placeholder="Manufacturer" className="mr-40 wt-33p" value={item.manufacturer} onChange={(e)=> { setItem({...item, manufacturer: e.target.value}) }} /> */}
          {/* <FormInput   inputClassName="rounded-pill bg-blue" placeholder="Model" className="mr-40 wt-33p" value={item.model} onChange={(e)=> { setItem({...item, model: e.target.value}) }} /> */}
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Description"
            className="mr-40 wt-33p"
            value={item.description}
            onChange={(e) => {
              setItem({ ...item, description: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="OEM"
            className="mr-40 wt-33p"
            value={item.oem}
            onChange={(e) => {
              setItem({ ...item, oem: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Sub Weight"
            className="mr-40 wt-33p"
            value={item.subweight}
            onChange={(e) => {
              setItem({ ...item, subweight: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="PT Assay"
            className="mr-40 wt-33p"
            value={item.ptAssay}
            onChange={(e) => {
              setItem({ ...item, ptAssay: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="PD Assay"
            className="mr-40 wt-33p"
            value={item.pdAssay}
            onChange={(e) => {
              setItem({ ...item, pdAssay: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="RH Assay"
            className="mr-40 wt-33p"
            value={item.rhAssay}
            onChange={(e) => {
              setItem({ ...item, rhAssay: e.target.value });
            }}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Comment"
            className="mr-40 wt-33p"
            value={item.comment}
            onChange={(e) => {
              setItem({ ...item, comment: e.target.value });
            }}
          />
          <div className="mr-40 wt-33p">
            <div className="c-gray fs-14 flex flex-between mb-4 flex-middle">
              <span>Family</span>
            </div>
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder=""
              value={item.family}
              onChange={(e) => {
                setItem({ ...item, family: e.target.value });
              }}
            />
          </div>

          <div className="mr-40 wt-33p">
            <div className="c-gray fs-14 flex flex-between mb-4 flex-middle">
              <span>Price</span>
            </div>
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder=""
              value={item.price}
              onChange={(e) => {
                setItem({ ...item, price: e.target.value });
              }}
            />
          </div>
          <div className="mr-40 mt-40 wt-33p">
            <div className="c-gray fs-14 flex flex-between mb-4 flex-middle">
              <span>Override Price</span>
              <div className="flex flex-middle">
                <Toggle
                  className="bg-theme hide-check-cross"
                  onChange={(e) => {
                    setItem({ ...item, isCalculatedPrice: e.target.checked });
                  }}
                  checked={item.isCalculatedPrice}
                />
              </div>
            </div>
          </div>

          {/*<FormInput   inputClassName="rounded-pill bg-blue" placeholder="Type" className="mr-40 wt-33p" value={item.type} onChange={(e)=> { setItem({...item, type: e.target.value}) }} />*/}
          <div className="mr-40 wt-33p">
            <span className="c-gray fs-14 mb-4 flex flex-middle">Select Type </span>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
              value={item.type}
              onChange={(e) => {
                setItem({ ...item, type: e.target.value });
              }}
            >
              <option value={""}>SELECT TYPE</option>
              {(convoTypes || []).map((type) => {
                return <option value={type.id}>{type.name}</option>;
              })}
            </select>
          </div>
          <div>
          <span className="c-gray wt-33p fs-14 mb-4 flex flex-middle">Notes </span>
          <textarea
            placeholder="Notes"
            className="mr-40 bg-blue"
            value={item.notes}
            onChange={(e) => {
              setItem({ ...item, notes: e.target.value });
            }}
            rows="4"
          />
          </div>
          
          <div className="mr-40 wt-33p"></div>
          {/* <div className="mr-40 wt-33p">
                        <input type="file" multiple={true} onChange={(e) => { uploadFiles(e) }} />
                        {item.urls && (item.urls || []).length > 0 && 
                          <div className="mt-8">
                            {(item.urls || []).map((url, index) => { return (
                                <React.Fragment><a href={url} key={index+1} download>File {(index+1)}</a><br/></React.Fragment>
                               )}
                            )}
                          </div>
                        }

                    </div> */}
          <div className="flex flex-middle wt-100p">
            <ImageUploading
              onChange={setImage}
              initialImage={item.profileUrl || ""}
              isMultiple={true}
              initialImages={(item.urls || []).map((url) => {
                return {
                  dataURL: url,
                };
              })}
            />
          </div>
        </div>
        <div className="flex flex-middle flex-right">
          {/* <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button> */}
          <Button
            type="modalCancel"
            className="flex flex-middle mr-20 btn btn-red"
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            type="modalSave"
            className="flex ht-40 flex-middle btn"
            onClick={() => {
              onSave({ ...item }, image);
            }}
          >
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            {/* <span>{type === 'add'?'Add': 'Edit'} Item</span> */}
            <span>SAVE</span>
          </Button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default ConvertersAddEdit;
