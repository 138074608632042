import React, {useState} from 'react'

import Modal from '../Modal';
import Button from '../Button';

import FormInput from '../FormInput';

import CaretIcon from '../../icons/caret.png';
import Tick from '../../icons/rev_confirm.png'
import DeleteIcon from '../../icons/rev_delete.png'


const PriceModifierAddEdit = ({item, onClose, onSave, type, onBack}) => {
    const [name, setName] = useState(item.name);
    const [value, setValue] = useState(item.value);
    const [hasSubmitted, setHasSubmitted] = useState(false);
    const [changePercentage, setChangePercentage] = useState("");

    const [validations, setValidations] = useState({
        nameValidation: false,
        valueValidation: false
    })
    const validateFields = ()=> {
        const updatedValidations = {...validations};

        updatedValidations.name = name;
        updatedValidations.value = !isNaN(Number(value || "TEMP"));
        setValidations(updatedValidations);
        return updatedValidations;
    }
    const getValue = ()=> {
        try {
            if(changePercentage && !isNaN(Number(changePercentage))) {
                return (value*(1+(changePercentage/100))).toFixed(2);
            }
            return value;
        } catch(e) {
            return value;
        }
    }
    return (
        // <Modal>
            <div className="wt-800 mt-8">
                <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
                    <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
                    <span className="fs-12 c-table-header fw-bolder">Back to Pricing</span>
                </div>
                <div className="fs-24 fw-bolder mb-40">{type === 'add'?'ADD': 'EDIT'} PRICE MODIFIER</div>
                <div className="flex flex-between flex-middle mb-40">
                    <FormInput 
                        placeholder="Name"
                        onChange={(e)=> {
                            setName(e.target.value);
                        }}
                        className="mr-40"
                        value={name}
                        hasError={hasSubmitted && !validations.name}
                    />
                    <FormInput 
                        placeholder="Value"
                        onChange={(e)=> {
                            setValue(e.target.value);
                        }}
                        className="mr-40"
                        value={getValue()}
                        hasError={hasSubmitted && !validations.value}

                    />
                    <FormInput 
                        placeholder="+/- %"
                        onChange={(e)=> {
                            setChangePercentage(e.target.value);
                        }}
                        value={changePercentage}
                        hasError={hasSubmitted && !validations.value}

                    />
                </div>
                <div className="flex flex-middle flex-right">
                    <Button className="flex flex-middle mr-20 bg-red" type="modalCancel" onClick={onClose}>
                        {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
                        <span>CANCEL</span>
                    </Button>
                    <Button type="modalSave" onClick={()=> {
                        setHasSubmitted(true);
                        const updatedValidations = validateFields();
                        if(
                            !updatedValidations.name ||
                            !updatedValidations.value
                        ) {
                            return;
                        }
                        onSave({
                            priceModifierId: item.priceModifierId,
                            name, 
                            value: Number(getValue())
                        })
                    }}>
                        {/* {type === 'add' ? 'Add' : 'Edit'} item */}
                        <img src={Tick} className="wt-20 ht-20 mr-4"/>
                        <span>SAVE</span>
                    </Button>
                </div>
            </div>
        // {/* </Modal> */}
    )
}

export default PriceModifierAddEdit
