import React, { useState, useEffect } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

import ImageUploading from "./ImageUpload";
import IconSearch from "../../icons/pos_find.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import CaretIcon from "../../icons/caret.png";
import Checkbox from "../../components/Checkbox";
import Toggle from "react-toggle";

import { getLoginDetails } from "../../utils/storage";

import {
  MULTIPLE_FILE_UPLOAD_URL,
  GET_CONVERTER_TYPES_URL,
  getData,
  uploadFile,
} from "../../utils/api";

const ConverterInfo = ({
  converter,
  convoTypes,
  makeAndModelList,
  onClose,
  onSave,
  type,
  onBack,
  setShowPricingTab,
  showAction = true,
}) => {
  const {
    canEditConverter,
    isSA,
    canViewMarkForTest,
    canAccessAssay = false,
    hierarchy,
  } = getLoginDetails();
  const [item, setItem] = useState(converter);
  const [isImageModalShown, setIsImageModalShown] = useState(false);
  const [image, setImage] = useState(null);
  const [isConfirmSavePopupshown, setIsConfirmSavePopupshown] = useState(false);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const uploadFiles = async (e) => {
    try {
      const response = await uploadFile(
        MULTIPLE_FILE_UPLOAD_URL,
        e.target.files
      );
      if (response && response.url) {
        item.urls = response.url;
        setItem(item);
      }
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Partner",
        id: new Date().getTime(),
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Please add correct details",
        id: new Date().getTime(),
      });
    }
  };

  return (
    // <Modal>
    <div className="pl-8 pt-8 pb-8 bg-lt-gray-3">
      {/* <div className="flex flex-between flex-middle mb-8">
                    <FormInput placeholder="NAME" className="mr-40 wt-33p" value={item.name} onChange={(e)=> { setItem({...item, name: e.target.value}) }} />
                    <FormInput placeholder="SERIAL NO" className="mr-40 wt-33p" value={item.serialNo} onChange={(e)=> { setItem({...item, serialNo: e.target.value}) }} />
                    <FormInput placeholder="AB CODE" className="mr-40 wt-33p" value={item.abCode} onChange={(e)=> { setItem({...item, abCode: e.target.value}) }} />
                    <FormInput placeholder="AB SUBCODE" className="mr-40 wt-33p" value={item.abSubcode} onChange={(e)=> { setItem({...item, abSubcode: e.target.value}) }} />
                </div> */}
      <div className="flex flex-between flex-middle mb-20">
        <FormInput
          placeholder="OEM NUMBER"
          isReadonly={!showAction || !canEditConverter}
          className="mr-40 flex-1"
          value={item.oem}
          onChange={(e) => {
            setItem({ ...item, oem: e.target.value });
          }}
        />
        <FormInput
          placeholder="MANUFACTURER"
          isReadonly={!showAction || !canEditConverter}
          className="mr-40 flex-1"
          value={item.manufacturer}
          onChange={(e) => {
            setItem({ ...item, manufacturer: e.target.value });
          }}
        />
        {/* <div className="flex-1 mr-40">
                        <div className="c-gray fs-14 mb-4">MAKE</div>
                        <select name="qty" className="select font-primary fs-14 fw-bolder"
                            value={item.makeId}
                            onChange={
                                (e)=> {
                                    const makeId = e.target.value;
                                    const make = makeAndModelList.find((make)=> {
                                        return make.makeId === makeId;
                                    })
                                    setItem({...item, manufacturer: make.make, makeId})
                                }
                            }
                        >
                            <option value={""}>Select the make</option>
                            {
                                (makeAndModelList || []).map((make)=> {
                                    return (
                                        <option value={make.makeId}>{make.make}</option>
                                    )
                                })
                            }
                        </select>
                        
                    </div> */}
        {/* <div className="flex-1 mr-40">
                        <div className="c-gray fs-14 mb-4">MODEL</div>
                        <select name="qty" className="select font-primary fs-14 fw-bolder"
                            value={item.model}
                            onChange={
                                (e)=> {
                                    const model = e.target.value;
                                    setItem({...item, model})
                                }
                            }
                        >
                            <option value={""}>Select the model</option>
                            {
                                (((makeAndModelList || []).find((make)=> {
                                    return make.makeId === item.makeId
                                }) || {model:[]}) || []).model.map((model)=> {
                                    return (
                                        <option value={model}>{model}</option>
                                    )
                                })
                            }
                        </select>
                        
                    </div> */}
        <FormInput
          placeholder="DESCRIPTION"
          isReadonly={!showAction || !canEditConverter}
          className="mr-40 flex-1"
          value={item.description}
          onChange={(e) => {
            setItem({ ...item, description: e.target.value });
          }}
        />
        {canAccessAssay && (
          <FormInput
            placeholder="SUB WEIGHT"
            isReadonly={!showAction || !canEditConverter}
            className="mr-40 flex-1"
            value={item.subweight}
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }
              setItem({ ...item, subweight: e.target.value });
            }}
          />
        )}
      </div>
      {(converter.urls || []).length > 1 ? (
        <div className="pl-8 fs-12">
          {(converter.urls || []).length} Images Available
        </div>
      ) : null}
      <div className="flex ">
        <div
          className={`wt-33p pr-40 ${
            (converter.urls || [])[0] ? "" : "border-lt-gray-dashed mr-8"
          }`}
          onClick={() => {
            setIsImageModalShown(true);
          }}
        >
          {(converter.urls || [])[0] &&
          ((converter.urls || [])[0] || "").length > 0 ? (
            <img alt="" src={(converter.urls || [])[0]} className="wt-100p" />
          ) : (
            <div className="pl-60 fs-12 mt-24 pt-52">No Preview Image</div>
          )}
        </div>
        <div className="flex-1">
          {canAccessAssay && (
            <div className="flex flex-between flex-middle mb-20">
              <FormInput
                placeholder="PT ASSAY"
                isReadonly={!showAction || !canEditConverter}
                className="mr-40 flex-1"
                value={item.ptAssay}
                onChange={(e) => {
                  if (isNaN(e.target.value)) {
                    return;
                  }
                  setItem({ ...item, ptAssay: e.target.value });
                }}
              />
              <FormInput
                placeholder="PD ASSAY"
                isReadonly={!showAction || !canEditConverter}
                className="mr-40 flex-1"
                value={item.pdAssay}
                onChange={(e) => {
                  if (isNaN(e.target.value)) {
                    return;
                  }
                  setItem({ ...item, pdAssay: e.target.value });
                }}
              />
              <FormInput
                placeholder="RH ASSAY"
                isReadonly={!showAction || !canEditConverter}
                className="mr-40 flex-1"
                value={item.rhAssay}
                onChange={(e) => {
                  if (isNaN(e.target.value)) {
                    return;
                  }
                  setItem({ ...item, rhAssay: e.target.value });
                }}
              />
            </div>
          )}
          {showAction ? (
            <>
              <div className="c-gray fs-14 mb-4"> Type </div>
              <div className="flex flex-middle mb-20">
                <select
                  name="type"
                  className="select font-primary mr-40 wt-50p fs-14 fw-bolder"
                  value={item.type}
                  onChange={(e) => {
                    setItem({ ...item, type: e.target.value });
                  }}
                  disabled={!showAction || !canEditConverter}
                >
                  <option value={""}>SELECT TYPE</option>
                  {(convoTypes || []).map((type) => {
                    return <option value={type.id}>{type.name}</option>;
                  })}
                </select>
              </div>{" "}
            </>
          ) : null}
          <div className="fs-14 flex mb-4 flex-middle">
            {!showAction ? (
              <>
                <div className="mr-24">
                  <FormInput
                    placeholder="TYPE"
                    isReadonly={!showAction || !canEditConverter}
                    className="mr-40 flex-1"
                    value={item.type}
                    onChange={(e) => {}}
                  />
                </div>
              </>
            ) : null}

            <div>
              {canViewMarkForTest ? (
                <Checkbox
                  isSelected={item.isMarkForTest}
                  label={<div className="c-gray fs-18"> Mark for Test </div>}
                  onClick={() => {
                    if (!showAction) {
                      return;
                    }
                    setItem({ ...item, isMarkForTest: !item.isMarkForTest });
                  }}
                  labelClassName="c-black fs-12"
                />
              ) : null}
              {!showAction && hierarchy === 1 ? (
                <Checkbox
                  isSelected={item.isCalculatedPrice}
                  label={
                    <div className="c-gray fs-18"> Is Override Price? </div>
                  }
                  onClick={() => {}}
                  labelClassName="c-black fs-12"
                />
              ) : null}
            </div>
          </div>
          {/* <div className="flex flex-between flex-middle mb-20">
                            <FormInput placeholder="PT ASSAY" className="mr-40 flex-1" value={item.ptAssay} onChange={(e)=> { setItem({...item, ptAssay: e.target.value}) }} />
                            <FormInput placeholder="PD ASSAY" className="mr-40 flex-1" value={item.pdAssay} onChange={(e)=> { setItem({...item, pdAssay: e.target.value}) }} />
                            <FormInput placeholder="RH ASSAY" className="mr-40 flex-1" value={item.rhAssay} onChange={(e)=> { setItem({...item, rhAssay: e.target.value}) }} />
                        </div> */}
          {canEditConverter && (
            <>
              <div className="c-gray fs-14 mb-4"> Notes </div>
              <div className="flex flex-between flex-middle">
                {showAction ? (
                  <textarea
                    placeholder="Notes"
                    className="mr-40 flex-1 bg-blue"
                    value={item.notes}
                    onChange={(e) => {
                      setItem({ ...item, notes: e.target.value });
                    }}
                    rows="2"
                  />
                ) : (
                  <span>{item.notes ? item.notes : "-"}</span>
                )}

                {showAction ? (
                  <div className="flex flex-right mr-16">
                    <div className="wt-50p" />
                    <div className="wt-35p" style={{ display: "flex" }}>
                      <div
                        className="btn  wt-120 ht-40 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                        onClick={() => setShowPricingTab(null)}
                      >
                        <span>CANCEL</span>
                      </div>
                      <div
                        className="btn  wt-180 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                        onClick={() => setIsConfirmSavePopupshown(true)}
                      >
                        <span>APPLY TO DB</span>
                      </div>
                    </div>
                  </div>
                ) : null}
              </div>
            </>
          )}
        </div>
      </div>

      {isImageModalShown && (
        <Modal>
          <OutsideClickHandler
            onOutsideClick={() => setIsImageModalShown(false)}
          >
            <div className="flex flex-middle wt-100p">
              <ImageUploading
                onChange={setImage}
                initialImage={item.profileUrl || ""}
                isMultiple={true}
                hideAction={!canEditConverter}
                initialImages={(item.urls || []).map((url) => {
                  return {
                    dataURL: url,
                  };
                })}
              />
            </div>

            <div className="flex flex-row-reverse flex-middle mt-20">
              {/* <textarea placeholder="Notes" className="mr-40 flex-1" value={item.notes} onChange={(e)=> { setItem({...item, notes: e.target.value}) }} rows="2"/> */}
              {canEditConverter && (
                <Button
                  type="saveButton"
                  className="flex flex-middle ml-40"
                  onClick={() => {
                    onSave({ ...item }, image);
                    setIsImageModalShown(false);
                  }}
                >
                  <img src={Tick} className="wt-20 ht-20 mr-4" />
                  <span>Save Changes</span>
                </Button>
              )}

              <Button
                type="saveButton"
                className="flex flex-middle"
                onClick={() => setIsImageModalShown(false)}
              >
                {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
                <span>Close</span>
              </Button>
            </div>
          </OutsideClickHandler>
        </Modal>
      )}

      {isConfirmSavePopupshown && (
        <Modal>
          <div className="pl-16 pr-16">
            <div className="fs-18 fw-bolder t-center mb-20">
              Are you sure you want to save the change ?
            </div>
            <div className="flex flex-middle flex-center">
              <Button
                className="mr-20"
                type="secondary"
                onClick={() => setIsConfirmSavePopupshown(false)}
              >
                Cancel
              </Button>
              <Button
                type="modalSave"
                onClick={() => {
                  onSave({ ...item }, image);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
    // </Modal>
  );
};

export default ConverterInfo;
