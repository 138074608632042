import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import HeaderIcon from "../../icons/black_user-group.png";
import IconUsersInactive from "../../icons/rev_user.png";
import IconGroupsActive from "../../icons/pos_user-group.png";
import Entities from "../Entities";
import CostSheetReport from "./CostSheetReport";
import LoadReport from "./LoadReport";

const activeClass = `ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder`;
const inactiveClass =
  "ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray";

const Dashboard = (props) => {
  const [searchVal, setSerchVal] = useState("");
  const [removeTabs, setRemoveTabs] = useState(false);
  const [activeView, setActiveView] = useState("costSheetReport");

  const handleSearch = (val) => {
    console.log(val);
  };

  return (
    <CostSheetReport />
    // <PageLayout
    //   icon={HeaderIcon}
    //   onSearchChange={(val) => handleSearch(val)}
    //   heading="Search Reports"
    // >
    //   {!removeTabs && false && (
    //     <div className="flex flex-middle mt-8 flex-between groups-tabs">
    //       <div className={`flex`}>
    //         <div
    //           className={
    //             activeView == "costSheetReport" ? activeClass : inactiveClass
    //           }
    //           onClick={() => {
    //             setActiveView("costSheetReport");
    //           }}
    //         >
    //           <img src={IconGroupsActive} className="wt-30 mr-8" />
    //           <span>Cost Sheet Report</span>
    //         </div>
    //         <div
    //           className={
    //             activeView == "loadReport" ? activeClass : inactiveClass
    //           }
    //           onClick={() => {
    //             setActiveView("loadReport");
    //           }}
    //         >
    //           <img
    //             src={
    //               activeView == "loadReport"
    //                 ? IconGroupsActive
    //                 : IconUsersInactive
    //             }
    //             className="wt-30 mr-8"
    //           />
    //           <span>Load Report</span>
    //         </div>
    //       </div>
    //     </div>
    //   )}

    //   {/* <div>
    //     {activeView == "costSheetReport" && (
    //       <div style={{ marginLeft: "-5px" }}>
    //         {" "}
    //         <CostSheetReport />{" "}
    //       </div>
    //     )}{" "}
    //   </div> */}
    //   {/*<div style={{ marginLeft: "-5px" }}>
    //     {activeView == "loadReport" && <CostSheetReport />}{" "}
    //   </div>*/}
    // </PageLayout>
  );
};

export default Dashboard;
