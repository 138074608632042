import React from "react";
import UnderConstruction from "../../components/UnderConstruction";

function LoadReport() {
  return (
    <div>
      <UnderConstruction />
    </div>
  );
}

export default LoadReport;
