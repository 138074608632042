import React, { useState } from "react";
import Checkbox from "../Checkbox";
import OutsideClickHandler from "react-outside-click-handler";

const SelectCheckbox = ({
  items,
  selectPlaceholder,
  selected,
  setSelectedItems,
  selectSingle = false,
  hasError = false,
  isRound = false,
  id,
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const toggleItem = (item) => {
    if (selectSingle) {
      setSelectedItems([{ ...item }]);
      return;
    }
    const updatedItems = selected.filter((selectedItem) => {
      return item.id !== selectedItem.id;
    });
    if (updatedItems.length === selected.length) {
      updatedItems.push({ ...item });
    }
    setSelectedItems(updatedItems);
  };
  // console.log("ITEMS", items);
  return (
    <div className="p-relative flex-1" id={id || "click-dropdown"}>
      <OutsideClickHandler
        onOutsideClick={() => {
          setIsDropdownShown(false);
        }}
      >
        <div
          className={`ht-30 fs-13 fw-bold flex flex-middle bg-lt-gray c-pointer pl-16 pr-16 flex-1 custom-select ${isRound?'rounded-pill':''}`}
          onClick={() => {
            setIsDropdownShown(!isDropdownShown);
          }}
        >
          {selectPlaceholder}
        </div>
        {hasError && !selected.length > 0 && (
          <div className="fs-10 c-error fw-bold p-absolute">
            Please enter the valid value
          </div>
        )}
        {isDropdownShown && (
          <div className="p-absolute bg-white flex-1 wt-100p">
            {items.map((item, index) => {
              return (
                <div className="flex flex-middle ht-40 pl-4 pr-4 mb-4 border-bottom-gray">
                  <Checkbox
                    label={item.value}
                    onClick={() => {
                      toggleItem(item);
                    }}
                    isSelected={
                      selected.findIndex((i) => {
                        return i.id === item.id;
                      }) > -1
                    }
                  />
                  {/* <span>{item.value}</span> */}
                </div>
              );
            })}
          </div>
        )}
        {true && (
          <div className="flex flex-middle flex-wrap mt-8">
            {selected.map((item, index) => {
              return (
                <div
                  style={{ backgroundColor: "#b1b1b1" }}
                  className="btn ht-40 bg-lt-gray fs-10 mr-8 mb-8"
                  onClick={() => {
                    toggleItem(item);
                  }}
                >
                  <span>{item.value}</span>
                  {!selectSingle && <span className="pl-8 fw-bolder">X</span>}
                </div>
              );
            })}
          </div>
        )}
      </OutsideClickHandler>
    </div>
  );
};

export default SelectCheckbox;
