import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import PageLayout from "../../components/PageLayout";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import ConverterInfo from "../../components/AddEditItems/ConverterInfo";
import ConverterPricing from "../../components/AddEditItems/ConverterPricing";
import TestAddEdit from "../../components/AddEditItems/TestAddEdit";
import Table from "../../components/Table";
import FormInput from "../../components/FormInput";
import Toast from "../../components/Toast";
import ActionLoader from "../../components/ActionLoader";
import PricingIdentifyConverters from "../Pricing/PricingIdentifyConverters";

import { getLoginDetails } from "../../utils/storage";

import CaretIcon from "../../icons/caret.png";
import HeaderIcon from "../../icons/black_pricing.png";
import HeaderIconPage from "../../icons/pos_pricing.png";

import Modal from "../../components/Modal";
import Button from "../../components/Button";

import {
  GET_CONVERTERS_URL,
  GET_PRICING_URL,
  EDIT_PRICING_URL,
  GET_CONVERTER_DETAILS_URL,
  DELETE_CONVERTERS_URL,
  ADD_CONVERTERS_URL,
  GET_MAKE_MODEL_URL,
  EDIT_CONVERTERS_URL,
  ADD_TEST_URL,
  EDIT_TEST_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_TEST_HISTORY_URL,
  GET_PRICE_HISTORY_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
  FACILITY_CITY_LIST,
  GET_TOP_CATS_URL,
  GET_FAMILIES_URL,
  ADD_OVERRIDE_MODIFIER_URL,
  GET_ALL_OVERRIDE_MODIFIER_URL,
  GET_FACILITIES_URL,
  GET_GLOBAL_DEFAULTS_URL,
  EDIT_GLOBAL_DEFAULTS_URL,
} from "../../utils/api";

import IconUsersInactive from "../../icons/rev_user.png";
import IconInformationInactive from "../../icons/rev_information.png";
import IconPricingInactive from "../../icons/rev_pricing.png";

import IconUsersActive from "../../icons/pos_user.png";
import IconInformationActive from "../../icons/pos_information.png";
import IconPricingActive from "../../icons/pos_pricing.png";
import Toggle from "react-toggle";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

const testModel = {
  productId: "",
  subweight: "",
  ptAssay: "",
  pdAssay: "",
  rhAssay: "",
  location: "",
  userName: "",
  notes: "",
  testId: "",
};

const activeTabs = {
  1: 1,
  3: 2,
  4: 3,
  5: 4,
  6: 5,
};

const getTitle = (type) => {
  switch (type) {
    case 3:
      return "Facilities Pricing";
      break;
    case 2:
      return "Overide Pricing";
      break;
    default:
      return "Preview Pricing";
  }
};
const PreviewPricing = (props) => {
  const { canEditMetalPrices,  
    canViewGlobalPricing,
    canViewIndividualPricing,
    canViewOverrideModifier,
    canViewFacilityPricing,
    canViewFamilyPricing,
    hierarchy } = getLoginDetails();
  const [priceModifierItems, setPriceModifierItems] = useState([]);
  const [topResults, setTopResults] = useState([]);
  const [allFamilies, setAllFamilies] = useState([]);
  const [showFamilies, setShowFamilies] = useState(false);
  const [showTopResults, setShowTopResults] = useState(false);
  const [pricingInfo, setPricingInfo] = useState(null);
  const [pricingInfoCopy, setPricingInfoCopy] = useState(null);

  const [marketPriceType, setMarketPriceType] = useState("oz");
  const [hedgePriceType, setHedgePriceType] = useState("oz");
  const [modifierPriceType, setModifierPriceType] = useState("%");

  const [ptModifier, setPtModifer] = useState("");
  const [pdModifier, setPdModifer] = useState("");
  const [rhModifier, setRhModifer] = useState("");

  const [pomPtModifier, setPomPtModifer] = useState("");
  const [pomPdModifier, setPomPdModifer] = useState("");
  const [pomRhModifier, setPomRhModifer] = useState("");

  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [productDetail, setProductDetail] = useState({});
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);

  const [overridesPrice, setOverridesPrice] = useState(0);
  const [isConfirmSavePopupshown, setIsConfirmSavePopupshown] = useState(false);
  const [isOverridesConfirm, setIsOverridesConfirm] = useState(false);
  const [showOverrideHistory, setShowOverrideHistory] = useState(false);
  const [overridesHistory, setOverridesHistory] = useState([]);
  const [overrideModifierData, setOverrideModifierdata] = useState({});
  const [showGlobalPreview, setShowGlobalPreview] = useState(false)
  const [previewPriceOveride, setPreviewPriceOveride] = useState(false);
  const [isPreview, setIsPreview] = useState(false);
  const [isPreviewConfirm, setIsPreviewConfirm] = useState(false);
  const activeTab =
    (props.location &&
      props.location &&
      props.location.state &&
      props.location.state.showTab) ||
    1;
  const [showTab, setShowTab] = useState(hierarchy ===  10 ? 3:canViewGlobalPricing?0:-1);
  const [params, setParams] = useState({
    limit: 100,
    offset: 0,
    text: "",
    areMoreItemsAvailable: false,
  });
  const [onlyCoreHistory, setOnlyCoreHistory] = useState(false);
  const [graphParams, setGraphParams] = useState({
    limit: 10,
    offset: 0,
  });

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [facilities, setFaciltiesItems] = useState([]);

  const [globalDefaults, setGlobalDefaults] = useState({
    "weight" : {name: 'NetWeight', value: ''},
    "pt" : {name: 'Platinum', value: ''},
    "pd" : {name: 'Palladium', value: ''},
    "rh" : {name: 'Rhodium', value: ''},
});

  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  useEffect(() => {
    getFacilities();
    getGlobalDefaults();
  }, []);

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(
        (data.list && data.list.filter((el) => el.isActive)).sort(
          (a, b) => (a.name > b.name && 1) || -1
        ) || []
      );
    } catch (e) {}
  };

  // useEffect(() => {
  //   if (modifierPriceType == "$") {
  //     setRhModifer(getRh("$"));
  //     console.log(rhModifier);
  //   }
  // }, [modifierPriceType]);

  const getPt = (t) => {
    const type = t === "kg" ? "ptKg" : "ptOz";
    const price = pricingInfo.abcPrice[type];
    try {
      if (ptModifier && !isNaN(Number(ptModifier))) {
        if (modifierPriceType === "%") {
          return Number((price * (1 + ptModifier / 100)).toFixed(2));
        } else {
          return Number(price) + Number(ptModifier);
        }
      }
      return price;
    } catch (e) {
      return price;
    }
  };
  const getPd = (t) => {
    const type = t === "kg" ? "pdKg" : "pdOz";
    const price = pricingInfo.abcPrice[type];
    try {
      if (pdModifier && !isNaN(Number(pdModifier))) {
        if (modifierPriceType === "%") {
          return Number((price * (1 + pdModifier / 100)).toFixed(2));
        } else {
          return Number(price) + Number(pdModifier);
        }
      }
      return price;
    } catch (e) {
      return price;
    }
  };
  const getRh = (t) => {
    const type = t === "kg" ? "rhKg" : "rhOz";
    const price = pricingInfo.abcPrice[type];
    try {
      if (rhModifier && !isNaN(Number(rhModifier))) {
        if (modifierPriceType === "%") {
          return Number((price * (1 + rhModifier / 100)).toFixed(2));
        } else {
          return Number(price) + Number(rhModifier);
        }
      }
      return price;
    } catch (e) {
      return price;
    }
  };

  const getPricing = async (isPercent) => {
    try {
      setIsLoading(true);
      setPriceModifierItems([]);
      const data = await getData(GET_PRICING_URL, {
        ptDiscIsPercentage: isPercent,
        pdDiscIsPercentage: isPercent,
        rhDiscIsPercentage: isPercent,
      });
      console.log("DATA: ", data);
      setIsLoading(false);
      setPricingInfo(data);
      setPricingInfoCopy(data);
      if (data && data.abcPrice) {
        setPtModifer(data.abcPrice.ptDisc || "");
        setPdModifer(data.abcPrice.pdDisc || "");
        setRhModifer(data.abcPrice.rhDisc || "");
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getAllFamilies = async () => {
    try {
      setIsLoading(true);
      const response = await getData(GET_FAMILIES_URL);
      setAllFamilies(response.list || []);
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const addOverrideModifier = async () => {
    try {
      setIsLoading(true);
      let dataParams = {
        overridesModifier: Number(overridesPrice),
        isPreview,
      };

      if (overrideModifierData && overrideModifierData.isCoreValuesChange) {
        dataParams["isCoreValuesChange"] = true;
        dataParams["avgPieceValue"] = Number(
          overrideModifierData.avgPieceValue
        );
        dataParams["weight"] = Number(overrideModifierData.lbs);
        dataParams["ptPPM"] = Number(overrideModifierData.pt);
        dataParams["pdPPM"] = Number(overrideModifierData.pd);
        dataParams["rhPPM"] = Number(overrideModifierData.rh);
      }

      const response = await postData(ADD_OVERRIDE_MODIFIER_URL, dataParams);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Pricing",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      setIsOverridesConfirm(false);
      getAllOverrideshistory();
      getTopResults();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setIsOverridesConfirm(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  const getAllCoreOverrideshistory = (onlyCore) => {
    setOnlyCoreHistory(onlyCore);
    setShowOverrideHistory(true);
  };

  useEffect(() => {
    setOverridesHistory([]);
    getAllOverrideshistory();
  }, [onlyCoreHistory]);

  const getAllOverrideshistory = async () => {
    try {
      const params = {
        count: 100,
        offset: 0,
      };
      if (onlyCoreHistory) {
        params["isCoreValuesChange"] = true;
      }
      const response = await getData(GET_ALL_OVERRIDE_MODIFIER_URL, params);
      setOverridesHistory(response.list || []);
      // setOverridesPrice(
      //   (response.list || []).length > 0
      //     ? response.list[0].overridesModifier
      //     : ""
      // );
      setOverrideModifierdata(response.metalData);
    } catch (e) {
      console.log("ERROR test", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editPricing = async (isPreview) => {
    const isPercent = modifierPriceType == "%" ? true : false;
    setIsPreviewConfirm(false);
    try {
      setIsLoading(true);
      let dataParams = {
        ...pricingInfo.abcPrice,
        globalDefaults: pricingInfo.globalDefaults,
        isPreview,
        ptKg: Number(pricingInfo.abcPrice["ptOz"] * 32.15).toFixed(2), //getPt('kg'),
        pdKg: Number(pricingInfo.abcPrice["pdOz"] * 32.15).toFixed(2), //getPd('kg'),
        rhKg: Number(pricingInfo.abcPrice["rhOz"] * 32.15).toFixed(2), //getRh('kg'),
        ptOz: Number(pricingInfo.abcPrice["ptOz"]), //getPt('oz'),
        pdOz: Number(pricingInfo.abcPrice["pdOz"]), //getPd('oz'),
        rhOz: Number(pricingInfo.abcPrice["rhOz"]), //getRh('oz'),
        // rhDiscIsPercentage: pricingInfo.abcPrice.modifierPriceType, //pricingInfo.abcPrice.rhDiscIsPercentage,
        rhDiscIsPercentage: isPercent,
        rhDisc: Number(pricingInfo.abcPrice.rhDisc),
        // ptDiscIsPercentage: pricingInfo.abcPrice.modifierPriceType, //pricingInfo.abcPrice.ptDiscIsPercentage,
        ptDiscIsPercentage: isPercent,
        ptDisc: Number(pricingInfo.abcPrice.ptDisc),
        // pdDiscIsPercentage: pricingInfo.abcPrice.modifierPriceType, //pricingInfo.abcPrice.pdDiscIsPercentage,
        pdDisc: Number(pricingInfo.abcPrice.pdDisc),
        pdDiscIsPercentage: isPercent,
        // schemeDiscIsPercentage: pricingInfo.abcPrice.schemeDiscIsPercentage,
        // schemeDisc: Number(pricingInfo.abcPrice.schemeDisc),
        // altPrice1DiscIsPercentage: pricingInfo.abcPrice.altPrice1DiscIsPercentage,
        // altPrice1Disc: Number(pricingInfo.abcPrice.altPrice1Disc),
        // rhModifierDiscIsPercentage: pricingInfo.abcPrice.rhModifierDiscIsPercentage,
        // rhModifierDisc: Number(pricingInfo.abcPrice.rhModifierDisc),
        overridesModifier: Number(pricingInfo.abcPrice.overridesModifier),
        Atlanta: Number(pricingInfo.abcPrice.Atlanta),
        Burlington: Number(pricingInfo.abcPrice.Burlington),
        Tampa: Number(pricingInfo.abcPrice.Tampa),
        marketPrice: {
          ptKg: Number(Number(pricingInfo.marketPrice.ptOz * 32.15).toFixed(2)),
          ptOz: Number(pricingInfo.marketPrice.ptOz),
          pdKg: Number(Number(pricingInfo.marketPrice.pdOz * 32.15).toFixed(2)),
          pdOz: Number(pricingInfo.marketPrice.pdOz),
          rhKg: Number(Number(pricingInfo.marketPrice.rhOz * 32.15).toFixed(2)),
          rhOz: Number(pricingInfo.marketPrice.rhOz),
        },
      };
      dataParams.pricingType = showTab === 0 ? 1 : showTab === 3 ? 2 :3;

      const response = await putData(EDIT_PRICING_URL, dataParams);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully updated the Preview Data",
        id: new Date().getTime(),
      });
      if (!isPreview) {
        // props.history.push("/pricing");
        setShowGlobalPreview(false);
        setIsConfirmSavePopupshown(false);
        getPricing();
      }
      getTopResults();
      getConverters();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      if (!isPreview) {
        setIsConfirmSavePopupshown(false);
      }
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  const DeleteConfirmation = ({ type, name, onClose, onConfirm }) => {};

  const getConverters = async () => {
    try {
      setIsLoading(true);
      setPriceModifierItems([]);
      const data = await getData(GET_CONVERTERS_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
      });
      setIsLoading(false);
      setPriceModifierItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getTopResults = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_TOP_CATS_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        isTopResults: true,
      });
      setIsLoading(false);
      setTopResults(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const deleteItem = async () => {
    try {
      setIsLoading(true);
      const response = await deleteData(DELETE_CONVERTERS_URL, {
        productId: itemForDelete.productId,
      });
      setIsDeletePopupShown(false);
      getConverters();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };

  const getGlobalDefaults = async () => {
    try {
      setIsLoading(true);
      

      const response = await getData(GET_GLOBAL_DEFAULTS_URL, {});

      const globalDefaultsCopy = JSON.parse(JSON.stringify(globalDefaults))
      globalDefaultsCopy['weight'].value = response.weight || 0;
      globalDefaultsCopy['pt'].value = response.pt || 0;
      globalDefaultsCopy['pd'].value = response.pd || 0;
      globalDefaultsCopy['rh'].value = response.rh || 0;
      setGlobalDefaults(globalDefaultsCopy);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };

  const editGlobalDefaults = async () => {
    try {
      setIsLoading(true);
      const response = await postData(EDIT_GLOBAL_DEFAULTS_URL, {
        "weight" : getValues('weight') || 0,
        "pt" : getValues('pt') || 0,
        "pd" : getValues('pd') || 0,
        "rh" : getValues('rh') || 0,
    });
      setIsLoading(false);
      setShowGlobalPreview(false);
      setIsConfirmSavePopupshown(false);
      setIsPreviewConfirm(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };

  const getValues = (key) => {
    if(globalDefaults[key]) {
      return globalDefaults[key].value
    }
    return 0
  }

  useEffect(() => {
    // getConverters();
    getAllFamilies();
    getTopResults();
    getPricing(true);
    getAllOverrideshistory();
  }, [params.offset, params.text, params.limit]);
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const chunkArray = (arr, n) => {
    let chunkLength = Math.max(arr.length / n, 1);
    let chunks = [];
    for (let i = 0; i < n; i++) {
      if (chunkLength * (i + 1) <= arr.length)
        chunks.push(arr.slice(chunkLength * i, chunkLength * (i + 1)));
    }
    return chunks;
  };

  const handleChangeModifierData = (key, value) => {
    setOverrideModifierdata({
      ...overrideModifierData,
      isCoreValuesChange: true,
      [key]: value,
    });
  };

  useEffect(() => {
    if(showTab!==1 && showTab!==2) {
      props.history.push("/previewPricing", {showTab});
    }
    setShowTopResults(false);
  }, [showTab])

  const resetFacilties = () => {
    setPricingInfo(pricingInfoCopy);
    setOverridesPrice(0);
  }

  return (
    <PageLayout
      icon={HeaderIconSvg}
      hideSearchBar={true}
      heading="Pricing"
      searchPlaceholder="Search Converters"
    >
      {/* <div className="flex flex-middle flex-between">
        <h1 className="mr-24 fs-24 c-gray">{getTitle(showTab)}</h1>
        <div
          className="flex flex-middle c-pointer mb-20 mr-24"
          onClick={() => {
            props.history.push("/pricing");
          }}
        >
          <img src={CaretIcon} className="wt-30 rotate-90 op-5" />
          <span className="fs-20 c-table-header fw-bolder">
            {" "}
            BACK TO PRICING{" "}
          </span>
        </div>
      </div> */}

      <div className="flex flex-middle mt-8 flex-between groups-tabs ">
        <div className={`tabs flex`}>
{canViewGlobalPricing ? (
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 0 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(0)}
          >
            {/*<img src={HeaderIconPage} className="wt-30 mr-8" />*/}
            <span>Global Pricing</span>
          </div>
          ) : null}
          {canViewIndividualPricing ? (
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(1)}
          >
            <span>Individual Pricing</span>
          </div>
          ) : null}
          {canViewOverrideModifier ? (
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(2)}
          >
            <span>Price Override Modifier</span>
          </div>
          ) : null}
          {canViewFacilityPricing ? (
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 3 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(3)}
          >
            <span>Facilities</span>
          </div>
          ) : null}
          {canViewFamilyPricing ? (
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 4 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(4)}
          >
            <span>Families</span>
          </div>
          ) : null}
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 5 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setShowTab(5)}
          >
            <span>Global Defaults</span>
          </div>
        </div>
        {
              showTab === 0 ? (
                <div
                    className="btn  ht-40 wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => {
                      setShowGlobalPreview(!showGlobalPreview)
                      setShowTopResults(false);
                    }
                    }
                  >
                    <span>{showGlobalPreview?'Hide':'Modify'}</span>
                  </div>
              ) : null
            }

{
              showTab === 2 ? (
                <div
                    className="btn  ht-40 wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => setPreviewPriceOveride(!previewPriceOveride)}
                  >
                    <span>{previewPriceOveride?'Hide':'Modify'}</span>
                  </div>
              ) : null
            }


      </div>

      {pricingInfo && (
        <div className="">
          {showTab === 2 ? (
            <>
              <div
                style={{ padding: "30px 10px 30px  0", width: "100%" }}
                className="flex-2"
              >
                <div className="mr-16 mb-16 flex">
                  <div
                    style={{ width: "25%" }}
                    className="flex flex-middle mr-16 mb-16"
                  >
                    <div className="c-gray fw-bolder fw-16 mr-20">
                      Price Override Modifier
                    </div>
                    <span className="fs-14 c-gray fw-bolder mr-4" />
                  </div>
                  <div className="flex flex-middle">
                    <span
                      className="c-black fs-24 fw-bolder"
                      style={{ marginTop: "-7px" }}
                    ></span>
                    <FormInput
                      style={{ width: 100 }}
                      isReadonly={!previewPriceOveride}
                      className={`mr-16 p-relative flex-1 ${previewPriceOveride?'bg-blue':''} rounded-pill`}
                      inputClassName="ht-40 fs-16 pl-28 rounded-pill"
                      value={overridesPrice} //(overridesHistory || []).length > 0 ? overridesHistory[0].overridesModifier : ""}
                      // value={pricingInfo.abcPrice.overridesModifier}
                      onChange={(e) => {
                        // if (isNaN(e.target.value)) {
                        //   return;
                        // }
                        let val = e.target.value.replace(/(?!^-)[^0-9.]/g, "").replace(/(\..*)\./g, '$1');
                        val.length > 4
                          ? (val = val.slice(0, 4))
                          : setOverridesPrice(val);
                      }}
                    />
                  </div>
                  <div className="flex flex-right mr-16">
                    <div className="wt-200p" style={{ display: "flex" }}>
                      <div
                        className="btn  ht-40 wt-180 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                        onClick={() => {
                          getAllCoreOverrideshistory(false);
                        }}
                      >
                        <span>OVR HISTORY</span>
                      </div>
                    </div>
                  </div>
                </div>
                {isOverridesConfirm && (
                  <Modal>
                    <div className="pl-16 pr-16">
                      <div className="fs-18 fw-bolder t-center mb-20">
                        {`Are you sure you want to ${isPreview?'update the Preview data':'save the change'} ?`}
                      </div>
                      <div className="flex flex-middle flex-center">
                        <Button
                          className="mr-20"
                          type="secondary"
                          onClick={() => setIsOverridesConfirm(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="modalSave"
                          onClick={() => addOverrideModifier()}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}
                {showOverrideHistory && (
                  <Modal>
                    <div className="pl-32 pr-16">
                      <div className="fs-18 fw-bolder mb-8">
                        OVERRIDES MODIFIER HISTORY
                      </div>
                    </div>
                    <Table
                      scrollTable={true}
                      columns={
                        onlyCoreHistory
                          ? [
                              "First Name",
                              "Last Name",
                              "Avg Piece  Value",
                              "LBS",
                              "NPA Plat",
                              "NPA Pall",
                              "NPA Rhod",
                              "Created",
                              "Status",
                            ]
                          : [
                              "First Name",
                              "Last Name",
                              "Overrides Modifier",
                              "Created",
                              "Status",
                            ]
                      }
                      items={(overridesHistory || []).map((item) => {
                        const createdDate = new Date(item.created);
                        const dd = String(createdDate.getDate()).padStart(
                          2,
                          "0"
                        );
                        const mm = String(createdDate.getMonth() + 1).padStart(
                          2,
                          "0"
                        ); //January is 0!
                        const yyyy = createdDate.getFullYear();
                        let hours = createdDate.getHours();
                        let minutes = createdDate.getMinutes();
                        const ampm = hours >= 12 ? "pm" : "am";
                        hours = hours % 12;
                        hours = hours ? hours : 12;
                        minutes = minutes < 10 ? "0" + minutes : minutes;
                        return {
                          ...item,
                          date: `${mm}-${dd}-${yyyy}`,
                        };
                      })}
                      keys={
                        onlyCoreHistory
                          ? [
                              "firstName",
                              "lastName",
                              "avgPieceValue",
                              "weight",
                              "ptPPM",
                              "pdPPM",
                              "rhPPM",
                              "date",
                              "status",
                            ]
                          : [
                              "firstName",
                              "lastName",
                              "overridesModifier",
                              "date",
                              "status",
                            ]
                      }
                      hideCheckBox={true}
                      hideTableCounter={true}
                      offset={params.offset}
                      areMoreItemsAvailable={params.areMoreItemsAvailable}
                      fetchMore={fetchMore}
                      fetchLess={fetchLess}
                      isLoading={isLoading}
                      // className="min-wt-1000"
                      onCountChange={(value) => {
                        setParams({
                          ...params,
                          limit: value,
                          offset: 0,
                        });
                      }}
                      limit={params.limit}
                    />
                    <div className="flex flex-middle flex-right">
                      <div
                        className="btn  ht-40 mt-20 wt-120 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                        onClick={() => setShowOverrideHistory(false)}
                      >
                        <span>CLOSE</span>
                      </div>
                    </div>
                  </Modal>
                )}
                
                {/* <div
                  style={{ justifyContent: "space-between" }}
                  className="flex fs-14 price-oveeride-form colour-blue"
                >
                  <div style={{ width: "18%" }}>
                    <div className="mb-12 fw-bolder">Avg Piece Value ($)</div>
                    <FormInput
                      style={{
                        border: "1px solid #787878",
                      }}
                      isReadonly={false}
                      onChange={(e) =>
                        // e.target.value.length > 4
                        //   ? (e.target.value = e.target.value.slice(0, 5))
                        //   : null;
                        handleChangeModifierData(
                          "avgPieceValue",
                          `${e.target.value}`
                        )
                      }
                      max="999"
                      value={overrideModifierData.avgPieceValue}
                      inputType="nonBold"
                      inputClassName="bg-blue rounded-pill"
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <div className="mb-12">LBs</div>
                    <FormInput
                      style={{
                        border: "1px solid #787878",
                      }}
                      isReadonly={false}
                      onChange={(e) =>
                        // e.target.value.length > 4
                        //   ? (e.target.value = e.target.value.slice(0, 5))
                        //   : null
                        handleChangeModifierData("lbs", e.target.value)
                      }
                      max="999"
                      value={overrideModifierData.lbs}
                      inputType="nonBold"
                      inputClassName="bg-blue rounded-pill"
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <div className="mb-12 ml-24 colour-blue">PLATINUM</div>
                    <div className="flex" style={{ alignItems: "center" }}>
                      <span style={{ marginRight: "3px" }}>PPM</span>
                      <FormInput
                        style={{
                          border: "1px solid #787878",
                        }}
                        isReadonly={false}
                        onChange={(e) =>
                          // e.target.value.length > 4
                          //   ? (e.target.value = e.target.value.slice(0, 5))
                          //   : null
                          handleChangeModifierData("pt", e.target.value)
                        }
                        max="999"
                        value={overrideModifierData.pt}
                        inputType="nonBold"
                        inputClassName="bg-blue rounded-pill"
                      />
                    </div>
                  </div>

                  <div style={{ width: "18%" }}>
                    <div className="mb-12 colour-blue">PALLADIUM</div>
                    <FormInput
                      inputClassName="bg-blue rounded-pill"
                      style={{
                        border: "1px solid #787878",
                      }}
                      isReadonly={false}
                      onChange={(e) =>
                        // e.target.value.length > 4
                        //   ? (e.target.value = e.target.value.slice(0, 5))
                        //   : null
                        handleChangeModifierData("pd", e.target.value)
                      }
                      max="999"
                      value={overrideModifierData.pd}
                      inputType="nonBold"
                    />
                  </div>
                  <div style={{ width: "18%" }}>
                    <div className="mb-12 colour-blue">RHODIUM</div>
                    <FormInput
                      inputClassName="bg-blue rounded-pill"
                      style={{
                        border: "1px solid #787878",
                      }}
                      isReadonly={false}
                      onChange={(e) =>
                        // e.target.value.length > 4
                        //   ? (e.target.value = e.target.value.slice(0, 5))
                        //   : null
                        handleChangeModifierData("rh", e.target.value)
                      }
                      max="999"
                      value={overrideModifierData.rh}
                      inputType="nonBold"
                    />
                  </div>
                </div> */}
                {
                  previewPriceOveride ? (
                    <div
                  className="flex flex-right mr-16 mt-20"
                  style={{ justifyContent: "space-between" }}
                >
                  {/* <div className="wt-50p">
                    <div
                      className="btn  ht-40 wt-180 mb-12 t-left fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                      onClick={() => {
                        getAllCoreOverrideshistory(true);
                      }}
                    >
                      <span>GBL HISTORY</span>
                    </div>
                  </div> */}
                  <div className="wt-35p" style={{ display: "flex" }}>
                  <div
                    className="btn  ht-40 wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => {
                      setOverridesPrice(0);
                      setPreviewPriceOveride(!previewPriceOveride);
                    }}
                  >
                    <span>{'Cancel'}</span>
                  </div>
                    <div
                      className="btn  ht-40 wt-120 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                      onClick={() => {
                        setIsPreview(true);
                        setIsOverridesConfirm(true)}
                      }
                    >
                      <span>Preview</span>
                    </div>
                    <div
                      className="btn  ht-40 wt-180 mb-12  t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                      onClick={() => {
                        setIsPreview(false);
                        setIsOverridesConfirm(true)}
                      }
                    >
                      <span>APPLY TO DB</span>
                    </div>
                  </div>
                </div>
                  ): null
                }
                
              </div>
            </>
          ) : null}
          {showTab === 3 ? (
            <>
              <div className="flex flex-space-around flex-wrap pricing-bottom-section-wrapper">
                {/* <div
                  style={{
                    width: "10px",
                    backgroundColor: "#000",
                    border: "2px solid #787878",
                  }}
                  className="seperator"
                ></div> */}
                <div style={{ padding: "30px" }} className="flex-1">
                  {facilities.map((facility, faIndex) => {
                    return (
                      <div
                        className="mr-16 mb-4 flex"
                        key={`facility-${faIndex}`}
                      >
                        <div
                          className="flex flex-row-reverse mr-16 mb-16"
                          style={{ width: "20%" }}
                        >
                          <div className="c-gray fw-bolder fw-16 mr-20  text-right">
                            {facility.name}
                          </div>
                          <span className="fs-14 c-gray fw-bolder mr-4" />
                        </div>
                        <span
                          style={{ marginTop: "-6px" }}
                          className="fs-24 c-black fw-bolder"
                        ></span>
                        <FormInput
                          className="flex-1 mr-16 p-relative t--8 "
                          inputClassName="ht-40 fs-16 pl-28 bg-blue rounded-pill"
                          style={{ width: "10%" }}
                          value={pricingInfo.abcPrice[facility.name] || ""}
                          onChange={(e) => {
                            if (isNaN(e.target.value) && e.target.value!=='' && e.target.value!=='-') {
                              return;
                            }
                            let val = e.target.value;
                            val.length > 4
                              ? (val = val.slice(0, 4))
                              : setPricingInfo({
                                  ...pricingInfo,
                                  abcPrice: {
                                    ...pricingInfo.abcPrice,
                                    [facility.name]: e.target.value!=='-'?parseInt(val):'-',
                                  },
                                });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}

{showTab === 5 ? (
            <>
              <div className="flex flex-space-around flex-wrap pricing-bottom-section-wrapper">
                <div style={{ padding: "30px" }} className="flex-1">
                  {Object.keys(globalDefaults).map((facility, faIndex) => {
                    return (
                      <div
                        className="mr-16 mb-4 flex"
                        key={`facility-${faIndex}`}
                      >
                        <div
                          className="flex flex-row-reverse mr-16 mb-16"
                          style={{ width: "20%" }}
                        >
                          <div className="c-gray fw-bolder fw-16 mr-20  text-right">
                            {globalDefaults[facility].name}
                          </div>
                          <span className="fs-14 c-gray fw-bolder mr-4" />
                        </div>
                        <span
                          style={{ marginTop: "-6px" }}
                          className="fs-24 c-black fw-bolder"
                        ></span>
                        <FormInput
                          className="flex-1 mr-16 p-relative t--8 "
                          inputClassName="ht-40 fs-16 pl-28 bg-blue rounded-pill"
                          style={{ width: "10%" }}
                          value={globalDefaults && globalDefaults[facility].value || ""}
                          onChange={(e) => {
                            if (isNaN(e.target.value) && e.target.value!=='') {
                              return;
                            }
                            let val = e.target.value;
                            val.length > 4
                              ? (val = val.slice(0, 4))
                              : setGlobalDefaults({
                                ...globalDefaults,
                                [facility]: {
                                  ...globalDefaults[facility],
                                  value: e.target.value!==''?parseInt(val):''
                                }
                              });
                          }}
                        />
                      </div>
                    );
                  })}
                </div>
              </div>
            </>
          ) : null}

          {/* <div
            style={{
              background: "#000",
              height: "12px",
              border: "2px solid #787878",
            }}
            className="border-bottom-thick mb-24 "
          /> */}
          {showTab === 4 ? (
            <>
              {(allFamilies || []).length > 0 && (
                <div className="mb-12 mt-12" style={{padding: 30}}>
                  {/* {showFamilies ? (
                    <div
                      className="c-red fs-18 fw-bolder c-pointer mb-12"
                      onClick={() => setShowFamilies(false)}
                    >
                      <span className="fs-20"> - </span> Hide Families
                    </div>
                  ) : (
                    <div
                      className="c-red fs-18 fw-bolder c-pointer mb-12"
                      onClick={() => setShowFamilies(true)}
                    >
                      <span className="fs-20"> + </span> Show Families
                    </div>
                  )} */}
                  {true && (
                    <div className="flex flex-wrap flex-between">
                      {(allFamilies || []).map((family, faIndex) => {
                        return (
                          <div className="flex mb-16" key={`family-${faIndex}`}>
                            <div className="flex flex-middle mr-16">
                              <div className="c-gray fw-bolder fw-16 mr-20 wt-100">
                                {family}
                              </div>
                              <span className="fs-14 c-gray fw-bolder mr-4" />
                            </div>
                            <FormInput
                              inputType="number"
                              // placeholder={`${(family || "").toUpperCase()}`}
                              className="flex-1 p-relative t--8 ml-8"
                              inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                              value={pricingInfo.abcPrice[family]}
                              onChange={(e) => {
                                let val = e.target.value;
                                val.match(/[a-z]/gi)
                                  ? (val = val.slice(0, val.length))
                                  : val.length > 5
                                  ? (val = val.slice(0, 5))
                                  : setPricingInfo({
                                      ...pricingInfo,
                                      abcPrice: {
                                        ...pricingInfo.abcPrice,
                                        [family]: val === "" ? "" : val,
                                      },
                                    });
                              }}
                            />
                          </div>
                        );
                      })}
                    </div>
                  )}
                </div>
              )}
            </>
          ) : null}
          {/*<div className="flex mr-16 mb-16">
                    <div className="flex flex-middle flex-1 mr-16">
                        <div className="c-gray fw-bolder fw-16 mr-20 wt-130">ALT PRICE</div>
                        <span className="fs-14 c-gray fw-bolder mr-4">%</span>
                        <Toggle className="bg-theme hide-check-cross"
                            onChange={(e)=> {
                                setPricingInfo({
                                    ...pricingInfo,
                                    abcPrice: {
                                        ...pricingInfo.abcPrice,
                                        altPrice1DiscIsPercentage: (!e.target.checked)
                                    }
                                })
                            }}
                            checked={!pricingInfo.abcPrice.altPrice1DiscIsPercentage}
                        />
                        <span className="fs-14 c-gray fw-bolder ml-4">$</span>
                    </div>
                    <FormInput placeholder="ALT PRICE" className="flex-1 mr-16 p-relative t--8 ml-8" inputClassName="ht-40 fs-24 pl-28" 
                        value={pricingInfo.abcPrice.altPrice1Disc}
                        onChange={(e)=> {
                            const val = e.target.value;
                            setPricingInfo({...pricingInfo, abcPrice: {
                                ...pricingInfo.abcPrice,
                                altPrice1Disc: val
                            }})
                            
                        }}
                    />
                    <div className="flex-1 mr-16"></div>
                    <div className="flex-1 mr-16"></div>
                </div>*/}
          {/*<div className="flex mr-16 mb-16">
                    <div className="flex flex-middle flex-1 mr-16">
                        <div className="c-gray fw-bolder fw-16 mr-20 wt-130">RH MODIFIER</div>
                        <span className="fs-14 c-gray fw-bolder mr-4">%</span>
                        <Toggle className="bg-theme hide-check-cross"
                            onChange={(e)=> {
                                setPricingInfo({
                                    ...pricingInfo,
                                    abcPrice: {
                                        ...pricingInfo.abcPrice,
                                        rhModifierDiscIsPercentage: (!e.target.checked)
                                    }
                                })
                            }}
                            checked={!pricingInfo.abcPrice.rhModifierDiscIsPercentage}
                        />
                        <span className="fs-14 c-gray fw-bolder ml-4">$</span>
                    </div>
                    <FormInput placeholder="RH MODIFIER" className="flex-1 mr-16 p-relative t--8 ml-8" inputClassName="ht-40 fs-24 pl-28" 
                        value={pricingInfo.abcPrice.rhModifierDisc}
                        onChange={(e)=> {
                            const val = e.target.value;
                            setPricingInfo({...pricingInfo, abcPrice: {
                                ...pricingInfo.abcPrice,
                                rhModifierDisc: val
                            }})
                            
                        }}
                    />
                    <div className="flex-1 mr-16"></div>
                    <div className="flex-1 mr-16"></div>
                </div>*/}
                {pricingInfo && showTab === 0 && (
        <div className="">
          {/*<div className="flex flex-middle mb-24">
            <img src={HeaderIconPage} className="ht-30 wt-30 mr-12" />
            <h1 className="mr-24 fs-24 c-gray">PRICING CONTROL PANEL</h1>
          </div>*/}
          {!showGlobalPreview ? (
            <div className="mb-4 mt-16">
              <div className="flex flex-middle mb-16 colour-blue ">
                <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
                  MATERIAL PRICES
                </div>
                <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
                  PLATINUM
                </div>
                <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
                  PALLADIUM
                </div>
                <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
                  RHODIUM
                </div>
              </div>
              <div className="flex flex-middle mb-20">
                <div className="flex flex-middle flex-1 mr-16">
                  <div className="c-gray fw-bolder fw-16 mr-20 wt-130">
                    MARKET PRICES
                  </div>
                  <span className="fs-14 c-gray fw-bolder mr-4">KG</span>
                  <Toggle
                    className="bg-theme hide-check-cross"
                    onChange={(e) => {
                      setMarketPriceType(e.target.checked ? "oz" : "kg");
                    }}
                    checked={marketPriceType !== "kg"}
                  />
                  <span className="fs-14 c-gray fw-bolder ml-4">OZ</span>
                </div>
                <div className="c-gray fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  $
                  <span>
                    {marketPriceType === "kg"
                      ? pricingInfo.marketPrice.ptKg
                      : pricingInfo.marketPrice.ptOz}
                  </span>
                  {/*<img
                  src={IconInformationActive}
                  className="ht-30 wt-30 ml-12"
                />*/}
                </div>
                <div className="c-gray fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  $
                  <span>
                    {marketPriceType === "kg"
                      ? pricingInfo.marketPrice.pdKg
                      : pricingInfo.marketPrice.pdOz}
                  </span>
                  {/*<img
                  src={IconInformationActive}
                  className="ht-30 wt-30 ml-12"
                />*/}
                </div>
                <div className="c-gray fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  $
                  <span>
                    {marketPriceType === "kg"
                      ? pricingInfo.marketPrice.rhKg
                      : pricingInfo.marketPrice.rhOz}
                  </span>
                  {/*<img
                  src={IconInformationActive}
                  className="ht-30 wt-30 ml-12"
                />*/}
                </div>
              </div>
              <div className="flex flex-middle mb-20">
                <div className="flex flex-middle flex-1 mr-16">
                  <div className="c-gray fw-bolder fw-16 mr-20 wt-130">
                    HEDGE PRICES
                  </div>
                  <span className="fs-14 c-gray fw-bolder mr-4">KG</span>
                  <Toggle
                    className="bg-theme hide-check-cross"
                    onChange={(e) => {
                      setHedgePriceType(e.target.checked ? "oz" : "kg");
                      if ((ptModifier || "").length > 0) setPtModifer("");
                      if ((pdModifier || "").length > 0) setPdModifer("");
                      if ((rhModifier || "").length > 0) setRhModifer("");
                    }}
                    checked={hedgePriceType !== "kg"}
                  />
                  <span className="fs-14 c-gray fw-bolder ml-4">OZ</span>
                </div>
                <FormInput
                  className="flex-1 mr-16 "
                  inputClassName="ht-40 boxWidth  fs-16 pl-28 bg-blue-price rounded-pill"
                  value={
                    "$" +
                    pricingInfo.abcPrice[
                      hedgePriceType === "kg" ? "ptKg" : "ptOz"
                    ]
                  } //getPt(hedgePriceType)}
                  isReadonly={true}
                  // isReadonly={!canEditMetalPrices}
                  onChange={(e) => {
                    const val = e.target.value;
                    setPtModifer("");
                    if (hedgePriceType === "kg") {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          ptKg: val,
                        },
                      });
                    } else {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          ptOz: val,
                        },
                      });
                    }
                  }}
                />

                <FormInput
                  className="flex-1 mr-16"
                  inputClassName="ht-40  boxWidth fs-16 pl-28 bg-blue-price rounded-pill"
                  value={
                    "$" +
                    pricingInfo.abcPrice[
                      hedgePriceType === "kg" ? "pdKg" : "pdOz"
                    ]
                  } //getPd(hedgePriceType)}
                  isReadonly={true}
                  // isReadonly={!canEditMetalPrices}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (hedgePriceType === "kg") {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          pdKg: val,
                        },
                      });
                    } else {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          pdOz: val,
                        },
                      });
                    }
                  }}
                />
                <FormInput
                  className="flex-1 mr-16"
                  inputClassName="ht-40 boxWidth fs-16 pl-28 bg-blue-price rounded-pill"
                  value={
                    "$" +
                    pricingInfo.abcPrice[
                      hedgePriceType === "kg" ? "rhKg" : "rhOz"
                    ]
                  } //getRh(hedgePriceType)}
                  // isReadonly={!canEditMetalPrices}
                  isReadonly={true}
                  onChange={(e) => {
                    const val = e.target.value;
                    if (hedgePriceType === "kg") {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          rhKg: val,
                        },
                      });
                    } else {
                      setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          rhOz: val,
                        },
                      });
                    }
                  }}
                />
              </div>
              <div
                style={{
                  background: "#000",
                  height: "12px",
                }}
                className="mt-4 mb-24"
              />
              <div className="flex flex-middle">
                <div className="flex flex-middle flex-1 mr-28">
                  <div className="c-gray fw-bolder fw-16 mr-20">
                    GLOBAL MODIFIERS
                  </div>
                </div>
                <FormInput
                  className="flex-1 mr-16 "
                  inputClassName="ht-40 boxWidth fs-16 pl-28 bg-blue-price rounded-pill"
                  value={pricingInfo.abcPrice.ptDisc}
                  isReadonly={true}
                  // isReadonly={!canEditMetalPrices || hedgePriceType==='kg'}
                  onChange={(e) => {
                    const val = hedgePriceType === "oz" ? e.target.value : "";
                    setPtModifer(val);
                  }}
                />
                <FormInput
                  className="flex-1 mr-16"
                  inputClassName="ht-40  boxWidth fs-16 pl-28 bg-blue-price rounded-pill"
                  value={pricingInfo.abcPrice.pdDisc}
                  isReadonly={true}
                  // isReadonly={!canEditMetalPrices || hedgePriceType==='kg'}
                  onChange={(e) => {
                    const val = hedgePriceType === "oz" ? e.target.value : "";
                    setPdModifer(val);
                  }}
                />
                <FormInput
                  className="flex-1 mr-16"
                  inputClassName="ht-40 boxWidth fs-16 pl-28 bg-blue-price rounded-pill"
                  value={pricingInfo.abcPrice.rhDisc}
                  isReadonly={true}
                  // isReadonly={!canEditMetalPrices || hedgePriceType==='kg'}
                  onChange={(e) => {
                    const val = hedgePriceType === "oz" ? e.target.value : "";
                    setRhModifer(val);
                  }}
                />
              </div>

              <div className="flex flex-middle mb-20">
                <div className="flex flex-middle flex-1 mr-28">
                  <div className="c-red fw-bolder fw-16 mr-20">
                    NET PRICE APPLIED
                  </div>
                </div>
                <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  <span>${getPt(hedgePriceType)}</span>
                </div>
                <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  <span>${getPd(hedgePriceType)}</span>
                </div>
                <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
                  <span>${getRh(hedgePriceType)}</span>
                </div>
              </div>
            </div>
          ) : <div className="mb-4 mt-16">
          <div className="flex flex-middle mb-16 colour-blue ">
            <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
              MATERIAL PRICES
            </div>
            <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
              PLATINUM
            </div>
            <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
              PALLADIUM
            </div>
            <div className="flex-1 c-red fs-16 fw-bolder mb-12 mr-16 border-bottom-thick pb-4">
              RHODIUM
            </div>
          </div>
          <div className="flex flex-middle mb-20">
            <div className="flex flex-middle flex-1 mr-16">
              <div className="c-gray fw-bolder fw-16 mr-20 wt-130">
                MARKET PRICES
              </div>
              <span className="fs-14 c-gray fw-bolder mr-4">KG</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setMarketPriceType(e.target.checked ? "oz" : "kg");
                }}
                checked={marketPriceType !== "kg"}
              />
              <span className="fs-14 c-gray fw-bolder ml-4">OZ</span>
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              {/*<span className="ml-8">{marketPriceType === 'kg'?pricingInfo.marketPrice.ptKg:pricingInfo.marketPrice.ptOz}</span>*/}
              <span className="c-black">$</span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.marketPrice[
                    marketPriceType === "kg" ? "ptKg" : "ptOz"
                  ]
                }
                onChange={(e) => {
                  if (marketPriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            ptKg: e.target.value,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            ptOz: e.target.value,
                          },
                        });
                  }
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              {/*<span className="ml-8">{marketPriceType === 'kg'?pricingInfo.marketPrice.pdKg:pricingInfo.marketPrice.pdOz}</span>*/}
              <span className="c-black">$</span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.marketPrice[
                    marketPriceType === "kg" ? "pdKg" : "pdOz"
                  ]
                }
                onChange={(e) => {
                  if (marketPriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            pdKg: e.target.value,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            pdOz: e.target.value,
                          },
                        });
                  }
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              {/*<span className="ml-8">{marketPriceType === 'kg'?pricingInfo.marketPrice.rhKg:pricingInfo.marketPrice.rhOz}</span>  */}
              <span className="c-black">$</span>
              <FormInput
                className="flex-1 "
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.marketPrice[
                    marketPriceType === "kg" ? "rhKg" : "rhOz"
                  ]
                }
                onChange={(e) => {
                  if (marketPriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            rhKg: e.target.value,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          marketPrice: {
                            ...pricingInfo.marketPrice,
                            rhOz: e.target.value,
                          },
                        });
                  }
                }}
              />
            </div>
          </div>

          <div className="flex flex-middle mb-20">
            <div className="flex flex-middle flex-1 mr-16">
              <div className="c-gray fw-bolder fw-16 mr-20 wt-130">
                HEDGE PRICES
              </div>
              <span className="fs-14 c-gray fw-bolder mr-4">KG</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setHedgePriceType(e.target.checked ? "oz" : "kg");
                  if ((ptModifier || "").length > 0) setPtModifer("");
                  if ((pdModifier || "").length > 0) setPdModifer("");
                  if ((rhModifier || "").length > 0) setRhModifer("");
                }}
                checked={hedgePriceType !== "kg"}
              />
              <span className="fs-14 c-gray fw-bolder ml-4">OZ</span>
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span className="c-black">$</span>
              <FormInput
                className="flex-1 "
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.abcPrice[
                    hedgePriceType === "kg" ? "ptKg" : "ptOz"
                  ]
                } //getPt(hedgePriceType)
                isReadonly={!canEditMetalPrices}
                onChange={(e) => {
                  const val = e.target.value;
                  if (hedgePriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            ptKg: val,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            ptOz: val,
                          },
                        });
                  }
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span className="c-black">$</span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.abcPrice[
                    hedgePriceType === "kg" ? "pdKg" : "pdOz"
                  ]
                } //getPd(hedgePriceType)}
                isReadonly={!canEditMetalPrices}
                onChange={(e) => {
                  const val = e.target.value;
                  if (hedgePriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            pdKg: val,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            pdOz: val,
                          },
                        });
                  }
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span className="c-black">$</span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={
                  pricingInfo.abcPrice[
                    hedgePriceType === "kg" ? "rhKg" : "rhOz"
                  ]
                } //getRh(hedgePriceType)}
                isReadonly={!canEditMetalPrices}
                onChange={(e) => {
                  const val = e.target.value;
                  if (hedgePriceType === "kg") {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            rhKg: val,
                          },
                        });
                  } else {
                    let val = e.target.value;
                    val.length > 5
                      ? (val = val.slice(0, 5))
                      : setPricingInfo({
                          ...pricingInfo,
                          abcPrice: {
                            ...pricingInfo.abcPrice,
                            rhOz: val,
                          },
                        });
                  }
                }}
              />
            </div>
          </div>
          <div
            style={{
              background: "#000",
              height: "12px",
              border: "2px solid #787878",
            }}
            className="border-bottom-thick mt-4 mb-24"
          />

          <div className="flex flex-middle mb-12">
            <div className="flex flex-middle flex-1 mr-20">
              <div className="c-gray fw-bolder fw-16 mr-20 wt-130">
                GLOBAL MODIFIERS
              </div>
              <span className="fs-14 c-gray fw-bolder mr-4">%</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setModifierPriceType(e.target.checked ? "$" : "%");
                  getPricing(!e.target.checked);
                }}
                checked={modifierPriceType !== "%"}
              />
              <span className="fs-14 c-gray fw-bolder ml-4">$</span>
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span
                className="c-black pl-4"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={pricingInfo.abcPrice.ptDisc}
                isReadonly={!canEditMetalPrices || hedgePriceType === "kg"}
                onChange={(e) => {
                  let val = hedgePriceType === "oz" ? e.target.value : "";

                  val.length > 5
                    ? (val = val.slice(0, 5))
                    : setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          ptDisc: val,
                        },
                      });
                  setPtModifer(val);
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span
                className="c-black pl-4"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={pricingInfo.abcPrice.pdDisc}
                isReadonly={!canEditMetalPrices || hedgePriceType === "kg"}
                onChange={(e) => {
                  let val = hedgePriceType === "oz" ? e.target.value : "";
                  val.length > 5
                    ? (val = val.slice(0, 5))
                    : setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          pdDisc: val,
                        },
                      });
                  setPdModifer(val);
                }}
              />
            </div>
            <div className="c-gray fs-24 fw-bolder flex-1 flex flex-middle mr-16">
              <span
                className="c-black pl-4"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <FormInput
                className="flex-1"
                inputClassName="ht-40 fs-16 pl-28 boxWidth bg-blue rounded-pill"
                value={pricingInfo.abcPrice.rhDisc}
                isReadonly={!canEditMetalPrices || hedgePriceType === "kg"}
                onChange={(e) => {
                  let val = hedgePriceType === "oz" ? e.target.value : "";

                  val.length > 5
                    ? (val = val.slice(0, 5))
                    : setPricingInfo({
                        ...pricingInfo,
                        abcPrice: {
                          ...pricingInfo.abcPrice,
                          rhDisc: val,
                        },
                      });
                  setRhModifer(val);
                }}
              />
            </div>
          </div>
          <div className="flex flex-middle mb-20">
            <div className="flex flex-middle flex-1 mr-20">
              <div className="c-red fw-bolder fw-16 mr-20 wt-130">
                NET PRICE APPLIED
              </div>
              <div style={{ visibility: "hidden" }}>
                <span className="fs-14 c-gray fw-bolder mr-4">%</span>
                <Toggle
                  className="bg-theme hide-check-cross"
                  onChange={(e) => {
                    getPricing(e.target.checked ? false : true);
                    setModifierPriceType(e.target.checked ? "$" : "%");
                  }}
                  checked={modifierPriceType !== "%"}
                />
                <span className="fs-14 c-gray fw-bolder ml-4">$</span>
              </div>
            </div>
            <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
            <span
                className="c-black pl-16"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <span className="ml-4">${getPt(hedgePriceType)}</span>
            </div>
            <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
            <span
                className="c-black pl-16"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <span className="ml-4">${getPd(hedgePriceType)}</span>
            </div>
            <div className="c-red fs-16 fw-bolder flex-1 mr-16 flex flex-middle">
            <span
                className="c-black pl-16"
                style={{ visibility: "hidden" }}
              >
                $
              </span>
              <span className="ml-4">${getRh(hedgePriceType)}</span>
            </div>
          </div>
        </div>}

          <div className="flex flex-row-reverse mr-16"></div>
        </div>
      )}
          {showTab != -1 && showTab !== 2 && showTab !== 1 && (showTab !== 0 || showGlobalPreview ) ? (
            <>
              <div className="flex flex-right mr-16">
                <div className="wt-50p" />
                <div className="wt-35p" style={{ display: "flex" }}>
                  {showTab !== 3 && showTab !== 4 && showTab !== 5 ? (
                    <>
                    {
                      showTab === 0 ? (
                        <div
                            className="btn  ht-40 wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                            onClick={() => {
                              resetFacilties();
                              setShowGlobalPreview(!showGlobalPreview)
                            }}
                          >
                            <span>{'Cancel'}</span>
                          </div>
                      ) : null
                    }
                    <div
                      className="btn  ht-40 wt-120 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                      onClick={() => setIsPreviewConfirm(true)}
                    >
                      <span>Preview</span>
                    </div>
                    </>
                  ) : (
                    <div
                      className="btn  ht-40 wt-120 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                      onClick={() => {
                        resetFacilties();
                        props.history.push("/pricing")
                      }}
                    >
                      <span>Cancel</span>
                    </div>
                  )}

                  <div
                    className="btn ht-40  wt-180 mb-12 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => setIsConfirmSavePopupshown(true)} //editPricing(false)}
                  >
                    <span>Apply To DB</span>
                  </div>
                </div>
              </div>
            </>
          ) : null}

          {isPreviewConfirm && (
                  <Modal>
                    <div className="pl-16 pr-16">
                      <div className="fs-18 fw-bolder t-center mb-20">
                        Are you sure you want to update the Preview data?
                      </div>
                      <div className="flex flex-middle flex-center">
                        <Button
                          className="mr-20"
                          type="secondary"
                          onClick={() => setIsPreviewConfirm(false)}
                        >
                          Cancel
                        </Button>
                        <Button
                          type="modalSave"
                          onClick={() => showTab !== 5?editPricing(true):editGlobalDefaults()}
                        >
                          Confirm
                        </Button>
                      </div>
                    </div>
                  </Modal>
                )}

        </div>
      )}
      {isLoading && (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      )}

{showTab === 1 && <PricingIdentifyConverters />}

      {/*<div className="flex flex-between mt-24">
                <div className="c-red fs-18 fw-bolder ">Converter Prices</div>
            </div>
            <Table scrollTable={true} 
                type="converters"
                columns={[
                    'Manufacturer',
                    'Description',
                    'OEM',
                    'Market Price'
                ]}
                items = {priceModifierItems.map((converter)=> {
                    return {
                        ...converter,
                        link: `/converter/${converter.productId}`
                    }
                })}
                keys={['manufacturer', 'description', 'oem', 'marketPrice']}
                actions={['DELETE', 'VIEW']}
                viewLinkKey='link'
                isCart={true}
                offset={params.offset}
                areMoreItemsAvailable={params.areMoreItemsAvailable}
                fetchMore={fetchMore}
                fetchLess={fetchLess}
                isLoading={isLoading}
                onDelete={setDelete}
                className="min-wt-1000"

                onCountChange={(value)=> {
                    setParams({
                        ...params,
                        limit: value,
                        offset: 0,
                    })
                }}
                limit={params.limit}

            />*/}
      {(((showTab !== 1 && showTab !==-1 && showTab !== 0) || (showTab ===0 && showGlobalPreview)) && hierarchy !== 10)? (
        <div className="flex flex-between mt-24">
          {showTopResults ? (
            <div
              className="c-red fs-18 fw-bolder c-pointer mb-12"
              onClick={() => setShowTopResults(false)}
            >
              <span className="fs-20"> - </span> Hide Top Results
            </div>
          ) : (
            <div
              className="c-red fs-18 fw-bolder c-pointer mb-12"
              onClick={() => setShowTopResults(true)}
            >
              <span className="fs-20"> + </span> Show Top Results
            </div>
          )}
        </div>
      ) : null}

      <div
        className="flex flex-between dividedTables"
        style={showTopResults ? null : { display: "none" }}
      >
        {chunkArray(topResults, 2).map((arr, idx) => {
          const formatedArr = (arr || []).map((obj) => {
            return {
              ...obj,
              atlantaRates: `semicolon-separated-inline-O: ${obj.atlantaPrice}; N: ${obj.atlantaPreviewPrice}`,
              burlingtonRates: `semicolon-separated-inline-O: ${obj.burlingtonPrice}; N: ${obj.burlingtonPreviewPrice}`,
              tampaRates: `semicolon-separated-inline-O: ${obj.tampaPrice}; N: ${obj.tampaPreviewPrice}`,
              marketRates: `semicolon-separated-inline-O: ${
                obj.marketPrice
              }; N: ${obj.mpp || ""}`,
            };
          });
          return (
            <Table
              scrollTable={true}
              key={`arr-${idx}`}
              type="converters"
              columns={["Make", "OEM", "Price", "A", "B", "T"]}
              items={formatedArr || []}
              keys={[
                "manufacturer",
                "oem",
                "marketRates",
                "atlantaRates",
                "burlingtonRates",
                "tampaRates",
              ]}
              offset={params.offset}
              areMoreItemsAvailable={params.areMoreItemsAvailable}
              fetchMore={fetchMore}
              fetchLess={fetchLess}
              isLoading={isLoading}
              hideCheckBox={true}
              hideTableCounter={true}
              widthClasses={["", "wt-90", "", "", "", ""]}
            />
          );
        })}
      </div>

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="converter"
          name={itemForDelete.name}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      {isConfirmSavePopupshown && (
        <Modal>
          <div className="pl-16 pr-16">
            <div className="fs-18 fw-bolder t-center mb-20">
              Are you sure you want to save the change ?
            </div>
            <div className="flex flex-middle flex-center">
              <Button
                className="mr-20"
                type="secondary"
                onClick={() => setIsConfirmSavePopupshown(false)}
              >
                Cancel
              </Button>
              <Button type="modalSave" onClick={() =>  showTab !== 5?editPricing(false):editGlobalDefaults()}>
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </PageLayout>
  );
};

export default PreviewPricing;
