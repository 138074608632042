import axios from "axios";

import { getToken, deleteToken } from "./storage";

const baseUrl = 'https://catapi.abcattech.io';
//const baseUrl = "http://3.17.154.101:9000";

export const SIGNIN_IN_URL = `${baseUrl}/write/v1/user/login`;
export const FORGOT_PASSWORD_URL = `${baseUrl}/write/v1/user/forgotPassword`;
export const FILE_UPLOAD_URL = `${baseUrl}/write/v1/image/upload`;
export const MULTIPLE_FILE_UPLOAD_URL = `${baseUrl}/write/v1/imageupload/upload/multiple`;
export const CHANGE_PASSWORD_URL = `${baseUrl}/write/v1/user/changePassword`;

export const PRICE_MODIFIER_URL = `${baseUrl}/read/v1/priceModifiers/getAll`;
export const ADD_PRICE_MODIFIER_URL = `${baseUrl}/write/v1/priceModifiers/add`;
export const EDIT_PRICE_MODIFIER_URL = `${baseUrl}/write/v1/priceModifiers/edit`;
export const DELETE_PRICE_MODIFIER_URL = `${baseUrl}/write/v1/priceModifiers/delete`;

export const GET_PERMISSIONS_URL = `${baseUrl}/read/v1/userPermissions/getAll`;
export const EDIT_PERMISSIONS_URL = `${baseUrl}/write/v1/userPermissions/update`;
export const GET_ROLES_URL = `${baseUrl}/read/v1/userRoles/getAll`;
export const ADD_ROLES_URL = `${baseUrl}/write/v1/userRoles/add`;
export const EDIT_ROLES_URL = `${baseUrl}/write/v1/userRoles/edit`;
export const DELETE_ROLES_URL = `${baseUrl}/write/v1/userRoles/delete`;

export const DOWNLOAD_INVOICES = `${baseUrl}/read/v1load/download/invoice/dump`;  

export const GET_ENTITIES_URL = `${baseUrl}/read/v1/entity/getAll`;
export const GET_ENTITY_DETAIL_URL = `${baseUrl}/read/v1/entity/get`;
export const ADD_ENTITIES_URL = `${baseUrl}/write/v1/entity/add`;
export const EDIT_ENTITIES_URL = `${baseUrl}/write/v1/entity/edit`;
export const MESSAGE_ENTITIES_URL = `${baseUrl}/read/v1/entity/send/login/details`;
export const DELETE_ENTITIES_URL = `${baseUrl}/write/v1/entity/delete`;
export const ONBORAD_ENTITY_URL = `${baseUrl}/write/v1/entity/onboard`;
export const GET_GLOBAL_MODIFIERS = `${baseUrl}/read/v1/pricing/global/modifier/dashboard`;

export const GET_FACILITIES_URL = `${baseUrl}/read/v1/facility/getAll`;
export const ADD_FACILITIES_URL = `${baseUrl}/write/v1/facility/add`;
export const EDIT_FACILITIES_URL = `${baseUrl}/write/v1/facility/edit`;
export const DELETE_FACILITIES_URL = `${baseUrl}/write/v1/facility/delete`;

export const GET_OEM_URL = `${baseUrl}/read/v1/oem/search`;

export const GET_USERS_URL = `${baseUrl}/read/v1/entityUser/getAll`;
export const ADD_USERS_URL = `${baseUrl}/write/v1/entityUser/add`;
export const EDIT_USERS_URL = `${baseUrl}/write/v1/entityUser/edit`;
export const DELETE_USERS_URL = `${baseUrl}/write/v1/entityUser/delete`;

export const PRICE_MODIFIER_GRAPH_URL = `${baseUrl}/read/v1/priceModifiers/getByType`;

export const GET_SALES_HISTORY_URL = `${baseUrl}/read/v1/history/sales`;

export const GET_CONVERTERS_URL = `${baseUrl}/read/v1/product/getAll`;
export const GET_CONVERTER_DETAILS_URL = `${baseUrl}/read/v1/product/get`;
export const DELETE_CONVERTERS_URL = `${baseUrl}/write/v1/product/delete`;
export const ADD_CONVERTERS_URL = `${baseUrl}/write/v1/product/add`;
export const EDIT_CONVERTERS_URL = `${baseUrl}/write/v1/product/edit`;
export const GET_PRODUCT_DETAILS_URL = `${baseUrl}/read/v1/product/get`;
export const GET_PRICE_HISTORY_URL = `${baseUrl}/read/v1/product/getPriceHistory`;

export const GET_TEST_HISTORY_URL = `${baseUrl}/read/v1/test/getAll`;
export const ADD_TEST_URL = `${baseUrl}/write/v1/test/add`;
export const EDIT_TEST_URL = `${baseUrl}/write/v1/test/edit`;
export const DELETE_TEST_URL = `${baseUrl}/write/v1/test/delete`;

export const ASK_EXPERT_POST_URL = `${baseUrl}/write/v1/expert/ask`;

export const GET_MAKE_MODEL_URL = `${baseUrl}/read/v1/make/getAll`;
export const ADD_MAKE_MODEL_URL = `${baseUrl}/write/v1/make/add`;
export const EDIT_MAKE_MODEL_URL = `${baseUrl}/write/v1/make/edit`;
export const DELETE_MAKE_MODEL_URL = `${baseUrl}/write/v1/make/delete`;

export const GET_EXPERT_ADVICES_URL = `${baseUrl}/read/v1/expert/getAll`;
export const EDIT_EXPERT_ADVICES_URL = `${baseUrl}/write/v1/expert/edit`;
export const DELETE_EXPERT_ADVICES_URL = `${baseUrl}/write/v1/expert/delete`;

export const GET_PRICING_URL = `${baseUrl}/read/v1/priceModifiers/getAll`;
export const EDIT_PRICING_URL = `${baseUrl}/write/v1/priceModifiers/edit`;

export const GET_GLOBAL_DEFAULTS_URL = `${baseUrl}/read/v1/global/metal/assay`;
export const EDIT_GLOBAL_DEFAULTS_URL = `${baseUrl}/write/v1/global/metal/assay`;

export const LOGOUT_URL = `${baseUrl}/write/v1/user/logout`;

export const GET_PREVIEW_LOAD_DETAILS = `${baseUrl}/read/v1/load/invoice/pdf/generate`;

export const GET_ALL_LOADS_URL = `${baseUrl}/read/v1/load/getAll`;
export const GET_ALL_SAGE_LOADS_URL = `${baseUrl}/read/v1/sage/invoice`;
export const CREATE_LOAD_URL = `${baseUrl}/write/v1/load/add`;
export const GET_LOAD_DETAILS_URL = `${baseUrl}/read/v1/load/get`;
export const ADD_ITEM_TO_LOAD = `${baseUrl}/write/v1/load/items/add`;
export const EDIT_ITEM_IN_LOAD = `${baseUrl}/write/v1/load/items/edit`;
export const DELETE_ITEM_FROM_LOAD = `${baseUrl}/write/v1/load/items/delete`;
export const GET_ALL_GRADER_URL = `${baseUrl}/read/v1/graders/getAll`;
export const MODIFY_LOAD_URL = `${baseUrl}/write/v1/load/edit`;
export const CREATE_NEW_SESSION = `${baseUrl}/write/v1/load/request`;
export const FINISH_LOAD_ORDER = `${baseUrl}/write/v1/load/accept`;
export const GET_LOAD_ITEMS_URL = `${baseUrl}/read/v1/load/items/getAll`;

export const SUBMIT_LOAD_INFO = `${baseUrl}/write/v1/load/update/lot/info`;

export const GET_CONVERTER_CART_URL = `${baseUrl}/read/v1/load/getAll`;
export const ADD_ITEMS_TO_CONV_CART_URL = `${baseUrl}/write/v1/load/items/add`;
export const GET_CONVERTER_TYPES_URL = `${baseUrl}/read/v1/product/getTypes`;
export const GET_PAYMENT_STATUS_URL = `${baseUrl}/read/v1/load/payments`;
export const POST_PAYMENT_STATUS_URL = `${baseUrl}/write/v1/load/payments/add`;

export const GET_RESEND_INVOICE_URL = `${baseUrl}/read/v1/load/resend/invoice`;

export const GET_BULK_UPLOAD_URL = `${baseUrl}/read/v1/product/bulk/upload/status`;
export const POST_BULK_UPLOAD_URL = `${baseUrl}/write/v1/product/bulk/upload`;
export const ADD_LOAD_URL = `${baseUrl}/write/v1/load/add`;

export const DELETE_PAYMENT_URL = `${baseUrl}/write/v1/load/payments/delete`;
export const UPLOAD_PDF_FILE_URL = `${baseUrl}/write/v1/load/complete`;
export const UPLOAD_INVOICE_PDF_URL = `${baseUrl}/write/v1/invoice/upload`;

export const DOWNLOAD_CONVERTER_URL = `${baseUrl}/read/v1/product/dump`;

export const PRODUCT_BULK_EDIT_URL = `${baseUrl}/write/v1/product/bulk/edit`;

export const POST_INVITE_USER_URL = `${baseUrl}/write/v1/entityUser/invite`;
export const GET_INVITE_USER_URL = `${baseUrl}/read/v1/entityUser/invite`;

export const GET_TOP_CUSTOMERS = `${baseUrl}/read/v1/load/top/customers/report`;

export const GET_TOP_CATS_URL = `${baseUrl}/read/v1/product/getTop100Cats`;
export const GET_FAMILIES_URL = `${baseUrl}/read/v1/product/getAllFamily`;
export const ADD_OVERRIDE_MODIFIER_URL = `${baseUrl}/write/v1/overrideModifier/add`;
export const GET_ALL_OVERRIDE_MODIFIER_URL = `${baseUrl}/read/v1/overrideModifier/getAll`;
export const GET_DASHBOARD_DATA_URL = `${baseUrl}/read/v1/load/cost/sheet/reports/dashboard`;
export const GET_DASHBOARD_REPORT_URL = `${baseUrl}/read/v1/load/cost/sheet/reports`;
export const GET_CUSTOMERS_REPORT_URL = `${baseUrl}/read/v1/entity/download`;

export const GET_TRANSACTIONS_DATA_URL = `${baseUrl}/read/v1/load/dashboard/transactions/report`;
export const GET_TRANSACTIONS_DATA_DOWNLOAD_URL = `${baseUrl}/read/v1/load/transactions/report`;

export const GET_DID_NOT_BUY_REPORT_URL = `${baseUrl}/read/v1/load/didNotBuy/report`;

const jsonToUrlParams = (json) => {
  return Object.keys(json)
    .map(function (k) {
      if (typeof json[k] != "undefined" && json[k] !== "") {
        return encodeURIComponent(k) + "=" + encodeURIComponent(json[k]);
      }
      return "";
    })
    .filter((item) => {
      return item;
    })
    .join("&");
};

export const postData = (api, data) => {
  return new Promise((resolve, reject) => {
    axios
      .post(api, data, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const putData = (api, data) => {
  return new Promise((resolve, reject) => {
    axios
      .put(api, data, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        console.log('e', e)
        reject(e);
      });
  });
};
export const deleteData = (api, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .delete(`${api}?${jsonToUrlParams(params)}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const getData = (api, params = {}) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${api}?${jsonToUrlParams(params)}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        if (e.response && e.response.status === 401) {
          deleteToken();
          window.location.href = "/";
        }
        reject(e);
      });
  });
};

export const uploadFile = (url, file, params = {}) => {
  let form = new FormData();
  if (Array.isArray(file)) {
    console.log("asas", file, file[0].file);
    if (file.length === 1) {
      if (file[0].file) {
        form.append("image", file[0].file);
      }
    } else {
      for (let i = 0; i < file.length; i++) {
        if (file[i].file) {
          form.append("image" + (i + 1), file[i].file);
        }
      }
    }
  } else {
    form.append("image", file);
  }
  console.log("file", file);
  Object.keys(params).forEach((key) => {
    form.append([key], params[key]);
  });
  return new Promise((resolve, reject) => {
    fetch(`${url}`, {
      headers: {
        Accept: "application/json",
      },
      method: "POST",
      body: form,
    })
      .then((response) => {
        if (response.status === 401) {
        } else if (response.status >= 400) {
          reject(response);
          return;
        }
        response.json().then((data) => {
          resolve(data);
        });
      })
      .catch((e) => {
        console.log("ERROR", e);
        reject(e);
      });
  });
};

export const uploadFileWithToken = (url, file, params = {}) => {
  let form = new FormData();
  if (Array.isArray(file)) {
    console.log("asas", file, file[0].file);
    if (file.length === 1) {
      if (file[0].file) {
        form.append("image", file[0].file);
      }
    } else {
      for (let i = 0; i < file.length; i++) {
        if (file[i].file) {
          form.append("image" + (i + 1), file[i].file);
        }
      }
    }
  } else {
    form.append("image", file);
  }

  Object.keys(params).forEach((key) => {
    form.append([key], params[key]);
  });
  return new Promise((resolve, reject) => {
    fetch(`${url}`, {
      headers: {
        Accept: "application/json",
        token: getToken(),
      },
      method: "POST",
      body: form,
    })
      .then((response) => {
        if (response.status === 401) {
        } else if (response.status >= 400) {
          // reject(response);
          response.json().then((data) => {
            reject(data);
          });
          return;
        }
        response.json().then((data) => {
          resolve(data);
        });
      })
      .catch((e) => {
        console.log("ERROR", e);
        reject(e);
      });
  });
};

export const topCatsTable = (start, end) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1/load/top/cats?from=${start}&to=${end}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const topCustomersTable = (start, end, startDate = null, endDate = null) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1/load/top/customers/report/dashboard?from=${start}&to=${end}&startDate=${startDate}&endDate=${endDate}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const downloadTopCatsTable = (start, end, startDate = null, endDate = null) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}/read/v1/load/top/cats/download?from=${start}&to=${end}`,
        {
          headers: {
            token: getToken(),
          },
        }
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const downloadGMTable = (start, end) => {
  return new Promise((resolve, reject) => {
    axios
      .get(
        `${baseUrl}/read/v1/pricing/global/modifier/report?from=${start}&to=${end}`,
        {
          headers: {
            token: getToken(),
          },
        }
      )
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const downloadInvoices = (input) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1load/download/invoice/dump?${input}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const downloadDonotBuy= (input) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1/load/didNotBuy/report/dump?${input}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const topLookupsTable = (api, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1/load/top/lookups?from=${api.from}&to=${api.to}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};
export const downloadTopLookupsTable = (api, data) => {
  return new Promise((resolve, reject) => {
    axios
      .get(`${baseUrl}/read/v1/load/top/lookups/download?from=${api.from}&to=${api.to}`, {
        headers: {
          token: getToken(),
        },
      })
      .then((data) => {
        resolve(data.data);
      })
      .catch((e) => {
        reject(e);
      });
  });
};

export const FACILITIY_LIST = ["Florida", "Wisconsin", "Georgia"];

export const FACILITY_CITY_LIST = ["Atlanta", "Burlington", "Tampa"];

export const STATES_LIST = [
  "Alabama",
  "Alaska",
  "Arizona",
  "Arkansas",
  "California",
  "Colorado",
  "Connecticut",
  "Delaware",
  "Florida",
  "Georgia",
  "Hawaii",
  "Idaho",
  "Illinois",
  "Indiana",
  "Iowa",
  "Kansas",
  "Kentucky",
  "Louisiana",
  "Maine",
  "Maryland",
  "Massachusetts",
  "Michigan",
  "Minnesota",
  "Mississippi",
  "Missouri",
  "Montana",
  "Nebraska",
  "Nevada",
  "New Hampshire",
  "New Jersey",
  "New Mexico",
  "New York",
  "North Carolina",
  "North Dakota",
  "Ohio",
  "Oklahoma",
  "Oregon",
  "Pennsylvania",
  "Rhode Island",
  "South Carolina",
  "South Dakota",
  "Tennessee",
  "Texas",
  "Utah",
  "Vermont",
  "Virginia",
  "Washington",
  "West Virginia",
  "Wisconsin",
  "Wyoming",
];
