import React, { useState, useEffect, useRef } from "react";
import { Link } from "react-router-dom";
import HeaderIcon from "../../icons/newicons/cart-fill.svg";
import IconUserAdd from "../../icons/rev_add_user.png";
import PageLayout from "../../components/PageLayout";
import FormInput from "../../components/FormInput";
import { getLoginDetails } from "../../utils/storage";
import Table from "../../components/Table";
import ActionLoader from "../../components/ActionLoader";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import CaretIcon from "../../icons/rev_carat.png";
import Refresh from "../../icons/refresh.svg";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_ALL_LOADS_URL,
  getData,
  postData,
  putData,
  deleteData,
  CREATE_NEW_SESSION,
  ADD_LOAD_URL,
} from "../../utils/api";
let TIMER = null;
const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};

const TABS = [
  { name: "Active Carts", id: 1 },
  { name: "Quotes", id: 5 },
  { name: "Request", id: 2 },
];

const TABS_1 = [
  { name: "Active Carts", id: 1 },
  { name: "Quotes", id: 5 },
  { name: "Request", id: 2 },
  { name: "Franchise", id: 30 },
]

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const ActiveLoads = (props) => {
  const loginDetail = getLoginDetails() || {};
  const isSeller = loginDetail.hierarchy == 2;
  const canAcceptLoadRequest = loginDetail.canAcceptLoadRequest;
  const deleteCart = loginDetail.deleteCart || false;
  const [activeLoadList, setActiveLoadList] = useState([]);
  const [pendingLoadList, setPemdingLoadList] = useState([]);
  const [quotesList, setQuotesList] = useState([]);
  const [franchiseList, setFranchiseList] = useState([]);
  const [selectedPandingLoad, setSelectedPendingLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [timerVal, setTimerVal] = useState(1);
  const [currentTab, setCurrentTab] = useState(1);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [loadForDelete, setLoadForDelete] = useState(null);
  const [activeLoadParams, setActiveLoadParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [pendingLoadParams, setPendingLoadParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [quotesParams, setQuotesParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [franchiseParams, setFranchiseParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const [showConfirmationModal, setShowConfimationModal] = useState(false);
  const [isMyCarts,setIsMyCarts] = useState(undefined)


//   useEffect(() => {
//     getLoads();
// }, [window.location.pathname.includes('/my-cart')]);

  const getLoads = async (type = 1, shouldRefreshTable = true) => {
    console.log("GET LOADS >>>>", shouldRefreshTable, searchValue);
    let params = {};
    try {
      if (type === 1) {
        shouldRefreshTable && setActiveLoadList([]);
        params = activeLoadParams;
      } else if (type === 5) {
        shouldRefreshTable && setQuotesList([]);
        params = quotesParams;
      }  else if (type === 30) {
        shouldRefreshTable && setFranchiseList([]);
        params = franchiseParams;
      } else {
        shouldRefreshTable && setPemdingLoadList([]);
        params = pendingLoadParams;
      }
      if(isSeller) {
        setIsLoading(false);
        return;
      }
      const data = await getData(GET_ALL_LOADS_URL, {
        count: params.limit,
        offset: params.offset,
        text: searchValue,
        isMyCarts:window.location.pathname.includes('/my-cart')?true:undefined,
        type,
      });
      setIsLoading(false);
      setTableLoading(false);
      if (type === 1) {
        setActiveLoadList(data.list);
        setActiveLoadParams({
          ...params,
          areMoreItemsAvailable: data.areMoreItemsAvailable,
        });
      } else if (type === 5) {
        setQuotesList(data.list);
        setQuotesParams({
          ...params,
          areMoreItemsAvailable: data.areMoreItemsAvailable,
        });
      }  else if (type === 30) {
        setFranchiseList(data.list);
        setFranchiseParams({
          ...params,
          areMoreItemsAvailable: data.areMoreItemsAvailable,
        });
      } else {
        setPemdingLoadList(data.list);
        setPendingLoadParams({
          ...params,
          areMoreItemsAvailable: data.areMoreItemsAvailable,
        });
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const onSearchChange = (val) => {
    console.log("ON SEARCH CHANGE", val);
    setSearchValue(val);
  };
  const fetchMore = (type) => {
    if (type === 1) {
      setActiveLoadParams({
        ...activeLoadParams,
        offset: activeLoadParams.offset + activeLoadParams.limit,
      });
    } else if (type === 5) {
      setQuotesParams({
        ...quotesParams,
        offset: quotesParams.offset + quotesParams.offset,
      });
    } else if (type === 30) {
      setFranchiseParams({
        ...franchiseParams,
        offset: quotesParams.offset + quotesParams.offset,
      });
    } else {
      setPendingLoadParams({
        ...pendingLoadParams,
        offset: pendingLoadParams.offset + pendingLoadParams.offset,
      });
    }
  };
  const fetchLess = (type) => {
    if (type === 1) {
      setActiveLoadParams({
        ...activeLoadParams,
        offset: activeLoadParams.offset - activeLoadParams.limit,
      });
    } else if (type === 5) {
      setQuotesParams({
        ...quotesParams,
        offset: quotesParams.offset + quotesParams.offset,
      });
    } else if (type === 30) {
      setFranchiseParams({
        ...franchiseParams,
        offset: franchiseParams.offset + franchiseParams.offset,
      });
    } else {
      setPendingLoadParams({
        ...pendingLoadParams,
        offset: pendingLoadParams.offset - pendingLoadParams.offset,
      });
    }
  };
  const handleRequestAccept = async () => {
    setShowConfimationModal(false);
    try {
      await putData(CREATE_NEW_SESSION, {
        loadId: selectedPandingLoad.loadId,
      });
      setTableLoading(true);
      getLoads(1);
      getLoads(2);
      getLoads(5);
      getLoads(30);
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteLoad = async () => {
    setIsLoading(true)
    setIsDeletePopupShown(false);
    setLoadForDelete(null);
    try {
      await deleteData(ADD_LOAD_URL, {
        cartId: loadForDelete.loadId,
        type: currentTab,
      });
      setTableLoading(true);
      if (currentTab === 2) {
        getLoads(2);
      } else if (currentTab === 1) {
        getLoads(1);
      } else if (currentTab === 5) {
        getLoads(5);
      } else if (currentTab === 30) {
        getLoads(30);
      }
      // getLoads(2);
      // getLoads(5);
      setIsLoading(false)
      setToastParams({
        type: "SUCCESS",
        message: "Deleted Successfully",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
      setIsLoading(false)
    }
  };
  const setDeleteLoad = (item) => {
    setIsDeletePopupShown(true);
    setLoadForDelete(item);
  };
  const hendelPendingLoadClick = async (item) => {
    setShowConfimationModal(true);
    setSelectedPendingLoad(item);
  };
  const hendelUserSelect = (item) => {
    props.history.push(window.location.pathname.includes('/my-cart')?`/my-cart/load/${item.loadId}`:`/loads/load/${item.loadId}`,{
      settledView: props.match.path,
    });
  };
  useEffect(() => {
    if (currentTab === 1) {
      getLoads(1);
    }
  }, [
    searchValue,
    pendingLoadParams.limit,
    pendingLoadParams.offset,
    timerVal,
    currentTab,
    window.location.pathname.includes('/my-cart')
  ]);

  useEffect(() => {
    console.log(currentTab);
    if (currentTab === 2) {
      getLoads(2);
    }
  }, [
    searchValue,
    activeLoadParams.limit,
    activeLoadParams.offset,
    timerVal,
    currentTab,
  ]);

  useEffect(() => {
    if (currentTab === 5) {
      getLoads(5);
    }
  }, [
    searchValue,
    quotesParams.limit,
    quotesParams.offset,
    timerVal,
    currentTab,
  ]);

  useEffect(() => {
    if (currentTab === 30) {
      getLoads(30);
    }
  }, [
    searchValue,
    franchiseParams.limit,
    franchiseParams.offset,
    timerVal,
    currentTab,
  ]);

  const setTimer = () => {};
  // useInterval(()=> {
  //   if (currentTab === 1) {
  //     getLoads(1, false);
  //   } else if (currentTab === 2) {
  //     getLoads(2, false);
  //   } else if (currentTab === 5) {
  //     getLoads(5, false);
  //   }
  // }, 10000)
  return (
    <PageLayout
      icon={HeaderIconSvg}
      heading="Carts"
      searchPlaceholder="Search Carts"
      onSearchChange={onSearchChange}
    >
      {isLoading ? (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      ) : (
        <>
          {showConfirmationModal && (
            <Modal>
              <div className="flex flex-column flex-between wt-400 ht-200">
                <div className="flex flex-middle flex-between">
                  <div className="flex flex-middle">
                    <h1 className="mr-24 fs-16 c-gray mb-4">
                      INCOMING GRADING REQUEST
                    </h1>
                  </div>
                  <div
                    className="flex flex-middle flex-between bg-gray c-white fw-bolder c-pointer pl-4 pr-4 fs-14 pt-4 pb-4"
                    onClick={() => {
                      setShowConfimationModal(false);
                    }}
                  >
                    <span>CLOSE </span>
                  </div>
                </div>
                <div className="c-gray fs-13">
                  You have recieved a grading request from
                </div>
                <div className="flex flex-column">
                  <span>{`${selectedPandingLoad.firstName} ${selectedPandingLoad.lastName}`}</span>
                  <span>{`${selectedPandingLoad.companyName}`}</span>
                  <span>{`${selectedPandingLoad.city}, ${selectedPandingLoad.state}`}</span>
                </div>
                <div className="flex  flex-between ">
                  <div
                    className="flex flex-middle flex-center c-white pl-24 pr-24 c-white fw-bolder c-pointer  bg-green fs-14 pt-4 pb-4"
                    onClick={handleRequestAccept}
                  >
                    <span> ACCEPT REQUEST NOW </span>
                  </div>
                  <div
                    className="flex flex-middle fs-14 bg-gray c-white fw-bolder c-pointer pl-24 pr-24 pt-4 pb-4"
                    onClick={() => {
                      setShowConfimationModal(false);
                    }}
                  >
                    <span>VIEW LOAD REQUEST </span>
                  </div>
                </div>
              </div>
            </Modal>
          )}

          <div
            className={` tabs flex p-absolute`}
            style={{ "margin-left": "-5px" }}
          >
            {window.location.pathname.includes('/my-cart')?[]:(loginDetail.hierarchy!==1?TABS:TABS_1).map((tab, index) => {
              return (
                <div
                  className={
                    currentTab === tab.id
                      ? `ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder'`
                      : `ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`
                  }
                  onClick={() => {
                    setCurrentTab(tab.id);
                  }}
                >
                  <span>{tab.name}</span>
                </div>
              );
            })}
            <div
              className="pl-8 pr-8 pb-8 pt-20 c-white fw-bolder c-pointer"
              onClick={() => {
                if (currentTab === 1) {
                  getLoads(1, false);
                } else if (currentTab === 2) {
                  getLoads(2, false);
                } else if (currentTab === 5) {
                  getLoads(5, false);
                } else if (currentTab === 30) {
                  getLoads(30, false);
                }
              }}
            >
              <img src={Refresh} className="ht-20 wt-20 " />
            </div>
            {/*<div className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`} onClick={() => { props.history.push('/entities/users') }}>
                  <span>USERS</span>
              </div>*/}
          </div>
          {!isSeller && currentTab!==1 && (
            <Link
              to="/entities/create"
              className="btn ht-40 decoration-none flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer  p-absolute r-4"
            >
              <img src={IconUserAdd} className="ht-20 wt-20 mr-12" />
              <span>Create Customer </span>
            </Link>
          )}

          {currentTab === 2 && (
            <>
              <div className="flex flex-middle flex-between">
                <div className="flex flex-middle">
                  <h1
                    className="mr-24 fs-24 c-gray mb-4 invisible"
                    style={{ visibility: "hidden" }}
                  >
                    GRADING SESSION REQUEST
                  </h1>
                </div>
              </div>
              {pendingLoadList.length === 0 && (
                <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
                  No requests available
                </div>
              )}
              {pendingLoadList.length > 0 && (
                <Table
                  scrollTable={true}
                  columns={[
                    "Customer Name",
                    "CART NAME",
                    "CUSTOMER ",
                    "LOCATION",
                    "Date",
                    "status",
                    "ASSISTANT",
                    "GRADER",
                  ]}
                  items={pendingLoadList.map((load, index) => {
                    const createdDate = new Date(
                      load.updatedDate || load.createdDate
                    );
                    const dd = String(createdDate.getDate()).padStart(2, "0");
                    const mm = String(createdDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    ); //January is 0!
                    const yyyy = createdDate.getFullYear();
                    let hours = createdDate.getHours();
                    let minutes = createdDate.getMinutes();
                    const ampm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    return {
                      ...load,
                      loadCustName: load.firstName + " " + load.lastName,
                      group: load.companyName,
                      location: load.city + ", " + load.state,
                      date: `${mm}-${dd}-${yyyy} ${hours}:${minutes}${ampm}`,
                      statusVal: STATUS_MAPPING[load.status],
                      assistantName: `${load.assistantFirstName || ""} ${
                        load.assistantLastName || ""
                      }`,
                      videoGraderName: `${load.videoGraderFirstName || ""} ${
                        load.videoGraderLastName || ""
                      }`,
                    };
                  })}
                  keys={[
                    "loadCustName",
                    "loadName",
                    "group",
                    "location",
                    "date",
                    "statusVal",
                    "assistantName",
                    "videoGraderName",
                  ]}
                  widthClasses={[
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "wt-100",
                  ]}
                  hideCheckBox={true}
                  onDelete={setDeleteLoad}
                  actions={["DELETE"]}
                  onItemClick={
                    canAcceptLoadRequest ? hendelPendingLoadClick : () => {}
                  }
                  isLoading={tableLoading}
                  fetchMore={fetchMore}
                  fetchLess={fetchLess}
                  onCountChange={(value) => {
                    setPendingLoadParams({
                      ...pendingLoadParams,
                      offset: 0,
                      limit: value,
                    });
                  }}
                  limit={pendingLoadParams.limit}
                />
              )}
              {pendingLoadList.length > 0 &&
                (pendingLoadParams.offset >= 10 ||
                  pendingLoadParams.areMoreItemsAvailable) && (
                  <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                    <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate-90 c-pointer ${
                          pendingLoadParams.offset > 0 ? "op-7" : "op-2"
                        }`}
                        onClick={
                          pendingLoadParams.offset > 0
                            ? () => {
                                fetchLess(2);
                              }
                            : () => {}
                        }
                      />
                    </div>
                    <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                      Showing {pendingLoadParams.offset + 1} -{" "}
                      {pendingLoadParams.offset + pendingLoadList.length}
                    </span>
                    <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate--90 c-pointer ${
                          pendingLoadParams.areMoreItemsAvailable
                            ? "op-7"
                            : "op-2"
                        }`}
                        onClick={
                          pendingLoadParams.areMoreItemsAvailable
                            ? () => {
                                fetchMore(2);
                              }
                            : () => {}
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          )}

          {currentTab === 5 && (
            <>
              <div className="flex flex-middle flex-between">
                <div className="flex flex-middle">
                  <h1 className="mr-24 fs-24 c-gray mb-4"> QUOTES </h1>
                </div>
              </div>
              {quotesList.length === 0 && (
                <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
                  No Quotes available
                </div>
              )}
              {quotesList.length > 0 && (
                <Table
                  scrollTable={true}
                  columns={[
                    "Customer",
                    "Cart Name",
                    "Facility",
                    "Date",
                    "Status",
                    "Buyer",
                    "Grader",
                  ]}
                  items={quotesList.map((load, index) => {
                    const createdDate = new Date(
                      load.updatedDate || load.createdDate
                    );
                    const dd = String(createdDate.getDate()).padStart(2, "0");
                    const mm = String(createdDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    ); //January is 0!
                    const yyyy = createdDate.getFullYear();
                    let hours = createdDate.getHours();
                    let minutes = createdDate.getMinutes();
                    const ampm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    return {
                      ...load,
                      loadCustName: load.firstName + " " + load.lastName,
                      group: load.companyName,
                      location: load.city + ", " + load.state,
                      date: `${mm}-${dd}-${yyyy} ${hours}:${minutes}${ampm}`,
                      statusVal: STATUS_MAPPING[load.status],
                      assistantName: `${load.assistantFirstName || ""} ${
                        load.assistantLastName || ""
                      }`,
                      videoGraderName: `${load.videoGraderFirstName || ""} ${
                        load.videoGraderLastName || ""
                      }`,
                    };
                  })}
                  keys={[
                    "loadCustName",
                    "loadName",
                    "facilityCity",
                    "date",
                    "statusVal",
                    "assistantName",
                    "videoGraderName",
                  ]}
                  widthClasses={[
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "wt-100",
                  ]}
                  hideCheckBox={true}
                  onDelete={setDeleteLoad}
                  actions={["DELETE"]}
                  onItemClick={hendelUserSelect}
                  isLoading={tableLoading}
                  fetchMore={fetchMore}
                  fetchLess={fetchLess}
                  limit={quotesParams.limit}
                  onCountChange={(value) => {
                    setQuotesParams({
                      ...quotesParams,
                      offset: 0,
                      limit: value,
                    });
                  }}
                />
              )}
               {quotesList.length > 0 &&
                (quotesParams.offset >= 10 ||
                  quotesParams.areMoreItemsAvailable) && (
                  <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                    <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate-90 c-pointer ${
                          quotesParams.offset > 0 ? "op-7" : "op-2"
                        }`}
                        onClick={
                          quotesParams.offset > 0
                            ? () => {
                                fetchLess(5);
                              }
                            : () => {}
                        }
                      />
                    </div>
                    <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                      Showing {quotesParams.offset + 1} -{" "}
                      {quotesParams.offset + quotesList.length}
                    </span>
                    <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate--90 c-pointer ${
                          quotesParams.areMoreItemsAvailable ? "op-7" : "op-2"
                        }`}
                        onClick={
                          quotesParams.areMoreItemsAvailable
                            ? () => {
                                fetchMore(5);
                              }
                            : () => {}
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          )}

          {currentTab === 1 && (
            <>
              {" "}
              <div className="flex flex-middle mt-12 flex-between">
                <div className="flex flex-middle">
                  <h1
                    className="mr-24 fs-24 c-gray mb-4"
                    style={{ visibility: "hidden" }}
                  >
                    ACTIVE CARTS
                  </h1>
                </div>
              </div>
              
              {activeLoadList.length === 0 && (
                <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
                  No Carts available
                </div>
              )}
              {activeLoadList.length > 0 && (<>
                <span>Select Customer</span>
                <Table
                  scrollTable={true}
                  highlight={true}
                  columns={[
                    "Customer",
                    "Cart Name",
                    "Facility",
                    "Date",
                    "Status",
                    "Buyer",
                    // "Franchise",
                    "Grader",
                  ]}
                  items={activeLoadList.map((load, index) => {
                    console.log(load);
                    const createdDate = new Date(
                      load.updatedDate || load.createdDate
                    );
                    const dd = String(createdDate.getDate()).padStart(2, "0");
                    const mm = String(createdDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    ); //January is 0!
                    const yyyy = createdDate.getFullYear();
                    let hours = createdDate.getHours();
                    let minutes = createdDate.getMinutes();
                    const ampm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    return {
                      ...load,
                      loadCustName: load.firstName + " " + load.lastName,
                      group: load.companyName,
                      location: load.city + ", " + load.state,
                      date: `${mm}-${dd}-${yyyy} ${hours}:${minutes}${ampm}`,
                      statusVal: STATUS_MAPPING[load.status],
                      assistantName: `${load.assistantFirstName || ""} ${
                        load.assistantLastName || ""
                      }`,
                      videoGraderName: `${load.videoGraderFirstName || ""} ${
                        load.videoGraderLastName || ""
                      }`,
                      parentCompanyName: `${load.parentCompanyName || "-"}`
                    };
                  })}
                  keys={[
                    "loadCustName",
                    "loadName",
                    "facilityCity",
                    "date",
                    "statusVal",
                    "assistantName",
                    // "parentCompanyName",
                    "videoGraderName",
                  ]}
                  widthClasses={[
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "wt-100",
                  ]}
                  // isCart={true}
                  onItemClick={hendelUserSelect}
                  isLoading={tableLoading}
                  hideCheckBox={true}
                  onDelete={setDeleteLoad}
                  actions={(deleteCart && !props.match.path.includes('/my-cart'))?["DELETE"]:[]}
                  fetchMore={fetchMore}
                  fetchLess={fetchLess}
                  limit={activeLoadParams.limit}
                  onCountChange={(value) => {
                    setActiveLoadParams({
                      ...activeLoadParams,
                      offset: 0,
                      limit: value,
                    });
                  }}
                />
                </>
              )}
              {activeLoadList.length > 0 &&
                (activeLoadParams.offset >= 10 ||
                  activeLoadParams.areMoreItemsAvailable) && (
                  <div className="flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                    <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate-90 c-pointer ${
                          activeLoadParams.offset > 0 ? "op-7" : "op-2"
                        }`}
                        onClick={
                          activeLoadParams.offset > 0
                            ? () => {
                                fetchLess(1);
                              }
                            : () => {}
                        }
                      />
                    </div>
                    <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                      Showing {activeLoadParams.offset + 1} -{" "}
                      {activeLoadParams.offset + activeLoadList.length}
                    </span>
                    <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate--90 c-pointer ${
                          activeLoadParams.areMoreItemsAvailable
                            ? "op-7"
                            : "op-2"
                        }`}
                        onClick={
                          activeLoadParams.areMoreItemsAvailable
                            ? () => {
                                fetchMore(1);
                              }
                            : () => {}
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          )}

          {currentTab === 30 && (
            <>
              {" "}
              <div className="flex flex-middle mt-12 flex-between">
                <div className="flex flex-middle">
                  <h1
                    className="mr-24 fs-24 c-gray mb-4"
                    style={{ visibility: "hidden" }}
                  >
                    Franchise
                  </h1>
                </div>
              </div>
              
              {franchiseList.length === 0 && (
                <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
                  No Franchise available
                </div>
              )}
              {franchiseList.length > 0 && (<>
                <span>Select Customer</span>
                <Table
                  scrollTable={true}
                  highlight={true}
                  columns={[
                    "Customer",
                    "Cart Name",
                    "Facility",
                    "Date",
                    "Status",
                    "Buyer",
                    "Grader",
                  ]}
                  items={franchiseList.map((load, index) => {
                    const createdDate = new Date(
                      load.updatedDate || load.createdDate
                    );
                    const dd = String(createdDate.getDate()).padStart(2, "0");
                    const mm = String(createdDate.getMonth() + 1).padStart(
                      2,
                      "0"
                    ); //January is 0!
                    const yyyy = createdDate.getFullYear();
                    let hours = createdDate.getHours();
                    let minutes = createdDate.getMinutes();
                    const ampm = hours >= 12 ? "pm" : "am";
                    hours = hours % 12;
                    hours = hours ? hours : 12;
                    minutes = minutes < 10 ? "0" + minutes : minutes;
                    return {
                      ...load,
                      loadCustName: load.firstName + " " + load.lastName,
                      group: load.companyName,
                      location: load.city + ", " + load.state,
                      date: `${mm}-${dd}-${yyyy} ${hours}:${minutes}${ampm}`,
                      statusVal: STATUS_MAPPING[load.status],
                      assistantName: `${load.assistantFirstName || ""} ${
                        load.assistantLastName || ""
                      }`,
                      videoGraderName: `${load.videoGraderFirstName || ""} ${
                        load.videoGraderLastName || ""
                      }`,
                    };
                  })}
                  keys={[
                    "loadCustName",
                    "loadName",
                    "facilityCity",
                    "date",
                    "statusVal",
                    "assistantName",
                    "videoGraderName",
                  ]}
                  widthClasses={[
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "wt-100",
                  ]}
                  // isCart={true}
                  onItemClick={hendelUserSelect}
                  isLoading={tableLoading}
                  hideCheckBox={true}
                  onDelete={setDeleteLoad}
                  actions={["DELETE"]}
                  fetchMore={fetchMore}
                  fetchLess={fetchLess}
                  limit={activeLoadParams.limit}
                  onCountChange={(value) => {
                    setActiveLoadParams({
                      ...activeLoadParams,
                      offset: 0,
                      limit: value,
                    });
                  }}
                />
                </>
              )}
              {franchiseList.length > 0 &&
                (franchiseParams.offset >= 10 ||
                  franchiseParams.areMoreItemsAvailable) && (
                  <div className="flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                    <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate-90 c-pointer ${
                          franchiseParams.offset > 0 ? "op-7" : "op-2"
                        }`}
                        onClick={
                          franchiseParams.offset > 0
                            ? () => {
                                fetchLess(1);
                              }
                            : () => {}
                        }
                      />
                    </div>
                    <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                      Showing {franchiseParams.offset + 1} -{" "}
                      {franchiseParams.offset + activeLoadList.length}
                    </span>
                    <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                      <img
                        src={CaretIcon}
                        alt=""
                        className={`wt-20 rotate--90 c-pointer ${
                          franchiseParams.areMoreItemsAvailable
                            ? "op-7"
                            : "op-2"
                        }`}
                        onClick={
                          franchiseParams.areMoreItemsAvailable
                            ? () => {
                                fetchMore(1);
                              }
                            : () => {}
                        }
                      />
                    </div>
                  </div>
                )}
            </>
          )}
        </>
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="cart"
          name={''} //loadForDelete.loadName
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteLoad}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default ActiveLoads;
