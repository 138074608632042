import React, { useState } from "react";

import Toggle from "react-toggle";

import Modal from "../../components/Modal";
import Button from "../../components/Button";
import FormInput from "../../components/FormInput";
import SelectCheckbox from "../../components/SelectCheckbox";
import Checkbox from "../../components/Checkbox";

import CaretIcon from "../../icons/caret.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import EYE from "../../icons/eye.png";

import { getLoginDetails } from "../../utils/storage";
import { STATES_LIST } from "../../utils/api";

import ImageUploading from "../../components/AddEditItems/ImageUpload";

const STATES = ["Florida", "Wisconsin", "Georgia"];

const PARTNER_TYPES = [
  {
    id: "YES",
    value: "YES",
  },
  {
    id: "NO",
    value: "NO",
  },
];
const COMPANY_TYPES = [
  {
    id: "BUYER",
    value: "Partner Buyer",
  },
  {
    id: "SELLER",
    value: "Partner Seller",
  },
  {
    id: "COLLECTOR",
    value: "Partner Collector",
  },
  {
    id: "SI",
    value: "Seller Individual",
  },
  {
    id: "VIEWER",
    value: "VIEWER",
  },
];

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (value.length > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

const EntityAddEdit = ({
  item,
  onClose,
  onSave,
  type,
  onBack,
  shouldShowPriceMod,
  facilities,
  editType,
  setRemoveTabs,
  isNewCustomer,
}) => {
  const [companyName, setCompanyName] = useState(item.companyName);
  const [address, setAddress] = useState(item.address);
  const [address1, setAddress1] = useState(item.address1);
  const [city, setCity] = useState(item.city);
  const [state, setState] = useState(item.state);
  const [zipcode, setZipcode] = useState(item.zipcode);
  const [email, setEmail] = useState(item.email);
  const [phone, setPhone] = useState(normalizeInput(item.phone || ""));
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [userEmail, setUserEmail] = useState(
    isNewCustomer ? item.userEmail : item.email
  );

  const [priceMod, setPriceMod] = useState(item.priceMod);
  const [businessLicense, setBusinessLicense] = useState(item.businessLicense);
  const [ein, setEin] = useState(item.ein);
  const [driversLicense, setDriversLicense] = useState(item.driversLicense);

  const [oldPassword, setOldPassword] = useState(item.oldPassword);
  const [newPassword, setNewPassword] = useState(item.newPassword);
  const [confirmPassword, setConfirmPassword] = useState(item.confirmPassword);

  const [seeOldPassword, setSeeOldPassword] = useState(false);
  const [seeNewPassword, setSeeNewPassword] = useState(false);
  const [seeConfirmPassword, setSeeConfirmPassword] = useState(false);

  const [sameUserEmail, setSameUserEmail] = useState(false);

  const hideAbcPricing = true;

  const [image, setImage] = useState(null);

  const [metalLicense, setMetalLicense] = useState(item.metalLicense);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [validations, setValidations] = useState({});
  const [isPriceModifierPercantage, setIsPriceModifierPercantage] = useState(
    item.groupModifierIsPercentage
  );
  const [modifierInputValue, setModifierInputValue] = useState(
    item.groupModifier || ""
  );
  const validateFields = () => {
    const updatedValidations = { ...validations };

    updatedValidations.companyName = companyName;
    updatedValidations.email = email;
    updatedValidations.userEmail = userEmail;
    updatedValidations.address = address;
    // updatedValidations.address1 = address1;
    updatedValidations.phone = phone;
    updatedValidations.city = city;
    updatedValidations.state = state;
    updatedValidations.zipcode = zipcode;
    updatedValidations.firstName = firstName;
    updatedValidations.lastName = lastName;

    updatedValidations.oldPassword = oldPassword;
    updatedValidations.newPassword = newPassword;
    updatedValidations.confirmPassword = confirmPassword;

    setValidations(updatedValidations);
    return updatedValidations;
  };

  return (
    // <Modal>
    <div className="mt-8">
      <div className="fs-24 fw-bolder mb-40 c-gray">
        FILL THE REQUIRED DETAILS
      </div>

      <div className="flex flex-between flex-middle mb-40 flex-wrap">
        <FormInput
          placeholder="Company Name"
          isMandatory={true}
          onChange={(e) => {
            setCompanyName(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={companyName}
          hasError={hasSubmitted && !validations.companyName}
        />
        <FormInput
          placeholder="First Name"
          isMandatory={true}
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={firstName}
          hasError={hasSubmitted && !validations.firstName}
        />
        <FormInput
          placeholder="Last Name"
          isMandatory={true}
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={lastName}
          hasError={hasSubmitted && !validations.lastName}
        />
        <FormInput
          placeholder="Company Email"
          isMandatory={true}
          onChange={(e) => {
            if (!isNewCustomer && (item.email || "").length > 0) return;
            setEmail(e.target.value);
            if (!isNewCustomer && (item.email || "").length <= 0) {
              setUserEmail(e.target.value);
            }
          }}
          className="mr-40 wt-33p"
          value={email}
          hasError={hasSubmitted && !validations.email}
        />
        <div className="mr-40 wt-33p">
          <FormInput
            placeholder="Admin/User Email"
            isMandatory={isNewCustomer}
            readOnly={!isNewCustomer}
            onChange={(e) => {
              if (!isNewCustomer) return;
              setUserEmail(e.target.value);
            }}
            // className="mr-40 wt-33p"
            value={userEmail}
            hasError={hasSubmitted && !validations.userEmail}
          />
          {isNewCustomer && (
            <Checkbox
              isSelected={sameUserEmail}
              label="Same as Company Email"
              labelClassName="fs-12"
              onClick={() => {
                setSameUserEmail(!sameUserEmail);
                setUserEmail(!sameUserEmail ? email : "");
              }}
            />
          )}
        </div>
        <FormInput
          placeholder="Phone"
          isMandatory={true}
          onChange={(e) => {
            setPhone(normalizeInput(e.target.value || ""));
          }}
          className="mr-40 wt-33p"
          value={phone}
          hasError={hasSubmitted && !validations.phone}
        />
      </div>
      <div className="flex flex-between flex-middle mb-40 flex-wrap">
        <FormInput
          placeholder="Company Address 1"
          isMandatory={true}
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={address}
          hasError={hasSubmitted && !validations.address}
        />
        <FormInput
          placeholder="Company Address 2"
          onChange={(e) => {
            setAddress1(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={address1}
          // hasError={hasSubmitted && !validations.address1}
        />
        <FormInput
          placeholder="Company City"
          isMandatory={true}
          onChange={(e) => {
            setCity(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={city}
          hasError={hasSubmitted && !validations.city}
        />
        <div className=" mr-40 wt-33p">
          <div className="c-gray fs-14 mb-4">
            Company State <span className="c-error">*</span>
          </div>
          <select
            name="type"
            className="select font-primary fs-14 fw-bolder"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value={""}>Select State</option>
            {(STATES_LIST || []).map((state) => {
              return <option value={state}>{state}</option>;
            })}
          </select>
          {hasSubmitted && !validations.state && (
            <div className="fs-10 c-error fw-bold p-absolute">
              Please select valid state
            </div>
          )}
        </div>
        <FormInput
          placeholder="Company Zipcode"
          isMandatory={true}
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setZipcode(e.target.value);
          }}
          className="mr-40 wt-33p"
          value={(zipcode || "").replace(/[^\d]/g, "")}
          hasError={hasSubmitted && !validations.zipcode}
        />
        <div className="mr-40 mb-20 wt-33p p-relative"></div>

        {!hideAbcPricing && shouldShowPriceMod && (
          <div className="wt-33p mr-40 p-relative t--8">
            <div className="fs-14 mb-4 fw-bold mt-20">Is ABC Pricing</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setPriceMod(e.target.checked);
              }}
              checked={priceMod}
            />
          </div>
        )}

        <div className="flex flex-middle flex-between wt-100p">
          <FormInput
            className="mr-40 mb-20 wt-33p"
            placeholder="Business License"
            onChange={(e) => {
              setBusinessLicense(e.target.value);
            }}
            value={businessLicense}
          />
          <FormInput
            className="mr-40 mb-20 wt-33p"
            placeholder="EIN"
            onChange={(e) => {
              setEin(e.target.value);
            }}
            value={ein}
          />
          <FormInput
            className="mr-40 mb-20 wt-33p"
            placeholder="Driver License"
            onChange={(e) => {
              setDriversLicense(e.target.value);
            }}
            value={driversLicense}
          />
        </div>

        <div className="flex flex-middle  flex-between wt-100p">
          {!isNewCustomer ? (
            <div className="mr-40 mb-20 wt-33p p-relative">
              <FormInput
                type={seeOldPassword ? "text" : "password"}
                isMandatory={true}
                placeholder="Current Password"
                onChange={(e) => {
                  setOldPassword(e.target.value);
                }}
                value={oldPassword}
              />
              <img
                src={EYE}
                className="wt-25 p-absolute  r-4 t-26 c-pointer"
                onClick={(e) => {
                  setSeeOldPassword(!seeOldPassword);
                }}
              />
            </div>
          ) : null}

          <div className="mr-40 mb-20 wt-33p p-relative">
            <FormInput
              type={seeNewPassword ? "text" : "password"}
              isMandatory={true}
              // className="mr-40 mb-20 wt-33p"
              placeholder="New Password"
              onChange={(e) => {
                setNewPassword(e.target.value);
              }}
              value={newPassword}
            />
            <img
              src={EYE}
              className="wt-25 p-absolute  r-4 t-26 c-pointer"
              onClick={(e) => {
                setSeeNewPassword(!seeNewPassword);
              }}
            />
          </div>

          <div className="mr-40 mb-20 wt-33p p-relative">
            <FormInput
              type={seeConfirmPassword ? "text" : "password"}
              isMandatory={true}
              // className="mr-40 mb-20 wt-33p"
              placeholder="Confirm Password"
              onChange={(e) => {
                setConfirmPassword(e.target.value);
              }}
              value={confirmPassword}
            />
            <img
              src={EYE}
              className="wt-25 p-absolute  r-4 t-26 c-pointer"
              onClick={(e) => {
                setSeeConfirmPassword(!seeConfirmPassword);
              }}
            />
          </div>

          {isNewCustomer && (
            <div className="mr-40 mb-20 wt-33p p-relative"></div>
          )}
        </div>

        <div className="wt-33p mr-40 p-relative"></div>
        <div className="wt-33p mr-40 p-relative"></div>
        <div className="wt-33p mr-40 p-relative"></div>
      </div>
      <div className="flex flex-middle flex-right">
        <Button
          className="flex flex-middle mr-20 bg-red"
          type="modalCancel"
          onClick={() => {
            setRemoveTabs(false);
            onClose();
          }}
        >
          {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
          <span>CANCEL</span>
        </Button>

        <Button
          type="modalSave"
          onClick={() => {
            // setRemoveTabs(false)
            setHasSubmitted(true);
            const updatedValidations = validateFields();
            if (
              !updatedValidations.companyName ||
              !updatedValidations.address ||
              !updatedValidations.userEmail ||
              !updatedValidations.city ||
              !updatedValidations.state ||
              !updatedValidations.zipcode ||
              !updatedValidations.firstName ||
              !updatedValidations.lastName ||
              !updatedValidations.email
            ) {
              return;
            }

            const dataParam = {
              companyName,
              address,
              address1,
              phone,
              city,
              state,
              zipcode,
              email,
              userEmail,
              firstName,
              lastName,
              newPassword,
              confirmPassword,
              driversLicense,
              businessLicense,
              ein,
              companyId: item.companyId,
              companyLogo: item.companyLogo,
              priceMod: shouldShowPriceMod ? priceMod : undefined,
              groupModifierIsPercentage: isPriceModifierPercantage,
              groupModifier: modifierInputValue,
            };

            if (!isNewCustomer) {
              dataParam["oldPassword"] = oldPassword;
              dataParam["oldEmail"] = getLoginDetails().email || "";
            }
            onSave(
              dataParam,
              image ? image[0] : null,
              metalLicense ? metalLicense[0] : null
            );
          }}
        >
          {/* {type === 'add' ? 'Add' : 'Edit'} item */}
          <img src={Tick} className="wt-20 ht-20 mr-4" />
          <span>SAVE</span>
        </Button>
      </div>
    </div>
    // </Modal>
  );
};

export default EntityAddEdit;
