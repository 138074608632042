import React from "react";

const Modal = ({ children }) => {
  return (
    <>
      <div className="overlay"></div>
      <div
        style={{
          boxShadow: "0 0 20px rgab(0,0,0,1)",
          borderRadius: "20px",
          padding: "30px",
          paddingBottom: "13px",
        }}
        className="page-center box-shadow pl-16 pr-16 pb-16 pt-16 bg-white z-50 br-2 modal-overflow"
      >
        {children}
      </div>
    </>
  );
};

export default Modal;
