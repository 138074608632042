import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import NewUserAddEdit from "./NewUserAddEdit";

import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/people-fill.svg";

import {
  GET_ENTITIES_URL,
  GET_FACILITIES_URL,
  ONBORAD_ENTITY_URL,
  EDIT_ENTITIES_URL,
  DELETE_ENTITIES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const entityModel = {
  companyName: "",
  companyLogo: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  firstName: "",
  lastName: "",
  companyId: undefined,
  businessLicense: "",
  ein: "",
  metalLicense: "",
  oldPassword: "",
  newPassword: "",
  driversLicense: "",
  confirmPassword: "",
  userEmail: "",
};

export const Entities = (props) => {
  const [entitiesItems, setEntitiesItems] = useState([]);
  // const [entityModelData, setEntityModelData] = useState(entityModel);
  // const { isRegistration } = getLoginDetails();
  // const [facilitiesItems, setFaciltiesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPopupShown, setIsAddPopupShown] = useState(true);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const isNewCustomer =
    props.match && props.match.params && props.match.params.id === "1";

  const addItem = async (data, image, metalLicenseImg) => {
    let { companyLogo, metalLicense } = data;
    try {
      console.log("metalLicenseImg", metalLicenseImg);
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      if (metalLicenseImg) {
        const metalResponse = await uploadFile(
          FILE_UPLOAD_URL,
          metalLicenseImg.file
        );
        metalLicense = metalResponse.imageUrl;
      }
      let dataParams = {
        ...data,
        companyLogo,
        metalLicense,
        // parentCompanyId: props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      };
      if (isNewCustomer) {
        dataParams["isNewCustomer"] = isNewCustomer;
      } else {
        dataParams["parentCompanyId"] =
          props.parentCompanyId || getLoginDetails().parentCompanyId;
      }
      const response = await postData(ONBORAD_ENTITY_URL, dataParams);
      // setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Partner",
        id: new Date().getTime(),
      });
      props.history.push("/logout");
      // getEntities();
    } catch (e) {
      console.log("ERROR", e);
      // debugger;
      if (e && e.response && e.response.data && e.response.data.error) {
        setToastParams({
          type: "ERROR",
          message:
            e.response.data.error ||
            "Oops! Something went wrong. Please try again later",
          id: new Date().getTime(),
        });
        return;
      }
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const loginData = getLoginDetails();
  const isInvited = loginData && loginData.isRegistration;
  const isUpdate = loginData && loginData.isUpdate;

  const redirectToLogin = () => {
    if (isInvited) {
      props.history.push("/invite");
    } else if (isNewCustomer) {
      props.history.push("/");
    }
  };

  return (
    <PageLayout
      icon={HeaderIcon}
      heading="CREATE ACCOUNT"
      hideSearchBar={true}
      hideLoginDetails={true}
      noSideBar={isNewCustomer}
    >
      {
        //isAddPopupShown &&
        <NewUserAddEdit
          item={isUpdate ? { ...loginData } : { ...entityModel }}
          onClose={
            isNewCustomer || isInvited
              ? redirectToLogin
              : setIsAddPopupShown.bind(null, false)
          }
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          isNewCustomer={isNewCustomer}
          type="add"
          shouldShowPriceMod={false}
          // shouldShowPriceMod = {getLoginDetails().hierarchy === 1}
          // facilities={facilitiesItems}
          setRemoveTabs={() => {
            props.history.push("/logout");
          }}
        />
      }

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Entities;
