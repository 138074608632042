import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import UserAddEdit from "../../components/AddEditItems/UserAddEdit";
import Toast from "../../components/Toast";

import HeaderIcon from "../../icons/newicons/people-fill.svg";

import IconAdd from "../../icons/rev_add_user.png";
import CaretIconPagination from "../../icons/rev_carat.png";
import IconGroupsGrey from "../../icons/pos_user-group.png";
import IconGroupsWhite from "../../icons/rev_user-group.png";

import IconUsersWhite from "../../icons/rev_user.png";
import IconUsersGrey from "../../icons/pos_user.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import Toggle from "react-toggle";

import {
  GET_USERS_URL,
  ADD_USERS_URL,
  EDIT_USERS_URL,
  DELETE_USERS_URL,
  GET_ROLES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const userModel = {
  email: "",
  companyId: "",
  roleId: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  profileUrl: "",
  smsNumber: "",
  notes: "",
  isActive: true,
};

// const userModel = {
//     "email": "ABC Organisation",
//     "companyId": "CE079521DDFD49F1B24A5F40202587D8",
//     "roleId": "7ADF59D7D3F1452BBB74DA7C10307D5E",
//     "password": "pass",
//     "firstName": "first name",
//     "lastName": "last name",
//     "phone": "company phone",
//     "address": "company address",
//     "city": "company city",
//     "state": "company state",
//     "zipcode": "560043",
//     "profileUrl": "http://a.b",
//     "smsNumber": "sms number of user",
//     "notes": "some notes - text area"
// }

const nameMapping = {};

export const Users = (props) => {
  const { canDeleteUser, hierarchy, companyId = false} = getLoginDetails();
  const hideCustomers = (props.location.state && props.location.state.hideCustomers) || false;
  const [userItems, setUserItems] = useState([]);
  const [rolesItems, setRolesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [userNameSort, setUserNameSort] = useState("asc");
  const [companySort, setCompanySort] = useState("asc");
  const [isFranchise, setIsFranchise] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getRolesAndPermissions = async () => {
    try {
      const data = await getData(GET_ROLES_URL, {
        count: params.limit,
        offset: params.offset,
        companyId: props.match.params.entityId || companyId,
      });
      setRolesItems(
        data.list.map((role, index) => {
          return {
            id: role.roleId,
            value: role.name,
          };
        })
      );
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const getUsers = async () => {
    try {
      setIsLoading(true);
      setUserItems([]);

      const payload = {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        companyId: props.match.params.entityId || companyId,
        userNameSort,
        companySort,
      };

      if(hideCustomers) {
        payload.isEmbed = true;
      }

      if (hierarchy === 1) {
        payload.isFranchise = window.location.pathname.includes('/Franchise')?true:false;

      }

      const data = await getData(GET_USERS_URL, payload);
      setIsLoading(false);
      setUserItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getUsers();
  }, [
    params.offset,
    params.text,
    params.limit,
    props.match.params.entityId,
    userNameSort,
    companySort,
    // isFranchise,
    (window.location.pathname.includes("/Franchise"))
  ]);

  useEffect(()=>{
    if(window.location.pathname.includes("/Franchise")){
      setIsFranchise(true);
    } else {
      setIsFranchise(false);
    }
  },[(window.location.pathname.includes("/Franchise"))])

  useEffect(() => {
    getRolesAndPermissions();
  }, []);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const addItem = async (data, image) => {
    let { profileUrl } = data;
    setIsLoading(true);
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        profileUrl = response.imageUrl;
      }
      const response = await postData(ADD_USERS_URL, {
        ...data,
        profileUrl,
        companyId: props.match.params.entityId,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the User",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      getUsers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
      setIsLoading(false);
    }
  };
  const editItem = async (data, image) => {
    let { profileUrl } = data;
    setIsLoading(true);
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        profileUrl = response.imageUrl;
      }
      const response = await putData(EDIT_USERS_URL, { ...data, profileUrl });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the User",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      getUsers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
      setIsLoading(false);
    }
  };
  const onIsActiveChange = (item, isActive) => {
    editItem({
      ...item,
      isActive,
    });
  };
  const deleteItem = async () => {
    setIsLoading(true);
    try {
      const response = await deleteData(DELETE_USERS_URL, {
        userId: itemForDelete.userId,
        companyId: itemForDelete.companyId,
      });
      setIsDeletePopupShown(false);
      setIsLoading(false);
      getUsers();
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };
  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Users"
      searchPlaceholder="Search Users"
    >
      <>
        {!isAddPopupShown && !isEditPopupShown && (
          <div className="flex flex-middle mt-8 flex-between">
            <div className={`tabs flex`}>
              {!hideCustomers && (
                <div
                  className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white fw-bolder'`}
                  onClick={() => {
                    // props.history.push("/entities/viewGroup");
                    props.history.push(isFranchise?"/entities/Franchise":"/entities/viewGroup");
                    //isFranchise?"entities/Franchise/users":"/entities/users"
                  }}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="30"
                    height="24"
                    fill="currentColor"
                    class="bi bi-people-fill"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fill-rule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>
                  <span>Customers</span>
                </div>
              )}
              <div
                className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray`}
                onClick={() => {}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="24"
                  fill="currentColor"
                  class="bi bi-person-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                </svg>
                <span>Users</span>
              </div>
              {/* {(hierarchy === 1 && !hideCustomers) && (
               
               <div
               // className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`}
               className={!isFranchise?`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`:`ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder'`}
               onClick={() => {
                 setIsFranchise(!isFranchise)
               }}
             >
               <svg
                 xmlns="http://www.w3.org/2000/svg"
                 width="30"
                 height="24"
                 fill="currentColor"
                 class="bi bi-people-fill"
                 viewBox="0 0 16 16"
               >
                 <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                 <path
                   fill-rule="evenodd"
                   d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                 />
                 <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
               </svg>
               <span>Is Franchise</span>
             </div>
              )} */}
            </div>

            {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
            {/* <div className="flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer" onClick={()=> {setIsAddPopupShown(true)}}>
                        <img src={IconAdd} className="ht-30 wt-30 mr-12" />
                        <span>Create user</span>
                    </div> */}
          </div>
        )}
        {!isAddPopupShown && !isEditPopupShown && (
          <Table
            scrollTable={true}
            type="user"
            columns={[
              () => {
                return (
                  <span>
                    Username{" "}
                    {userNameSort === "asc" ? (
                      <span
                        className={"fs-16 c-pointer"}
                        onClick={() => setUserNameSort("desc")}
                      >
                        ↓
                      </span>
                    ) : (
                      <span
                        className={"fs-16 c-pointer"}
                        onClick={() => setUserNameSort("asc")}
                      >
                        ↑
                      </span>
                    )}
                  </span>
                );
              },
              "Email",
              "Address",
              () => {
                return (
                  <span>
                    Company Name{" "}
                    {companySort === "asc" ? (
                      <span
                        className={"fs-16 c-pointer"}
                        onClick={() => setCompanySort("desc")}
                      >
                        ↓
                      </span>
                    ) : (
                      <span
                        className={"fs-16 c-pointer"}
                        onClick={() => setCompanySort("asc")}
                      >
                        ↑
                      </span>
                    )}
                  </span>
                );
              },
              "Role",
              "Is Active?",
            ]}
            // className="wt-600"
            items={userItems.map((entity) => {
              const createdDate = new Date(entity.createdDate);
              const dd = String(createdDate.getDate()).padStart(2, "0");
              const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
              const yyyy = createdDate.getFullYear();
              let hours = createdDate.getHours();
              let minutes = createdDate.getMinutes();
              const ampm = hours >= 12 ? "pm" : "am";
              hours = hours % 12;
              hours = hours ? hours : 12;
              minutes = minutes < 10 ? "0" + minutes : minutes;

              return {
                ...entity,
                displayAddress: `${entity.address || ""} ${entity.city || ""} ${
                  entity.state || ""
                } ${entity.zipcode || ""}`,
                name: `${entity.firstName || " "} ${entity.lastName || " "}`,
                displayIsActive: entity.isActive ? "YES" : "NO",
                displayIsAdmin: entity.isAdmin ? "YES" : "NO",
                companyNameAndDate: `semicolon-separated-inline-${entity.companyName}; ${mm}-${dd}-${yyyy}`,
              };
            })}
            keys={[
              "name",
              "email",
              "displayAddress",
              "companyNameAndDate",
              "type",
              "displayIsActive",
            ]}
            widthClasses={[
              "flex-1",
              "flex-1",
              "flex-1",
              "flex-1",
              "wt-100",
              "wt-100",
            ]}
            headerBgWhite={true}
            actions={["EDIT", canDeleteUser && "DELETE"]}
            onEdit={setEdit}
            onDelete={setDelete}
            onChange={onSearchChange}
            onIsActiveChange={onIsActiveChange}
            offset={params.offset}
            areMoreItemsAvailable={params.areMoreItemsAvailable}
            fetchMore={fetchMore}
            fetchLess={fetchLess}
            isLoading={isLoading}
            hideCheckBox={true}
            onCountChange={(value) => {
              setParams({
                ...params,
                limit: value,
                offset: 0,
                areMoreItemsAvailable: false,
              });
            }}
            limit={params.limit}
            className="min-wt-1000"

            // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
          />
        )}

        {isAddPopupShown && (
          <UserAddEdit
            item={{ ...userModel }}
            isLoading={isLoading}
            onClose={setIsAddPopupShown.bind(null, false)}
            onBack={setIsAddPopupShown.bind(null, false)}
            onSave={addItem}
            type="add"
            roles={rolesItems}
            selected={[]}
          />
        )}
        {isEditPopupShown && (
          <UserAddEdit
            item={{ ...itemForEdit }}
            isLoading={isLoading}
            onClose={setIsEditPopupShown.bind(null, false)}
            onBack={setIsEditPopupShown.bind(null, false)}
            onSave={editItem}
            type="edit"
            roles={rolesItems}
            selected={[
              {
                id: itemForEdit.roleId,
                value: (
                  rolesItems.find((role) => {
                    return role.id === itemForEdit.roleId;
                  }) || {}
                ).value,
              },
            ]}
          />
        )}
        {isDeletePopupShown && (
          <DeleteConfirmation
            type="user"
            name={itemForDelete.name}
            onClose={setIsDeletePopupShown.bind(null, false)}
            onConfirm={deleteItem}
          />
        )}
        <Toast
          type={toastParams.type}
          message={toastParams.message}
          id={toastParams.id}
        />
      </>
    </PageLayout>
  );
};

export default Users;
