import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import SearchIcon from "../../icons/pos_find.png";
import SearchIconWhite from "../../icons/rev_find.png";
import Table from "../../components/Table";
import HeaderIcon from "../../icons/newicons/arrow-down-up.svg";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";

import {
  GET_DASHBOARD_DATA_URL,
  GET_DASHBOARD_REPORT_URL,
  GET_ENTITIES_URL,
  GET_USERS_URL,
  FACILITY_CITY_LIST,
  getData,
  GET_FACILITIES_URL,
  topCatsTable,
  downloadTopCatsTable,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";
import Toggle from "react-toggle";
import InvoiceLink from "./invoiceLink";
import PageLayout from "../../components/PageLayout";
import { withRouter, useHistory } from "react-router";
import CurrencyFormat from "react-currency-format";
// import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

// import SearchIconSvg from "../../icons/newicons/search.svg";

function TopCats(props) {
  const [startDate, setStartDate] = useState(null);
  const [faacilities, setFaciltiesItems] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [facility, setFacility] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [buyer, setBuyer] = useState("");
  const [customer, setCustomer] = useState("");
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [buyerItems, setBuyerItems] = useState([]);
  const [showBuyerDropdown, setShowBuyerDropdown] = useState(false);
  const [selectedBuyerItem, setSelectedBuyerItem] = useState({});
  const [comingFromInvoice, setComingFromInvoice] = useState(
    props.location.params
      ? props.location.params.loadFilterDate == true
        ? true
        : false
      : false
  );

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });

  const [dataPersistence, setPersistenceData] = useState({});

  const [toggleCheck, setToggleCheck] = useState(null);
  const [tableData, setTableData] = useState([]);

  const getDashboardDetails = async () => {
    try {
      setIsLoading(true);
      const startDt = new Date(startDate).setHours(0, 0, 0, 0);
      const endDt = new Date(endDate).setHours(23, 59, 59, 59);
      let data;

      if (!toggleCheck) {
        data = await getData(GET_DASHBOARD_DATA_URL, {
          facility,
          from: new Date(startDt).getTime(),
          to: new Date(endDt).getTime(),
          buyer:
            Object.keys(selectedBuyerItem || {}).length > 0
              ? selectedBuyerItem.userId
              : buyer,
          customer:
            Object.keys(selectedItem || {}).length > 0
              ? selectedItem.companyId
              : customer,
        });
      } else {
        data = await getData(GET_DASHBOARD_DATA_URL, {
          facility,
          from: new Date(startDt).getTime(),
          to: new Date(endDt).getTime(),
          buyer:
            Object.keys(selectedBuyerItem || {}).length > 0
              ? selectedBuyerItem.userId
              : buyer,
          customer:
            Object.keys(selectedItem || {}).length > 0
              ? selectedItem.companyId
              : customer,
          type: 2,
        });
        console.log(buyer, customer);
        console.log("DATA", data);
      }

      setIsLoading(false);

      setDashboardDetails(data.list || []);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable || false,
      });
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };

  const getTopCatsTable = () => {
    const startDt = new Date(Date.now()).setHours(0, 0, 0, 0);
    const endDt = new Date(Date.now()).setHours(23, 59, 59, 59);
    topCatsTable(startDt,endDt)
    .then((res) => {
      let newarr = [];
      res.list.map(ele=>{
        newarr.push({'count':ele.count,
        'maxPrice':ele.maxPrice,
        'minPrice':ele.minPrice,
        'oem':ele.oem,
        'pdAssay':ele.pdAssay,
        'priceOverride': (ele.priceOverride===true? 'Yes' : 'No'),
        'ptAssay' : ele.ptAssay,
        'quantity' : ele.quantity,
        'rhAssay' : ele.rhAssay
       })
      })
      //console.log(newarr);
      setTableData(newarr);
      //console.log(res.list);
    })
    .catch((err) => {
      console.log(err);
    });
  }

  useEffect(() => {
    setStartDate(Date.now());
    setEndDate(Date.now());
    const startDt = new Date(Date.now()).setHours(0, 0, 0, 0);
    const endDt = new Date(Date.now()).setHours(23, 59, 59, 59);
    topCatsTable(startDt,endDt)
      .then((res) => {
        let newarr = [];
        res.list.map(ele=>{
          newarr.push({'count':ele.count,
          'maxPrice':ele.maxPrice,
          'minPrice':ele.minPrice,
          'oem':ele.oem,
          'pdAssay':ele.pdAssay,
          'priceOverride': (ele.priceOverride===true? 'Yes' : 'No'),
          'ptAssay' : ele.ptAssay,
          'quantity' : ele.quantity,
          'rhAssay' : ele.rhAssay
         })
        })
        //console.log(newarr);
        setTableData(newarr);
        setStartDate(new Date());
        setEndDate(new Date());
        //console.log(res.list);
      })
      .catch((err) => {
        console.log(err);
      });
  }, []);

  
  const getFilterDetail = () => {
    const startDt = new Date(startDate).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate).setHours(23, 59, 59, 59);
    topCatsTable(startDt,endDt)
    .then((res) => {
      let newarr = [];
      res.list.map(ele=>{
        newarr.push({'count':ele.count,
        'maxPrice':ele.maxPrice,
        'minPrice':ele.minPrice,
        'oem':ele.oem,
        'pdAssay':ele.pdAssay,
        'priceOverride': (ele.priceOverride===true? 'Yes' : 'No'),
        'ptAssay' : ele.ptAssay,
        'quantity' : ele.quantity,
        'rhAssay' : ele.rhAssay
       })
      })
      //console.log(newarr);
      setTableData(newarr);
      //console.log(res.list);
    })
      .catch((err) => {
        console.log(err);
      });
  }

  const resetData = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    localStorage.setItem("startDate", new Date());
    localStorage.setItem("endDate", new Date());
    getTopCatsTable()
  };

  const downloadTopCatData = () => {
    const startDt = new Date(startDate).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate).setHours(23, 59, 59, 59);
    downloadTopCatsTable(startDt,endDt)
      .then((res) => {
        const element = document.createElement("a");
        element.setAttribute("href", res.link);
        element.setAttribute("target","_blank");
        element.setAttribute("download", true);
        element.click();
      })
      .catch((err) => {
        console.log(err);
      });
  };
  const [showTab, setShowTab] = useState(2);

  return (
    <PageLayout
      icon={HeaderIcon}
      svg={true}
      //   onSearchChange={(val) => handleSearch(val)}
      heading="Top Cats Report"
      searchPlaceholder="Search"
      inputClassName=" form-control "
    >
      <div className="flex flex-middle mt-8 flex-between groups-tabs ">
      <div className={`tabs flex`}>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 0 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(0)
              props.history.push('/dashboard/costsheetreport')

            }}
          >
            {/*<img src={HeaderIconPage} className="wt-30 mr-8" />*/}
            <span>Cost Sheet</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(1)
              props.history.push('/dashboard/transactionsreport')
            } }
          >
            <span>Transactions</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(2)
              props.history.push('/dashboard/top-cats')
            }
            }
          >
            <span>Top Cats</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 3 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(3)
              props.history.push('/dashboard/top-lookups')
            }
            }
          >
            <span>Top Lookups</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 4 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(4)
              props.history.push('/dashboard/didnotbuy')
            }
            }
          >
            <span>Do Not Buy</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 5 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(5)
              props.history.push('/dashboard/top-customers')
            }
            }
          >
            <span>Top Customers</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 6 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(6)
              props.history.push('/dashboard/globalmodifier')
            }
            }
          >
            <span>Global Modifier</span>
          </div>
        </div>
      </div>
       <div className="costSheetFilters flex">
            <div>
              <div className="c-gray fs-12"> Start Date </div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          <div style={{marginRight:'30%'}}>
            <div className="c-gray fs-12"> End Date </div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
      <div style={{display:'flex',flexDirection:'row'}}>
        <div className="search-button">
          <div
            className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={getFilterDetail}
          >
            <img
              src={SearchIconWhite}
              alt="searchicon"
              width="20px"
              height="20px"
            />
          </div>
        </div>
        <div className="search-button ml-10">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center  closeicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={resetData}
            >
              <span className="fs-16 c-white"> X </span>
            </div>
          </div>
          {/* <div
            class="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={downloadTopCatData}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download mr-8"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            Download Reports
          </div> */}
          <div className="search-button ml-10" >
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={downloadTopCatData}
            >
            
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
            </div>
          </div>
          </div>
      </div>
     <div>
     <div className="table">
          {
            <Table
              scrollTable={toggleCheck ? true : false}
              columns={[
                "OEM",
                "Count",
                "Quantity",
                "Min Price",
                "Max Price",
                "Price Override",
                "Pt Assay",
                "Pd Assay",
                "Rh Assay",
              ]}
              className={`${toggleCheck ? "costSheetTable scroll-table " : ""}`}
              items={tableData}
              hideCheckBox={true}
              keys={[
                "oem",
                "count",
                "quantity",
                "minPrice",
                "maxPrice",
                "priceOverride",
                "ptAssay",
                "pdAssay",
                "rhAssay",
              ]}
            />
          }
        </div>
     </div>
    </PageLayout>
  );
}

export default withRouter(TopCats);
   