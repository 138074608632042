import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import HeaderIcon from "../../icons/newicons/cart-fill.svg";
import HeaderIconWhite from "../../icons/newicons/cart-fill.svg";
import PageLayout from "../../components/PageLayout";
import FormInput from "../../components/FormInput";
import { getLoginDetails } from "../../utils/storage";
import Table from "../../components/Table";
import SellerCreateLoads from "./SellerCreateLoads";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import CaretIcon from "../../icons/rev_carat.png";
import cartIconWhite from "../../icons/newicons/cart-fill-white.svg";
import Toggle from "react-toggle";
import IconAdd from "../../icons/rev_add_group.png";

import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import {
  GET_ENTITIES_URL,
  getData,
  postData,
  CREATE_LOAD_URL,
  // STATES_LIST,
  FACILITY_CITY_LIST,
  GET_FACILITIES_URL,
} from "../../utils/api";
import Toast from "../../components/Toast";

let TIMER = null;

const CreateLoads = (props) => {
  const loginDetail = getLoginDetails() || {};
  const isSeller = loginDetail.hierarchy == 2;
  const canCreateUser = loginDetail.canCreateUser || false;
  const [customerList, setCustomerList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [selectedUser, setSelectedUser] = useState({});
  const [tableLoading, setTableLoading] = useState(true);
  const [searchValue, setSearchValue] = useState("");
  const [loading, setLoading] = useState(false);
  const [priceValue, setPriceValue] = useState('');
  const [isPriceModifierPercantage, setIsPriceModifierPercantage] = useState(false)
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getUsers = async () => {
    try {
      setCustomerList([]);
      const data = await getData(GET_ENTITIES_URL, {
        count: params.limit,
        offset: params.offset,
        text: searchValue,
        companyId: getLoginDetails().companyId,
        isLoads: true,
      });
      setTableLoading(false);
      setCustomerList(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const hendelUserSelect = async () => {
    setLoading(true);
    if (
      !selectedUser.loadName ||
      selectedUser.loadName.length <= 0 ||
      !selectedUser.facilityCity ||
      selectedUser.facilityCity.length <= 0
    ) {
      setToastParams({
        type: "ERROR",
        message: "Enter Valid Cart Name & Facility",
        id: new Date().getTime(),
      });
      setLoading(false);
      return;
    }
    try {
      const createLoadResp = await postData(CREATE_LOAD_URL, {
        customerId: selectedUser.companyId,
        loadName: selectedUser.loadName,
        isQuote: selectedUser.isQuote || false,
        facilityCity: selectedUser.facilityCity,
        businessLicense: selectedUser.businessLicense,
        driversLicense: selectedUser.driversLicense,
        ein: selectedUser.ein,
      });
      props.history.push(`/load/${createLoadResp.loadId}`);
      setSelectedUser({});
      setLoading(false)
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
      setLoading(false)
    }
  };
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  useEffect(() => {
    getUsers();
  }, [searchValue, params.offset, params.limit]);

  const [facilities, setFaciltiesItems] = useState([]);

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {}
  };

  useEffect(() => {
    getFacilities();
  }, []);

  const goTo = (path, data) => {
    props.history.push(`${path}`, data);
  }
 console.log(selectedUser);
  return (
    <PageLayout icon={HeaderIconSvg} searchPlaceholder="Search Customers" onSearchChange={(val) => {
      setSearchValue(val);
    }} heading="Carts" >
      {isSeller ? (
        <SellerCreateLoads
          customerId={loginDetail.userId}
          companyId={loginDetail.companyId}
          history={props.history}
          setToastParams={setToastParams}
        />
      ) : (
        <>
          <div className="flex flex-middle mb-24 flex-between">
            <div className="flex flex-middle mb-24">
              {/* <img src={HeaderIcon} className="ht-30 wt-30 mr-12" /> */}
              {/* <h1 className="mr-24 fs-24 c-gray">CREATE CART</h1> */}
            </div>
            {
              canCreateUser ? <div
              onClick={() => goTo('/entities/create', {isFromCart: true})}
              className="btn ht-40 decoration-none flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer  p-absolute r-4"
            >
              <img src={IconAdd} className="ht-20 wt-20 mr-12" />
              <span>Create Customer </span>
            </div> : null
            }
            
          </div>
          {/* <div classNam="flex-1">
            {customerList.length > 0 &&
              (params.offset >= 10 || params.areMoreItemsAvailable) && (
                <div className="ht-60 flex flex-middle flex-center pl-16 pr-16  pagination-wrapper">
                  <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                    <img
                      src={CaretIcon}
                      alt=""
                      className={`wt-20 rotate-90 c-pointer ${
                        params.offset > 0 ? "op-7" : "op-2"
                      }`}
                      onClick={params.offset > 0 ? fetchLess : () => {}}
                    />
                  </div>
                  <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                    Showing {params.offset + 1} -{" "}
                    {params.offset + customerList.length}
                  </span>
                  <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                    <img
                      src={CaretIcon}
                      alt=""
                      className={`wt-20 rotate--90 c-pointer ${
                        params.areMoreItemsAvailable ? "op-7" : "op-2"
                      }`}
                      onClick={
                        params.areMoreItemsAvailable ? fetchMore : () => {}
                      }
                    />
                  </div>
                </div>
              )}
          </div> */}
          <div className="flex-1"></div>
          <Table
            scrollTable={true}
            highlight={true}
            columns={["Customer", "Contact Person Name", "Address", "Facility","Company Type", "Active/Inactive", "City,State"]}
            items={customerList.map((customer, index) => {
              return {
                ...customer,
                customerName: customer.firstName + " " + customer.lastName,
                group: customer.companyName,
                location: customer.city + ", " + customer.state,
                isActive: customer.isActive?'Active':'Inactive',
                type: "-",
              };
            })}
            keys={["group", "customerName", "address1", "facility","companyType", "isActive", "location"]}
            widthClasses={[
              "flex-1",
              "flex-1",
              "flex-1",
              "flex-1",
              "flex-1",
              "wt-200",
            ]}
            isLoading={tableLoading}
            isCart={true}
            onItemClick={(item) => {
              setSelectedUser({...item, loadName: item.customerName});
            }}
            offset={params.offset}
            areMoreItemsAvailable={params.areMoreItemsAvailable}
            fetchMore={fetchMore}
            fetchLess={fetchLess}
            onCountChange={(value) => {
              setParams({
                ...params,
                offset: 0,
                limit: value,
              });
            }}
            limit={params.limit}
            className="customerSearchTable"
            // onItemClick={hendelUserSelect}
          />
          {Object.keys(selectedUser).length > 0 && (
            <Modal>
              <div className="pt-10 pb-20 pl-10 pr-10 wt-320">
                <div className="flex flex-middle mr-12 pb-20">
                  <h1 className="mr-24 fs-20 c-black fw-bolder">CREATE CART</h1>
                </div>

                <div className="flex flex-middle mb-24 mt-10">
                  <span className="c-gray mr-4 wt-30p"> NAME : </span>
                  <FormInput
                    className={"border-bottom-black flex-1"}
                    // placeholderType="red"
                    // placeholder="Name"
                    value={selectedUser.loadName}
                    onChange={(e) => {
                      setSelectedUser({
                        ...selectedUser,
                        loadName: e.target.value,
                      });
                    }}
                  />
                </div>
                <div className="flex flex-middle mb-24 mt-10">
                  <span className="c-gray mr-4 wt-30p"> LOCATION: </span>

                  {/*<FormInput 
                    className={'border-bottom-gray'}
                    // placeholderType="red" 
                    // placeholder="Name" 
                    value={selectedUser.location} 
                    onChange={(e)=> { 
                      setSelectedUser({...selectedUser, location: e.target.value}) 
                    }} 
                  />*/}

                  {/*<div className="c-gray fs-14 mb-4">Company State</div>*/}
                  <select
                    name="type"
                    className="bg-blue rounded-pill select font-primary fs-14 fw-bolder "
                    value={selectedUser.facilityCity}
                    onChange={(e) =>
                      setSelectedUser({
                        ...selectedUser,
                        facilityCity: e.target.value,
                      })
                    }
                  >
                    <option value={""}>SELECT FACILITY</option>
                    {facilities.map((state) => {
                      return <option value={state.name}>{state.name}</option>;
                    })}
                  </select>
                </div>

                <div className="flex flex-middle mb-24 mt-10 ">
                  <span className="c-gray mr-4 wt-30p"> IS QUOTE? </span>
                  <Toggle
                    className="bg-theme hide-check-cross"
                    onChange={(e) => {
                      setSelectedUser({
                        ...selectedUser,
                        isQuote: e.target.checked,
                      });
                    }}
                    checked={selectedUser.isQuote}
                  />
                </div>

            <div className="wt-33p mr-40 p-relative t--8">
            <div className="flex flex-middle">
              
              
              {/* <div className="flex flex-middle fs-18 wt-30  bg-white">{isPriceModifierPercantage ? "%" : "$"}</div> */}
            </div>
          </div>

                <div className="flex flex-middle flex-right pt-25">
                  <Button
                    type="modalCancel"
                    className="btn ht-40 btn-red flex flex-middle mr-20 bg-red"
                    onClick={() => {
                      setSelectedUser({});
                    }}
                  >
                    <span>CANCEL</span>
                  </Button>
                  <Button
                    type="modalSave"
                    className="btn ht-40 flex flex-middle"
                    onClick={loading?() => {}:hendelUserSelect}
                  >
                    <span>{loading?'Loading':'SAVE'}</span>
                  </Button>
                </div>
              </div>
            </Modal>
          )}
        </>
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default CreateLoads;
