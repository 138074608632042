import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import ConverterInfo from "../../components/AddEditItems/ConverterInfo";
import ConverterPricing from "../../components/AddEditItems/ConverterPricing";
import TestAddEdit from "../../components/AddEditItems/TestAddEdit";
import Table from "../../components/Table";
import Toast from "../../components/Toast";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Button from "../../components/Button";
import FormInput from "../../components/FormInput";
import Toggle from "react-toggle";

import { getLoginDetails } from "../../utils/storage";
import Modal from "../../components/Modal";

import HeaderIcon from "../../icons/black_pricing.png";

import {
  GET_MAKE_MODEL_URL,
  EDIT_CONVERTERS_URL,
  ADD_TEST_URL,
  EDIT_TEST_URL,
  DELETE_TEST_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_TEST_HISTORY_URL,
  GET_PRICE_HISTORY_URL,
  GET_CONVERTER_TYPES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import IconUsersInactive from "../../icons/rev_user.png";
import IconInformationInactive from "../../icons/rev_information.png";
import IconPricingInactive from "../../icons/rev_pricing.png";

import IconUsersActive from "../../icons/pos_user.png";
import IconInformationActive from "../../icons/pos_information.png";
import IconPricingActive from "../../icons/pos_pricing.png";

const testModel = {
  productId: "",
  subweight: "",
  ptAssay: "",
  pdAssay: "",
  rhAssay: "",
  location: "",
  userName: "",
  notes: "",
  testId: "",
};

const ConverterPricingDetail = (props) => {
  const { canShowTestHistory, canShowPricing, hierarchy } = getLoginDetails();

  const [isLoading, setIsLoading] = useState(true);
  const [productDetail, setProductDetail] = useState(null);
  const [makeAndModelList, setMakeAndModelList] = useState(null);
  const [testHistory, setTestHistory] = useState([]);
  const [activeView, setActiveView] = useState(1);
  const [isAddTestPopupShown, setIsAddTestPopupShown] = useState(false);
  const [isEditTestPopupShown, setIsEditTestPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [convoTypes, setConvoTypes] = useState([]);
  const [updateItem, setUpdateItem] = useState({});
  const [isConfirmSavePopupshown, setIsConfirmSavePopupshown] = useState(false);
  const [testItemForEdit, setTestItemForEdit] = useState(null);
  const [testForDelete, setTestForDelete] = useState(null);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const [calculatedPrice, setCalculatedPrice] = useState(0)
  const [tableLoading, setTableLoading] = useState(false);
  const [points, setPoints] = useState([]);
  const [historyGraphItems, setHistoryGraphItems] = useState([]);

  const parentProductId = props.data.productId || "";

  const getProductDetail = async (productId) => {
    try {
      setIsLoading(true);
      setProductDetail(null);
      const data = await getData(GET_PRODUCT_DETAILS_URL, {
        productId: productId,
      });
      setProductDetail(data);
      setCalculatedPrice(data.price || 0);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };
  const getHistoryForGraph = async (productId) => {
    try {
      const data = await getData(GET_PRICE_HISTORY_URL, {
        productId: productId,
      });
      // const pts = {abListPrice: [], abPublishPrice: []};
      const pts = [["Date", "List Price", "Publish Price"]];

      (data.list || []).map((obj, index) => {
        // pts.abListPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abListPrice)])
        // pts.abPublishPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abPublishPrice)])
        pts.push([
          moment(obj.date).format("Do, MMM YY, hh:mm A"),
          parseFloat(obj.abListPrice),
          parseFloat(obj.abPublishPrice),
        ]);
      });
      setPoints(pts);
      setHistoryGraphItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getTestHistory = async () => {
    try {
      const data = await getData(GET_TEST_HISTORY_URL, {
        productId: parentProductId,
      });
      setTestHistory(data.list);
      setTableLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, { count: 100 });
      setIsLoading(false);
      setMakeAndModelList(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getConverterTypes = async () => {
    try {
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data, images) => {
    let urls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      urls = updatedUrls.map((item) => {
        if ((item.imageUrl || "").length > 0) {
          return item.imageUrl;
        }
      });
    }
    try {
      // setIsLoading(true)
      const response = await putData(EDIT_CONVERTERS_URL, { ...productDetail, ...data, price: productDetail.price, isCalculatedPrice: productDetail.isCalculatedPrice, urls });
      // setIsEditPopupShown(false);
      // setIsLoading(true);

      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Conveter",
        id: new Date().getTime(),
      });
      const productId = parentProductId;

      getProductDetail(productId);
      getTestHistory(productId);

      setIsLoading(false);
      setIsConfirmSavePopupshown(false)
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
      setIsConfirmSavePopupshown(false)
    }
  };
  const addTest = async (data) => {
    try {
      const { firstName, lastName, userName } = getLoginDetails();
      const productId = parentProductId;
      const response = await postData(ADD_TEST_URL, {
        ...data,
        userName,
        firstName,
        lastName,
        productId,
      });

      setToastParams({
        type: "SUCCESS",
        message: "Successfully Added the test",
        id: new Date().getTime(),
      });
      setIsAddTestPopupShown(false);
      getTestHistory(productId);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editTest = async (data) => {
    try {
      const { firstName, lastName, userName } = getLoginDetails();
      const productId = parentProductId;
      const response = await putData(EDIT_TEST_URL, {
        ...data,
        userName,
        firstName,
        lastName,
        productId,
      });

      setToastParams({
        type: "SUCCESS",
        message: "Successfully Edited the test",
        id: new Date().getTime(),
      });
      setIsEditTestPopupShown(false);
      getTestHistory(productId);
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteTest = async () => {
    try {
      setIsLoading(true);
      const response = await deleteData(DELETE_TEST_URL, {
        testId: testForDelete.testId,
      });
      setIsDeletePopupShown(false);
      getTestHistory();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };
  const setDeleteTest = (item) => {
    setIsDeletePopupShown(true);
    setTestForDelete(item);
  };

  useEffect(() => {
    const { firstName, lastName, userName } = getLoginDetails();
    const productId = parentProductId;
    getProductDetail(parentProductId);
    getTestHistory(productId);
    // getHistoryForGraph(productId);
    getConverterTypes();
    // getMakeAndModel();
  }, []);

  return (
    <div>
      {!isLoading &&
        !isAddTestPopupShown &&
        !isEditTestPopupShown &&
        productDetail && (
          <>
            <div className="flex flex-between flex-middle">
              <div className="flex" style={{marginTop: 10}}>
            <div className="fs-16 mr-20">
                <span className="c-gray mr-12">OEM: </span>
                {productDetail.oem}
              </div>
              <div className="fs-16 mr-20">
                <span className="c-gray mr-12">Manufacturer: </span>
                {productDetail.manufacturer}
              </div>
              <div className="fs-16">
                <span className="c-gray mr-28">Description: </span>
                {productDetail.description}
              </div>
            </div>
              <div className="flex flex-middle">
                {/* {productDetail.price ? ( */}
                  {/* <div className="flex mr-40 flex-bottom">
                    <span className="c-red fs-18 mr-12 fw-bolder">
                      OFFER PRICE
                    </span>
                    <span className="fs-28 fw-bolder">
                      ${Math.round(productDetail.offerPrice || 0.00)}
                    </span>
                  </div> */}
                {/* ) : null} */}

                <div
                    className="btn wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => props.setShowPricingTab(null)}
                  >
                    <span>Back</span>
                  </div>
              </div>
            </div>
            <div className="pl-8 pt-8 pb-16 bg-lt-gray-3">
                <div className="flex flex-middle mb-20">
                <div className="flex mr-40 flex-bottom">
                    <span className="c-red fs-18 mr-12 fw-bolder">
                      CALCULATED PRICE
                    </span>
                    <span className="fs-28 fw-bolder">
                      ${Math.round(productDetail.offerPrice || 0.00)}
                    </span>
                  </div>
                  <span class="c-red fw-bolder mr-12"> OVERRIDE PRICE {"  "}</span>
                  <span class="c-black mr-4 fw-bolder">$</span>
                  <FormInput
                    type="text"
                    className="mr-40 wt-120 border-black"
                    inputClassName="pl-8"
                    value={productDetail.price}
                    onChange={(e) => {
                      const val = Math.round(e.target.value.replace(/(?!^-)[^0-9.]/g, "").replace(/(\..*)\./g, '$1'));
                      setProductDetail({
                        ...productDetail,
                        price: val
                      });
                    }}
                  />
                  <div className="mr-4">
                    <div className="c-red fw-bolder fs-14 flex flex-between flex-middle">
                      <span className="mr-4">USE OVERRIDE PRICE ?</span>
                      <div className="flex flex-middle">
                        <Toggle
                          className="bg-theme"
                          checked={productDetail.isCalculatedPrice}
                          onChange={(e) => {
                            setProductDetail({
                              ...productDetail,
                              isCalculatedPrice: e.target.checked,
                            });
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            <div className={`tabs flex`}>
        </div>

                <ConverterInfo
                  converter={{ ...productDetail }}
                  convoTypes={[...convoTypes]}
                  makeAndModelList={makeAndModelList}
                  onSave={editItem}
                  setShowPricingTab={props.setShowPricingTab}
                  showAction={true}
                />
                <div className="border-bottom-thick mt-16 mb-8" />
                {canShowTestHistory && (
                  <>
                    <div className="flex flex-between mt-24">
                      <div className="c-red fs-18 fw-bolder ">Test History</div>
                      <div className="wt-35p" style={{display: 'flex'}}>
                          <div
                            className="btn  wt-180 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                            onClick={() => {
                              setIsAddTestPopupShown(true);
                            }}
                          >
                            <span>Add Test</span>
                          </div>
                        </div>
                    </div>
                    <Table
                      scrollTable={true}
                      columns={[
                        "Date",
                        "Person ",
                        "PT Assay",
                        "PD Assay",
                        "RH Assay",
                      ]}
                      // className="wt-600"
                      items={testHistory.map((test) => {
                        return {
                          ...test,
                          date: (() => {
                            const dateObj = new Date(test.createdDate);
                            const year = dateObj.getFullYear();
                            const month = `0${dateObj.getMonth() + 1}`.slice(
                              -2
                            );
                            const date = dateObj.getDate();
                            return `${year}-${month}-${date}`;
                          })(),
                          user: `${test.firstName} ${test.lastName}`,
                        };
                      })}
                      keys={["date", "user", "ptAssay", "pdAssay", "rhAssay"]}
                      widthClasses={[
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "wt-100",
                      ]}
                      actions={["EDIT", "DELETE"]}
                      isLoading={tableLoading}
                      onEdit={(item) => {
                        setTestItemForEdit(item);
                        setIsEditTestPopupShown(true);
                      }}
                      onDelete={setDeleteTest}
                    />
                  </>
                )}
          </>
        )}
      {isAddTestPopupShown && (
        <TestAddEdit
          item={{ ...testModel }}
          type="add"
          onSave={addTest}
          onClose={setIsAddTestPopupShown.bind(null, false)}
          onBack={setIsAddTestPopupShown.bind(null, false)}
        />
      )}
      {isEditTestPopupShown && (
        <TestAddEdit
          item={{ ...testItemForEdit }}
          type="edit"
          onSave={editTest}
          onClose={setIsEditTestPopupShown.bind(null, false)}
          onBack={setIsEditTestPopupShown.bind(null, false)}
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="test"
          name={testForDelete.testId}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteTest}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {isConfirmSavePopupshown && (
        <Modal>
          <div className="pl-16 pr-16">
            <div className="fs-18 fw-bolder t-center mb-20">
              Are you sure you want to save the change ?
            </div>
            <div className="flex flex-middle flex-center">
              <Button
                className="mr-20"
                type="secondary"
                onClick={() => setIsConfirmSavePopupshown(false)}
              >
                Cancel
              </Button>
              <Button type="modalSave"
                onClick={() => {
                  editItem({ ...productDetail }, null);
                }}
              >
                Confirm
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </div>
  );
};

export default ConverterPricingDetail;
