import React from "react";

function InvoiceLink(props) {
  return (
    <a href="" onClick={() => props.handleFilterDataPersistence(props.href)}>
      Invoice
    </a>
  );
}

export default InvoiceLink;
