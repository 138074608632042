import React, {useState} from 'react';
import Button from '../Button';

import FormInput from '../FormInput';

import CaretIcon from '../../icons/caret.png';


const TestAddEdit = ({item, onClose, onSave, type, onBack, shouldShowPriceMod, facilities, editType}) => {
    const [subweight, setSubweight] = useState(item.subweight);
    const [ptAssay, setPtAssay] = useState(item.ptAssay);
    const [pdAssay, setPdAssay] = useState(item.pdAssay);
    const [rhAssay, setRhAssay] = useState(item.rhAssay);
    const [location, setLocation] = useState(item.location);
    const [notes, setNotes] = useState(item.notes);


    const [image, setImage] = useState(null);

    const [hasSubmitted, setHasSubmitted] = useState(false);

    const [validations, setValidations] = useState({})
    const validateFields = ()=> {
        const updatedValidations = {...validations};

        updatedValidations.subweight = subweight;
        updatedValidations.ptAssay = ptAssay;
        updatedValidations.pdAssay = pdAssay;
        updatedValidations.rhAssay = rhAssay;
        updatedValidations.location = location;

        setValidations(updatedValidations);
        return updatedValidations;

    }

    return (
        // <Modal>
            <div className="mt-8">
                <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
                    <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
                    <span className="fs-12 c-table-header fw-bolder">BACK TO CONVERTERS</span>
                </div>
                <div className="fs-24 fw-bolder mb-40">{type === 'add'?'Add': 'Edit'} Test</div>
                {/* <div className="flex flex-middle flex-center">
                    <ImageUploading onChange={setImage} initialImage={item.companyLogo}/>
                </div> */}

                <div className="flex flex-between flex-middle mb-40 flex-wrap">
                    <FormInput 
                        placeholder="Sub weight"
                        onChange={(e)=> {
                            setSubweight(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={subweight}
                        hasError={hasSubmitted && !validations.subweight}

                    />
                    <FormInput 
                        placeholder="PT Assay"
                        onChange={(e)=> {
                            setPtAssay(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={ptAssay}
                        hasError={hasSubmitted && !validations.ptAssay}

                    />
                    <FormInput 
                        placeholder="PD Assay"
                        onChange={(e)=> {
                            setPdAssay(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={pdAssay}
                        hasError={hasSubmitted && !validations.pdAssay}

                    />
                    <FormInput 
                        placeholder="RH Assay"
                        onChange={(e)=> {
                            setRhAssay(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={rhAssay}
                        hasError={hasSubmitted && !validations.rhAssay}

                    />
                    <FormInput 
                        placeholder="Location"
                        onChange={(e)=> {
                            setLocation(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={location}
                        hasError={hasSubmitted && !validations.location}

                    />
                    <textarea placeholder="Notes" className="mr-40 wt-33p" value={notes} onChange={(e)=> { setNotes(e.target.value) }} rows="4"/>
                    
                </div>
                <div className="flex flex-middle flex-right">
                    <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button>
                    <Button type="modalSave" onClick={()=> {
                        setHasSubmitted(true);
                        const updatedValidations = validateFields();
                        if(
                            !updatedValidations.subweight ||
                            !updatedValidations.ptAssay ||
                            !updatedValidations.pdAssay ||
                            !updatedValidations.rhAssay ||
                            !updatedValidations.location
                        ) {
                            return;
                        }
                        onSave({
                            subweight,
                            pdAssay,
                            ptAssay,
                            rhAssay,
                            location,
                            notes,
                            testId: item.testId                        
                        })}}
                    >{type === 'add'?'Add': 'Edit'} item</Button>
                </div>
            </div>
        // </Modal>
    )
}

export default TestAddEdit
