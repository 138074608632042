import React, {useEffect} from 'react'

import PageLayout from '../../components/PageLayout';
import ActionLoader from '../../components/ActionLoader';

import { 
	LOGOUT_URL,

    deleteData,

} from '../../utils/api';
import { 

    deleteToken,

} from '../../utils/storage';

const Logout = (props) => {
    const logout = async ()=> {
        try {
            const response = await deleteData(LOGOUT_URL);
            deleteToken();
            props.history.push('/');
        } catch(e) {
            console.log("ERROR", e);
        }
    }
    useEffect(() => {
        logout();
    }, [])
    return (
        <PageLayout>
            <ActionLoader position={'fixed'}/>
        </PageLayout>
    )
}

export default Logout
