import React, { useState, useRef } from "react";
import SearchIcon from "../../icons/pos_find.png";

const FormInput = ({
  onChange,
  value,
  className,
  placeholder,
  placeholderType,
  inputPlaceHolder,
  hasError,
  errorMessage,
  inputType,
  inputClassName,
  spanText,
  isReadonly,
  isMandatory,
  pill,
  width = false,
  ...props
}) => {
  const [focusState, setFocusState] = useState(false);
  const ref = useRef(null);
  return (
    <div
      className={`${className || ""} p-relative forminput-wrap ${
        inputType === "search" && !width ? "wt-300" : width
      }`}
    >
      {placeholder && inputType !== "search" && placeholderType !== "red" && (
        <div className={`mb-4 c-gray placeholder`}>
          {placeholder} {isMandatory && <span className="c-error">*</span>}
        </div>
      )}
      {placeholder && inputType !== "search" && placeholderType === "red" && (
        <div className={`mb-4 c-red fw-bolder placeholder colour-blue`}>
          {placeholder}{" "}
          {spanText && <span className="c-gray fs-14">{spanText}</span>}
        </div>
      )}
      {inputType === "search" && (
        <img
          alt=""
          src={SearchIcon}
          className={`wt-20 t-8 p-absolute ${
            props.floatSearchIconLeft ? "r-8" : "l-8"
          }`}
        />
      )}
      <input
        className={`${inputClassName || ""}  form-control forminput fs-16 ${
          inputType === "nonBold" ||
          inputType === "search" ||
          inputType === "login"
            ? ""
            : "fw-bolder"
        } ${
          inputType === "search"
            ? `ht-35 search ${props.floatSearchIconLeft ? "pr-32" : "pl-32"}`
            : pill == true
            ? "rounded-pill"
            : ""
        }`}
        onChange={onChange}
        {...props}
        autoComplete={"off"}
        onFocus={(e) => {
          setFocusState(true);
        }}
        onBlur={(e) => {
          setFocusState(false);
        }}
        value={value}
        focus={focusState}
        ref={ref}
        placeholder={
          inputType === "search" ? placeholder : inputPlaceHolder || ""
        }
        readOnly={isReadonly}
      />
      {hasError && (
        <div className="fs-10 c-error fw-bold p-absolute">
          Please enter the valid value
        </div>
      )}
    </div>
  );
};

export default FormInput;
