import React from "react";
import { IoCheckboxSharp } from "react-icons/io5";
import { RiCheckboxBlankLine } from "react-icons/ri";

import { IconContext } from "react-icons";

const Checkbox = ({
  className,
  isSelected,
  onClick,
  id,
  label,
  labelClassName,
}) => {
  return (
    <div
      className="flex flex-middle"
      onClick={(e) => {
        e.preventDefault();
        e.stopPropagation();
        onClick(id);
      }}
    >
      <IconContext.Provider value={{ size: "1.3em", color: "#0B5FFF", className: className || "" }}>
        <div>{isSelected ? <IoCheckboxSharp /> : <RiCheckboxBlankLine />}</div>
      </IconContext.Provider>
      {label && <div className={`pl-8 ${labelClassName || ""}`}>{label}</div>}
    </div>
  );
};

export default Checkbox;
