import React, { useState } from "react";
import PageLayout from "../../components/PageLayout";

import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import { GET_CUSTOMERS_REPORT_URL, getData } from "../../utils/api";
import ActionLoader from "../../components/ActionLoader";

export const DownloadCustomers = (props) => {
  const [isLoading, setIsLoading] = useState(false);
  const getReport = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_CUSTOMERS_REPORT_URL, {});
      if (data && data.link && data.link != "") {
        const element = document.createElement("a");
        element.setAttribute("href", data.link);
        element.setAttribute("download", true);
        element.click();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };

  return (
    <PageLayout
      icon={HeaderIconSvg}
      hideSearchBar={true}
      heading="Download Customers"
      searchPlaceholder="Search File"
    >
      <div className="flex mb-16 mt-8 flex-between">
        <div className="flex flex-between"></div>
          <div className="flex flex-between">
        <div className="flex flex-between mr-4">
          <div
            className="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              getReport(true);
            }}
          >
            <span>Download Customers</span>
          </div>
        </div>
        </div>
      </div>
      {isLoading && (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      )}
    </PageLayout>
  );
};

export default DownloadCustomers;
