import React, { useState } from "react";

import Toggle from "react-toggle";

// import Modal from '../Modal';
import Button from "../Button";

import FormInput from "../FormInput";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import { STATES_LIST } from "../../utils/api";

const STATES = ["Florida", "Wisconsin", "Georgia"];

const FacilityAddEdit = ({
  item,
  onClose,
  onSave,
  type,
  onBack,
  shouldShowPriceMod,
  isLoading=false
}) => {
  const [name, setName] = useState(item.name);

  const [address, setAddress] = useState(item.address);
  const [city, setCity] = useState(item.city);
  const [state, setState] = useState(item.state);
  const [zipcode, setZipcode] = useState(item.zipcode);
  const [email, setEmail] = useState(item.email);
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [phone, setPhone] = useState(item.phone);
  const [isActive, setIsActive] = useState(item.isActive);
  const [isFacility, setIsFacility] = useState(item.isFacility || false);
  const [isPicRequired, setIsPicRequired] = useState(item.isPicRequired || false);
  const [isSignRequired, setIsSignRequired] = useState(item.isSignRequired || false);
  const [isEinRequired, setIsEinRequired] = useState(item.isEinRequired || false);


  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [validations, setValidations] = useState({});
  const validateFields = () => {
    const updatedValidations = { ...validations };

    updatedValidations.name = name;
    updatedValidations.email = email;
    updatedValidations.address = address;
    updatedValidations.city = city;
    updatedValidations.state = state;
    updatedValidations.zipcode = zipcode;
    updatedValidations.firstName = firstName;
    updatedValidations.lastName = lastName;
    updatedValidations.phone = phone;

    setValidations(updatedValidations);
    return updatedValidations;
  };

  
  const normalizeInput = (value) => {
    if (!value) return value;
    const currentValue = value.replace(/[^\d]/g, "");
    const cvLength = currentValue.length;
  
    if (value.length > 0) {
      if (cvLength < 4) return currentValue;
      if (cvLength < 7)
        return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
      return `${currentValue.slice(0, 3)}-${currentValue.slice(
        3,
        6
      )}-${currentValue.slice(6, 10)}`;
    }
  };

  return (
    // <Modal>
    <div className="mt-8">
      {/* <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
                    <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
                    <span className="fs-12 c-table-header fw-bolder">BACK TO CUSTOMERS</span>
                 </div> */}
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="fs-24 fw-bolder mb-40">
          {type === "add" ? "ADD" : "EDIT"} FACILITY
        </div>
        {/* <div className="flex flex-middle flex-center">
                      <ImageUploading onChange={setImage} initialImage={item.companyLogo}/>
                  </div> */}

        <div className="flex flex-between mb-40 flex-wrap">
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Facility Name"
            onChange={(e) => {
              setName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={name}
            hasError={hasSubmitted && !validations.name}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Director First Name"
            onChange={(e) => {
              setFirstName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={firstName}
            hasError={hasSubmitted && !validations.firstName}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Director Last Name"
            onChange={(e) => {
              setLastName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={lastName}
            hasError={hasSubmitted && !validations.lastName}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Phone"
            onChange={(e) => {
              setPhone(normalizeInput(e.target.value));
            }}
            className="mr-40 wt-33p"
            value={phone}
            hasError={hasSubmitted && !validations.phone}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Email"
            onChange={(e) => {
              setEmail(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={email}
            hasError={hasSubmitted && !validations.email}
          />
        </div>
        <div className="flex flex-between flex-middle mb-40 flex-wrap">
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Address"
            onChange={(e) => {
              setAddress(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={address}
            hasError={hasSubmitted && !validations.address}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="City"
            onChange={(e) => {
              setCity(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={city}
            hasError={hasSubmitted && !validations.city}
          />
          <div className=" mr-40 wt-33p">
            <div className="c-gray fs-14 mb-4">State</div>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder bg-blue rounded-pill"
              value={state}
              onChange={(e) => setState(e.target.value)}
            >
              <option value={""}>Select State</option>
              {STATES_LIST.map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </select>
          </div>
          {/*<FormInput inputClassName="rounded-pill bg-blue" 
                          placeholder="State"
                          onChange={(e)=> {
                              setState(e.target.value);
                          }}
                          className="mr-40 wt-33p"
                          value={state}
                          hasError={hasSubmitted && !validations.state}

                      />*/}
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Zipcode"
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }
              setZipcode(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={(zipcode || "").replace(/[^\d]/g, "")}
            hasError={hasSubmitted && !validations.zipcode}
          />
          <div className="wt-33p mr-40 p-relative t--8">
            <div className="fs-14 mb-4 fw-bold mt-20">Is Active?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsActive(e.target.checked);
              }}
              checked={isActive}
            />
          </div>
          <div className="wt-33p mr-40 p-relative t--8">
            <div className="fs-14 mb-4 fw-bold mt-20">Is Franchise?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsFacility(e.target.checked);
              }}
              checked={isFacility}
            />
          </div>
          <div className="wt-33p mr-40 p-relative">
          <div className="fs-14 mb-4 fw-bold mt-20">Picture Required?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsPicRequired(e.target.checked);
              }}
              checked={isPicRequired}
            />
          </div>
          <div className="wt-33p mr-40 p-relative">
          <div className="fs-14 mb-4 fw-bold mt-20">Signature Required?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsSignRequired(e.target.checked);
              }}
              checked={isSignRequired}
            />
          </div>
          <div className="wt-33p mr-40 p-relative">
          <div className="fs-14 mb-4 fw-bold mt-20">EIN# Required?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsEinRequired(e.target.checked);
              }}
              checked={isEinRequired}
            />
          </div>
          <div className="wt-33p mr-40 p-relative"></div>
        </div>
        <div className="flex flex-middle flex-right">
          <Button
            className="flex  ht-40 flex-middle mr-20 btn btn-red"
            type="modalCancel"
            style={{padding: '10px 30px'}}
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            className="btn  ht-40"
            type="modalSave"
            style={{padding: '10px 30px'}}
            onClick={() => {
              if(isLoading) {
                return;
              }
              setHasSubmitted(true);
              const updatedValidations = validateFields();
              if (
                !updatedValidations.name ||
                !updatedValidations.address ||
                !updatedValidations.city ||
                !updatedValidations.state ||
                !updatedValidations.zipcode ||
                !updatedValidations.firstName ||
                !updatedValidations.lastName ||
                !updatedValidations.email
              ) {
                return;
              }
              onSave({
                name,
                address,
                city,
                state,
                zipcode,
                email,
                firstName,
                lastName,
                phone,
                facilityId: item.facilityId,
                companyLogo: item.companyLogo,
                isActive,
                isFacility,
                isPicRequired,
                isSignRequired,
                isEinRequired
              });
            }}
          >
            {/* {type === 'add' ? 'Add' : 'Edit'} item */}
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            <span>{isLoading?'Loading...':'SAVE'}</span>
          </Button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default FacilityAddEdit;
