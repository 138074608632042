import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import MakeAddEdit from "../../components/AddEditItems/MakeAddEdit";

import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import MakeIcon from "../../icons/rev_manufacturer.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_MAKE_MODEL_URL,
  ADD_MAKE_MODEL_URL,
  EDIT_MAKE_MODEL_URL,
  FILE_UPLOAD_URL,
  DELETE_MAKE_MODEL_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";
import makeIcon from "../../icons/newicons/gear-fill.svg";

const makeSample = {
  make: "",
  model: [],
  description: "",
  imglUrls: [],
  videoUrls: [],
  documentUrls: [],
};

export const MakeAndModel = (props) => {
  const [makeAndModelList, setMakeAndModelList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);

  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
      });
      setIsLoading(false);
      setMakeAndModelList(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getMakeAndModel();
  }, [params.offset, params.text, params.limit]);

  // useEffect(()=> {
  //     getEntities();
  // }, [params.offset]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const addItem = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        return uploadFile(FILE_UPLOAD_URL, image.file);
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      const response = await postData(ADD_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Make",
        id: new Date().getTime(),
      });
      getMakeAndModel();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await putData(EDIT_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Make",
        id: new Date().getTime(),
      });
      getMakeAndModel();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_MAKE_MODEL_URL, {
        makeId: itemForDelete.makeId,
      });
      setIsDeletePopupShown(false);
      getMakeAndModel();
    } catch (e) {
      setIsDeletePopupShown(false);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong",
        id: new Date().getTime(),
      });
      console.log("ERROR");
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  const {

    hierarchy
  } = getLoginDetails();
  return (
    <PageLayout
      onSearchChange={onSearchChange}
      icon={HeaderIconSvg}
      heading="Make"
      searchPlaceholder="Search Make"
    >
      {!isAddPopupShown && !isEditPopupShown && (hierarchy ===1) && (
        <div className="flex flex-middle mb-16 mt-8 flex-between">
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW FACILITIES</h1> */}
          </div>
          {/* {makeAndModelList.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + makeAndModelList.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )} */}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          <div
            className="btn  ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
            }}
          >
            <img src={MakeIcon} className="ht-20 wt-20 mr-12" />
            <span>Add Make</span>
          </div>
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && (
        <Table
          className="makeTable"
          scrollTable={true}
          // type="entity"
          columns={["Make", "Models", "Description", "Created Date"]}
          // className="wt-600"
          items={makeAndModelList.map((make) => {
            return {
              ...make,
              models: (make.model || []).join(", ") || " - ",
              date: (() => {
                const dateObj = new Date(make.createdDate);
                const year = dateObj.getFullYear();
                const month = `0${dateObj.getMonth() + 1}`.slice(-2);
                const date = dateObj.getDate();
                return `${month}-${date}-${year}`;
              })(),
            };
          })}
          keys={["make", "models", "description", "date"]}
          widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]}
          actions={[(hierarchy ===1) && "EDIT",(hierarchy ===1) &&  "DELETE"]}
          onEdit={setEdit}
          onDelete={setDelete}
          onChange={onSearchChange}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          hideCheckBox={true}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
          limit={params.limit}

          // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
        />
      )}
      {isAddPopupShown && (
        <MakeAddEdit
          item={{ ...makeSample }}
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          type="add"
        />
      )}
      {isEditPopupShown && (
        <MakeAddEdit
          item={{ ...itemForEdit }}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="make"
          name={itemForDelete.make}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default MakeAndModel;
