import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

import PageLayout from "../../components/PageLayout";

import ConverterInfo from "../../components/AddEditItems/ConverterInfo";
import ConverterPricing from "../../components/AddEditItems/ConverterPricing";
import Table from "../../components/Table";
import FormInput from "../../components/FormInput";
import SelectCheckbox from "../../components/SelectCheckbox";
import ConverterCard from "../../components/ConverterCard";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import AskExpert from "../../components/AddEditItems/AskExpert";
import MakeAddEdit from "../../components/AddEditItems/MakeAddEdit";
import ConverterPricingDetail from "./ConverterPricingDetail";

import SearchByImageIcon from "../../icons/rev_search_by_image.png";
import SearchByImageIconActive from "../../icons/pos_search_by_image.png";
import AskExpertIcon from "../../icons/rev_ask_expert.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/black_converter.png";
import Cross from "../../icons/cross.svg";

import { getLoginDetails } from "../../utils/storage";

import {
  GET_CONVERTERS_URL,
  GET_MAKE_MODEL_URL,
  ASK_EXPERT_POST_URL,
  ADD_MAKE_MODEL_URL,
  EDIT_MAKE_MODEL_URL,
  GET_CONVERTER_DETAILS_URL,
  DELETE_CONVERTERS_URL,
  ADD_CONVERTERS_URL,
  EDIT_CONVERTERS_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_TEST_HISTORY_URL,
  GET_PRICE_HISTORY_URL,
  GET_CONVERTER_CART_URL,
  ADD_ITEMS_TO_CONV_CART_URL,
  GET_CONVERTER_TYPES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";
import { Link } from "react-router-dom";

const makeSample = {
  make: "",
  model: [],
  description: "",
  imglUrls: [],
  videoUrls: [],
  documentUrls: [],
};

let DEBOUNCE_TIMEOUT = null;

const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};

const PricingIdentifyConverters = (props) => {
  let makes = "";
  let oemNumbers = "";
  let convoSearchs = "";
  if (props.match && props.match.params && props.match.params.parameters) {
    const queryString = (props.match.params.parameters || "").split(";");
    for (let i = 0; i < queryString.length; i++) {
      if (queryString[i] !== "") {
        const item = queryString[i].split("=");
        if (item[0] === "make") {
          makes = item[1];
        } else if (item[0] === "convoSearch") {
          convoSearchs = item[1];
        } else if (item[0] === "oemNumber") {
          oemNumbers = item[1];
        }
      }
    }
  }

  const { firstName, lastName, hierarchy, canDeleteConverter } = getLoginDetails();
  const [isLoading, setIsLoading] = useState(hierarchy === 1);
  const [oemNumber, setOemNumber] = useState(oemNumbers || "");

  const [priceModifierItems, setPriceModifierItems] = useState([]);
  const [makeAndModelList, setMakeAndModelList] = useState([]);
  const [make, setMake] = useState(makes ? { make: makes } : null);
  const [model, setModel] = useState(null);

  const [converterViewType, setConverterViewType] = useState("TABLE");
  const [imgInModal, setImgInModal] = useState("");

  const [isAskExpertShown, setIsAskExpertShown] = useState(false);
  const [isAddMakeShown, setIsAddMakeShown] = useState(false);
  const [isEditMakeShown, setIsEditMakeShown] = useState(false);
  const [isImgModalShown, setIsImgModalShown] = useState(false);

  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForDelete, setItemForDelete] = useState(null);

  const [box, setBox] = useState(0);
  const [quantity, setQuatity] = useState(1);
  const [unit, setUnit] = useState(1);
  const [convoTypes, setConvoTypes] = useState([]);
  const [convoSearch, setConvoSearch] = useState(
    convoSearchs ? { name: convoSearchs } : null
  );
  const [showPricingTab, setShowPricingTab] = useState(null);

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [params, setParams] = useState({
    limit: 25,
    offset: 0,
    text: "",
    areMoreItemsAvailable: false,
  });
  const askExpert = async (image, message) => {
    let imageUrl = "";
    try {
      setIsLoading(true);
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        imageUrl = response.imageUrl;
      }
      const response = await postData(ASK_EXPERT_POST_URL, {
        message,
        url: imageUrl,
        firstName: firstName,
        lastName: lastName,
      });
      setIsAskExpertShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully posted the message to expert",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message: "Couldn't post the message to expert ",
        id: new Date().getTime(),
      });
    }
    setIsLoading(false);
  };
  const getConverters = async () => {
    try {
      setIsLoading(true);
      setPriceModifierItems([]);
      if (hierarchy !== 1 && (oemNumber || "").length === 0) {
        setIsLoading(false);
        return;
      }
      const data = await getData(GET_CONVERTERS_URL, {
        count: params.limit,
        offset: params.offset,
        make: (make || {}).make || "",
        ctype: (convoSearch || {}).name || "",
        text: `${params.text || ""};${oemNumber || ""};${model || ""}`
          .split(";")
          .filter((a) => a)
          .join(" "),
      });
      setIsLoading(false);
      setPriceModifierItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, { count: 100 });
      setIsLoading(false);
      setMakeAndModelList(data.list);
      if (Object.keys(makes || {}).length > 0) {
        const mItem = (data.list || []).filter((el) => {
          return el.make === makes;
        });
        if (mItem.length > 0) {
          setMake(mItem[0]);
        }
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };
  const addMake = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        return uploadFile(FILE_UPLOAD_URL, image.file);
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      const response = await postData(ADD_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsAddMakeShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Make",
        id: new Date().getTime(),
      });
      getConverters();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editMake = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await putData(EDIT_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsEditMakeShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Make",
        id: new Date().getTime(),
      });
      getConverters();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  const getConverterTypes = async () => {
    try {
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
      if (Object.keys(convoSearchs || {}).length > 0) {
        const convoObj = (data.list || []).filter((convo) => {
          return convo.name === convoSearchs;
        });
        if (convoObj.length > 0) {
          setConvoSearch(convoObj[0]);
        }
      }
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    getConverters();
  }, [
    params.offset,
    params.text,
    params.limit,
    make,
    model,
    convoSearch,
    converterViewType === "IMAGE",
  ]);

  useEffect(() => {
    if(!showPricingTab) {
      getConverters();
    }
  }, [showPricingTab])

  useEffect(() => {
    if (hierarchy === 1) {
      getMakeAndModel();
    }

    if (!DEBOUNCE_TIMEOUT) {
      if ((oemNumber || "").length > 0 || convoSearch || make) {
        getConverters();
      }
    }
  }, []);
  useEffect(() => {
    if (hierarchy === 1) {
      getConverterTypes();
    }
  }, []);
  useEffect(() => {
    if (DEBOUNCE_TIMEOUT) {
      clearTimeout(DEBOUNCE_TIMEOUT);
      DEBOUNCE_TIMEOUT = setTimeout(getConverters, 500);
      if (oemNumber.length === 0) {
        DEBOUNCE_TIMEOUT = null;
        return () => clearTimeout(DEBOUNCE_TIMEOUT);
      }
      return;
    }
    DEBOUNCE_TIMEOUT = setTimeout(() => {});
  }, [oemNumber]);

  useEffect(() => {
    DEBOUNCE_TIMEOUT = null;
    return () => clearTimeout(DEBOUNCE_TIMEOUT);
  }, []);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  const handleDeleteItem = async (item) => {
    setIsDeletePopupShown(false);
    try {
      setIsLoading(true);
      const response = await deleteData(DELETE_CONVERTERS_URL, {
        productId: itemForDelete.productId,
      });
      getConverters();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };

  const openImageModal = (img) => {
    setImgInModal(img);
    setIsImgModalShown(true);
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const renderConvertersInfo = () => {
    return (
      <div className="mt-16">
        {!isAskExpertShown && !isAddMakeShown && !isEditMakeShown && (
          <>
            {converterViewType === "TABLE" && (
              <>
                <div
                  style={{
                    boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "20px",
                    margin: "10px",
                  }}
                >
                  <div className="flex">
                    <form
                      className=" mr-40 flex-1"
                      onSubmit={(e) => {
                        e.preventDefault();
                        getConverters();
                      }}
                    >
                      <div className="flex flex-between flex-bottom">
                        <div className="wt-45p">
                          <FormInput
                            className="color-blue"
                            inputClassName="rounded-pill bg-blue "
                            placeholderType="red"
                            placeholder="SEARCH BY OEM NUMBER"
                            spanText="OR OTHER DATA"
                            className=""
                            value={oemNumber}
                            onChange={(e) => {
                              if (DEBOUNCE_TIMEOUT === null) {
                                DEBOUNCE_TIMEOUT = setTimeout(() => {});
                              }
                              setOemNumber(e.target.value);
                            }}
                          />
                        </div>
                        {oemNumber && (
                          <img
                            src={Cross}
                            className="wt-15 ht-15 mb-4 c-red c-pointer"
                            onClick={() => {
                              setOemNumber("");
                            }}
                          />
                        )}
                      </div>
                    </form>

                    <div className="flex flex-1">
                      <div className="w-200 mr-40">
                        <div className="c-red fw-bolder mb-20 colour-blue">
                          SELECT TYPE
                        </div>

                        <select
                          name="qty"
                          className="select font-primary fs-14 fRegular mb-12 rounded-pill bg-blue"
                          value={(convoSearch || {}).id}
                          onChange={(e) => {
                            const convoObj = convoTypes.find((convo) => {
                              return convo.id === e.target.value;
                            });
                            setConvoSearch(convoObj);
                          }}
                        >
                          <option value={""}>SELECT TYPE</option>
                          {(convoTypes || []).map((type) => {
                            return <option value={type.id}>{type.name}</option>;
                          })}
                        </select>
                      </div>
                    </div>

                      {
                        hierarchy === 1 ? (
                          <div className="flex flex-1">
                          <div className="wt-200 mr-40">
                            <div className="c-red fw-bolder mb-20 colour-blue">
                              SELECT MAKE
                            </div>
                            <select
                              name="qty"
                              className="select font-primary fs-14 fRegular mb-12 bg-blue rounded-pill"
                              value={(make || {}).makeId}
                              onChange={(e) => {
                                const id = e.target.value;
                                const make = makeAndModelList.find((make) => {
                                  return make.makeId === id;
                                });
                                setMake(make);
                                setModel(null);
                              }}
                            >
                              <option value={""}>SELECT MAKE</option>
                              {(makeAndModelList || []).map((make) => {
                                return (
                                  <option value={make.makeId}>{make.make}</option>
                                );
                              })}
                            </select>
                          </div>
                        </div>
                        ) : null
                      }

                  </div>
                </div>

                <div
                  className={`flex ${make || convoSearch ? "flex-column" : ""}`}
                >
                  {hierarchy !== 1 && (
                    <div className="c-red pt-12 pb-4 fs-12">
                      Search results limited to 5 items. Please refine your
                      search criteria for more results
                    </div>
                  )}
                </div>

                <div className=" mt-16 mb-24" />
              </>
            )}
            {converterViewType === "IMAGE" && (
              <div>
                <div className="flex flex-middle flex-between">
                  <div
                    className="btn  ht-40 flex flex-middle pl-16 pr-16 pb-4 pt-4 c-gray fs-22 fw-bolder c-pointer"
                    onClick={() => {
                      setConverterViewType(
                        converterViewType === "IMAGE" ? "TABLE" : "IMAGE"
                      );
                    }}
                  >
                    <img
                      alt=""
                      src={SearchByImageIconActive}
                      className="wt-50 ht-50 mr-8"
                    />
                    <span className="fw-bolder">Search By Image</span>
                  </div>
                  {priceModifierItems.length > 0 &&
                    (params.offset >= 10 || params.areMoreItemsAvailable) && (
                      <div className="ht-30 flex flex-middle flex-center pl-16 pr-16">
                        <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                          <img
                            src={CaretIcon}
                            alt=""
                            className={`wt-20 rotate-90 c-pointer ${
                              params.offset > 0 ? "op-7" : "op-2"
                            }`}
                            onClick={params.offset > 0 ? fetchLess : () => {}}
                          />
                        </div>
                        <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                          Showing {params.offset + 1} -{" "}
                          {params.offset + priceModifierItems.length}
                        </span>
                        <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                          <img
                            src={CaretIcon}
                            alt=""
                            className={`wt-20 rotate--90 c-pointer ${
                              params.areMoreItemsAvailable ? "op-7" : "op-2"
                            }`}
                            onClick={
                              params.areMoreItemsAvailable
                                ? fetchMore
                                : () => {}
                            }
                          />
                        </div>
                      </div>
                    )}

                  <div
                    className="flex flex-middle flex-between bg-gray pl-16 pr-16 pb-8 pt-8 c-white fw-bolder c-pointer"
                    onClick={() => {
                      setConverterViewType("TABLE");
                    }}
                  >
                    Back
                  </div>
                </div>
                <div className="mr-40">
                  <div className="c-red fw-bolder mb-8 colour-blue">
                    Search by Make
                  </div>
                  <select
                    name="qty"
                    className="select font-primary fs-14 fRegular mb-12"
                    value={(make || {}).makeId}
                    onChange={(e) => {
                      const id = e.target.value;
                      const make = makeAndModelList.find((make) => {
                        return make.makeId === id;
                      });
                      setMake(make);
                      setModel(null);
                    }}
                  >
                    <option value={""}>Select the make</option>
                    {(makeAndModelList || []).map((make) => {
                      return <option value={make.makeId}>{make.make}</option>;
                    })}
                  </select>
                </div>
              </div>
            )}
            {((oemNumber || "").length > 0 ||
              Object.keys(make || {}).length > 0 ||
              Object.keys(convoSearch || {}).length > 0) && (
              <>
                <div className="flex flex-middle flex-between wt-100p">
                  <div className="c-red fw-bolder flex-1">Results</div>
                  <div className="flex-1">
                    {priceModifierItems.length > 0 &&
                      (params.offset >= 10 || params.areMoreItemsAvailable) && (
                        <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                          <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                            <img
                              src={CaretIcon}
                              alt=""
                              className={`wt-20 rotate-90 c-pointer ${
                                params.offset > 0 ? "op-7" : "op-2"
                              }`}
                              onClick={params.offset > 0 ? fetchLess : () => {}}
                            />
                          </div>
                          <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                            Showing {params.offset + 1} -{" "}
                            {params.offset + priceModifierItems.length}
                          </span>
                          <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                            <img
                              src={CaretIcon}
                              alt=""
                              className={`wt-20 rotate--90 c-pointer ${
                                params.areMoreItemsAvailable ? "op-7" : "op-2"
                              }`}
                              onClick={
                                params.areMoreItemsAvailable
                                  ? fetchMore
                                  : () => {}
                              }
                            />
                          </div>
                        </div>
                      )}
                  </div>
                  <div className="flex-1">
                    {priceModifierItems.length > 0 && (
                      <div className="ht-40 wt-150 p-relative t-8 f-Right">
                        <select
                          name=""
                          id=""
                          value={params.limit}
                          onChange={(e) => {
                            setParams({
                              ...params,
                              limit: parseInt(e.target.value),
                            });
                          }}
                        >
                          <option value={25}>25</option>
                          <option value={50}>50</option>
                          <option value={100}>100</option>
                        </select>
                      </div>
                    )}
                  </div>
                </div>
                {converterViewType === "TABLE" && (
                  <Table
                    scrollTable={true}
                    type="converters"
                    columns={[
                      "OEM",
                      "CALCULATED PRICE",
                      "OVERRIDE PRICE",
                      "O/ C",
                      "MAKE",
                      "DESCRIPTION",
                      "TYPE",
                    ]}
                    items={priceModifierItems.map((converter) => {
                      return {
                        ...converter,
                        photo: `is-photo-${(converter.urls || [])[0]}`,
                        markPrice: `$${converter.marketPrice}`,
                        basePriceWithDollar: `$${converter.offerPrice}`,
                        price: `$${Math.round(converter.price)}`,
                        isOC: converter.isCalculatedPrice === true ? "O" : "C",
                      };
                    })}
                    keys={[
                      "oem",
                      "basePriceWithDollar",
                      "price",
                      "isOC",
                      "manufacturer",
                      "description",
                      "type",
                    ]}
                    actions={canDeleteConverter?["EDIT", "DELETE"]:["EDIT"]}
                    widthClasses={[
                      "flex-1",
                      "flex-1",
                      "flex-1",
                      "flex-1",
                      "flex-1",
                      "wt-200",
                      "flex-1",
                    ]}
                    offset={params.offset}
                    areMoreItemsAvailable={params.areMoreItemsAvailable}
                    fetchMore={fetchMore}
                    fetchLess={fetchLess}
                    isLoading={isLoading}
                    counterDropdown={[25, 50, 100]}
                    hideCheckBox={true}
                    className="min-wt-1000"
                    onEdit={(item) => {
                      setShowPricingTab(item);
                    }}
                    onDelete={(item) => setDelete(item)}
                    onCountChange={(value) => {
                      setParams({
                        ...params,
                        limit: value,
                      });
                    }}
                    limit={params.limit}
                  />
                )}
              </>
            )}
            {converterViewType === "IMAGE" &&
              priceModifierItems.length > 0 &&
              (params.offset >= 10 || params.areMoreItemsAvailable) && (
                <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                  <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                    <img
                      src={CaretIcon}
                      alt=""
                      className={`wt-20 rotate-90 c-pointer ${
                        params.offset > 0 ? "op-7" : "op-2"
                      }`}
                      onClick={params.offset > 0 ? fetchLess : () => {}}
                    />
                  </div>
                  <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                    Showing {params.offset + 1} -{" "}
                    {params.offset + priceModifierItems.length}
                  </span>
                  <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                    <img
                      src={CaretIcon}
                      alt=""
                      className={`wt-20 rotate--90 c-pointer ${
                        params.areMoreItemsAvailable ? "op-7" : "op-2"
                      }`}
                      onClick={
                        params.areMoreItemsAvailable ? fetchMore : () => {}
                      }
                    />
                  </div>
                </div>
              )}
          </>
        )}
        {isAskExpertShown && (
          <AskExpert
            onClose={setIsAskExpertShown.bind(null, false)}
            onBack={setIsAskExpertShown.bind(null, false)}
            isLoading={isLoading}
            onSave={askExpert}
          />
        )}
        {isAddMakeShown && (
          <MakeAddEdit
            item={{ ...makeSample }}
            onClose={setIsAddMakeShown.bind(null, false)}
            onBack={setIsAddMakeShown.bind(null, false)}
            onSave={addMake}
            type="add"
          />
        )}
        {isEditMakeShown && (
          <MakeAddEdit
            item={{ ...make }}
            onClose={setIsEditMakeShown.bind(null, false)}
            onBack={setIsEditMakeShown.bind(null, false)}
            onSave={editMake}
            type="edit"
          />
        )}
        {isImgModalShown && (
          <Modal>
            <OutsideClickHandler
              onOutsideClick={() => setIsImgModalShown(false)}
            >
              <img src={imgInModal} alt="" />
            </OutsideClickHandler>
          </Modal>
        )}

      {isDeletePopupShown && (
        <DeleteConfirmation
          type="Item"
          name={itemForDelete.name}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={handleDeleteItem}
        />
      )}

        <Toast
          type={toastParams.type}
          message={toastParams.message}
          id={toastParams.id}
        />
      </div>
    );
  };

  return (
    <div>
      {showPricingTab ? (
        <ConverterPricingDetail
          data={showPricingTab}
          setShowPricingTab={setShowPricingTab}
        />
      ) : (
        <div>{renderConvertersInfo()}</div>
      )}
    </div>
  );
};

export default PricingIdentifyConverters;
