import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import SearchIcon from "../../icons/pos_find.png";
import SearchIconWhite from "../../icons/rev_find.png";
import Table from "../../components/Table";
import HeaderIcon from "../../icons/black_user-group.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import ActionLoader from "../../components/ActionLoader";

import {
  GET_TRANSACTIONS_DATA_URL,
  GET_DASHBOARD_REPORT_URL,
  GET_ENTITIES_URL,
  GET_USERS_URL,
  GET_OEM_URL,
  FACILITY_CITY_LIST,
  getData,
  GET_FACILITIES_URL,
  GET_LOAD_DETAILS_URL,
  GET_TRANSACTIONS_DATA_DOWNLOAD_URL,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";
import Toggle from "react-toggle";
import InvoiceLink from "./invoiceLink";
import Modal from "../../components/Modal";
import PageLayout from "../../components/PageLayout";
import Toast from "../../components/Toast";
import { withRouter, useHistory } from "react-router";
import CurrencyFormat from "react-currency-format";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import InvoicePDF from "../InvoicePDF";
import SearchIconSvg from "../../icons/newicons/search.svg";

function TransactionsReport(props) {
  const [startDate, setStartDate] = useState(null);
  const [faacilities, setFaciltiesItems] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [facility, setFacility] = useState("");
  const [showDetails, setShowDetails] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [dashboardDetails, setDashboardDetails] = useState([]);
  const [buyer, setBuyer] = useState("");
  const [customer, setCustomer] = useState("");
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [buyerItems, setBuyerItems] = useState([]);
  const [showBuyerDropdown, setShowBuyerDropdown] = useState(false);
  const [selectedBuyerItem, setSelectedBuyerItem] = useState({});

  const [oem, setOem] = useState("");
  const [oemItems, setOemItems] = useState([]);
  const [showOemDropdown, setShowOemDropdown] = useState(false);
  const [selectedOemItem, setSelectedOemItem] = useState("");

  const [franchise, setFranchise] = useState("");
  const [showFranchiseDropdownDiv, setShowFranchiseDropdownDiv] = useState(false);
  const [selectedFranchiseItem, setSelectedFranchiseItem] = useState({});

  const [invoice, setInvoice] = useState("");

  const [printPopup, setPrintPopup] = useState(null);
  const [comingFromInvoice, setComingFromInvoice] = useState(
    props.location.params
      ? props.location.params.loadFilterDate == true
        ? true
        : false
      : false
  );

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [dataPersistence, setPersistenceData] = useState({});

  const [toggleCheck, setToggleCheck] = useState(null);

  const getDashboardDetails = async () => {
    try {
      setIsLoading(true);
      const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
      const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
      let data;

      if (!toggleCheck) {
        data = await getData(GET_TRANSACTIONS_DATA_URL, {
          facility,
          from: new Date(startDt).getTime(),
          to: new Date(endDt).getTime(),
          buyer:
            Object.keys(selectedBuyerItem || {}).length > 0
              ? selectedBuyerItem.userId
              : buyer,
          oem: selectedOemItem ? selectedOemItem : oem,
          customer:
            Object.keys(selectedItem || {}).length > 0
              ? selectedItem.companyId
              : customer,
          franchise:
            Object.keys(selectedFranchiseItem || {}).length > 0
              ? selectedFranchiseItem.companyId
              : franchise,
          text: invoice,
        });
      } else {
        data = await getData(GET_TRANSACTIONS_DATA_URL, {
          facility,
          from: new Date(startDt).getTime(),
          to: new Date(endDt).getTime(),
          buyer:
            Object.keys(selectedBuyerItem || {}).length > 0
              ? selectedBuyerItem.userId
              : buyer,
          oem: selectedOemItem ? selectedOemItem : oem,
          customer:
            Object.keys(selectedItem || {}).length > 0
              ? selectedItem.companyId
              : customer,
          franchise:
            Object.keys(selectedFranchiseItem || {}).length > 0
              ? selectedFranchiseItem.companyId
              : franchise,
          type: 2,
          text: invoice,
        });
      }

      setIsLoading(false);

      setDashboardDetails(data.list || []);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable || false,
      });
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };

  useEffect(() => {
    getDashboardDetails();
  }, [toggleCheck]);

  const getReport = async () => {
    try {
      setIsLoading(true);
      const startDt = new Date(startDate).setHours(0, 0, 0, 0);
      const endDt = new Date(endDate).setHours(23, 59, 59, 59);
      const data = await getData(GET_TRANSACTIONS_DATA_DOWNLOAD_URL, {
        facility,
        from: new Date(startDt).getTime(),
        to: new Date(endDt).getTime(),
        buyer:
          Object.keys(selectedBuyerItem || {}).length > 0
            ? selectedBuyerItem.userId
            : buyer,
        oem: selectedOemItem ? selectedOemItem : oem,
        customer:
          Object.keys(selectedItem || {}).length > 0
            ? selectedItem.companyId
            : customer,
          franchise:
            Object.keys(selectedFranchiseItem || {}).length > 0
              ? selectedFranchiseItem.companyId
              : franchise,
          text: invoice,
      });
      if (data && data.link) {
        const element = document.createElement("a");
        element.setAttribute("href", data.link);
        element.setAttribute("download", true);
        element.click();
        setIsLoading(false);
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };

  const getEntities = async (text, isFranchise = false) => {
    try {
      const data = await getData(GET_ENTITIES_URL, {
        count: 500,
        offset: 0,
        text,
        parentCompanyId: getLoginDetails().parentCompanyId,
        isEmbed: false,
        isFranchise,
        // customerSort: 'asc'
      });
      setEntitiesItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const getOem = async (text) => {
    try {
      const data = await getData(GET_OEM_URL, {
        text,
        companyId: getLoginDetails().companyId,
      });
      setOemItems(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const getBuyers = async (text) => {
    try {
      const data = await getData(GET_USERS_URL, {
        count: 500,
        offset: 0,
        text,
        companyId: getLoginDetails().companyId,
        isBuyer: true,
      });
      setBuyerItems(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };

  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const handleFilterDataPersistence = (href) => {
    localStorage.setItem("startDate", startDate);
    localStorage.setItem("endDate", endDate);
    localStorage.setItem("facility", facility);
    localStorage.setItem("buyer", buyer);
    localStorage.setItem("customer", customer);
    localStorage.setItem("oem", oem);
    localStorage.setItem("invoice", invoice);
    localStorage.setItem("toggleCheck", toggleCheck);

    props.history.push({
      pathname: `${href}?fromDash=true`,
    });
  };

  useEffect(() => {
    const comingFromInvoice = props.location.params
      ? props.location.params.filterData
      : false;
    if (
      localStorage.getItem("startDate") &&
      localStorage.getItem("endDate") &&
      localStorage.getItem("facility") == "" &&
      localStorage.getItem("buyer") == "" &&
      localStorage.getItem("customer") == "" &&
      localStorage.getItem("franchise") == "" &&
      comingFromInvoice
    ) {
      setStartDate(new Date(localStorage.getItem("startDate")));
      setEndDate(new Date(localStorage.getItem("endDate")));
      setFacility(localStorage.getItem("facility"));
      setBuyer(localStorage.getItem("buyer"));
      setCustomer(localStorage.getItem("customer"));
      setFranchise(localStorage.getItem("franchise"));
      // setToggleCheck(
      //   localStorage.getItem("toggleCheck") == "false" ? false : true
      // );
    } else {
      console.log("DATA NOT AVAIL");
      setStartDate(new Date());
      setEndDate(new Date());
      setFacility("");
      setBuyer("");
      setCustomer("");
      setFranchise("");
      // setToggleCheck(false);
    }
  }, []);

  const handleSearch = async (val) => {
    setIsLoading(true);
    const startDt = new Date(startDate).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate).setHours(23, 59, 59, 59);
    let data;
    if (val.length) {
      data = await getData(GET_TRANSACTIONS_DATA_URL, {
        facility,
        from: new Date(startDt).getTime(),
        to: new Date(endDt).getTime(),
        text: val,
        buyer:
          Object.keys(selectedBuyerItem || {}).length > 0
            ? selectedBuyerItem.userId
            : buyer,
        customer:
          Object.keys(selectedItem || {}).length > 0
            ? selectedItem.companyId
            : customer,
          franchise:
            Object.keys(selectedFranchiseItem || {}).length > 0
              ? selectedFranchiseItem.companyId
              : franchise,
      });
    } else {
      return getDashboardDetails();
    }

    setIsLoading(false);

    setDashboardDetails(data.list || []);
    setParams({
      ...params,
      areMoreItemsAvailable: data.areMoreItemsAvailable || false,
    });
  };

  const resetData = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setFacility("");
    setDashboardDetails([]);
    setBuyer("");
    setOem("");
    setInvoice("");
    setCustomer("");
    setEntitiesItems([]);
    setShowDropdownDiv(false);
    setBuyerItems([]);
    setShowBuyerDropdown(false);
    setSelectedItem({});
    setSelectedBuyerItem({});
    setOemItems([]);
    setShowOemDropdown(false);
    setShowFranchiseDropdownDiv(false);
    setSelectedFranchiseItem({});
    setFranchise("");
    localStorage.setItem("startDate", new Date());
    localStorage.setItem("endDate", new Date());
    localStorage.setItem("facility", "");
    localStorage.setItem("buyer", "");
    localStorage.setItem("customer", "");
    localStorage.setItem("oem", "");
    localStorage.setItem("invoice", "");
    localStorage.setItem("franchise", "");
  };

  const topIcon = (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="16"
      height="16"
      fill="#000"
      class="bi bi-people-fill mr-8"
      viewBox="0 0 16 16"
    >
      <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
      <path
        fill-rule="evenodd"
        d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
      />
      <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
    </svg>
  );
  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(
        (data.list && data.list.filter((el) => el.isActive)) || []
      );
    } catch (e) {}
  };
  useEffect(() => {
    getFacilities();
    return () => {
      setFaciltiesItems([]);
    };
  }, []);

  const showPrintPopup = (item) => {
    getLoadDetails(item.loadId);
  };

  const getLoadDetails = async (loadId) => {
    setIsLoading(true);
    try {
      const loadData = await getData(GET_LOAD_DETAILS_URL, {
        loadId,
      });
      if (loadData) {
        setPrintPopup(loadData);
      }
      setIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
      setIsLoading(false);
    }
  };
  const [showTab, setShowTab] = useState(1);


  return (
    <PageLayout
      icon={HeaderIconSvg}
      svg={true}
      hideSearchBar={true}
      heading="Transactions Report"
      inputClassName=" form-control "
    >

<div className={`tabs flex`}>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 0 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(0)
              props.history.push('/dashboard/costsheetreport')

            }}
          >
            {/*<img src={HeaderIconPage} className="wt-30 mr-8" />*/}
            <span>Cost Sheet</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(1)
              props.history.push('/dashboard/transactionsreport')
            } }
          >
            <span>Transactions</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(2)
              props.history.push('/dashboard/top-cats')
            }
            }
          >
            <span>Top Cats</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 3 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(3)
              props.history.push('/dashboard/top-lookups')
            }
            }
          >
            <span>Top Lookups</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 4 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(4)
              props.history.push('/dashboard/didnotbuy')
            }
            }
          >
            <span>Do Not Buy</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 5 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(5)
              props.history.push('/dashboard/top-customers')
            }
            }
          >
            <span>Top Customers</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 6 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(6)
              props.history.push('/dashboard/globalmodifier')
            }
            }
          >
            <span>Global Modifier</span>
          </div>
        </div>
      <div>
        <div
          className="costSheetFilters flex"
          style={{ marginBottom: 0, justifyContent: "flex-start" }}
        >
          <div className="mr-20">
            <div className="c-gray fs-12"> Start Date </div>
            <DatePicker
              selected={startDate}
              onChange={(date) => setStartDate(date)}
              selectsStart
              startDate={startDate}
              endDate={endDate}
              className="form-control"
            />
          </div>
          <div className="mr-20">
            <div className="c-gray fs-12"> End Date </div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
              className="form-control"
            />
          </div>

          <div className="buyer-filter mr-20">
            <div className="mb-16"> </div>
            <div className=" p-relative">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={oem}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Search By OEM"
                onChange={(e) => {
                  setOem(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getOem(e.target.value);
                    setShowOemDropdown(true);
                  }
                }}
              />

              {showOemDropdown && oemItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300"
                    style={{ overflow: "scroll" }}
                  >
                    {[...oemItems].map((item) => {
                      return (
                        <div
                          key={`oem-${item}`}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowOemDropdown(false);
                            setSelectedOemItem(item);
                            setOem(item);
                          }}
                        >
                          <div className="fs-14 mb-4">{item}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="buyer-filter mr-20">
            <div className="mb-16"> </div>
            <div className=" p-relative">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={invoice}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Invoice"
                onChange={(e) => {
                  setInvoice(e.target.value);
                }}
              />
            </div>
          </div>

          <div className="facility-filter mr-20">
            <div className="mb-16"> </div>

            <select
              name="type"
              className="select font-primary fs-14 form-select"
              value={facility}
              onChange={(e) => setFacility(e.target.value)}
            >
              <option value={""}>Select Facility</option>
              {faacilities.map((fac, idx) => (
                <option key={idx} value={fac.name}>
                  {fac.name}
                </option>
              ))}
            </select>
          </div>

          <div className="buyer-filter mr-20">
            <div className="mb-16"> </div>
            <div className=" p-relative">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={buyer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Buyer Filter"
                onChange={(e) => {
                  setBuyer(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getBuyers(e.target.value);
                    setShowBuyerDropdown(true);
                  }
                }}
              />

              {showBuyerDropdown && buyerItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300"
                    style={{ overflow: "scroll" }}
                  >
                    {[...buyerItems].map((item) => {
                      return (
                        <div
                          key={`buyer-${item.companyId}`}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowBuyerDropdown(false);
                            setSelectedBuyerItem(item);
                            setBuyer(`${item.firstName} ${item.lastName}`);
                          }}
                        >
                          <div className="fs-14 mb-4">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="franchise-filter">
            <div className="mb-16"> </div>
            <div className=" p-relative ">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={franchise}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Franchise Filter"
                style={{paddingLeft: 35}}
                onChange={(e) => {
                  setFranchise(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getEntities(e.target.value, true);
                    setShowFranchiseDropdownDiv(true);
                  }
                }}
              />

              {showFranchiseDropdownDiv && entitiesItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                    style={{ overflow: "scroll" }}
                  >
                    {[...entitiesItems].map((item) => {
                      return (
                        <div
                          key={item.companyId}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowFranchiseDropdownDiv(false);
                            setEntitiesItems([]);
                            setSelectedFranchiseItem(item);
                            setFranchise(item.companyName);
                          }}
                        >
                          <div className="fs-14 mb-4">{item.companyName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="customer-filter mr-20">
            <div className="mb-16"> </div>
            <div className=" p-relative ">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={customer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Customer Filter"
                onChange={(e) => {
                  setCustomer(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getEntities(e.target.value);
                    setShowDropdownDiv(true);
                  }
                }}
              />

              {showDropdownDiv && entitiesItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                    style={{ overflow: "scroll" }}
                  >
                    {[...entitiesItems].map((item) => {
                      return (
                        <div
                          key={item.companyId}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowDropdownDiv(false);
                            setEntitiesItems([]);
                            setSelectedItem(item);
                            setCustomer(item.companyName);
                          }}
                        >
                          <div className="fs-14 mb-4">{item.companyName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="search-button mt-16 mr-20">
          <div
            className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={getDashboardDetails}
          >
            <img
              src={SearchIconWhite}
              alt="searchicon"
              width="20px"
              height="20px"
            />
          </div>
        </div>

          <div className="search-button mt-16 mr-20">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={getReport}
            >
              {/* <img
                src={SearchIconWhite}
                alt="searchicon"
                width="20px"
                height="20px"
              /> */}
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
            </div>
          </div>

          <div className="search-button mt-16">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center  closeicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={resetData}
            >
              <span className="fs-16 c-white"> X </span>
            </div>
          </div>
        </div>
        {/* <div className="toggle-btn">
          <div
            className="flex flex-middle"
            style={{ justifyContent: "flex-end", margin: "10px 0" }}
          >
            <div class=" download-btn  ht-40 costSheetReport-dwnld-btn ml-8 ">
              <div
                class="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
                style={{ marginLeft: "auto" }}
                onClick={getReport}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  class="bi bi-download mr-8"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
                Download Report
              </div>
            </div>
          </div>
        </div> */}
        <div className="table">
          {
            <Table
              scrollTable={toggleCheck ? true : false}
              columns={[
                "Assistant",
                "Company",
                "Facility",
                "Family",
                "Invoice",
                "Manufacturer",
                "OEM",
                "Type",
                "Adjusted",
                "Base Price",
                "QTY",
                "Date"
              ]}
              className={`${toggleCheck ? "costSheetTable scroll-table " : ""}`}
              items={dashboardDetails.map((el) => {
                return {
                  ...el,
                  createdDate: (() => {
                    const dateObj = new Date(el.createdDate);
                    const year = dateObj.getFullYear();
                    const month = `0${dateObj.getMonth() + 1}`.slice(-2);
                    const date = dateObj.getDate();
                    return `${year}-${month}-${date}`;
                  })(),
                };
              })}
              hideCheckBox={true}
              keys={[
                "assistantName",
                "companyName",
                "facilityCity",
                "family",
                "invoiceNo",
                "manufacturer",
                "oem",
                "type",
                "adjustedPrice",
                "basePrice",
                "finalQty",
                "createdDate"
              ]}
            />
          }
        </div>
        {isLoading && (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      )}
      </div>
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {Object.keys(printPopup || {}).length > 0 && (
        <Modal style={{}}>
          <InvoicePDF setPrintPopup={setPrintPopup} itemData={printPopup} />
        </Modal>
      )}
    </PageLayout>
  );
}

export default withRouter(TransactionsReport);
