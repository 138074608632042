import React, { useState, useEffect } from "react";

import "react-toggle/style.css";

import { BrowserRouter as Router, Switch, Route } from "react-router-dom";

import Signin from "./containers/Signin";
import Loads from "./containers/Loads";

import Dashboard from "./containers/Dashboard";
import Invoices from "./containers/Invoices";
import InvoicePDF from "./containers/InvoicePDF/PreviewPdf";

import Converters from "./containers/Converters";
import ConverterDetail from "./containers/Converters/ConverterDetail";
import IdentifyConverters from "./containers/Converters/IdentifyConverters";
import VehicleSearch from "./containers/Converters/VehicleSearch";

import PriceModifiers from "./containers/PriceModifiers";
import Pricing from "./containers/Pricing";
import Roles from "./containers/Roles";
import Permissions from "./containers/Permissions";
import Entities from "./containers/Entities";
import CreateEntity from "./containers/Entities/Create";
import NewUserProfile from "./containers/NewUser/Create";
import Groups from "./containers/Groups";
import GroupDetails from "./containers/Entities/GroupDetails";
import Users from "./containers/Users";
import Facilities from "./containers/Facilities";
import FacilitiesConfig from "./containers/FacilitiesConfig";
import MakeAndModel from "./containers/MakeAndModel";
import ExpertAdvice from "./containers/ExpertAdvice";
import Settings from "./containers/Settings";
import SalesHistory from "./containers/SalesHistory";
import SettingsConfig from "./containers/SettingsConfig";
import Logout from "./containers/Logout";
import CreateLoads from "./containers/Loads/CreateLoads";
import ActiveLoads from "./containers/Loads/ActiveLoads";
import LoadsReport from "./containers/Loads/LoadsReport";
import LoadsCart from "./containers/Loads/LoadsCart";
import BulkUpload from "./containers/BulkUpload";
import DownloadCustomers from "./containers/DownloadCustomers";
import InviteUsers from "./containers/InviteUsers";
import PreviewPricing from "./containers/Pricing/PreviewPricing";
import LoadReport from "./containers/Dashboard/LoadReport";
import TopCats from "./containers/Dashboard/TopCats";
import TopCustomers from "./containers/Dashboard/Customer";
import DidNotBuyReport from "./containers/Dashboard/DidNotBuyReport";

import TopLookups from "./containers/Dashboard/TopLookups";
import TransactionsReport from "./containers/Dashboard/TransactionsReport";
import GlobalModifierReport from "./containers/Dashboard/GlobalModifierReport";

// v2 components
// import LoadsCartV2 from "./containers/V2/Loads/LoadsCart";
// import ActiveLoadsV2 from "./containers/V2/Loads/ActiveLoads";
// import CreateLoadsV2 from "./containers/V2/Loads/CreateLoads";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

function App() {
  const [isMobile, setIsMobile] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions]);

  return (
    <Router>
      <Switch>
        <Route exact path="/" component={Signin} />
        <Route exact path="/login/:username" component={Signin} />
        <Route exact path="/invite" component={Signin} />
        <Route exact path="/logout" component={Logout} />

        <Route exact path="/config" component={BulkUpload} />
        <Route exact path="/config/bulk-upload" component={BulkUpload} />
        <Route
          exact
          path="/config/download-customers"
          component={DownloadCustomers}
        />
        <Route exact path="/config/cart-config" component={FacilitiesConfig} />

        <Route exact path="/loads" component={ActiveLoads} />
        <Route exact path="/invoices/load/:loadId" component={LoadsCart} />
        <Route
          exact
          path="/invoices/Franchise/load/:loadId"
          component={LoadsCart}
        />
        <Route
          exact
          path="/invoices/settled/load/:loadId"
          component={LoadsCart}
        />
        <Route exact path="/my-cart/load/:loadId" component={LoadsCart} />
        <Route exact path="/loads/my-cart" component={ActiveLoads} />
        <Route exact path="/loads/reports" component={LoadsReport} />
        <Route exact path="/dashboard" component={Dashboard} />
        <Route exact path="/dashboard/costsheetreport" component={Dashboard} />
        <Route
          exact
          path="/dashboard/transactionsreport"
          component={TransactionsReport}
        />
        <Route exact path="/dashboard/loadreport" component={LoadReport} />
        <Route exact path="/dashboard/top-cats" component={TopCats} />
        <Route exact path="/dashboard/top-customers" component={TopCustomers} />
        <Route exact path="/dashboard/didNotBuy" component={DidNotBuyReport} />
        <Route
          exact
          path="/dashboard/globalmodifier"
          component={GlobalModifierReport}
        />
        <Route exact path="/dashboard/top-lookups" component={TopLookups} />

        <Route exact path="/invoices" component={Invoices} />
        <Route exact path="/invoices/pending" component={Invoices} />
        <Route exact path="/invoices/settled" component={Invoices} />
        <Route exact path="/invoices/franchise" component={Invoices} />
        <Route exact path="/invoices/myInvoices" component={Invoices} />
        <Route exact path="/invoices/pdf/:loadId" component={InvoicePDF} />

        <Route exact path="/converters" component={Converters} />
        <Route
          exact
          path="/converters/identify/:parameters?"
          component={IdentifyConverters}
        />
        <Route exact path="/converters/vehicle" component={VehicleSearch} />
        <Route
          exact
          path="/converter/:id/:type?/:parameters?"
          component={ConverterDetail}
        />
        <Route exact path="/pricing" component={PreviewPricing} />
        <Route exact path="/previewPricing" component={PreviewPricing} />
        <Route exact path="/converters/make-model" component={MakeAndModel} />
        <Route
          exact
          path="/converters/expert-advice"
          component={ExpertAdvice}
        />
        {/* <Route exact path="/converters/bulk-upload" component={BulkUpload} /> */}

        <Route exact path="/price-modifiers" component={PriceModifiers} />
        <Route exact path="/config/roles" component={Roles} />
        <Route exact path="/config/Permissions" component={Permissions} />
        <Route
          exact
          path="/entities/viewGroup/:searchText?"
          component={Groups}
        />
        <Route exact path="/entities" component={Groups} />
        <Route
          exact
          path="/entities/viewGroupId/:entityId/:searchText?"
          component={GroupDetails}
        />
        <Route exact path="/config/facilities" component={Facilities} />
        <Route exact path="/config/sageinvoice" component={Invoices} />
        <Route exact path="/entities/users" component={Users} />
        <Route exact path="/entities/Franchise" component={Groups} />
        <Route exact path="/entities/Franchise/:companyId" component={Groups} />
        <Route exact path="/entities/Franchise/users" component={Users} />
        <Route
          exact
          path="/entities/Franchise/viewGroupId/:entityId/:searchText?"
          component={GroupDetails}
        />
        <Route exact path="/entities/create" component={CreateEntity} />
        <Route exact path="/entities/inviteUsers" component={InviteUsers} />
        <Route exact path="/new-user/:id?" component={NewUserProfile} />

        <Route exact path="/settings" component={Settings} />
        <Route exact path="/settings-config" component={SettingsConfig} />
        <Route exact path="/sales" component={SalesHistory} />

        {/* {!isMobile ? (
          <> */}
        <Route exact path="/loads/create-load" component={CreateLoads} />
        <Route exact path="/loads/active-load" component={ActiveLoads} />
        <Route exact path="/load/:loadId" component={LoadsCart} />
        <Route exact path="/loads/load/:loadId" component={LoadsCart} />
        {/* </>
        ) : (
          <>
            <Route exact path="/loads/create-load" component={CreateLoadsV2} />
            <Route exact path="/loads/active-load" component={ActiveLoadsV2} />
            <Route exact path="/load/:loadId" component={LoadsCartV2} />
            <Route exact path="/loads/load/:loadId" component={LoadsCartV2} />
          </>
        )} */}
      </Switch>
    </Router>
  );
}

export default App;
