import React, { useState, useEffect } from "react";
import classNames from "classnames";

const Toast = ({ message, type, id }) => {
  const [isActive, setIsActive] = useState(false);

  useEffect(() => {
    setIsActive(true);
    setTimeout(() => {
      setIsActive(false);
    }, 2000);
  }, [message, type, id]);
  return (
    <>
      <div
        className={classNames(
          "toast-custom p-fixed ht-60 br-5 mwt-500 flex flex-center flex-middle fw-bolder fs-16 c-white pl-100 pr-100 r-100 transition-bottom",
          {
            "bg-success": type === "SUCCESS",
            "bg-error": type === "ERROR",
            active: isActive,
          }
        )}
      >
        {message}
      </div>
    </>
  );
};

export default Toast;
