import React, { useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import HeaderIcon from "../../icons/newicons/people-fill.svg";
import IconUsersGrey from "../../icons/pos_user.png";
import IconUsersWhite from "../../icons/rev_user.png";
import IconGroupsGrey from "../../icons/pos_user-group.png";
import IconGroupsWhite from "../../icons/rev_user-group.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import Entities from "../Entities";
import { useState } from "react";
import Toggle from "react-toggle";
import { getLoginDetails } from "../../utils/storage";

export const Groups = (props) => {
  const [searchVal, setSerchVal] = useState("");
  const [removeTabs, setRemoveTabs] = useState(false);
  const [isFranchise, setIsFranchise] = useState(false);
   useEffect(()=>{
    if(window.location.pathname.includes("/Franchise")){
      setIsFranchise(true);
    } else {
      setIsFranchise(false);
    }
  },[(window.location.pathname)]);
  const { hierarchy } = getLoginDetails();
  return (
    // <PageLayout
    //   icon={HeaderIconSvg}
    //   onSearchChange={(val) => {
    //     setSerchVal(val);
    //   }}
    //   heading="Users"
    //   searchPlaceholder="Search Users"
    // >
    <div>
      {!removeTabs && (
        <div
          className="flex flex-middle mt-8 flex-between groups-tabs"
          style={{ paddingLeft: 285, paddingTop: 80 }}
        >
          <div className={`tabs flex`}>
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder'`}
              onClick={() => {}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path
                  fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                />
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
              <span>{window.location.pathname.includes("/Franchise")?"Franchise":"Customers"}</span>
            </div>
            {!window.location.pathname.includes('/Franchise') &&
            <div
              className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`}
              onClick={() => {
                props.history.push(isFranchise?"/entities/Franchise/users":"/entities/users");
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span>Users</span>
            </div>
}
            {/* {hierarchy === 1 && (
              <div className="pl-16 pr-16 flex" style={{ border: 0 }}>
                <Toggle
                  onChange={(e) => {
                    setIsFranchise(e.target.checked);
                  }}
                  className="bg-theme"
                  checked={isFranchise}
                />
                <span className="pl-16">Is Franchise</span>
              </div>
            )} */}
            {/* {hierarchy === 1 && (

                <div
              // className={`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`}
              className={!isFranchise?`ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`:`ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder'`}
              onClick={() => {
                setIsFranchise(!isFranchise)
              }}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                fill="currentColor"
                class="bi bi-people-fill"
                viewBox="0 0 16 16"
              >
                <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                <path
                  fill-rule="evenodd"
                  d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                />
                <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
              </svg>
              <span>Is Franchise</span>
            </div>
          )} */}
          </div>

        </div>
      )}
      <div>
        <Entities
          {...props}
          isEmbed={false}
          searchVal={searchVal}
          setRemoveTabs={setRemoveTabs}
          hideTop={true}
          isFranchise={isFranchise}
        />
      </div>
    </div>
  );
};

export default Groups;
