import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";

import Toast from "../../components/Toast";

import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import SearchIconWhite from "../../icons/rev_find.png";
import { getLoginDetails } from "../../utils/storage";
import SearchIcon from "../../icons/pos_find.png";
import {
  GET_DID_NOT_BUY_REPORT_URL,
  getData,
  GET_FACILITIES_URL,
  GET_USERS_URL,
  GET_ENTITIES_URL,
  downloadDonotBuy
} from "../../utils/api";
import Toggle from "react-toggle";

export const DidNotBuy = (props) => {
  const [reportList, setReportList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [startDate, setStartDate] = useState(null);
  const [facility, setFacility] = useState("");
  const [faacilities, setFaciltiesItems] = useState([]);
  const [endDate, setEndDate] = useState(null);
  const [buyerItems, setBuyerItems] = useState([]);
  const [buyer, setBuyer] = useState("");
  const [customer, setCustomer] = useState("");
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [showBuyerDropdown, setShowBuyerDropdown] = useState(false);
  const [selectedBuyerItem, setSelectedBuyerItem] = useState({});
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  const [isGroup, setIsGroup] = useState(false);

  const [franchise, setFranchise] = useState("");
  const [showFranchiseDropdownDiv, setShowFranchiseDropdownDiv] = useState(false);
  const [selectedFranchiseItem, setSelectedFranchiseItem] = useState({});

  const jsonToUrlParams = (json) => {
    return Object.keys(json)
      .map(function (k) {
        if (typeof json[k] != "undefined" && json[k] !== "") {
          return encodeURIComponent(k) + "=" + encodeURIComponent(json[k]);
        }
        return "";
      })
      .filter((item) => {
        return item;
      })
      .join("&");
  };

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getReports = async () => {
    const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
    try {
      const data = await getData(GET_DID_NOT_BUY_REPORT_URL, {
        count: params.limit,
        offset: params.offset,
        from: startDt,
        to: endDt,
        buyer:
            Object.keys(selectedBuyerItem || {}).length > 0
              ? selectedBuyerItem.userId
              : buyer,
          customer:
            Object.keys(selectedItem || {}).length > 0
              ? selectedItem.companyId
              : customer,
              franchise:
              Object.keys(selectedFranchiseItem || {}).length > 0
                ? selectedFranchiseItem.companyId
                : franchise,
        facility,
        isGroup,
      });
      setIsLoading(false);
      setReportList(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
  }, []);
  
  useEffect(() => {
    getReports();
  }, [params.offset, params.limit]);

  useEffect(() => {
    getFacilities();
    return () => {
      setFaciltiesItems([]);
    };
  }, []);

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {}
  };

  const getBuyers = async (text) => {
    try {
      const data = await getData(GET_USERS_URL, {
        count: 500,
        offset: 0,
        text,
        companyId: getLoginDetails().companyId,
        isBuyer: true,
      });
      setBuyerItems(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };


  const getEntities = async (text, isFranchise = false) => {
    try {
      const data = await getData(GET_ENTITIES_URL, {
        count: 500,
        offset: 0,
        text,
        parentCompanyId: getLoginDetails().parentCompanyId,
        isEmbed: false,
        isFranchise,
        // customerSort: 'asc'
      });
      setEntitiesItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const resetData = () => {
    setStartDate(new Date());
    setReportList([])
    setEndDate(new Date());
    setFacility("");
    setBuyer("");
    setCustomer("");
    setEntitiesItems([]);
    setShowDropdownDiv(false);
    setBuyerItems([]);
    setShowBuyerDropdown(false);
    setSelectedItem({});
    setSelectedBuyerItem({});
    setShowFranchiseDropdownDiv(false);
    setSelectedFranchiseItem({});
    setFranchise("");
    setCompanyId('')
  };

  const getFilterDetail = () => {
    getReports();
  }

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const [showTab, setShowTab] = useState(4);
  const [companyId, setCompanyId] = useState('');

  const downloadDontBuy = () => {
    const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
    const data = {
      count: params.limit,
      offset: params.offset,
      from:startDt,
      to:endDt,
      facility:facility?facility:undefined,
      buyer:
      Object.keys(selectedBuyerItem || {}).length > 0
        ? selectedBuyerItem.userId
        : undefined,
    customer:
      Object.keys(selectedItem || {}).length > 0
        ? selectedItem.companyId
        : undefined,
      companyId:companyId?companyId:'',
      isGroup,
    }
    downloadDonotBuy(jsonToUrlParams(data))
      .then((res) => {
        const element = document.createElement("a");
        element.setAttribute("href", res.link);
        element.setAttribute("target","_blank");
        element.setAttribute("download", true);
        element.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }


  return (
    <PageLayout
      onSearchChange={onSearchChange}
      icon={HeaderIconSvg}
      heading="Did Not Buy Report"
      searchPlaceholder="Search"
      inputClassName=" form-control "
    >
         <div className="flex flex-middle mt-8 flex-between groups-tabs ">
         <div className={`tabs flex`}>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 0 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(0)
              props.history.push('/dashboard/costsheetreport')

            }}
          >
            {/*<img src={HeaderIconPage} className="wt-30 mr-8" />*/}
            <span>Cost Sheet</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(1)
              props.history.push('/dashboard/transactionsreport')
            } }
          >
            <span>Transactions</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(2)
              props.history.push('/dashboard/top-cats')
            }
            }
          >
            <span>Top Cats</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 3 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(3)
              props.history.push('/dashboard/top-lookups')
            }
            }
          >
            <span>Top Lookups</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 4 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(4)
              props.history.push('/dashboard/didnotbuy')
            }
            }
          >
            <span>Do Not Buy</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 5 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(5)
              props.history.push('/dashboard/top-customers')
            }
            }
          >
            <span>Top Customers</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 6 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(6)
              props.history.push('/dashboard/globalmodifier')
            }
            }
          >
            <span>Global Modifier</span>
          </div>
        </div>
      </div>
      <div className="costSheetFilters flex" style={{justifyContent: 'start'}}>
            <div style={{marginRight:5}}>
            <div className="c-gray fs-12"> Start Date </div>
              <div className="c-gray fs-12">  </div>
              <DatePicker
                selected={startDate}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
              />
            </div>
          <div style={{marginRight:5}}>
            <div className="c-gray fs-12"> End Date </div>
            <DatePicker
              selected={endDate}
              onChange={(date) => setEndDate(date)}
              selectsEnd
              startDate={startDate}
              endDate={endDate}
              minDate={startDate}
            />
          </div>
          <div className="facility-filter" style={{marginRight:5}}>
          <div className="mb-16"> </div>
            <select
              name="type"
              className="select font-primary fs-14 form-select"
              value={facility}
              onChange={(e) => setFacility(e.target.value)}
            >
              <option value={""}>Select Facility</option>
              {faacilities.map((fac, idx) => (
                <option key={idx} value={fac.name}>
                  {fac.name}
                </option>
              ))}
            </select>
          </div>
          <div className="buyer-filter" style={{marginRight:5}}>
            <div className="mb-16"> </div>
            <div className=" p-relative">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={buyer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Buyer Filter"
                onChange={(e) => {
                  setBuyer(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getBuyers(e.target.value);
                    setShowBuyerDropdown(true);
                  }
                }}
              />

              {showBuyerDropdown && buyerItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300"
                    style={{ overflow: "scroll" }}
                  >
                    {[...buyerItems].map((item) => {
                      return (
                        <div
                          key={`buyer-${item.companyId}`}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowBuyerDropdown(false);
                            setSelectedBuyerItem(item);
                            setBuyer(`${item.firstName} ${item.lastName}`);
                          }}
                        >
                          <div className="fs-14 mb-4">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="franchise-filter">
            <div className="mb-16"> </div>
            <div className=" p-relative ">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={franchise}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Franchise Filter"
                style={{paddingLeft: 35}}
                onChange={(e) => {
                  setFranchise(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getEntities(e.target.value, true);
                    setShowFranchiseDropdownDiv(true);
                  }
                }}
              />

              {showFranchiseDropdownDiv && entitiesItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                    style={{ overflow: "scroll" }}
                  >
                    {[...entitiesItems].map((item) => {
                      return (
                        <div
                          key={item.companyId}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowFranchiseDropdownDiv(false);
                            setEntitiesItems([]);
                            setSelectedFranchiseItem(item);
                            setFranchise(item.companyName);
                            setCompanyId(item.companyId)
                          }}
                        >
                          <div className="fs-14 mb-4">{item.companyName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="customer-filter" style={{marginRight:5}}>
            <div className="mb-16"> </div>
            <div className=" p-relative ">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={customer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Customer Filter"
                onChange={(e) => {
                  setCustomer(e.target.value);
                  if ((e.target.value || "").length > 2) {
                    getEntities(e.target.value);
                    setShowDropdownDiv(true);
                  }
                }}
              />

              {showDropdownDiv && entitiesItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                    style={{ overflow: "scroll" }}
                  >
                    {[...entitiesItems].map((item) => {
                      return (
                        <div
                          key={item.companyId}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowDropdownDiv(false);
                            setEntitiesItems([]);
                            setSelectedItem(item);
                            setCustomer(item.companyName);
                          }}
                        >
                          <div className="fs-14 mb-4">{item.companyName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
          </div>
          <div className="customer-filter" style={{marginRight:5}}>
          <div className="c-black fs-14 flex-between flex-middle">
                      <span className="c-gray fs-12">Is Group</span>
                      <div className="flex flex-middle">
                        <Toggle
                          className="bg-theme hide-check-cross"
                          onChange={(e) => {
                            setIsGroup(e.target.checked);
                          }}
                          checked={isGroup}
                        />
                      </div>
                    </div>
                   </div>

        <div className="flex" style={{marginTop: 10}}>
        <div className="search-button">
          <div
            style={{marginRight: 10}}
            className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={getFilterDetail}
          >
            <img
              src={SearchIconWhite}
              alt="searchicon"
              width="20px"
              height="20px"
            />
          </div>
        </div>
        <div className="search-button">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center  closeicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={resetData}
            >
              <span className="fs-16 c-white"> X </span>
            </div>
          </div>
          <div class="search-button mb-10  ml-10">
          <div
            class="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            // style={{ marginLeft: "10px", marginTop:'13px'}}
            style={{ marginLeft:'27px'}}
            onClick={downloadDontBuy}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            
          </div>
          </div>
          </div>
      </div>
      <Table
        className="makeTable"
        scrollTable={true}
        // type="entity"
        columns={["OEM", "Type", "Price", "Quantity", "Unit", "Date", "Buyer", "Customer", "Facility"]}
        // className="wt-600"
        items={reportList}
        keys={["oem", "type", "price", "qty", "unit", "date", "buyer", "customer", "facility"]}
        widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]}
        onChange={onSearchChange}
        offset={params.offset}
        areMoreItemsAvailable={params.areMoreItemsAvailable}
        fetchMore={fetchMore}
        fetchLess={fetchLess}
        isLoading={isLoading}
        hideCheckBox={true}
        onCountChange={(value) => {
          setParams({
            ...params,
            limit: value,
            offset: 0,
          });
        }}
        limit={params.limit}

        // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
      />
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default DidNotBuy;
