import React, { useState } from 'react'
import { Link } from 'react-router-dom';
import PlusIcon from '../../icons/pos_plus.png';
import EyeIcon from '../../icons/pos_explore.png';
import OutsideClickHandler from 'react-outside-click-handler';
import Modal from '../Modal';
import Button from '../Button';
import Carousel, { Dots } from '@brainhubeu/react-carousel';
import '@brainhubeu/react-carousel/lib/style.css';

const ConverterCard = ({converter, link, hasAddToCart, handleAddToCart}) => {
    const [isImageModalShown, setIsImageModalShown] = useState(false);
    const [slides] = useState([]);
    const [value, setValue] = useState(0);
    const handleOnChange = (value) => {
        console.log(value);
        setValue(value);
    }
    const convertToImages = (urls) => {
        const images = [];
        for(var i = 0; i < urls.length; i++) {
            images.push(
                <img className="wt-100p" src={urls[i]} alt="image" />
            )
        }
        return images;
    }
    return (
        <div className="bg-white pl-16 pr-16 pb-16 pt-16">
            <div className="flex flex-between mb-12">
                <div className="flex-1 ">
                    <div className="fs-18 fw-bolder c-black flex flex-middle flex-between mb-8">
                        <div className="flex flex-middle">
                            <img alt="" className="wt-20 mr-4" src={PlusIcon} onClick={() => { if (hasAddToCart) { handleAddToCart(converter) } }}  />
                            <span>{converter.name}</span>
                        </div>
                        <Link className="flex flex-middle" to={link}><img alt="" className="wt-30 mr-4" src={EyeIcon} /></Link>
                    </div>
                    <div className="c-gray fs-13">{converter.oem}</div>
                    <div className="c-gray fs-13">{converter.manufacturer}</div>
                    <div className="c-gray fs-13">{converter.model}</div>
                    <div className="c-gray fs-13">{converter.description}</div>
                </div>
                <div className="wt-50p" onClick={()=> {setIsImageModalShown(true)}}>
                    {(converter.urls || [])[0] && <img src={(converter.urls || [])[0]} alt="Converter Image" className="wt-100p" />}
                    {(converter.urls || []).length > 1 ? <div className="c-gray fs-13 pl-16">{(converter.urls || []).length} Images Available</div> : null}
                </div>
            </div>
            <div className="fs-14 mb-4">{converter.notes}</div>
            <div className="flex flex-between mb-4">
                <div className="fs-14 flex-1">
                    <span className="c-black fw-bolder">High: </span>
                    <span className="c-gray fw-bolder">${converter.high} </span>
                </div>
                <div className="fs-14 flex-1">
                    <span className="c-black fw-bolder">Low: </span>
                    <span className="c-gray fw-bolder">${converter.high}</span>
                </div>
                <div className="fs-14 flex-1">
                    <span className="c-black fw-bolder">Average: </span>
                    <span className="c-gray fw-bolder">${converter.high}</span>
                </div>
            </div>
            <div className="flex flex-between">
                {/*<div className="fs-14 flex-1">
                    <span className="c-black fw-bolder">Market Price: </span>
                    <span className="c-gray fw-bolder">${converter.marketPrice} </span>
                </div>*/}
                <div className="fs-14 flex-1">
                    <span className="c-black fw-bolder">Offer Price: </span>
                    <span className="c-gray fw-bolder">${converter.basePrice}</span>
                </div>
                <div className="fs-14 flex-1">
                    <span className="c-black fw-bolder"> </span>
                    <span className="c-gray fw-bolder"></span>
                </div>
            </div>
            {isImageModalShown &&
                <Modal>
                    <OutsideClickHandler onOutsideClick={() => setIsImageModalShown(false)}>
                        <div className="wt-40vw" >
                            {converter.urls && converter.urls.length === 1 && <div>
                                <img src={(converter.urls || [])[0]} alt="Converter Image" className="wt-100p" />
                            </div>}
                            {converter.urls && converter.urls.length > 1 &&  <div>
                                <Carousel
                                    value={value}
                                    slides={convertToImages(converter.urls)}
                                    onChange={handleOnChange}
                                />
                                <Dots value={value} onChange={handleOnChange} number={converter.urls.length} />
                            </div>}
                        </div>
                        <div className="flex flex-row-reverse flex-middle mt-20">
                            {/* <textarea placeholder="Notes" className="mr-40 flex-1" value={item.notes} onChange={(e)=> { setItem({...item, notes: e.target.value}) }} rows="2"/> */}
                            {/* <Button type="saveButton" className="flex flex-middle ml-40">
                                <img src={Tick} className="wt-20 ht-20 mr-4"/>
                                <span>Save Changes</span>
                            </Button> */}
                            
                            <Button type="saveButton" className="flex flex-middle" onClick={() => setIsImageModalShown(false)}>
                                {/* <img src={DeleteIcon} className="wt-20 ht-20 mr-4"/> */}
                                <span>Close</span>
                            </Button>
                        </div>
                    </OutsideClickHandler>
                </Modal>
            }
        </div>
    )
}

export default ConverterCard
