import React, { useState, useEffect } from "react";

import Toggle from "react-toggle";

import Modal from "../Modal";
import Button from "../Button";

import FormInput from "../FormInput";
import SelectCheckbox from "../SelectCheckbox";

import CaretIcon from "../../icons/caret.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";

import { getLoginDetails } from "../../utils/storage";

// import ImageUploader from 'react-images-upload';

// import ImageUploading from "react-images-uploading";
import { STATES_LIST } from "../../utils/api";
import ImageUploading from "./ImageUpload";

// const STATES = [ 'Florida', 'Wisconsin', 'Georgia'];

const PARTNER_TYPES = [
  {
    id: "YES",
    value: "YES",
  },
  {
    id: "NO",
    value: "NO",
  },
];
const COMPANY_TYPES = [
  {
    id: "BUYER",
    value: "Partner Buyer",
  },
  {
    id: "SELLER",
    value: "Partner Seller",
  },
  {
    id: "COLLECTOR",
    value: "Partner Collector",
  },
  {
    id: "SI",
    value: "Seller Individual",
  },
  {
    id: "VIEWER",
    value: "VIEWER",
  },
];

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (value.length > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

const EntityAddEdit = ({
  item,
  onClose,
  onSave,
  type,
  onBack,
  shouldShowPriceMod,
  facilities,
  editType,
  setRemoveTabs,
  isDirectDisplay,
}) => {
  const {
    hierarchy,
    canShowPriceModifier,
    canAdjustCustomerSettings,
  } = getLoginDetails();
  const [companyName, setCompanyName] = useState(item.companyName);
  const [companyType, setCompanyType] = useState(item.companyType);
  const [address, setAddress] = useState(item.address);
  const [address1, setAddress1] = useState(item.address1);
  const [city, setCity] = useState(item.city);
  const [state, setState] = useState(item.state);
  const [zipcode, setZipcode] = useState(item.zipcode);
  const [email, setEmail] = useState(item.email);
  const [phone, setPhone] = useState(normalizeInput(item.phone || ""));
  const [driversLicense, setDriversLicense] = useState(item.driversLicense);
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [isActive, setIsActive] = useState(item.isActive);
  const [isPictureMandatory, setIsPictureMandatory] = useState(
    item.isPictureMandatory || false
  );
  const [canSeeUnitPrice, setCanSeeUnitPrice] = useState(
    item.canSeeUnitPrice || false
  );
  const [canSeeMarkup, setCanSeeMarkup] = useState(item.canSeeMarkup || false);
  const [canSeeSubtotal, setCanSeeSubtotal] = useState(
    item.canSeeSubtotal || false
  );
  const [isPartner, setIsPartner] = useState(item.isPartner);
  const [priceMod, setPriceMod] = useState(item.priceMod);
  const [businessLicense, setBusinessLicense] = useState(item.businessLicense);
  const [ein, setEin] = useState(item.ein);
  const [selectedFacilities, setSelectedFacilities] = useState(
    (item.facilities || []).map((facility) => {
      const referenceFacility = facilities.find((refFacility) => {
        return facility === refFacility.facilityId;
      });
      return {
        id: (referenceFacility || {}).facilityId,
        value: (referenceFacility || {}).name,
      };
    })
  );

  // useEffect(()=> {
  //     setSelectedFacilities(
  //         (item.facilities || []).map((facility)=> {
  //             const referenceFacility = facilities.find((refFacility)=> {
  //                 return facility === refFacility.facilityId;
  //             })
  //             return {
  //                 id: (referenceFacility || {}).facilityId,
  //                 value: (referenceFacility || {}).name,
  //             }
  //         }
  //     ));
  // }, [facilities]);

  const hideAbcPricing = true;

  const [image, setImage] = useState(null);

  const [metalLicense, setMetalLicense] = useState(item.metalLicense);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [canBuildCart, setCanBuildCart] = useState(item.canBuildCart || false);

  const [validations, setValidations] = useState({});
  const [isPriceModifierPercantage, setIsPriceModifierPercantage] = useState(
    item.groupModifierIsPercentage
  );
  const [modifierInputValue, setModifierInputValue] = useState(
    item.groupModifier || ""
  );
  const validateFields = () => {
    const updatedValidations = { ...validations };

    updatedValidations.companyName = companyName;
    updatedValidations.email = email;
    updatedValidations.companyType = companyType;
    updatedValidations.address = address;
    // updatedValidations.address1 = address1;
    updatedValidations.phone = phone;
    updatedValidations.city = city;
    updatedValidations.state = state;
    updatedValidations.zipcode = zipcode;
    updatedValidations.firstName = firstName;
    updatedValidations.lastName = lastName;

    setValidations(updatedValidations);
    return updatedValidations;
  };

  return (
    // <Modal>
    <div
      className="mt-8 pl-12 pr-12 pb-12 pt-12"
      style={{
        boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
        backgroundColor: "#fff",
        padding: "20px",
        borderRadius: "20px",
        margin: "10px",
      }}
    >
      {!isDirectDisplay && (
        <div
          className="flex flex-middle c-pointer mb-20"
          onClick={() => {
            setRemoveTabs(false);
            onBack();
          }}
        >
          <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
          <span className="fs-12 c-table-header fw-bolder">
            Back To Customers
          </span>
        </div>
      )}
      {!isDirectDisplay && (
        <div className="fs-24 fw-bolder mb-40 c-gray">
          {type === "add" ? "CREATE" : "EDIT"} CUSTOMER
        </div>
      )}
      {/* <div className="flex flex-middle flex-center">
                    <ImageUploading onChange={setImage} initialImage={item.companyLogo}/>
                </div> */}

      <div className="flex flex-between flex-middle mb-40 flex-wrap">
        {type === "edit" ? (
          <div className=" mr-40 wt-33p">
            <div className="c-gray fs-14 mb-4">Company Name</div>
            <div> {companyName} </div>
          </div>
        ) : (
          <FormInput
            inputClassName="bg-blue rounded-pill"
            placeholder="Company Name"
            onChange={(e) => {
              setCompanyName(e.target.value);
            }}
            className="mr-40 wt-33p "
            value={companyName}
            hasError={hasSubmitted && !validations.companyName}
          />
        )}
        <FormInput
          inputClassName="bg-blue rounded-pill"
          placeholder="Primary Contact First Name"
          onChange={(e) => {
            setFirstName(e.target.value);
          }}
          className="mr-40 wt-33p "
          value={firstName}
          hasError={hasSubmitted && !validations.firstName}
        />
        <FormInput
          placeholder="Primary Contact Last Name"
          onChange={(e) => {
            setLastName(e.target.value);
          }}
          inputClassName="bg-blue rounded-pill"
          className="mr-40 wt-33p "
          value={lastName}
          hasError={hasSubmitted && !validations.lastName}
        />
        <FormInput
          placeholder="Company Email"
          onChange={(e) => {
            setEmail(e.target.value);
          }}
          inputClassName="bg-blue rounded-pill"
          className="mr-40 wt-33p "
          value={email}
          hasError={hasSubmitted && !validations.email}
        />
        <FormInput
          placeholder="Company Phone"
          inputClassName="bg-blue rounded-pill"
          onChange={(e) => {
            setPhone(normalizeInput(e.target.value || ""));
          }}
          className="mr-40 wt-33p "
          value={phone}
          hasError={hasSubmitted && !validations.phone}
        />
        {/*<FormInput 
                        placeholder="Driver License"
                        onChange={(e)=> {
                            setDriversLicense(e.target.value);
                        }}
                        className="mr-40 wt-33p "
                        value={driversLicense}
                        // hasError={hasSubmitted && !validations.driversLicense}
                    />*/}
      </div>
      <div className="flex flex-between flex-middle mb-40 flex-wrap">
        <FormInput
          inputClassName="bg-blue rounded-pill"
          placeholder="Company Address 1"
          onChange={(e) => {
            setAddress(e.target.value);
          }}
          className="mr-40 wt-33p "
          value={address}
          hasError={hasSubmitted && !validations.address}
        />
        <FormInput
          inputClassName="bg-blue rounded-pill"
          placeholder="Company Address 2"
          onChange={(e) => {
            setAddress1(e.target.value);
          }}
          className="mr-40 wt-33p "
          value={address1}
          // hasError={hasSubmitted && !validations.address1}
        />
        <FormInput
          inputClassName="bg-blue rounded-pill"
          placeholder="Company City"
          onChange={(e) => {
            setCity(e.target.value);
          }}
          className="mr-40 wt-33p "
          value={city}
          hasError={hasSubmitted && !validations.city}
        />
        <div className=" mr-40 wt-33p">
          <div className="c-gray fs-14 mb-4">Company State</div>
          <select
            name="type"
            className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
            value={state}
            onChange={(e) => setState(e.target.value)}
          >
            <option value={""}>Select State</option>
            {STATES_LIST.map((state) => {
              return <option value={state}>{state}</option>;
            })}
          </select>
          {hasSubmitted && !validations.state && (
            <div className="fs-10 c-error fw-bold p-absolute">
              Please select valid state
            </div>
          )}
        </div>
        <FormInput
          inputClassName="bg-blue rounded-pill"
          placeholder="Company Zipcode"
          onChange={(e) => {
            if (isNaN(e.target.value)) {
              return;
            }
            setZipcode(e.target.value);
          }}
          className="mr-40 wt-33p "
          value={(zipcode || "").replace(/[^\d]/g, "")}
          hasError={hasSubmitted && !validations.zipcode}
        />

        {!hideAbcPricing && shouldShowPriceMod && (
          <div className="wt-33p mr-40 p-relative t--8">
            <div className="fs-14 mb-4 fw-bold mt-20">Is ABC Pricing</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setPriceMod(e.target.checked);
              }}
              checked={priceMod}
            />
          </div>
        )}
        {/*<div className="wt-33p mr-40 p-relative t-12">    
                        <SelectCheckbox 
                            id={'companyType-dropdowns'}
                            selectPlaceholder={'Company Type'}
                            selected={companyType?[{
                                id: companyType,
                                value: COMPANY_TYPES.find((type)=> {
                                    return type.id === companyType
                                }).value
                            }]:[]}
                            items={COMPANY_TYPES}
                            selectSingle={true}
                            setSelectedItems = {(items)=> {
                                hierarchy === 1 && setCompanyType(items[0].id);
                            }}
                            hasError={hasSubmitted && !validations.companyType}
                        />
                    </div>*/}
        {/*<div className="wt-33p mr-40 p-relative t-12">    
                        <SelectCheckbox 
                            id={'selectedFacilities-dropdowns'}
                            selectPlaceholder={'Facilities'}
                            selected={selectedFacilities || []}
                            items={facilities.map((facility)=> {
                                return {
                                    id: facility.facilityId,
                                    value: facility.name
                                }
                            })}
                            setSelectedItems = {(items)=> {
                                console.log("ITEMS", items)
                                setSelectedFacilities(items);
                            }}
                            // hasError={hasSubmitted && !validations.companyType}
                        />
                    </div>*/}
        {canShowPriceModifier && (
          <div className="wt-33p mr-40 p-relative t--8">
            <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
              Price Modifier
            </div>
            <div className="flex flex-middle">
              <div className="flex flex-middle mr-16">
                <span className="c-gray mr-4">%</span>
                <Toggle
                  className="bg-theme hide-check-cross"
                  onChange={(e) => {
                    e.target.checked
                      ? setIsPriceModifierPercantage(false)
                      : setIsPriceModifierPercantage(true);
                  }}
                  checked={!isPriceModifierPercantage}
                />
                <span className="c-gray ml-4">$</span>
              </div>
              <FormInput
                inputClassName="fs-16"
                className="hideSpinner wt-150"
                type="number"
                step="0.01"
                value={modifierInputValue}
                onChange={(e) => {
                  setModifierInputValue(e.target.value);
                }}
              />
              {/* <div className="flex flex-middle fs-18 wt-30  bg-white">{isPriceModifierPercantage ? "%" : "$"}</div> */}
            </div>
          </div>
        )}

        <div className="wt-33p mr-40 mt-20">
          <div className="fs-14 mb-4 fw-bold mt-20 c-gray"> Company Logo </div>
          <div className="flex flex-middle">
            <ImageUploading
              onChange={setImage}
              initialImage={item.companyLogo}
            />
          </div>
        </div>

        <div className="flex flex-between flex-middle mb-40 flex-wrap wt-100p">
          <FormInput
            placeholder="Business License"
            inputClassName="bg-blue rounded-pill"
            onChange={(e) => {
              setBusinessLicense(e.target.value);
            }}
            className="mr-40 wt-33p "
            value={businessLicense}
            // hasError={hasSubmitted && !validations.businessLicense}
          />
          <FormInput
            placeholder="EIN"
            inputClassName="bg-blue rounded-pill"
            onChange={(e) => {
              setEin(e.target.value);
            }}
            className="mr-40 wt-33p "
            value={ein}
            // hasError={hasSubmitted && !validations.ein}
          />
          <div className="mr-40 wt-33p">
            <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
              Metal Recycler License
            </div>
            <ImageUploading
              onChange={setMetalLicense}
              initialImage={metalLicense}
            />
          </div>
        </div>

        {hierarchy === 1 && canAdjustCustomerSettings && (
          <div className="flex flex-between flex-middle mb-40 flex-wrap wt-100p">
            <div className="mr-10 wt-20p p-relative">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Is Picture Mandatory?
              </div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setIsPictureMandatory(e.target.checked);
                }}
                checked={isPictureMandatory}
              />
            </div>
            <div className="mr-10 wt-20p p-relative">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Can See Offer Price?
              </div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setCanSeeUnitPrice(e.target.checked);
                }}
                checked={canSeeUnitPrice}
              />
            </div>
            <div className="mr-10 wt-20p p-relative">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Can See Markup?
              </div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setCanSeeMarkup(e.target.checked);
                }}
                checked={canSeeMarkup}
              />
            </div>
            <div className="mr-10 wt-20p p-relative">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Can See Subtotal ?
              </div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setCanSeeSubtotal(e.target.checked);
                }}
                checked={canSeeSubtotal}
              />
            </div>
            <div className="mr-10 wt-20p p-relative">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Can Build Cart ?
              </div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setCanBuildCart(e.target.checked);
                }}
                checked={canBuildCart}
              />
            </div>
          </div>
        )}
        <div className="wt-33p mr-40 p-relative"></div>
        <div className="wt-33p mr-40 p-relative"></div>
        <div className="wt-33p mr-40 p-relative"></div>
        <div className="wt-33p mr-40 p-relative"></div>
      </div>
      <div className="flex flex-middle flex-right">
        {/*<Button className="flex flex-middle mr-20 bg-red" type="modalCancel" onClick={() => {setRemoveTabs(false); onClose()}}>
                        <span>CANCEL</span>
                    </Button>*/}

        <Button
          type="modalSave"
          onClick={() => {
            setHasSubmitted(true);
            const updatedValidations = validateFields();
            if (
              !updatedValidations.companyName ||
              !updatedValidations.companyType ||
              !updatedValidations.address ||
              // !updatedValidations.address1 ||
              !updatedValidations.city ||
              !updatedValidations.state ||
              !updatedValidations.zipcode ||
              !updatedValidations.firstName ||
              !updatedValidations.lastName ||
              !updatedValidations.email
            ) {
              return;
            }
            setRemoveTabs(false);
            onSave(
              {
                companyName,
                companyType,
                address,
                address1,
                phone,
                city,
                state,
                zipcode,
                email,
                firstName,
                lastName,
                companyId: item.companyId,
                companyLogo: item.companyLogo,
                isPartner,
                isActive,
                isPictureMandatory,
                canSeeUnitPrice,
                canSeeMarkup,
                canSeeSubtotal,
                businessLicense,
                canBuildCart,
                driversLicense,
                // metalLicense,
                ein,
                priceMod: shouldShowPriceMod ? priceMod : undefined,
                // facilities: selectedFacilities.map((facility)=> {
                //     return facility.id
                // }),
                groupModifierIsPercentage: isPriceModifierPercantage,
                groupModifier: modifierInputValue,
              },
              image ? image[0] : null,
              metalLicense ? metalLicense[0] : null
            );
          }}
          className="rounded-pill ht-40 btn"
        >
          {/* {type === 'add' ? 'Add' : 'Edit'} item */}
          <img src={Tick} className="wt-20 ht-20 mr-4" />
          <span>SAVE</span>
        </Button>
      </div>
    </div>
    // </Modal>
  );
};

export default EntityAddEdit;
