import React, { useState, useEffect, useRef } from "react";
import moment from "moment";
import OutsideClickHandler from "react-outside-click-handler";

import PageLayout from "../../components/PageLayout";
import TextField from '@material-ui/core/TextField';
import Autocomplete from '@material-ui/lab/Autocomplete';
import ConverterInfo from "../../components/AddEditItems/ConverterInfo";
import ConverterPricing from "../../components/AddEditItems/ConverterPricing";
import Table from "../../components/Table";
import FormInput from "../../components/FormInput";
import SelectCheckbox from "../../components/SelectCheckbox";

import ConverterCard from "../../components/ConverterCard";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import Button from "../../components/Button";

import AskExpert from "../../components/AddEditItems/AskExpert";
import MakeAddEdit from "../../components/AddEditItems/MakeAddEdit";

import SearchByImageIcon from "../../icons/rev_search_by_image.png";
import SearchByImageIconActive from "../../icons/pos_search_by_image.png";
import AskExpertIcon from "../../icons/rev_ask_expert.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/arrow-down-up.svg";
import Cross from "../../icons/cross.svg";
import SearchIcon from "../../icons/pos_find.png";

import { getLoginDetails } from "../../utils/storage";
import "./converters.css";

import {
  GET_CONVERTERS_URL,
  GET_MAKE_MODEL_URL,
  ASK_EXPERT_POST_URL,
  ADD_MAKE_MODEL_URL,
  EDIT_MAKE_MODEL_URL,
  GET_CONVERTER_DETAILS_URL,
  DELETE_CONVERTERS_URL,
  ADD_CONVERTERS_URL,
  EDIT_CONVERTERS_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_TEST_HISTORY_URL,
  GET_PRICE_HISTORY_URL,
  GET_CONVERTER_CART_URL,
  ADD_ITEMS_TO_CONV_CART_URL,
  GET_CONVERTER_TYPES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
  FACILITY_CITY_LIST,
  GET_ENTITIES_URL,
  GET_FACILITIES_URL,
} from "../../utils/api";
import { Link } from "react-router-dom";

const makeSample = {
  make: "",
  model: [],
  description: "",
  imglUrls: [],
  videoUrls: [],
  documentUrls: [],
};

let DEBOUNCE_TIMEOUT = null;

const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};

const IdentifyConverters = (props) => {
  let makes = "";
  let oemNumbers = "";
  let convoSearchs = "";
  if (props.match && props.match.params && props.match.params.parameters) {
    const queryString = (props.match.params.parameters || "").split(";");
    for (let i = 0; i < queryString.length; i++) {
      if (queryString[i] !== "") {
        const item = queryString[i].split("=");
        if (item[0] === "make") {
          makes = item[1];
        } else if (item[0] === "convoSearch") {
          convoSearchs = item[1];
        } else if (item[0] === "oemNumber") {
          oemNumbers = item[1];
        }
      }
    }
  }

  const {
    firstName,
    lastName,
    hierarchy,
    isAdmin,
    minimumSettingsOnly,
    canShowAdvancedSearchFilters,
  } = getLoginDetails();  

  const [isLoading, setIsLoading] = useState(hierarchy === 1 || hierarchy === 10);
  const [oemNumber, setOemNumber] = useState(oemNumbers || "");

  const [priceModifierItems, setPriceModifierItems] = useState([]);
  const [makeAndModelList, setMakeAndModelList] = useState([]);
  const [make, setMake] = useState(makes ? { make: makes } : null);
  const [model, setModel] = useState(null);

  const [converterViewType, setConverterViewType] = useState("TABLE");
  const [imgInModal, setImgInModal] = useState("");

  const [isAskExpertShown, setIsAskExpertShown] = useState(false);
  const [facility, setFacility] = useState("");
  const [companyID, setCompanyId] = useState("");
  const [customerName, setCustomerName] = useState("");

  const [entitiesItems, setEntitiesItems] = useState([]);
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [isAddMakeShown, setIsAddMakeShown] = useState(false);
  const [isEditMakeShown, setIsEditMakeShown] = useState(false);
  const [isImgModalShown, setIsImgModalShown] = useState(false);
  const [showCartItems, setShowCartItems] = useState(false);
  const [cartItemsList, setCartItemsList] = useState([]);
  const [selectedCartItem, setSelectedCartItem] = useState({});
  const [selectedItem, setSelectedItem] = useState({});
  const [isClicked, setisClicked] = useState('');
  const [box, setBox] = useState(0);
  const [quantity, setQuatity] = useState(1);
  const [unit, setUnit] = useState(1);
  const [convoTypes, setConvoTypes] = useState([]);
  const [facilities, setFaciltiesItems] = useState([]);
  const [convoSearch, setConvoSearch] = useState(
    convoSearchs ? { name: convoSearchs } : null
  );

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [params, setParams] = useState({
    limit: 25,
    offset: 0,
    text: "",
    areMoreItemsAvailable: false,
  });
  const askExpert = async (image, message, oem, make, model) => {
    let imageUrl = "";
    try {
      setIsLoading(true);
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        imageUrl = response.imageUrl;
      }
      const response = await postData(ASK_EXPERT_POST_URL, {
        message,
        url: imageUrl,
        firstName: firstName,
        lastName: lastName,
        oem,
        make,
        model
      });
      setIsAskExpertShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully posted the message to expert",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message: "Couldn't post the message to expert ",
        id: new Date().getTime(),
      });
    }
    setIsLoading(false);
  };
  const getConverters = async () => {
    try {
      console.log("get");
      setIsLoading(true);
      setPriceModifierItems([]);
      // if ( (oemNumber || "").length === 0) {
      //   setIsLoading(false);
      //   return;
      // }
      const data = await getData(GET_CONVERTERS_URL, {
        count: params.limit,
        offset: params.offset,
        make: (make || {}).make || "",
        facilityCity: facility || "",
        ctype: (convoSearch || {}).name || "",
        text: `${params.text || ""};${oemNumber || ""};${model || ""}`
          .split(";")
          .filter((a) => a)
          .join(" "),
        companyId: companyID,
        isClicked
      });
      setIsLoading(false);
      setPriceModifierItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, { count: 100 });
      setIsLoading(false);
      setMakeAndModelList(data.list);
      if (Object.keys(makes || {}).length > 0) {
        const mItem = (data.list || []).filter((el) => {
          return el.make === makes;
        });
        if (mItem.length > 0) {
          setMake(mItem[0]);
        }
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };
  const addMake = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        return uploadFile(FILE_UPLOAD_URL, image.file);
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      const response = await postData(ADD_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsAddMakeShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Make",
        id: new Date().getTime(),
      });
      getConverters();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editMake = async (data, images) => {
    let imglUrls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      imglUrls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await putData(EDIT_MAKE_MODEL_URL, {
        ...data,
        imglUrls,
      });
      setIsEditMakeShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Make",
        id: new Date().getTime(),
      });
      getConverters();
      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const getCartItems = async (item) => {
    console.log('clicked')
    try {
      setIsLoading(true);
      const data = await getData(GET_CONVERTER_CART_URL, {
        count: 10,
        offset: 0,
        type: 1,
      });
      setIsLoading(false);
      setCartItemsList(data.list);
      setShowCartItems(item);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const updateSelectCartItem = async () => {
    console.log('selectedCartItem', selectedCartItem)
    try {
      if (!quantity || !unit) {
        setToastParams({
          type: "ERROR",
          message: "Please enter valid count for unit and quantity",
          id: new Date().getTime(),
        });
        return;
      }
      setIsLoading(true);
      const data = await postData(ADD_ITEMS_TO_CONV_CART_URL, {
        productId: showCartItems.productId,
        oem: showCartItems.oem,
        description: showCartItems.description,
        url:
          showCartItems.urls & (showCartItems.urls.length > 0)
            ? showCartItems.urls[0]
            : " ",
        type: showCartItems.type,
        loadId: selectedCartItem.loadId,
        basePrice: showCartItems.basePrice,
        adjustedPrice: showCartItems.basePrice,
        isAddToLoad: true,
        box,
        quantity,
        unit,
      });
      setIsLoading(false);
      setToastParams({
        type: "SUCCESS",
        message:
          data && data.message ? data.message : "Successfully added item",
        id: new Date().getTime(),
      });
      setShowCartItems(false);
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error ? e.error : "Couldn't add to cart, please try again.",
        id: new Date().getTime(),
      });
    }
  };
  const getConverterTypes = async () => {
    try {
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
      if (Object.keys(convoSearchs || {}).length > 0) {
        const convoObj = (data.list || []).filter((convo) => {
          return convo.name === convoSearchs;
        });
        if (convoObj.length > 0) {
          setConvoSearch(convoObj[0]);
        }
      }
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };
  const handleCartItemSelect = (item) => {
    console.log('item111', item)
    setSelectedCartItem(item);
  };
  const isFirstRun = useRef(true);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    getConverters();
  }, [
    params.offset,
    params.text,
    params.limit,
    make,
    model,
    convoSearch,
    converterViewType === "IMAGE",
    facility,
    companyID,
  ]);
  useEffect(() => {
    getFacilities();
    if (hierarchy === 1 || hierarchy === 10) {
      getMakeAndModel();
    }

    if (!DEBOUNCE_TIMEOUT) {
      if ((oemNumber || "").length > 0 || convoSearch || make) {
        getConverters();
      }
    }
  }, []);
  useEffect(() => {
    if (hierarchy === 1 || hierarchy === 10) {
      getConverterTypes();
    }
  }, []);
  useEffect(() => {
    if ((oemNumber || "").length > 0) {
      getConverters();
    } else {
      setPriceModifierItems([]);
    }
  }, [oemNumber]);

  useEffect(() => {
    DEBOUNCE_TIMEOUT = null;
    return () => clearTimeout(DEBOUNCE_TIMEOUT);
  }, []);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const openImageModal = (img) => {
    setImgInModal(img);
    setIsImgModalShown(true);
  };
  const options = ['Option 1', 'Option 2'];
  const [customer, setCustomer] = useState("");
  const [value, setValue] = React.useState();
  const [inputValue, setInputValue] = React.useState('');

  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const getEntities = async (text) => {
    try {
      const data = await getData(GET_ENTITIES_URL, {
        count: 500,
        offset: 0,
        text,
        parentCompanyId: getLoginDetails().parentCompanyId,
        isEmbed: false,
        // customerSort: 'asc'
      });
      setEntitiesItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {}
  };

  return (
    <PageLayout
      icon={HeaderIcon}
      heading="Converters"
      hideSearchBar={true}
      // searchPlaceholder="Other data" onSearchChange={onSearchChange}
    >
      {!isAskExpertShown && !isAddMakeShown && !isEditMakeShown && (
        <>
          {converterViewType === "TABLE" && (
            <>
              <div
                style={{
                  boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                  backgroundColor: "#fff",
                  padding: "20px",
                  borderRadius: "20px",
                  margin: "10px",
                }}
              >
                <div
                  style={{ flexWrap: "wrap" }}
                  className="flex"
                  style={{ alignItems: "center" }}
                >
                  <form
                    className="flex-1 ml-8 mr-8"
                    onSubmit={(e) => {
                      e.preventDefault();
                      getConverters();
                    }}
                  >
                    <div className="flex flex-between flex-bottom">
                      <div className="flex-1">
                        <div className="c-red fw-bolder mb-20">
                          Search By OEM / Description</div>

                        <div
                          style={
                            hierarchy == 2 || !isAdmin ? { width: "300px" } : {}
                          }
                        >
                { hierarchy === 1 ?
                      <FormInput
                            pill
                            className="w-100"
                            inputClassName="bg-blue identify-converter-search"
                            inputPlaceHolder="Search By OEM"
                            spanText="or other data"
                            className="converter-formInput"
                            value={oemNumber}
                            onChange={(e) => {
                              if (DEBOUNCE_TIMEOUT === null) {
                                DEBOUNCE_TIMEOUT = setTimeout(() => {});
                              }
                              setOemNumber(e.target.value);
                            }}
                          />
:
                      <Autocomplete
                        value={value}
                        onChange={(event, newValue,reason) => {
                          setValue(newValue);
                          setSelectedItem(newValue);
                          setisClicked('true')
                          if(reason === "clear"){
                            setPriceModifierItems([])
                            setOemNumber('')
                            setValue('');
                            setSelectedItem({});
                            setInputValue('');
                            setisClicked('')
                          }
                        }}
                        inputValue={inputValue}
                        onInputChange={(event, newInputValue) => {
                          setInputValue(newInputValue);
                          setOemNumber(newInputValue);
                          if(!newInputValue) {
                            setPriceModifierItems([]);
                            setOemNumber('')
                            setValue('');
                            setSelectedItem({});
                            setInputValue('');
                            setisClicked('')
                          }
                        }}
                        getOptionLabel={(option) => `${option.oem} - ${option.description}`}
                        id="controllable-states-demo"
                        options={(oemNumber && (oemNumber || "").length > 0) ?[...priceModifierItems]:[]}
                        style={{ width: 300 }}
                        renderInput={(params) => <TextField {...params} variant="outlined" />}
                      />
                      }
              
                        </div>
                      </div>
                    </div>
                  </form>

                  {/* //hierarchy === 1 &&*/}
                  {canShowAdvancedSearchFilters && (
                    <React.Fragment>
                      <div
                        className="flex-1 ml-8 mr-8"
                        style={{ marginTop: "15px" }}
                      >
                        <div className="c-red fw-bolder mb-20">
                          Type
                        </div>

                        <select
                          name="qty"
                          style={{ height: "auto", padding: "10px" }}
                          className="select  form-control rounded-pill font-primary fs-14 fRegular mb-12 converter-formInput bg-blue"
                          value={(convoSearch || {}).id}
                          onChange={(e) => {
                            const convoObj = convoTypes.find((convo) => {
                              return convo.id === e.target.value;
                            });
                            setConvoSearch(convoObj);
                          }}
                        >
                          <option value={""}>Select Type</option>
                          {(convoTypes || []).map((type) => {
                            return <option value={type.id}>{type.name}</option>;
                          })}
                        </select>
                      </div>

                      <div
                        className="make-filter flex-1"
                        style={{ marginTop: "10px" }}
                      >
                        <div className="c-red fw-bolder mb-20">
                          Make
                        </div>

                        <select
                          name="qty"
                          style={{ height: "auto", padding: "10px" }}
                          className="select form-control rounded-pill pb-10 font-primary fs-14 fRegular mb-12 converter-formInput bg-blue"
                          value={(make || {}).makeId}
                          onChange={(e) => {
                            const id = e.target.value;
                            const make = makeAndModelList.find((make) => {
                              return make.makeId === id;
                            });
                            setMake(make);
                            setModel(null);
                          }}
                        >
                          <option value={""}>Select Make</option>
                          {(makeAndModelList || []).map((make) => {
                            return (
                              <option value={make.makeId}>{make.make}</option>
                            );
                          })}
                        </select>
                      </div>
                      <div
                        style={{ marginTop: "10px" }}
                        className="facility-filter flex-1 ml-8 mr-8"
                      >
                        <div className="c-red fw-bolder mb-20">
                          Facility
                        </div>
                        <select
                          style={{ height: "auto", padding: "10px" }}
                          name="type"
                          className="select form-control rounded-pill pb-10 font-primary fs-14 fRegular mb-12 converter-formInput bg-blue"
                          value={facility}
                          onChange={(e) => {
                            setFacility(e.target.value)
                          }}
                        >
                          <option value={""}>Select Facility</option>
                          {facilities.map((state) => {
                            return <option value={state.name}>{state.name}</option>;
                          })}
                        </select>
                      </div>

                      <div
                        style={{ marginTop: "10px" }}
                        className="customer-filter flex-1 ml-8 mr-8"
                      >
                        <div className="c-red fw-bolder mb-20">
                          Customer
                        </div>
                        <div className=" p-relative ">
                          <img
                            src={SearchIcon}
                            className="wt-20 t-12 p-absolute l-8"
                          />
                          <input
                            value={customerName}
                            className="form-control top_form_input idn_cus_filter forminput rounded-pill pb-10 font-primary fs-14 fRegular mb-12 converter-formInput bg-blue"
                            placeholder="Customer Name"
                            onChange={(e) => {
                              setCompanyId(e.target.value);
                              setCustomerName(e.target.value);
                              if ((e.target.value || "").length > 2) {
                                getEntities(e.target.value);
                                setShowDropdownDiv(true);
                              }
                            }}
                            style={{
                              paddingLeft: "30px",
                              backgroundColor: "#f1f1f1 !important",
                              height: "45px",
                            }}
                          />
                          {showDropdownDiv && entitiesItems.length > 0 && (
                            <div className="p-absolute z-1 border-black">
                              {console.log("RENDERED")}
                              <div
                                className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                                style={{ overflow: "scroll" }}
                              >
                                {[...entitiesItems].map((item) => {
                                  return (
                                    <div
                                      key={item.companyId}
                                      className="flex selectListDiv c-pointer"
                                      onClick={() => {
                                        setShowDropdownDiv(false);
                                        setEntitiesItems([]);
                                        setSelectedItem(item);
                                        setCompanyId(item.companyId);
                                        setCustomerName(item.companyName);
                                      }}
                                    >
                                      <div className="fs-14 mb-4">
                                        {item.companyName}
                                      </div>
                                    </div>
                                  );
                                })}
                              </div>
                            </div>
                          )}
                        </div>
                      </div>
                    </React.Fragment>
                  )}

                  {oemNumber || facility || companyID ? (
                    <div
                      style={{
                        marginTop: "10px",
                        display: "flex",
                        alignItems: "center",
                        padding: "5px",
                        marginLeft: "10px",
                        marginTop: "35px",
                        backgroundColor: "#ee5756",
                      }}
                      className="rounded-circle"
                    >
                      <div
                        style={{
                          margin: "0",
                          color: "#fff",
                          textAlign: "center",
                          fontWeight: "bold",
                          width: "25px",
                          height: "100%",
                        }}
                        className="c-pointer"
                        onClick={() => {
                          setOemNumber("");
                          setFacility("");
                          setCompanyId("");
                          setCustomerName("");
                          setSelectedItem({});
                          setShowDropdownDiv(false);
                          setCustomer('');
                          setSelectedItem({})


                        }}
                      >
                        X
                      </div>
                    </div>
                  ) : null}
                  {(!isAdmin || hierarchy == 2) && (
                    <div
                      style={{ marginTop: "40px" }}
                      className="flex ml-8 mr-8  flex-middle search-btns flex-1"
                    >
                      {(hierarchy === 1 || hierarchy === 10) && (
                        <div
                          className="btn ht-40 fs-14  flex flex-middle  c-whitefw-bolder c-pointer mr-40"
                          onClick={() => {
                            setConverterViewType(
                              converterViewType === "IMAGE" ? "TABLE" : "IMAGE"
                            );
                          }}
                        >
                          {/*<img
                            alt=""
                            src={SearchByImageIcon}
                            className="wt-20 ht-20 mr-8"
                          />*/}
                          {converterViewType === "TABLE" && (
                            <span className="fw-bolder">Image Search</span>
                          )}
                          {converterViewType === "IMAGE" && (
                            <span className="fw-bolder">Search By Table</span>
                          )}
                        </div>
                      )}
                      {!minimumSettingsOnly ? (
                        <div
                          style={{ height: "auto" }}
                          className="btn ht-40 flex flex-middle bg-gray c-white  c-pointer"
                          onClick={() => {
                            setIsAskExpertShown(true);
                          }}
                        >
                          <span className="fw-bolder">Expert</span>
                        </div>
                      ) : null}
                    </div>
                  )}
                </div>
                {isAdmin && (hierarchy == 1 || hierarchy === 10) && (
                  <div
                    style={{ margin: "10px 0" }}
                    className="flex ml-8 mr-8  flex-middle search-btns flex-1"
                  >
                    {(hierarchy === 1 || hierarchy === 10) && (
                      <div
                        className="btn ht-40 fs-14  flex flex-middle  c-whitefw-bolder c-pointer mr-40"
                        onClick={() => {
                          setConverterViewType(
                            converterViewType === "IMAGE" ? "TABLE" : "IMAGE"
                          );
                        }}
                      >
                        <img
                          alt=""
                          src={SearchByImageIcon}
                          className="wt-20 ht-20 mr-8"
                        />
                        {converterViewType === "TABLE" && (
                          <span className="fw-bolder">Image Search</span>
                        )}
                        {converterViewType === "IMAGE" && (
                          <span className="fw-bolder">Search By Table</span>
                        )}
                      </div>
                    )}
                    {!minimumSettingsOnly ? (
                      <div
                        style={{height: "auto" }}
                        className="btn ht-40 flex flex-middle bg-gray c-white  c-pointer"
                        onClick={() => {
                          setIsAskExpertShown(true);
                        }}
                      >
                        <span className="fw-bolder">Ask Expert</span>
                      </div>
                    ) : null}
                  </div>
                )}
              </div>

              {(hierarchy !== 1 && hierarchy !== 10) && (
                <div className="c-red pt-12 pb-4 fs-12">
                  Search results limited to 5 items. Please refine your search
                  criteria for more results
                </div>
              )}
              {/* {hierarchy === 1 && <div className=" mt-16 mb-24" />} */}

              <div
                className={`flex ${make || convoSearch ? "flex-column" : ""}`}
              >
                {console.log(make)}
                {(hierarchy === 1 || hierarchy === 10) && make && (
                  <div
                    style={{
                      boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                      backgroundColor: "#fff",
                      padding: "20px",
                      borderRadius: "20px",
                      margin: "10px",
                      flex: 1,
                    }}
                  >
                    <div className="flex flex-between">
                      <div className="mr-40" style={{ width: "30%" }}>
                        {/* {(make || {}).makeId && <>
                              <div className="c-gray fs-14 mb-4"> Model</div>
                              <select name="qty" className="select font-primary fs-14 fRegular mb-12"
                                  onChange={(e)=> {
                                      setModel(e.target.value);
                                  }}
                              >
                                  <option value={""}>Select the Model</option>
                                  {
                                      ((make || {}).model || []).map((model)=> {
                                          return (
                                              <option value={model}>{model}</option>
                                          )
                                      })
                                  }
                              </select>
                          </>} */}

                        <>
                          <div className="c-gray fs-14 mb-4"> Description</div>
                          <div className="bg-white c-gray pt-8 pb-8 pr-8 pl-8 fs-14">
                            {make.description}
                          </div>
                        </>

                        {/* <div className=" flex flex-row-reverse mt-20" >
                              {make && <div className="wt-100 pl-16 pr-16 pb-4 pt-4 c-white fw-bolder c-pointer bg-gray ml-20" onClick={()=> {
                                  setIsEditMakeShown(true);
                              }}>Edit Make</div>}
                              <div className="wt-100 pl-16 pr-16 pb-4 pt-4 c-white fw-bolder c-pointer bg-gray ml-20" onClick={()=> {
                                  setIsAddMakeShown(true);
                              }}>Add Make</div>
                          </div> */}
                      </div>
                      <div className="wt-60p">
                        {make && (
                          <>
                            <div className="c-red fw-bolder mb-20">
                              Manufacturer Examples
                              <span className="ml-8 fs-14 t-initial">
                                click images to enlarge
                              </span>
                            </div>
                            <div className="bg-white flex flex-wrap flex-between flex-top pl-16 pr-16 pt-16 manufacture-img-container">
                              {(make.imglUrls || []).map((img, index) => {
                                return (
                                  <img
                                    alt=""
                                    onClick={() => {
                                      openImageModal(img);
                                    }}
                                    src={img}
                                    className="ht-100 mb-16 manufacturer-img"
                                  />
                                );
                              })}
                            </div>
                          </>
                        )}
                      </div>
                    </div>
                  </div>
                )}

                {/* {hierarchy === 1 && <div className=" mt-16 mb-24" />} */}

                {/* {hierarchy === 1 && (
                  <div
                    style={{
                      boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                      backgroundColor: "#fff",
                      padding: "20px",
                      borderRadius: "20px",
                      margin: "10px",
                      flex: 1,
                    }}
                  >
                    <div className="flex flex-between"></div>
                  </div>
                )} */}
              </div>

              {/* <div className="mt-16 mb-24" /> */}
            </>
          )}
          {converterViewType === "IMAGE" && (
            <div>
              <div className="flex flex-middle flex-between">
                <div
                  className="btn ht-40 flex flex-middle c-gray fs-14 fw-bolder c-pointer"
                  onClick={() => {
                    setConverterViewType(
                      converterViewType === "IMAGE" ? "TABLE" : "IMAGE"
                    );
                  }}
                >
                  <span>Image Search</span>
                </div>
                {/* {priceModifierItems.length > 0 &&
                  (params.offset >= 10 || params.areMoreItemsAvailable) && (
                    <div className="ht-30 flex flex-middle flex-center pl-16 pr-16">
                      <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                        <img
                          src={CaretIcon}
                          alt=""
                          className={`wt-20 rotate-90 c-pointer ${
                            params.offset > 0 ? "op-7" : "op-2"
                          }`}
                          onClick={params.offset > 0 ? fetchLess : () => {}}
                        />
                      </div>
                      <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                        Showing {params.offset + 1} -{" "}
                        {params.offset + priceModifierItems.length}
                      </span>
                      <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                        <img
                          src={CaretIcon}
                          alt=""
                          className={`wt-20 rotate--90 c-pointer ${
                            params.areMoreItemsAvailable ? "op-7" : "op-2"
                          }`}
                          onClick={
                            params.areMoreItemsAvailable ? fetchMore : () => {}
                          }
                        />
                      </div>
                    </div>
                  )} */}

                <div
                  className="flex ht-40 flex-middle flex-between btn  c-white fw-bolder c-pointer"
                  onClick={() => {
                    setConverterViewType("TABLE");
                  }}
                >
                  Back
                </div>
              </div>

              <div className="wt-50p mr-40" style={{ marginTop: "30px" }}>
                <div className="c-red fw-bolder mb-8 mt-10">SELECT MAKE</div>
                <select
                  style={{ width: "33.33%" }}
                  name="qty"
                  className="select font-primary fs-14  fRegular mb-12 rounded-pill bg-blue"
                  value={(make || {}).makeId}
                  onChange={(e) => {
                    const id = e.target.value;
                    const make = makeAndModelList.find((make) => {
                      return make.makeId === id;
                    });
                    setMake(make);
                    setModel(null);
                  }}
                >
                  <option value={""}>SELECT MAKE</option>
                  {(makeAndModelList || []).map((make) => {
                    return <option value={make.makeId}>{make.make}</option>;
                  })}
                </select>
              </div>
            </div>
          )}
          {/* hierarchy === 1 ? oemNumber: */}
          {(oemNumber!="" || facility!="" || Object.keys(selectedItem || {}).length > 0 ||
            Object.keys(make || {}).length > 0 ||
            Object.keys(convoSearch || {}).length > 0) && (
            <>
              <div className="flex flex-middle flex-between wt-100p">
                <div className="c-red fw-bolder flex-1">Results</div>
                <div className="flex-1">
                  {/* {priceModifierItems.length > 0 &&
                    (params.offset >= 10 || params.areMoreItemsAvailable) && (
                      <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                        <div
                          style={{
                            backgroundColor: "#4862f1",
                            width: "40px",
                            transform: "scale(0.9)",
                            marginLeft: "-10px",
                          }}
                          className="ht-20 rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4"
                        >
                          <img
                            src={CaretIcon}
                            alt=""
                            className={`wt-20 rotate-90 c-pointer ${
                              params.offset > 0 ? "op-7" : "op-2"
                            }`}
                            onClick={params.offset > 0 ? fetchLess : () => {}}
                          />
                        </div>
                        <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                          Showing {params.offset + 1} -{" "}
                          {params.offset + priceModifierItems.length}
                        </span>
                        <div
                          style={{
                            backgroundColor: "#4862f1",
                            width: "40px",
                            transform: "scale(0.9)",
                            marginLeft: "-10px",
                          }}
                          className="ht-20 rounded-circle flex flex-center flex-middle pl-8 pb-4 pt-4"
                        >
                          <img
                            src={CaretIcon}
                            alt=""
                            className={`wt-20 rotate--90 c-pointer ${
                              params.areMoreItemsAvailable ? "op-7" : "op-2"
                            }`}
                            onClick={
                              params.areMoreItemsAvailable
                                ? fetchMore
                                : () => {}
                            }
                          />
                        </div>
                      </div>
                    )} */}
                </div>
                {/* <div className="flex-1">
                  {priceModifierItems.length > 0 && (
                    <div className="ht-40 wt-150 p-relative t-8 f-Right">
                      <select
                        name=""
                        id=""
                        value={params.limit}
                        onChange={(e) => {
                          setParams({
                            ...params,
                            limit: parseInt(e.target.value),
                          });
                        }}
                      >
                        <option value={25}>25</option>
                        <option value={50}>50</option>
                        <option value={100}>100</option>
                      </select>
                    </div>
                  )}
                </div> */}
              </div>
              {converterViewType === "TABLE" && (
                <Table
                customClassName="pr-16"
                  scrollTable={true}
                  scrollTable={true}
                  type="converters"
                  columns={[
                    "OEM",
                    "Offer Price",
                    "Make",
                    "Photo",
                    "Description",
                    "Type",
                  ]}
                  items={( hierarchy === 1 ? priceModifierItems: [selectedItem]).map((converter) => {
                    let linkDetails = `/converter/${converter.productId}/2/`;
                    if (params.text) {
                      linkDetails += `;text=${params.text}`;
                    }
                    if (oemNumber) {
                      linkDetails += `;oemNumber=${oemNumber}`;
                    }
                    if (model) {
                      linkDetails += `;model=${model}`;
                    }
                    if (make) {
                      linkDetails += `;make=${(make || "").make}`;
                    }
                    if (convoSearch) {
                      linkDetails += `;convoSearch=${
                        (convoSearch || {}).name || ""
                      }`;
                    }
                    return {
                      ...converter,
                      link: linkDetails,
                      photo: `is-photo-${(converter.urls || [])[0]}`,
                      markPrice: `$${converter.marketPrice}`,
                      basePriceWithDollar: `$${converter.basePrice}`,
                    };
                  })}
                  keys={[
                    "oem",
                    "basePriceWithDollar",
                    "manufacturer",
                    "photo",
                    "description",
                    "type",
                  ]}
                  actions={["VIEW"]}
                  viewLinkKey="link"
                  widthClasses={[
                    "flex-1",
                    " flex-1",
                    "flex-1",
                    "flex-1",
                    "flex-1",
                    "wt-100",
                    "wt-200",
                  ]}
                  offset={params.offset}
                  areMoreItemsAvailable={params.areMoreItemsAvailable}
                  fetchMore={fetchMore}
                  fetchLess={fetchLess}
                  isLoading={isLoading}
                  counterDropdown={[25, 50, 100]}
                  isCart={(hierarchy === 1 || hierarchy === 10)? true : false}
                  hideCheckBox={(hierarchy === 1 || hierarchy === 10) ? false : true}
                  className="min-wt-1000"
                  addCartLabel={(hierarchy === 1 || hierarchy === 10) ? `Cart` : ""}
                  hasAddToCart={(hierarchy === 1 || hierarchy === 10) ? true : false}
                  handleAddToCart={getCartItems}
                  onCountChange={(value) => {
                    setParams({
                      ...params,
                      limit: value,
                    });
                  }}
                  limit={params.limit}
                />
              )}
            </>
          )}

          {converterViewType === "IMAGE" && (
            <div className="flex flex-between flex-wrap mt-16">
              {priceModifierItems.map((converter, index) => {
                return (
                  <div className="decoration-none wt-48p mb-40">
                    <ConverterCard
                      key={index}
                      converter={converter}
                      link={`/converter/${converter.productId}`}
                      hasAddToCart={true}
                      handleAddToCart={getCartItems}
                    />
                  </div>
                );
              })}
            </div>
          )}
          {converterViewType === "IMAGE" &&
            priceModifierItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div
                  style={{
                    backgroundColor: "#4862f1",
                    width: "40px",
                    transform: "scale(0.9)",
                    marginRight: "-10px",
                  }}
                  className="ht-20  rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4"
                >
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + priceModifierItems.length}
                </span>
                <div
                  style={{
                    backgroundColor: "#4862f1",
                    width: "40px",
                    transform: "scale(0.9)",
                    marginLeft: "-10px",
                  }}
                  className="ht-20 rounded-circle flex flex-center flex-middle pl-8 pb-4 pt-4"
                >
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
        </>
      )}
      {isAskExpertShown && (
        <AskExpert
          onClose={setIsAskExpertShown.bind(null, false)}
          onBack={setIsAskExpertShown.bind(null, false)}
          isLoading={isLoading}
          onSave={askExpert}
        />
      )}
      {isAddMakeShown && (
        <MakeAddEdit
          item={{ ...makeSample }}
          onClose={setIsAddMakeShown.bind(null, false)}
          onBack={setIsAddMakeShown.bind(null, false)}
          onSave={addMake}
          type="add"
        />
      )}
      {isEditMakeShown && (
        <MakeAddEdit
          item={{ ...make }}
          onClose={setIsEditMakeShown.bind(null, false)}
          onBack={setIsEditMakeShown.bind(null, false)}
          onSave={editMake}
          type="edit"
        />
      )}
      {isImgModalShown && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => setIsImgModalShown(false)}>
            <img src={imgInModal} alt="" />
          </OutsideClickHandler>
        </Modal>
      )}

      {showCartItems && (
        <Modal>
          <OutsideClickHandler onOutsideClick={() => setShowCartItems(false)}>
            <h2> CARTS </h2>
            <Table
              scrollTable={true}
              columns={[
                "Customer Name",
                "CUSTOMER ",
                "LOCATION",
                "Date",
                "CART NAME",
                "STATUS",
                "ASSISTANT",
                "GRADER",
              ]} //, "Status"]}
              items={cartItemsList.map((load, index) => {
                const createdDate = new Date(
                  load.updatedDate || load.createdDate
                );
                const dd = String(createdDate.getDate()).padStart(2, "0");
                const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yyyy = createdDate.getFullYear();
                let hours = createdDate.getHours();
                let minutes = createdDate.getMinutes();
                const ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                return {
                  ...load,
                  custName: load.firstName + " " + load.lastName,
                  group: load.companyName,
                  location: load.city + ", " + load.state,
                  date: `${dd}-${mm}-${yyyy} ${hours}:${minutes}${ampm}`,
                  statusVal: STATUS_MAPPING[load.status],
                  assistantFullName:
                    (load.assistantFirstName || "") +
                    " " +
                    (load.assistantLastName || ""),
                  videoGraderFullName:
                    (load.videoGraderFirstName || "") +
                    " " +
                    (load.videoGraderLastName || ""),
                };
              })}
              keys={[
                "custName",
                "group",
                "location",
                "date",
                "loadName",
                "statusVal",
                "assistantFullName",
                "videoGraderFullName",
              ]} //, "statusVal"]}
              widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]} //, "flex-1"]}
              isCart={false}
              hideCheckBox={false}
              isSingleSelect={true}
              onItemClick={handleCartItemSelect}
              onSelection={false}
              offset={params.offset}
              areMoreItemsAvailable={params.areMoreItemsAvailable}
              fetchMore={fetchMore}
              fetchLess={fetchLess}
              isLoading={isLoading}
            />
            <div className="flex flex-middle flex-right">
              <FormInput
                placeholder="Box"
                type="number"
                className="mr-20 "
                value={box}
                onChange={(e) => {
                  setBox(e.target.value);
                }}
              />
              <FormInput
                placeholder="Unit"
                type="number"
                className="mr-20 "
                value={unit}
                onChange={(e) => {
                  setUnit(e.target.value);
                }}
              />
              <FormInput
                placeholder="Quantity"
                type="number"
                className="mr-40"
                value={quantity}
                onChange={(e) => {
                  setQuatity(e.target.value);
                }}
              />
              <Button
                className="mr-20"
                type="secondary"
                onClick={updateSelectCartItem}
              >
                Add to Cart
              </Button>
              <Button
                className="mr-20"
                type="secondary"
                onClick={() => {
                  setShowCartItems(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </OutsideClickHandler>
        </Modal>
      )}

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default IdentifyConverters;
