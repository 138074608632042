/* eslint-disable jsx-a11y/alt-text */
import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import useStateWithCallback from "use-state-with-callback";
import { Link, withRouter } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import HeaderIcon from "../../icons/newicons/cart-fill.svg";
import FormInput from "../../components/FormInput";
import HeaderIconWhite from "../../icons/newicons/cart-fill.svg";
import Table from "../../components/Table";
import Toast from "../../components/Toast";

import Modal from "../../components/Modal";
import Button from "../../components/Button";

import VideoCallIcon from "../../icons/videocall.png";
import Refresh from "../../icons/refresh.svg";
import UpArrow from "../../icons/up-arrow.svg";
import DownArrow from "../../icons/down-arrow.svg";
import Cross from "../../icons/cross.svg";
import Pencil from "../../icons/pencil.svg";
import Tick from "../../icons/tick.svg";

import ImageUploading from "../../components/AddEditItems/ImageUpload";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import OutsideClickHandler from "react-outside-click-handler";

import moment from "moment";

import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import Call from "./Call";
import Chat from "./Chat";
import SignatureCanvas from "react-signature-canvas";

// import InvoicePDF from "../InvoicePDF";
import Checkbox from "../../components/Checkbox";

import CurrencyFormat from "react-currency-format";
import { useLocation } from "react-router-dom";

import {
  GET_CONVERTERS_URL,
  GET_LOAD_DETAILS_URL,
  GET_ENTITY_DETAIL_URL,
  ADD_ITEM_TO_LOAD,
  EDIT_ITEM_IN_LOAD,
  DELETE_ITEM_FROM_LOAD,
  getData,
  postData,
  putData,
  deleteData,
  GET_ALL_GRADER_URL,
  GET_FACILITIES_URL,
  MODIFY_LOAD_URL,
  FINISH_LOAD_ORDER,
  MULTIPLE_FILE_UPLOAD_URL,
  ADD_CONVERTERS_URL,
  FILE_UPLOAD_URL,
  GET_CONVERTER_TYPES_URL,
  GET_PAYMENT_STATUS_URL,
  POST_PAYMENT_STATUS_URL,
  DELETE_PAYMENT_URL,
  UPLOAD_PDF_FILE_URL,
  UPLOAD_INVOICE_PDF_URL,
  GET_RESEND_INVOICE_URL,
  uploadFile,
  uploadFileWithToken,
  GET_ENTITIES_URL,
  GET_LOAD_ITEMS_URL,
} from "../../utils/api";
import ActionLoader from "../../components/ActionLoader";
import { getLoginDetails } from "../../utils/storage";
import Toggle from "react-toggle";

let TIMER = null;

const STATUSES = [
  { id: 1, name: "Approved", color: "success-color" },
  { id: 2, name: "Rejected", color: "error-color" },
  { id: 3, name: "Modified", color: "c-lt-gray" },
];

const converterModalObj = {
  name: "",
  oem: "",
  type: "",
  basePrice: "",
  urls: [],
};

const STATIC_UNIT_TYPES = ["CERAMIC", "DIESEL", "AFTER MKT"];

function useInterval(callback, delay) {
  const savedCallback = useRef();

  // Remember the latest callback.
  useEffect(() => {
    savedCallback.current = callback;
  }, [callback]);

  // Set up the interval.
  useEffect(() => {
    function tick() {
      savedCallback.current();
    }
    if (delay !== null) {
      let id = setInterval(tick, delay);
      return () => clearInterval(id);
    }
  }, [delay]);
}

const LoadsCart = (props) => {
  const loginDetail = getLoginDetails() || {};
  const isActive = () => {
    if(loginDetail.hierarchy === 10){
      if(props.location && props.location.state){
        if(!props.location.state.settledView.includes('/active-load')){
              return true
        }
        return false;
      }
      return false;
    }
    return false;
  }

  const isFranchiseLogin = () => {
    if(loginDetail.hierarchy === 1){
      if(props.location && props.location.state){
        if(props.location.state.settledView.includes('/franchise')){
              return true
        }
        return false;
      }
      return false;
    }
    return false;
  }
  const isSeller2 = (loginDetail.hierarchy === 2 || isActive());
  const isSeller = isSeller2 || isFranchiseLogin();
  console.log(loginDetail.hierarchy);
  console.log(isSeller);
  console.log("2",isFranchiseLogin());

  const {
    canEditInvoice,
    didNotBuyIndicator,
    canAddMarkDownOnly,
  } = loginDetail;
  let addItemRef = useRef();
  let scrollToTop = useRef();
  let scrollToEnd = useRef();
  const currentDate = new Date();
  const [signature, setSignature] = useState("");
  const [date, setDate] = useState(currentDate.toLocaleDateString("en-US"));
  const [displayCart, setDisplayCart] = useState(true);
  const [customerDetails, setCustomerDetails] = useState({});
  const [customerAddress, setCustomerAddress] = useState("");
  const [videoGraderList, setVideoGraderList] = useState([]);
  const [currentAssistantList, setCurrentAssistantList] = useState([]);
  const [locationList, setLocationList] = useState([]);
  const [loadData, setLoadData] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [addToCartIsLoading, setAddToCartIsLoading] = useState(false);
  const [isDropdownLoading, setIsDropdownLoading] = useState(false);
  const [notesVal, setNotesVal] = useState("");
  const [searchValue, setSearchValue] = useState("");
  const [itemsList, setItemsList] = useState(null);
  const [selectedItem, setSelectedItem] = useState({});
  const [selectedRow, setSelectedRow] = useState({});
  const [fetchLoadDetailsOnly, setFetchLoadDetailsOnly] = useState(false);
  const [isTableLoading, setIsTableLoading] = useState(false);
  const [selectedItemIndex, setSelectedItemIndex] = useState("");
  const [selectedGraderIndex, setSelectedGraderIndex] = useState("");
  const [selectedLocationIndex, setSelectedLocationIndex] = useState("");
  const [isVideoCallShown, setIsVideoCallShown] = useState(false);
  const [image, setImage] = useState([]);
  const [itemImages, setItemImages] = useState([]);
  const [isImageModalShown, setIsImageModalShown] = useState(false);
  const [convoTypes, setConvoTypes] = useState([]);
  const [newConverter, setNewConverter] = useState(false);
  const [converterItem, setConverterItem] = useState({ ...converterModalObj });
  const [converterImage, setConverterImage] = useState([]);
  const [paymentStatus, setPaymentStatus] = useState(false);
  const [paymentModes, setPaymentModes] = useState([]);
  const [paymentModesLoaded, setPaymentModesLoaded] = useState(false);
  const [amountReceived, setAmountReceived] = useState(0);
  const [showItemImg, setShowItemImg] = useState(null);
  const [clearSelection, setClearSelection] = useState(false);
  const [isSignLoading, setIsSignLoading] = useState(false);
  const [showCustData, setShowCustData] = useState(false);
  const [defaultBoxValue, setDefaultBoxValue] = useState(1);
  const [delConfirmPopup, setDelConfirmPopup] = useState(false);
  const [delConfirmItem, setDelConfirmItem] = useState({});
  const [previewImage, setPreviewImage] = useState("");
  const [showInvoicePdf, setShowInvoicePdf] = useState(false);
  const [pdfIsLoading, setPdfIsLoading] = useState(false);
  const [pdfFile, setPdfFile] = useState(null);
  const [completeOrderErrorMsg, setCompleteOrderErrorMsg] = useState("");
  const [loadItems, setLoadItems] = useState([]);
  const [startDate, setStartDate] = useState(new Date().setHours(0, 0, 0, 0));
  const [isChangePayForwardDate, setIsChangePayForwardDate] = useState(false);
  const [paymentObj, setPaymentObj] = useState({
    referenceNumber: "",
    amount: "",
    paymentMethod: "",
    date: startDate,
    loadId: props.match.params.loadId,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const [refreshData, setRefreshData] = useState(false);
  const [convertersParams, setConvertersParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [itemParams, setItemParams] = useState({
    tools: "",
    qty: 1,
    type: "",
    unit: 1,
    box: 1,
    adjusted: "",
  });
  const [updateGraderParam, setUpdateGraderParam] = useState({
    videoGraderId: "",
    videoGraderFirstName: "",
    videoGraderLastName: "",
    facilityId: "",
    facilityCity: "",
    facilityState: "",
  });
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [showCustomerDropdownDiv, setShowCustomerDropdownDiv] = useState(false);
  const [itemToEdit, setItemToedit] = useState(null);
  const [itemToModify, setItemToModify] = useState(null);
  const [discountVal, setDiscountVal] = useState("");
  const [discountIsPercentage, setDiscountIsPercentage] = useState(true);
  const [signatureImageUrl, setSignatureImageUrl] = useState("");
  const [displaySign, setDisplaySign] = useState(true);
  const [sigPadUrl, setSignPadUrl] = useState("");
  const [selectedCustomer, setSelelctedCustomer] = useState(false);
  const [searchCustomerText, setSearchCustomerText] = useState("");
  const [isResendInvoice, setIsResendInvoice] = useState(false);
  const [resendIsLoading, setResendIsLoading] = useState(false);
  const [isCompleteOrderLoading, setIsCompleteOrderLoading] = useState(false);
  const [payForwardDate, setPayForwardDate] = useState(
    new Date().setHours(0, 0, 0, 0)
  );
  const [isSaveLoading, setIsSaveLoading] = useState(false);
  const [edit, setEdit] = useState({
    customerInfo: false,
    items: false,
    resendInvocieSection: false,
    paymentStatusSection: false,
    completeOrderSection: false,
  });
  const [showActionBtn, setShowActionBtn] = useState(false);
  const [showAllItems, setShowAllItems] = useState(false);
  const [params, setParams] = useState({
    limit: 20,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  // const [sigPadUrl, setSignPadUrl] = useStateWithCallback("", sigPadUrl => {
  //   if(!sigPadUrl)
  //     return;
  //   // var block = sigPadUrl.split(";");
  //   // // Get the content type of the image
  //   // var contentType = block && block[0].split(":")[1];// In this case "image/gif"
  //   // // get the real base64 content of the file
  //   // var realData = block && block[1].split(",")[1];
  //   // const file = b64toBlob(realData, contentType);
  //   // console.log(file);
  //   fetch(sigPadUrl)
  //   .then(res => res.blob())
  //   .then(blob => {
  //     const file = new File([blob], "File name",{ type: "image/png" });
  //     console.log(file);
  //     uploadSignature(file);

  //   })
  // } );
  const location = useLocation();

  const uploadSignature = async (file) => {
    setIsSignLoading(true);
    const response = await uploadFile(FILE_UPLOAD_URL, file);
    setIsSignLoading(false);
    setToastParams({
      type: "SUCCESS",
      message: "Signature saved successfully !!!",
      id: new Date().getTime(),
    });
    setSignatureImageUrl(response.imageUrl);
  };
  let sigPad = useRef({});
  const searchByKey = (list, matchVal, key) => {
    let matchIndex = -1;
    for (let i = 0; i < list.length; i++) {
      let element = list[i];
      if (element[key] === matchVal) {
        matchIndex = i;
        break;
      }
    }
    return { element: list[matchIndex], index: matchIndex };
  };
  const getLoadDetails = async (isLoadsOnly) => {
    try {
      const loadData = await getData(GET_LOAD_DETAILS_URL, {
        loadId: props.match.params.loadId,
      });
      setLoadData(loadData);
      setNotesVal(loadData.notes);
      setLoadItems(loadData.items);
      setDisplayCart(isSeller ? loadData.canSellerAddToCart : true);
      setEdit({
        ...edit,
        customerInfo: false,
      });
      const currentDate = new Date();
      setDate(loadData.date || currentDate.toLocaleDateString("en-US"));
      setSignature(loadData.signature || "");
      if (loadData.type === 4 || loadData.type === 3) {
        setDisplayCart(false);
      }
      setDiscountIsPercentage(loadData.discountIsPercentage);
      setDiscountVal(loadData.discount || "");
      if (!fetchLoadDetailsOnly && !isLoadsOnly) {
        getPaymentModes();
        // const customerDetails = await getData(GET_ENTITY_DETAIL_URL, {
        //   companyId: loadData.companyId,
        // });
        // setCustomerDetails(customerDetails);

        // getVideoGradersPlusFacility();
        if (loadData.type !== 4 || canEditInvoice) {
          getConverterTypes();
        }
      } else {
        setPaymentModesLoaded(true);
      }
      setIsLoading(false);
      setIsTableLoading(false);
      setFetchLoadDetailsOnly(false);
      if (loadData.payForwardDate) {
        setPayForwardDate(loadData.payForwardDate);
      }
      setParams({
        ...params,
        areMoreItemsAvailable: loadData.areMoreItemsAvailable,
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
      setFetchLoadDetailsOnly(false);
    }
  };
  const getVideoGradersPlusFacility = async () => {
    try {
      setIsDropdownLoading(true);
      const videoGraderDetails = await getData(GET_ALL_GRADER_URL);
      setVideoGraderList(videoGraderDetails.list);
      const searchGreader = searchByKey(
        videoGraderDetails.list,
        loadData.videoGraderId,
        "userId"
      );
      setSelectedGraderIndex(searchGreader.index);

      const facilityDetails = await getData(GET_FACILITIES_URL);
      const searchFacility = searchByKey(
        facilityDetails.list,
        loadData.facilityId,
        "facilityId"
      );
      setLocationList(facilityDetails.list);
      setSelectedLocationIndex(searchFacility.index);
      setUpdateGraderParam({
        videoGraderId:
          (searchGreader.element && searchGreader.element.userId) || "",
        videoGraderFirstName:
          (searchGreader.element && searchGreader.element.firstName) || "",
        videoGraderLastName:
          (searchGreader.element && searchGreader.element.lastName) || "",
        facilityId:
          (searchFacility.element && searchFacility.element.facilityId) || "",
        facilityCity:
          (searchFacility.element && searchFacility.element.city) || "",
        facilityState:
          (searchFacility.element && searchFacility.element.state) || "",
      });
      setIsDropdownLoading(false);
    } catch (e) {
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };
  const uploadPDFFIleBeforeCompletion = async () => {
    setIsCompleteOrderLoading(true);
    try {
      setPdfIsLoading(true);
      const data = await uploadFileWithToken(UPLOAD_PDF_FILE_URL, "", {
        loadId: props.match.params.loadId,
        invoiceNo: loadData.invoiceNo,
        facility: loadData.facilityCity,
        email: loadData.customerEmail,
      });
      // const fileLink = await uploadFileWithToken(UPLOAD_INVOICE_PDF_URL, pdfFile);
      handleCompleteOrder(true);
      setPdfFile(null);
      setPdfIsLoading(false);
      setShowInvoicePdf(false);
      if ((completeOrderErrorMsg || "").length > 0) {
        setCompleteOrderErrorMsg("");
      }
    } catch (e) {
      setPdfIsLoading(false);
      setShowInvoicePdf(false);
      setIsCompleteOrderLoading(false);
      if (e && e.error) {
        setCompleteOrderErrorMsg(e.error);
      }
      // setToastParams({
      //   type: "ERROR",
      //   message: e && e.error ? e.error : 'Unable to upload file.',
      //   id: new Date().getTime()
      // })
    }
  };
  const getConverterTypes = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };
  useEffect(() => {
    getLoadDetails();
  }, []);
  // useEffect(() => {
  //   getPaymentModes();
  // }, []);

  useEffect(() => {
    if (fetchLoadDetailsOnly || refreshData) getLoadDetails();
  }, [fetchLoadDetailsOnly, refreshData]);
  useEffect(() => {
    if ((completeOrderErrorMsg || "").length > 0) {
      setCompleteOrderErrorMsg("");
    }
  }, [paymentModes, loadData]);
  // useInterval(()=> {
  //   getLoadDetails();
  // }, 10000);
  const getConverters = async (searchParam) => {
    try {
      setItemsList([]);
      const data = await getData(GET_CONVERTERS_URL, {
        count: searchValue ? 50 : convertersParams.limit,
        offset: convertersParams.offset,
        text: searchParam ? searchParam : searchValue,
        isLoad: true,
        companyId: loadData.customerId,
        facilityCity: loadData.facilityCity,
      });
      setItemsList(data.list);
      setShowDropdownDiv(true);
      setConvertersParams({
        ...convertersParams,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };

  const getCustomers = async (searchParam) => {
    try {
      setItemsList([]);

      const data = await getData(GET_ENTITIES_URL, {
        count: 10,
        offset: 0,
        text: searchCustomerText,
        parentCompanyId: getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
        customerSort: "asc",
      });

      setItemsList(data.list);
      setShowCustomerDropdownDiv(true);
      setConvertersParams({
        ...convertersParams,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };

  const addItemToLoad = async ({}, addDoNotBuy = false) => {
    setAddToCartIsLoading(true);
    if (loginDetail.isPictureMandatory && itemImages.length === 0) {
      setToastParams({
        type: "ERROR",
        message: "Please upload atleast one image",
        id: new Date().getTime(),
      });
      return;
    }
    try {
      const postParams = {
        loadId: props.match.params.loadId,
        productId: selectedItem.productId,
        type: itemParams.type || selectedItem.type,
        quantity: parseFloat(itemParams.qty),
        unit: parseInt(itemParams.unit),
        box: parseInt(itemParams.box),
        basePrice: selectedItem.basePrice,
        adjustedPrice: parseFloat(itemParams.adjusted),
        oem: selectedItem.oem || searchValue,
        description: selectedItem.description,
        url: selectedItem.urls && selectedItem.urls[0],
        isAddToLoad: true,
        loadUrls: itemImages,
        isEditInvoice: loadData.type === 4 && canEditInvoice,
        isDidNotBuy: addDoNotBuy ? true : false,
      };

      if (addDoNotBuy) {
        postParams.buyer =
          loadData.assistantFirstName + loadData.assistantLastName;
        postParams.buyerId = loadData.assistantId;
        postParams.customer = loadData.companyName;
        postParams.customerId = loadData.customerId;
        postParams.facility = loadData.facilityCity;
      }

      await postData(ADD_ITEM_TO_LOAD, postParams);
      clearItemSelected();
      setFetchLoadDetailsOnly(true);
      setIsTableLoading(true);
      setImage([]);
      setItemImages([]);
      setToastParams({
        type: "SUCCESS",
        message: addDoNotBuy
          ? "Updated Successfully"
          : "Successfully added the item in load",
        id: new Date().getTime(),
      });
      setAddToCartIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Unable to add item please try agian later",
        id: new Date().getTime(),
      });
      setAddToCartIsLoading(false);
    }
  };
  const clearItemSelected = () => {
    setSelectedItem({});
    setImage([]);
    setItemImages([]);
    setItemToModify(null);
    setItemToedit(null);
    setItemParams({
      tools: "",
      type: "",
      qty: 1,
      unit: 1,
      box: defaultBoxValue || 1,
      adjusted: "",
    });
    setSearchValue("");
    setClearSelection(false);
    setSelectedRow({});
  };
  const CreateDropDown = ({
    name,
    value,
    onChange,
    list,
    placeholder,
    disabled,
  }) => {
    placeholder = disabled ? value : placeholder || `Select the ${name}`;
    return (
      <select
        name={name}
        className="select font-primary fs-11 fRegular  ht-35"
        value={value}
        onChange={(e) => {
          onChange(list[parseInt(e.target.value)], e.target.value);
        }}
        disabled={disabled}
      >
        <option value={""}>{placeholder}</option>
        {(list || []).map((obj, index) => {
          return <option value={index}>{obj.name}</option>;
        })}
      </select>
    );
  };
  const handleEditItem = async (data, addDoNotBuy = false) => {
    setAddToCartIsLoading(true);
    try {
      let dataParams = {};
      if (Object.keys(data || {}).length > 0) {
        dataParams = { ...data };
      } else if (itemToModify) {
        dataParams = {
          status: 3,
          loadId: selectedItem.loadId,
          itemId: selectedItem.itemId,
          userId: selectedItem.userId,
          type: itemParams.type || selectedItem.type,
          quantity: parseFloat(itemParams.qty),
          unit: parseInt(itemParams.unit),
          finalQty: parseFloat(itemParams.finalQty),
          finalUnit: parseInt(itemParams.finalUnit),
        };
      } else {
        dataParams = {
          ...selectedItem,
          type: itemParams.type || selectedItem.type,
          quantity: parseFloat(itemParams.qty),
          unit: parseInt(itemParams.unit),
          box: parseInt(itemParams.box),
          adjustedPrice: parseFloat(itemParams.adjusted),
          loadUrls: itemImages, //imgs,
        };
      }
      if (loadData.type === 4 && canEditInvoice) {
        dataParams.isEditInvoice = true;
      }
      dataParams.isDidNotBuy = addDoNotBuy ? true : false;
      await putData(EDIT_ITEM_IN_LOAD, dataParams);
      clearItemSelected();
      setFetchLoadDetailsOnly(true);
      setIsTableLoading(true);
      setItemToedit(null);
      setItemImages([]);
      setImage([]);
      setClearSelection(true);
      setSelectedRow({});
      setToastParams({
        type: "SUCCESS",
        message: addDoNotBuy
          ? "Updated Success"
          : "Successfully edited the item",
        id: new Date().getTime(),
      });
      setAddToCartIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Cannot edit item please try agian later",
        id: new Date().getTime(),
      });
      setAddToCartIsLoading(false);
    }
  };
  const handleDeleteItem = async (item) => {
    try {
      await deleteData(DELETE_ITEM_FROM_LOAD, {
        loadId: item.loadId,
        itemId: item.itemId,
        isEditInvoice: loadData.type === 4 && canEditInvoice,
      });
      setFetchLoadDetailsOnly(true);
      setIsTableLoading(true);
      setDelConfirmPopup(false);
      setDelConfirmItem({});
    } catch (e) {
      setDelConfirmPopup(false);
      setDelConfirmItem({});
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Not able to delete please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  const handlePaymentItemDelete = async (item) => {
    try {
      setPaymentModesLoaded(false);
      setIsLoading(true);
      await deleteData(DELETE_PAYMENT_URL, {
        paymentId: item.paymentId,
        amount: item.amount,
        loadId: item.loadId,
        isEditInvoice: loadData.type === 4 && canEditInvoice,
      });
      setIsLoading(false);
      getPaymentModes();
    } catch (e) {
      setIsLoading(false);
      setPaymentModesLoaded(true);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Not able to delete please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  const updateLoad = async (
    e,
    updatediscountOnly = false,
    isRequestingGradingSession
  ) => {
    try {
      setPaymentModesLoaded(false);
      setIsSaveLoading(true);
      let params = !updatediscountOnly
        ? {
            ...updateGraderParam,
            notes: notesVal,
            assistantId: loadData.assistantId,
            assistantFirstName: loadData.assistantFirstName,
            assistantLastName: loadData.assistantLastName,
            loadId: loadData.loadId,
          }
        : {
            loadId: loadData.loadId,
            discount: Number(discountVal),
            discountIsPercentage: discountIsPercentage || false,
          };
      if (isRequestingGradingSession) {
        params["isRequestingGradingSession"] = isRequestingGradingSession;
      }

      if (canEditInvoice) {
        params["loadId"] = loadData.loadId;
        params["customerId"] = loadData.customerId;
        params["firstName"] = selectedItem.firstName;
        params["lastName"] = selectedItem.lastName;
        params["companyName"] = selectedItem.companyName;
        params["customerEmail"] = selectedItem.email;
        params["state"] = selectedItem.state;
        params["city"] = selectedItem.city;
        params["zipcode"] = selectedItem.zipcode;
        params["address"] = selectedItem.address;
        params["address1"] = selectedItem.address1;
      }

      if (loadData.type === 4 && canEditInvoice) {
        params.isEditInvoice = true;
      }

      if (payForwardDate) {
        params.payForwardDate = payForwardDate;
      }

      await putData(MODIFY_LOAD_URL, params);
      setFetchLoadDetailsOnly(true);
      setIsSaveLoading(false);
      // setRefreshData(true)
      setToastParams({
        type: "SUCCESS",
        message: "Successfully updated the load",
        id: new Date().getTime(),
      });
      // setPaymentModesLoaded(true);
    } catch (e) {
      setPaymentModesLoaded(true);
      setIsSaveLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Failed to update the load",
        id: new Date().getTime(),
      });
    }
  };
  const handleCompleteOrder = async (hasPdfFile) => {
    // return;
    setIsCompleteOrderLoading(true);
    try {
      const params = {
        loadId: props.match.params.loadId,
        signature: signatureImageUrl || signature,
        date,
      };
      if (loadData.type === 3) {
        params.isInvoice = true;
      }
      if (loadData.type === 4 && canEditInvoice) {
        params.isEditInvoice = true;
      }
      await putData(FINISH_LOAD_ORDER, params);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully completed order",
        id: new Date().getTime(),
      });
      if (!hasPdfFile) {
        getLoadDetails(true);
      }
      setIsCompleteOrderLoading(false);
      if (!isSeller) {
        // if (loadData.type === 3 || (loadData.type === 4 && canEditInvoice)) {
        props.history.push("/invoices/settled");
        // } else {
        // props.history.push("/invoices/pending");
        // }
      }
    } catch (e) {
      setIsCompleteOrderLoading(false);
      setShowInvoicePdf(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };
  const uploadFiles = async (e) => {
    try {
      setIsLoading(true);
      // (image || []).map( async (dUrl) => {
      //   const response = await uploadFile(MULTIPLE_FILE_UPLOAD_URL, dUrl.file);
      //   if (response && response.url && response.url.length>0) {
      //     item.push(response.url[0]);
      //     setItemImages(item)
      //   }
      // })
      if (image.length > 0 && image.filter((obj) => obj.file).length > 0) {
        let item = [...itemImages];
        const response = await uploadFile(MULTIPLE_FILE_UPLOAD_URL, image);
        if (response && response.url && response.url.length > 0) {
          (response.url || []).map((link) => {
            item.push(link);
          });
          setItemImages(item);
        }
        setToastParams({
          type: "SUCCESS",
          message: "Uploaded Image",
          id: new Date().getTime(),
        });
      }
      setIsImageModalShown(false);
      setIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const onConverterSave = async (data, images) => {
    let urls = data.urls || [];
    if (images) {
      const promises = (images || []).map((image) => {
        return uploadFile(FILE_UPLOAD_URL, image.file);
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      urls = updatedUrls.map((item) => {
        return item.imageUrl;
      });
    }
    try {
      setIsLoading(true);
      const response = await postData(ADD_CONVERTERS_URL, {
        ...data,
        urls,
        isEditInvoice: loadData.type === 4 && canEditInvoice,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Converter",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      setNewConverter(false);
      setConverterImage([]);
      setConverterItem({
        name: "",
        oem: "",
        type: "",
        basePrice: "",
        urls: [],
      });
    } catch (e) {
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const getPaymentModes = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_PAYMENT_STATUS_URL, {
        loadId: props.match.params.loadId,
        count: 20,
        offset: 0,
      });
      setPaymentModes(data.list);
      setAmountReceived(data.amountReceived);
      setIsLoading(false);
      // setPaymentModesLoaded(true);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Unable Payment Modes.",
        id: new Date().getTime(),
      });
    }
  };
  const addPaymentStatus = async () => {
    try {
      setIsLoading(true);
      setPaymentModesLoaded(false);
      const data = await postData(POST_PAYMENT_STATUS_URL, {
        ...paymentObj,
        isEditInvoice: loadData.type === 4 && canEditInvoice,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Payment",
        id: new Date().getTime(),
      });
      setPaymentObj({
        referenceNumber: "",
        amount: "",
        paymentMethod: "",
        loadId: props.match.params.loadId,
      });
      getPaymentModes();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      setPaymentModesLoaded(true);
      setToastParams({
        type: "ERROR",
        message: "Unable Payment Modes.",
        id: new Date().getTime(),
      });
    }
  };

  const clearSign = async () => {
    if (sigPad) sigPad.clear();
  };
  const editSign = async () => {
    setSignatureImageUrl("");
  };
  useEffect(() => {
    if (!sigPadUrl) return;
    fetch(sigPadUrl)
      .then((res) => res.blob())
      .then((blob) => {
        const file = new File([blob], "File name", { type: "image/png" });
        uploadSignature(file);
      });
  }, [sigPadUrl]);
  const saveSign = async () => {
    setSignPadUrl(sigPad.getTrimmedCanvas().toDataURL("image/png"));
  };

  let backState = null;
  if (props.location && props.location.state) {
    backState = props.location.state.settledView;
    // if (props.location.state.settledView===false) {
    //   backState = "/invoices/pending"
    // }
    // if (props.location.state.settledView===true) {
    //   backState = "/invoices/settled"
    // }
  }

  const handleChangeCustomerDetails = (key, value) => {
    setLoadData({
      ...loadData,
      [key]: value,
    });
  };

  const resendInvoice = async (file) => {
    setResendIsLoading(true);

    try {
      const data = await postData(GET_RESEND_INVOICE_URL, {
        loadId: props.match.params.loadId,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Invoice Resent successfully !!!",
        id: new Date().getTime(),
      });
      setResendIsLoading(false);
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Unable to resend.",
        id: new Date().getTime(),
      });
    }
    setResendIsLoading(false);
  };

  const handlePreviewClick = (event, url) => {
    setPreviewImage(url);
    event.stopPropagation();
    event.preventDefault();
  };

  useEffect(() => {
    // if(params.offset) {
    getLoadItemDetails();
    // }
  }, [params.offset, params.limit]);

  useEffect(() => {
    getLoadItemDetails();
  }, [showAllItems]);

  const getLoadItemDetails = async () => {
    try {
      const request = {
        loadId: props.match.params.loadId,
      };

      if (showAllItems) {
        request.isShowAll = showAllItems;
      } else {
        request.count = params.limit;
        request.offset = params.offset;
      }

      const data = await getData(GET_LOAD_ITEMS_URL, request);
      setLoadItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const searchCustomer = () => {
    return (
      <div
        className={`flex flex-column wt-30p p-relative add-item-section-left ${
          itemToModify ? `mb-20 pb-20` : ""
        }`}
      >
        <FormInput
          className="item-search-box-input"
          inputType="search"
          placeholder="Search Customer"
          value={searchCustomerText}
          onChange={(e) => {
            const val = e.target.value;
            setSearchCustomerText(val);
            if (TIMER) {
              clearTimeout(TIMER);
            }
            TIMER = setTimeout(() => {
              getCustomers(val);
            }, 500);
          }}
          floatSearchIconLeft={true}
        />
        {showCustomerDropdownDiv && itemsList && searchCustomerText.length > 0 && (
          <div className="p-absolute t-40 z-1">
            <div
              className="pl-8 pr-8 bg-white wt-300 p-absolut t-80 z-1 max-ht-300 "
              style={{ overflow: "scroll" }}
            >
              {[...itemsList].map((item) => {
                return (
                  <div
                    className="flex selectListDiv c-pointer"
                    onClick={() => {
                      setShowCustomerDropdownDiv(false);
                      setItemsList([]);
                      setSelectedItem(item);
                      // setLoadData({
                      //   ...loadData,
                      //   ...item,
                      // })
                      setSearchCustomerText("");
                    }}
                  >
                    <div className="wt-200 mr-24">{item.companyName}</div>
                    <div className="">
                      <img
                        src={item.companyLogo || ""}
                        className="ht-30 wt-30 mr-12"
                      />
                    </div>
                  </div>
                );
              })}
            </div>
          </div>
        )}
      </div>
    );
  };

  const showActionsButton = () => {
    return (
      <Modal>
        <div className="mt-8">
          <div className="flex flex-middle fs-24 fw-bolder mb-20 c-gray">
            <span className="mr-12">ACTIONS</span>
          </div>
          {/* {canEditInvoice ? (
            <div className="mb-20">
              <div
                className={` ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder bg-gray mr-12 ${
                  !edit.customerInfo ? "bg-gray-imp" : "bg-green-imp"
                }`}
                onClick={() => {
                  setEdit({
                    ...edit,
                    customerInfo: !edit.customerInfo,
                  });
                }}
              >
                <span>
                  {canEditInvoice && loadData.type === 4 && !edit.customerInfo
                    ? "EDIT CUSTOMER INFO"
                    : "HIDE CUSTOMER INFO"}
                </span>
              </div>
            </div>
          ) : null}

          {canEditInvoice ? (
            <div className="mb-20">
              <div
                className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder bg-gray mr-12 ${
                  !edit.items ? "bg-gray-imp" : "bg-green-imp"
                }`}
                onClick={() => {
                  setEdit({
                    ...edit,
                    items: !edit.items,
                  });
                }}
              >
                <span>
                  {canEditInvoice && loadData.type === 4 && !edit.items
                    ? "SHOW EDIT ITEMS"
                    : "HIDE EDIT ITEMS"}
                </span>
              </div>
            </div>
          ) : null} */}

          <div className="mb-20">
            <div
              onClick={() => {
                setEdit({
                  ...edit,
                  resendInvocieSection: !edit.resendInvocieSection,
                });
              }}
              className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder bg-gray mr-12 ${
                !edit.resendInvocieSection ? "bg-gray-imp" : "bg-green-imp"
              }`}
            >
              {!edit.resendInvocieSection ? "RESEND INVOICE" : "HIDE RESEND"}
            </div>
          </div>
          <div className="mb-20">
            <div
              onClick={() => {
                setEdit({
                  ...edit,
                  paymentStatusSection: !edit.paymentStatusSection,
                });
              }}
              className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder bg-gray mr-12 ${
                !edit.paymentStatusSection ? "bg-gray-imp" : "bg-green-imp"
              }`}
            >
              {!edit.paymentStatusSection
                ? "MODIFY PAYMENT STATUS"
                : "HIDE PAYMENT STATUS"}
            </div>
          </div>
          <div className="flex flex-middle flex-right">
            <Button
              className="flex flex-middle mr-20 bg-red"
              type="modalCancel"
              onClick={() => setShowActionBtn(false)}
            >
              <span>CLOSE</span>
            </Button>
          </div>
        </div>
      </Modal>
    );
  };

  // console.log(backState.includes("/active-load"));
  return (
    <PageLayout
      icon={HeaderIcon}
      heading={
        isSeller
          ? "SELL"
          : `BUY - ${
              !displayCart
                ? loadData.invoiceNo
                : `GRADING SESSION - ${loadData.loadName || ""}`
            }`
      }
      hideSearchBar
    >
      {isLoading ? (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      ) : (
        <>
          <div className="flex f-Right" ref={scrollToTop}>
            {/*<div className="flex flex-middle mb-8">
              <img src={HeaderIcon} className="ht-30 wt-30 mr-12" />
              <h1 className="mr-24 fs-24 c-gray mb-12 mt-12">
                {!displayCart ? loadData.invoiceNo : `GRADING SESSION - ${loadData.loadName || ''}`}
              </h1>
            </div>*/}
            <div className="flex">
              {canEditInvoice && loadData.type === 4 && loginDetail.hierarchy !== 10 && (
                <div
                  className={`btn  ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder bg-gray mr-12`}
                  onClick={() => {
                    setShowActionBtn(!showActionBtn);
                  }}
                >
                  <span>
                    {canEditInvoice && loadData.type === 4  && !showActionBtn
                      ? "Show Actions"
                      : "Hide Actions"}
                  </span>
                </div>
              )}
              {location.search.includes("fromDash=true") ? (
                <Link
                  className="btn ht-40 decoration-none flex flex-middle flex-center bg-gray pl-4 pr-4 pb-4 pt-4 c-white fw-bolder c-pointer mr-16"
                  to={{
                    pathname: "/dashboard",
                    params: {
                      filterData: true,
                    },
                  }}
                >
                  Back
                </Link>
              ) : (
                <Link
                  to={window.location.pathname.includes('/my-cart')?backState ? backState : "/loads/my-cart":backState ? backState : "/loads/active-load"}
                  className=" btn ht-40 decoration-none flex flex-middle flex-center bg-gray c-white fw-bolder c-pointer mr-16"
                  // onClick={updateLoad}
                >
                  <span>Back</span>
                </Link>
              )}
            </div>
          </div>
          <div className="mb-8 mt-4">
            <div
              className="c-black fs-16 fw-bolder mb-8"
              onClick={() => setShowCustData(!showCustData)}
            >
              {showCustData ? "- Hide" : "+ Show"} Customer Details
            </div>
          </div>
          {showCustData && (!window.location.pathname.includes('/my-cart')) &&  (
            <div
              style={{
                boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "20px",
                margin: "10px",
              }}
            >
              <div>
                <div className=" flex flex-between">
                  <div className="wt-30p">
                    <div className="c-black fw-bolder flex-1 mb-8">
                      CUSTOMER
                    </div>
                    {(displayCart || (canEditInvoice && loadData.type === 4)) &&
                    edit.customerInfo
                      ? searchCustomer()
                      : null}
                    <div>{loadData.companyName || ""}</div>
                    <div>{loadData.address || ""}</div>
                    <div>{loadData.city || ""}</div>
                    <div>{`${loadData.state || ""}, ${
                      loadData.zipcode || ""
                    }`}</div>
                  </div>
                  {isDropdownLoading && (
                    <div className="ht-80 p-relative">
                      <ActionLoader />
                    </div>
                  )}

                  <div className="flex flex-column wt-70p">
                    <div className="flex flex-between ">
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          VIDEO GRADER
                        </div>
                        {displayCart ||
                        (loadData.type === 4 &&
                          edit.customerInfo &&
                          canEditInvoice) ? (
                          <div
                            className="flex"
                            onClick={() => {
                              if (videoGraderList.length === 0) {
                                getVideoGradersPlusFacility();
                              }
                            }}
                          >
                            <CreateDropDown
                              name="Grader"
                              list={videoGraderList.map((videoGrader) => {
                                return {
                                  ...videoGrader,
                                  name: `${videoGrader.firstName} ${videoGrader.lastName}`,
                                };
                              })}
                              value={selectedGraderIndex}
                              onChange={(grader, index) => {
                                setSelectedGraderIndex(index);
                                setUpdateGraderParam({
                                  ...updateGraderParam,
                                  videoGraderId: grader.userId,
                                  videoGraderFirstName: grader.firstName,
                                  videoGraderLastName: grader.lastName,
                                });
                              }}
                            />
                            <div
                              className="flex flex-middle flex-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer fs-12"
                              onClick={(e) => {
                                updateLoad(e, null, true);
                              }}
                            >
                              <span>NOTIFY </span>
                            </div>
                          </div>
                        ) : (
                          `${loadData.videoGraderFirstName || "-"} ${
                            loadData.videoGraderLastName || "-"
                          }`
                        )}
                      </div>
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          CURRENT ASSISTANT
                        </div>
                        {displayCart ||
                        (loadData.type === 4 &&
                          edit.customerInfo &&
                          canEditInvoice) ? (
                          <CreateDropDown
                            name="Assistant"
                            placeholder={`${loadData.assistantFirstName} ${loadData.assistantLastName}`}
                            disabled={
                              loadData.assistantFirstName ? true : false
                            }
                          />
                        ) : (
                          `${loadData.assistantFirstName || "-"} ${
                            loadData.assistantLastName || "-"
                          }`
                        )}
                      </div>
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          LOCATION
                        </div>
                        {displayCart ||
                        (loadData.type === 4 &&
                          edit.customerInfo &&
                          canEditInvoice) ? (
                          <div //className="flex"
                            onClick={() => {
                              // if (locationList.length===0) {
                              //   getVideoGradersPlusFacility();
                              // }
                            }}
                          >
                            {loadData.facilityCity || ""}
                            {/*<CreateDropDown
                        name="Location"
                        list={locationList}
                        value={selectedLocationIndex}
                        onChange={(location, index) => {
                          setSelectedLocationIndex(index);
                          setUpdateGraderParam({
                            ...updateGraderParam,
                            facilityId: location.facilityId,
                            facilityCity: location.city,
                            facilityState: location.state,
                          });
                        }}
                      />*/}
                          </div>
                        ) : (
                          `${loadData.facilityCity || "-"} ${
                            loadData.facilityState || "-"
                          }`
                        )}
                      </div>
                    </div>
                    <div className="mt-12">
                      <div className="c-black fw-bolder flex-1 mb-8">NOTES</div>
                      {displayCart ||
                      (loadData.type === 4 &&
                        edit.customerInfo &&
                        canEditInvoice) ? (
                        <textarea
                          placeholder="--"
                          className="mr-20 wt-100p pl-4 bg-blue "
                          value={notesVal}
                          onChange={(e) => {
                            setNotesVal(e.target.value);
                          }}
                          rows="4"
                        />
                      ) : (
                        notesVal || "--"
                      )}
                    </div>
                  </div>
                </div>
                {displayCart ||
                (canEditInvoice && loadData.type === 4 && edit.customerInfo) ? (
                  <div
                    className="btn ht-40 c-white fw-bolder c-pointer"
                    onClick={updateLoad}
                  >
                    <span>SAVE </span>
                  </div>
                ) : null}
              </div>
            </div>
          )}
           {showCustData && (window.location.pathname.includes('/my-cart')) && (
            <div
              style={{
                boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                backgroundColor: "#fff",
                padding: "20px",
                borderRadius: "20px",
                margin: "10px",
              }}
            >
              <div>
                <div className=" flex flex-between">
                  <div className="wt-30p">
                    <div className="c-black fw-bolder flex-1 mb-8">
                      CUSTOMER
                    </div>
                    {(displayCart || (canEditInvoice && loadData.type === 4)) &&
                    edit.customerInfo
                      ? searchCustomer()
                      : null}
                    <div>{loadData.companyName || ""}</div>
                    <div>{loadData.address || ""}</div>
                    <div>{loadData.city || ""}</div>
                    <div>{`${loadData.state || ""}, ${
                      loadData.zipcode || ""
                    }`}</div>
                  </div>
                  {isDropdownLoading && (
                    <div className="ht-80 p-relative">
                      <ActionLoader />
                    </div>
                  )}

                  <div className="flex flex-column wt-70p">
                    <div className="flex flex-between ">
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          VIDEO GRADER
                        </div>
                        {
                          `${loadData.videoGraderFirstName || "-"} ${
                            loadData.videoGraderLastName || "-"
                          }`
                        }
                      </div>
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          CURRENT ASSISTANT
                        </div>
                       {
                          `${loadData.assistantFirstName || "-"} ${
                            loadData.assistantLastName || "-"
                          }`
}
                      </div>
                      <div className="flex flex-column wt-25p">
                        <div className="c-black fw-bolder flex-1 mb-8">
                          LOCATION
                        </div>
                       {
                          `${loadData.facilityCity || "-"} ${
                            loadData.facilityState || "-"
                          }`
}
                      </div>
                    </div>
                    <div className="mt-12">
                      <div className="c-black fw-bolder flex-1 mb-8">NOTES</div>
                    
                        {notesVal || "--"}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          )}
          {(loadData.type !== 4 || (canEditInvoice && edit.items)) &&
            ((isSeller && loadData.canSellerAddToCart && loadData.type !== 3) ||
              !isSeller) && (!window.location.pathname.includes('/my-cart')) && (//displayCart &&
              <>
                {" "}
                <div
                  style={{
                    boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
                    backgroundColor: "#fff",
                    padding: "20px",
                    borderRadius: "20px",
                    margin: "10px",
                  }}
                >
                  <div className=" mt-12 mb-8" />
                  <div className="flex">
                    <div className="wt-60p mr-28" />
                    <label>Default Box:</label>
                    <FormInput //placeholder="Default Box"
                      style={{ borderRadius: "100px" }}
                      inputClassName="pl-12"
                      className="bg-blue rounded-circle wt-20p mb-12 ml-24 pl-10"
                      value={defaultBoxValue}
                      onChange={(e) => {
                        setDefaultBoxValue(e.target.value);
                        setItemParams({ ...itemParams, box: e.target.value });
                      }}
                    />
                  </div>
                  <div
                    className="flex flex-middle add-item-section"
                    ref={addItemRef}
                  >
                    <div
                      style={{ width: "24%" }}
                      className={`flex flex-column wt-40p p-relative add-item-section-left ${
                        itemToModify ? `mb-20 pb-20` : ""
                      }`}
                    >
                      <div
                        className="flex flex-column"
                        style={{ marginTop: "8px" }}
                      >
                        <div className="c-black fw-bolder mb-8">ADD ITEM</div>

                        <FormInput
                          className="mr-32 item-search-box-input bg-blue"
                          inputClassName="bg-blue"
                          inputType="search"
                          isReadonly={selectedItem.oem}
                          width={"wt-200"}
                          value={
                            selectedItem.oem
                              ? `${selectedItem.oem}` //${selectedItem.name}`
                              : searchValue
                          }
                          onChange={(e) => {
                            const val = e.target.value;
                            setSearchValue(val);
                            setItemParams({
                              ...itemParams,
                              type: "CERAMIC",
                              unit: Math.ceil(itemParams.qty),
                            });
                            if (TIMER) {
                              clearTimeout(TIMER);
                            }
                            TIMER = setTimeout(() => {
                              getConverters(val);
                            }, 500);
                          }}
                          floatSearchIconLeft={true}
                        />
                      </div>

                      {showDropdownDiv && itemsList && searchValue.length > 0 && (
                        <div className="p-absolute t-80 z-1">
                          <div
                            className="pl-8 pr-8 bg-white wt-300 p-absolut t-80 z-1 max-ht-300 "
                            style={{ overflow: "scroll" }}
                          >
                            {
                              //[...itemsList].splice(0, 4).map((item) => {
                              [...itemsList].map((item) => {
                                return (
                                  <div
                                    className="flex selectListDiv c-pointer"
                                    onClick={() => {
                                      setShowDropdownDiv(false);
                                      setItemsList([]);
                                      setSelectedItem(item);
                                      const typeObj = (convoTypes || []).filter(
                                        (el) => {
                                          return el.id === item.type;
                                        }
                                      );
                                      const name =
                                        typeObj.length > 0
                                          ? typeObj[0].id
                                          : item.name;
                                      setItemParams({
                                        ...itemParams,
                                        type: name,
                                        unit: STATIC_UNIT_TYPES.includes(name)
                                          ? Math.ceil(itemParams.qty)
                                          : 1,
                                        adjusted: item.basePrice,
                                      });
                                      setSearchValue("");
                                    }}
                                  >
                                    <div className="wt-100 mr-24">
                                      {item.oem}
                                    </div>
                                    <div className="wt-100 mr-24">
                                      {item.description}
                                    </div>
                                    <div className="ht-30 wt-30">
                                      <img
                                        src={item.urls[0]}
                                        onClick={(e) =>
                                          handlePreviewClick(e, item.urls[0])
                                        }
                                        className="ht-30 wt-30 mr-12"
                                      />
                                    </div>
                                  </div>
                                );
                              })
                            }
                            {itemsList.length > 4 && loginDetail.canShowOther && (
                              <div
                                className="fs-16 fw-bolder"
                                onClick={() => {
                                  getConverterTypes();
                                  setNewConverter(true);
                                }}
                              >
                                Other...
                              </div>
                            )}
                          </div>
                        </div>
                      )}
                    </div>
                    {newConverter && (
                      <Modal>
                        <div className="flex flex-middle mr-12">
                          <h1 className="mr-24 fs-20 c-gray">
                            ADD NEW CONVERTER
                          </h1>
                        </div>
                        <div className="flex flex-between flex-middle mb-40 flex-wrap">
                          <FormInput
                            placeholder="Name"
                            className="wt-50p mb-12"
                            value={converterItem.name}
                            onChange={(e) => {
                              setConverterItem({
                                ...converterItem,
                                name: e.target.value,
                              });
                            }}
                          />
                          <div className="wt-50p mb-12">
                            <div className="c-gray fs-14 mb-4">Type</div>
                            <select
                              name="qty"
                              className="select font-primary fs-14 fw-bolder bg-blue rounded-pill"
                              value={converterItem.type}
                              onChange={(e) => {
                                setConverterItem({
                                  ...converterItem,
                                  type: e.target.value,
                                });
                              }}
                            >
                              <option value={""}>SELECT TYPE</option>
                              {(convoTypes || []).map((type) => {
                                return (
                                  <option value={type.id}>{type.name}</option>
                                );
                              })}
                            </select>
                          </div>
                          <FormInput
                            placeholder="OEM"
                            className=" wt-50p mb-12"
                            value={converterItem.oem}
                            onChange={(e) => {
                              setConverterItem({
                                ...converterItem,
                                oem: e.target.value,
                              });
                            }}
                          />
                          <FormInput
                            placeholder="Base Price"
                            type="number"
                            className="wt-50p mb-12"
                            value={converterItem.basePrice}
                            onChange={(e) => {
                              setConverterItem({
                                ...converterItem,
                                basePrice: e.target.value,
                              });
                            }}
                          />

                          <div className="flex flex-middle wt-100p">
                            <ImageUploading
                              onChange={setConverterImage}
                              // initialImage={item.profileUrl || ""}
                              isMultiple={true}
                              initialImages={(converterImage || []).map(
                                (url) => {
                                  return {
                                    dataURL: url,
                                  };
                                }
                              )}
                            />
                          </div>

                          <div className="flex flex-middle flex-right">
                            <Button
                              type="modalCancel"
                              className="flex flex-middle mr-20 bg-red"
                              onClick={() => {
                                setNewConverter(false);
                              }}
                            >
                              CANCEL
                            </Button>
                            <Button
                              type="modalSave"
                              className="flex flex-middle"
                              onClick={() => {
                                onConverterSave(
                                  { ...converterItem },
                                  converterImage
                                );
                              }}
                            >
                              SAVE
                            </Button>
                          </div>
                        </div>
                      </Modal>
                    )}
                    <div className="flex flex-between mt-8 add-item-section-right">
                      {/*<div className="flex flex-column">
                      <div className="c-black fw-bolder mb-8">TOOLS</div>
                      <div>--</div>
                    </div>*/}
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">TYPE</div>
                        <div>
                          {/*<div> {selectedItem.type || '-'} </div>*/}
                          <select
                            name="qty"
                            value={itemParams.type}
                            disabled={
                              loadData.type === 4 && canEditInvoice
                                ? false
                                : searchValue.length === 0
                            }
                            className="select font-primary fs-14 fw-bolder bg-blue rounded-pill"
                            onChange={(e) => {
                              setItemParams({
                                ...itemParams,
                                type: e.target.value,
                                unit: STATIC_UNIT_TYPES.includes(e.target.value)
                                  ? Math.ceil(itemParams.qty)
                                  : 1,
                              });
                            }}
                          >
                            <option value={""}>SELECT TYPE</option>
                            {(convoTypes || []).map((type) => {
                              return (
                                <option value={type.id}>{type.name}</option>
                              );
                            })}
                          </select>
                        </div>
                      </div>

                      {/* <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">Units</div>
                        {loadData.type === 4 && canEditInvoice ? (
                          <FormInput
                            inputClassName="flex flex-middle ht-35 t-right fs-20 pr-16"
                            className="hideSpinner wt-45"
                            type="number"
                            value={
                              itemToModify
                                ? itemParams.finalUnit
                                : itemParams.unit
                            }
                            inputPlaceHolder="--"
                            inputType="nonBold"
                            isReadonly={true}
                            // isReadonly={itemToModify}
                            onChange={(e) => {
                              if (itemToModify) {
                                setItemParams({
                                  ...itemParams,
                                  finalUnit: e.target.value,
                                });
                              } else {
                                setItemParams({
                                  ...itemParams,
                                  unit: e.target.value,
                                });
                              }
                            }}
                          />
                        ) : itemToModify ? (
                          itemParams.finalUnit
                        ) : (
                          itemParams.unit
                        )}
                      </div> */}
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">QTY/ WT</div>

                        <FormInput
                          style={{ paddingRight: "10px" }}
                          inputClassName="flex flex-middle ht-35 t-right fs-20 pr-20 bg-blue rounded-pill"
                          className="hideSpinner wt-140"
                          type="number"
                          value={
                            itemToModify ? itemParams.finalQty : itemParams.qty
                          }
                          inputPlaceHolder="0"
                          inputType="nonBold"
                          // isReadonly={itemToModify}
                          onChange={(e) => {
                            if (itemToModify) {
                              setItemParams({
                                ...itemParams,
                                finalQty: e.target.value,
                              });
                            } else {
                              setItemParams({
                                ...itemParams,
                                qty: e.target.value,
                                unit:
                                  Number(e.target.value) > 1 &&
                                  STATIC_UNIT_TYPES.includes(itemParams.type)
                                    ? Math.ceil(e.target.value)
                                    : 1,
                              });
                            }
                          }}
                        />
                        {/* {itemToModify && <div> {itemParams.qty} </div>} */}
                      </div>
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-12"></div>
                        <div
                          className="c-black fs-12 mb-4 c-pointer"
                          onClick={() => {
                            const modifyType = itemToModify
                              ? "finalQty"
                              : "qty";
                            setItemParams({
                              ...itemParams,
                              [modifyType]: 0.25,
                              unit: 1,
                            });
                          }}
                        >
                          <span>0.25</span>
                        </div>
                        <div
                          className="c-black fs-12 mb-4 c-pointer"
                          onClick={() => {
                            const modifyType = itemToModify
                              ? "finalQty"
                              : "qty";
                            setItemParams({
                              ...itemParams,
                              [modifyType]: 0.5,
                              unit: 1,
                            });
                          }}
                        >
                          <span>0.5</span>
                        </div>
                        <div
                          className="c-black fs-12 mb-4 c-pointer"
                          onClick={() => {
                            const modifyType = itemToModify
                              ? "finalQty"
                              : "qty";
                            setItemParams({
                              ...itemParams,
                              [modifyType]: 0.75,
                              unit: 1,
                            });
                          }}
                        >
                          <span>0.75</span>
                        </div>
                      </div>
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">BOX</div>
                        <FormInput
                          style={{ paddingRight: "10px" }}
                          inputClassName="flex flex-middle ht-35 t-right fs-20 pr-16 bg-blue rounded-pill"
                          className="hideSpinner wt-45"
                          type="number"
                          value={itemParams.box}
                          inputPlaceHolder="--"
                          inputType="nonBold"
                          isReadonly={itemToModify}
                          onChange={(e) => {
                            setItemParams({
                              ...itemParams,
                              box: e.target.value,
                            });
                          }}
                        />
                      </div>
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">OFFER</div>
                        <div>{selectedItem.basePrice || "--"}</div>
                      </div>
                      {loginDetail.canOveridePrice && (
                        <div className="flex flex-column">
                          <div className="c-black fw-bolder mb-8">ADJUSTED</div>
                          <FormInput
                            style={{ paddingRight: "10px" }}
                            inputClassName="flex flex-middle ht-35 t-right fs-20 pr-16 bg-blue rounded-pill"
                            className="hideSpinner wt-70"
                            type="number"
                            value={itemParams.adjusted}
                            inputPlaceHolder="--"
                            inputType="nonBold"
                            isReadonly={itemToModify}
                            onChange={(e) => {
                              setItemParams({
                                ...itemParams,
                                adjusted: e.target.value,
                              });
                            }}
                          />
                        </div>
                      )}
                      <div className="flex flex-column">
                        <div className="c-black fw-bolder mb-8">UPLOAD</div>
                        <div>
                          <div
                            onClick={() => {
                              setIsImageModalShown(true);
                            }}
                          >
                            {" "}
                            {itemImages.length} Files
                          </div>
                          {isImageModalShown && (
                            <Modal>
                              <OutsideClickHandler
                                onOutsideClick={() =>
                                  setIsImageModalShown(false)
                                }
                              >
                                <div className="flex flex-middle wt-100p">
                                  <ImageUploading
                                    onChange={(imgs) => {
                                      setImage(imgs);
                                    }}
                                    isMultiple={true}
                                    initialImages={(itemImages || []).map(
                                      (url) => {
                                        return { dataURL: url };
                                      }
                                    )}
                                  />
                                </div>
                                <div className="flex flex-row-reverse flex-middle mt-20">
                                  {/* <textarea placeholder="Notes" className="mr-40 flex-1" value={item.notes} onChange={(e)=> { setItem({...item, notes: e.target.value}) }} rows="2"/> */}
                                  <Button
                                    type="saveButton"
                                    className="flex flex-middle ml-40"
                                    onClick={(e) => {
                                      uploadFiles(e);
                                    }}
                                  >
                                    <span>Save Changes</span>
                                  </Button>
                                  <Button
                                    type="saveButton"
                                    className="flex flex-middle"
                                    onClick={() => setIsImageModalShown(false)}
                                  >
                                    <span>Close</span>
                                  </Button>
                                </div>
                              </OutsideClickHandler>
                            </Modal>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 flex flex-between">
                    <div>
                      {selectedItem.catAlert && (
                        <span
                          style={{
                            color: "#c93c34",
                            fontWeight: "bold",
                            fontSize: 18,
                          }}
                        >
                          ALERT: {selectedItem.catAlert}
                        </span>
                      )}
                    </div>
                    <div className="flex flex-between pl-60 grading-cta-buttons">
                      <div
                        style={{
                          height: "auto",
                          width: "auto",
                          padding: "2px 20px",
                        }}
                        className="btn ht-40  btn-red flex mr-12 flex-middle flex-center bg-gray  c-white fw-bolder c-pointer"
                        onClick={clearItemSelected}
                      >
                        <span>CLEAR</span>
                      </div>
                      {didNotBuyIndicator ? (
                        <div
                          style={{
                            height: "auto",
                            width: "auto",
                            padding: "2px 20px",
                            backgroundColor: "yellow",
                            color: "#000",
                          }}
                          className="btn ht-40 flex mr-12 flex-middle flex-center bg-gray  c-white fw-bolder c-pointer"
                          onClick={
                            addToCartIsLoading
                              ? {}
                              : itemToEdit
                              ? () => handleEditItem({}, true)
                              : () => addItemToLoad({}, true)
                          }
                        >
                          <span>
                            {addToCartIsLoading
                              ? "Loading..."
                              : "CLEAR/DID NOT BUY"}
                          </span>
                        </div>
                      ) : null}

                      <div
                        style={{
                          height: "auto",
                          width: "auto",
                          padding: "2px 20px",
                        }}
                        className="btn ht-40 mr-12 flex flex-middle flex-center bg-gray  c-white fw-bolder c-pointer"
                        onClick={
                          addToCartIsLoading
                            ? {}
                            : itemToEdit
                            ? () => handleEditItem()
                            : addItemToLoad
                        }
                      >
                        <span>
                          {addToCartIsLoading
                            ? "Loading..."
                            : itemToEdit
                            ? "EDIT ITEM"
                            : "ADD TO CART"}
                        </span>
                      </div>
                      <div className="flex mr-12 ">
                        {/* <Checkbox
                  isSelected={itemParams.isDidNotBuy}
                  label={<div className="c-black fw-bolder fs-18"> CLEAR/DID NOT BUY</div>}
                  onClick={() => setItemParams({
                    ...itemParams,
                    isDidNotBuy: !itemParams.isDidNotBuy,
                  })}
                  labelClassName="c-black fs-12"
                /> */}
                      </div>
                    </div>
                  </div>
                  <div className="mt-12 mb-8" />
                </div>
              </>
            )}
          {
            <div className="mt-12 flex fRight flex-right">
              <div
                onClick={() => {
                  getLoadDetails(true);
                }}
                className="mt-12 flex c-pointer fRight flex-right"
              >
                <img src={Refresh} className="ht-20 wt-20 mr-12" />
              </div>
              <div className="flex">
                <Checkbox
                  id={"allitems"}
                  onClick={(id) => {
                    setShowAllItems(!showAllItems);
                  }}
                  isSelected={showAllItems}
                  className="mr-20 mt-12 mb-12"
                />
                <span
                  onClick={(id) => {
                    setShowAllItems(!showAllItems);
                  }}
                  className="mt-12"
                >
                  Show All
                </span>
              </div>
            </div>
          }
          <div style={{ marginBottom: showAllItems ? 0 : 35 }}>
            <Table
              scrollTable={true}
              columns={[
                "Item",
                // "Desc",
                "Type",
                // " ",
                // loginDetail.canSeeUnitPrice
                // ? !loadData.canSellerAddToCart && isSeller
                //   ? "Price"
                //   : "Offer Price"
                // : undefined,
                // loginDetail.canSeeUnitPrice
                //   ? !loadData.canSellerAddToCart && isSeller
                //     ? undefined
                //     : "Adj. Price"
                //   : undefined,
                "Price",
                "Units",
                "Qty/ Weight",
                "Box",
                loginDetail.canSeeUnitPrice ? "Total" : undefined,
              ].filter((a) => a)}
              items={(loadItems || []).map((item) => {
                let statusName =
                  STATUSES.filter((el) => {
                    return item.status === el.id;
                  }).length > 0
                    ? STATUSES.filter((el) => {
                        return item.status === el.id;
                      })[0]
                    : null;
                return {
                  ...item,
                  nameModified: statusName
                    ? `semicolon-separated-inline-${item.oem};text-color-${
                        statusName.color
                      }_${(statusName.name || "").toUpperCase()}`
                    : item.oem,
                  total: item.total || "-",
                  totalWithSymbol: ReactDOMServer.renderToStaticMarkup(
                    <CurrencyFormat
                      value={item.total || 0}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(val) => val}
                    />
                  ),
                  image: `is-photo-${item.url}`,
                  // totalQty: `${item.finalQty}, \n SQ: ${item.quantity}`,
                  // totalUnit: `${item.finalUnit}, \n SU: ${item.unit} `,
                  // status: item.status,
                  adjustedPriceWithSymbol: item.adjustedPrice
                    ? ReactDOMServer.renderToStaticMarkup(
                        <CurrencyFormat
                          value={item.adjustedPrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(val) => val}
                        />
                      )
                    : "",
                  basePriceWithSymbol: item.basePrice
                    ? ReactDOMServer.renderToStaticMarkup(
                        <CurrencyFormat
                          value={item.basePrice}
                          displayType={"text"}
                          thousandSeparator={true}
                          prefix={"$"}
                          renderText={(val) => val}
                        />
                      )
                    : "",
                  totalQty: item.finalQty, //`semicolon-separated-inline-${item.finalQty}; SQ : ${item.quantity}`,
                  totalUnit: item.finalUnit, //`semicolon-separated-inline-${item.finalUnit}; SU : ${item.unit}`,
                };
              })}
              keys={[
                "nameModified",
                // "description",
                "type",
                // "image",
                // loginDetail.canSeeUnitPrice
                //   ? !loadData.canSellerAddToCart && isSeller
                //     ? "adjustedPriceWithSymbol"
                //     : "basePriceWithSymbol"
                //   : undefined,
                // loginDetail.canSeeUnitPrice
                //   ? !loadData.canSellerAddToCart && isSeller
                //     ? undefined
                //     : "adjustedPriceWithSymbol"
                //   : undefined,
                "adjustedPriceWithSymbol",
                "totalUnit",
                "totalQty",
                "box",
                loginDetail.canSeeUnitPrice ? "totalWithSymbol" : undefined,
              ].filter((a) => a)}
              widthClasses={[
                `flex-1 `,
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "wt-100",
              ]}
              actions={
                loadData.type === 4 && canEditInvoice && edit.items
                  ? ["DELETE"]
                  : (!displayCart || props.match.path.includes('/my-cart'))
                  ? null
                  : loginDetail.canVerifyLoad
                  ? ["EDIT", "DELETE"]
                  : ["EDIT", "DELETE"]
              }
              isLoading={isTableLoading}
              onItemClick={(item) => {
                setShowItemImg(item);
              }}
              onEdit={(item) => {
                item.name = item.description;
                setItemImages(item.loadUrls);
                setSelectedItem(item);
                setImage(item.loadUrls);
                setItemToedit(item);
                setItemParams({
                  tools: "",
                  qty: item.quantity,
                  unit: STATIC_UNIT_TYPES.includes(item.type)
                    ? Math.ceil(item.quantity) || item.unit
                    : 1,
                  type: item.type,
                  box: item.box,
                  adjusted: item.adjustedPrice,
                });
                window.scrollTo({
                  behavior: "smooth",
                  top: addItemRef.current.offsetTop - 55,
                });
              }}
              isSingleSelect={true}
              clearSelection={clearSelection}
              hideCheckBox={
                isSeller ||
                (loadData.type !== 3 &&
                  (loadData.type !== 4 || !canEditInvoice || !edit.items))
              }
              onClearSelection={(val) => setClearSelection(val)}
              onDelete={(item) => {
                setDelConfirmPopup(true);
                setDelConfirmItem(item);
                // handleDeleteItem(item);
              }}
              onSelection={(item) => {
                if (item) {
                  item.name = item.description;
                  setSelectedRow(item);
                } else {
                  setSelectedRow({});
                  setSelectedItem({});
                  setItemToModify(null);
                  setItemToedit(null);
                  setItemImages([]);
                  setImage([]);
                  setItemParams({
                    tools: "",
                    qty: 1,
                    unit: 1,
                    type: "",
                    box: "",
                    adjusted: "",
                  });
                }
              }}
              offset={params.offset}
              areMoreItemsAvailable={
                showAllItems ? false : params.areMoreItemsAvailable
              }
              fetchMore={fetchMore}
              fetchLess={fetchLess}
              onCountChange={(value) => {
                setParams({
                  ...params,
                  limit: value,
                  offset: 0,
                });
              }}
              limit={params.limit}
            />
          </div>
          {
            // delConfirmPopup &&
            // <Modal>
            //   <OutsideClickHandler onOutsideClick={() => setShowItemImg(null)}>
            //   </OutsideClickHandler>
            // </Modal>
          }
          {delConfirmPopup && (
            <DeleteConfirmation
              type="item"
              name={delConfirmItem.oem}
              onClose={() => setDelConfirmPopup(false)}
              onConfirm={() => handleDeleteItem(delConfirmItem)}
            />
          )}

          {showItemImg && (
            <Modal>
              <OutsideClickHandler onOutsideClick={() => setShowItemImg(null)}>
                <div className="flex flex-middle wt-100p">
                  <ImageUploading
                    // onChange={(imgs) => { setImage(imgs) }}
                    // isMultiple={true}
                    initialImages={(showItemImg.loadUrls || []).map((url) => {
                      return { dataURL: url };
                    })}
                  />
                </div>
                <div className=" flex flex-row-reverse flex-middle mt-20">
                  {
                    // <textarea placeholder="Notes" className="mr-40 flex-1" value={item.notes} onChange={(e)=> { setItem({...item, notes: e.target.value}) }} rows="2"/>
                  }
                  {/*<Button type="saveButton" className="flex flex-middle ml-40" onClick={(e)=> { uploadFiles(e) }}>
                            <span>Save Changes</span>
                        </Button>*/}
                  <Button
                    type="saveButton"
                    className="flex ht-40 flex-middle btn btn-red"
                    onClick={() => setShowItemImg(null)}
                  >
                    <span>Close</span>
                  </Button>
                </div>
              </OutsideClickHandler>
            </Modal>
          )}

          <div
            style={{
              boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
              backgroundColor: "#fff",
              padding: "20px",
              borderRadius: "20px",
              margin: "10px",
            }}
            className="mb-80"
          >
            <div className="c-black fw-bolder">CART TOTALS</div>
            <div className="flex flex-between mb-24 invoice-bottom-panel">
              {(displayCart ||
              (!isSeller &&
                (loadData.type === 3 ||
                  (loadData.type === 4 && canEditInvoice)) &&
                Object.keys(loadData || {}).length > 0 &&
                Object.keys(loadData.itemDetails || {}).length > 0)) &&(!window.location.pathname.includes('/my-cart')) ? (
                <>
                  <div className="flex flex-column wt-30p">
                    {displayCart ||
                    (!isSeller &&
                      (loadData.type === 3 ||
                        (loadData.type === 4 && canEditInvoice))) ? (
                      <>
                        {displayCart ||
                        (!isSeller &&
                          (loadData.type === 3 ||
                            (loadData.type === 4 &&
                              canEditInvoice && 
                              edit.paymentStatusSection))) ? (
                          <>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Total Units:</div>{" "}
                              <div>
                                {loadData.itemDetails["Total Units"] || 0}
                              </div>
                            </div>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Total Ceramic:</div>{" "}
                              <div>
                                {loadData.itemDetails["Total Ceramic"] || 0}
                              </div>
                            </div>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Total Diesel:</div>{" "}
                              <div>
                                {loadData.itemDetails["Total Diesels"] || 0}
                              </div>
                            </div>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Total Wires:</div>{" "}
                              <div>
                                {loadData.itemDetails["Total Wires"] || 0}
                              </div>
                            </div>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Domestic Wires(lb):</div>{" "}
                              <div>
                                {loadData.itemDetails["Domestic Wire (lb)"] ||
                                  0}
                              </div>
                            </div>
                            <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                              <div>Foreign Wires(lb):</div>{" "}
                              <div>
                                {loadData.itemDetails["Import Wire (lb)"] || 0}
                              </div>
                            </div>
                          </>
                        ) : null}
                        {canEditInvoice && edit.resendInvocieSection && (
                          <div
                            className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mt-24 bg-green`}
                            onClick={() => {
                              if (resendIsLoading) {
                                return;
                              }
                              setIsResendInvoice(true);
                              resendInvoice(true);
                            }}
                          >
                            <span>
                              {resendIsLoading
                                ? "Sending..."
                                : "RESEND INVOICE"}
                            </span>
                          </div>
                        )}
                      </>
                    ) : null}
                  </div>
                  <div className="flex flex-column wt-30p">
                    {displayCart ||
                    (!isSeller &&
                      (loadData.type === 3 ||
                        (loadData.type === 4 &&
                          canEditInvoice &&
                          edit.paymentStatusSection))) ? (
                      <>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          <div>Total Aftermarket:</div>{" "}
                          <div>
                            {loadData.itemDetails["Total Aftermarket"] || 0}
                          </div>
                        </div>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          <div>Test Units:</div>{" "}
                          <div>{loadData.itemDetails["Test Units"] || 0}</div>
                        </div>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          <div>Pulled Units:</div>{" "}
                          <div>{loadData.itemDetails["Pulled Units"] || 0}</div>
                        </div>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          <div>Misc. Item Count:</div>{" "}
                          <div>
                            {loadData.itemDetails["Misc. Item Count"] || 0}
                          </div>
                        </div>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          <div>Misc. Item Qty:</div>{" "}
                          <div>
                            {loadData.itemDetails["Misc. Items Qty"] || 0}
                          </div>
                        </div>
                        <div className="flex flex-between fs-12 fw-bolder wt-100p mt-4 pl-16 pr-16 bg-lt-gray-2 pt-12 pb-12">
                          {loginDetail.canSeeSubtotal ? (
                            <>
                              <div>Subtotal</div>{" "}
                              <div>
                                {" "}
                                <CurrencyFormat
                                  value={loadData.subtotal}
                                  displayType={"text"}
                                  thousandSeparator={true}
                                  prefix={"$"}
                                />{" "}
                              </div>
                            </>
                          ) : null}
                        </div>
                        {!isSeller &&
                          (loadData.type !== 4 ||
                            (canEditInvoice && edit.paymentStatusSection)) && (
                            <div
                              className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mt-24 bg-green`}
                              onClick={() => {
                                // getPaymentModes();
                                setPaymentStatus(true);
                              }}
                            >
                              <span> SET PAYMENT STATUS </span>
                            </div>
                          )}
                      </>
                    ) : null}
                  </div>
                </>
              ) : loadData.type !== 3 && loadData.type !== 4 && loginDetail.hierarchy !== 10 && !isFranchiseLogin()  ? (
                <div className="wt-66p bg-lt-gray-2 flex flex-column pl-12 pr-12 pt-24 pb-24 mr-12">
                  <div className="c-black fw-bolder mb-8">
                    SIGNATURE AND DATE
                  </div>
                  <div className="fw-bolder fs-14 pb-24">
                    I am the rightfull owner of, or entitled to sell the
                    regulated metal property being sold:
                  </div>
                  <div className="fw-bolder fs-14 pb-24">
                    <div className="flex flex-between sellerCanvas">
                      <div>
                        {/* {signatureImageUrl}#####signatureImageUrl
                        {signature}####signature */}
                        {isSignLoading && (
                          <div className="ht-80 p-relative">
                            <ActionLoader />
                          </div>
                        )}
                        {!isSignLoading && !signatureImageUrl && !signature && (
                          <SignatureCanvas
                            backgroundColor="white"
                            penColor="black"
                            canvasProps={{
                              width: 480,
                              height: 250,
                              className: "sigCanvas",
                            }}
                            ref={(ref) => {
                              sigPad = ref;
                            }}
                          />
                        )}

                        {/* <SignatureCanvas backgroundColor="white" penColor='black' canvasProps={{width: 480, height: 250, className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }} /> */}
                        <div className="flex flex-between">
                          {(signature || signatureImageUrl) && (
                            <img
                              src={signature || signatureImageUrl}
                              alt="signature"
                            />
                          )}
                          {/* <img src={signatureImageUrl} alt="signature" /> */}
                        </div>
                        {!(loadData.sellerFinalisedBy && isSeller) && (
                          <div className="flex flex-between mt-12">
                            {/* <Button type="modalSave" className="flex flex-middle mr-20" onClick={clearSign}>Clear</Button> */}
                            {/* <Button type="modalSave" className="flex flex-middle mr-0" onClick={saveSign}>Save</Button> */}
                            {(signature || signatureImageUrl) && (
                              <Button
                                type="modalSave"
                                className="flex flex-middle mr-20"
                                onClick={editSign}
                              >
                                Edit
                              </Button>
                            )}
                            {!signatureImageUrl && !signature && (
                              <Button
                                type="modalSave"
                                className="flex flex-middle mr-20"
                                onClick={clearSign}
                              >
                                Clear
                              </Button>
                            )}
                            {!signatureImageUrl && !signature && (
                              <Button
                                type="modalSave"
                                className="flex flex-middle mr-0"
                                onClick={saveSign}
                              >
                                Save
                              </Button>
                            )}
                          </div>
                        )}
                      </div>
                      <div className="fw-bolder fs-14 pr-12 sign-date">
                        <input
                          value={date || ""}
                          onChange={(e) => {
                            if (
                              e.target.value !== "/" &&
                              isNaN(e.target.value)
                            ) {
                              return;
                            }
                            setDate(e.target.value);
                          }}
                          className="ml-12 wt-100p ht-65  bg-white fs-10 fw-bolder pl-12 fs-24 "
                          placeholder="Date"
                        />
                      </div>
                    </div>
                    {/* <input value={signature} onChange={(e)=>{setSignature(e.target.value)}} className="wt-60p ht-65 bg-white  fs-10 fw-bolder pl-12 fs-24 " placeholder="Signature of individual "/> */}
                    <div></div>
                  </div>
                </div>
              ) : (
                <div></div>
              )}
              <div className="flex flex-column flex-between wt-30p invoice-bottom-panel-col">
                <div
                  className={`flex flex-between wt-100p ${
                    !displayCart ? "flex-column" : ""
                  }`}
                >
                  {(!displayCart || (window.location.pathname.includes('/my-cart'))) && (
                    <>
                      <div className="flex flex-between wt-100p">
                        <div className="fs-16 fw-bolder pl-12">
                          Total Units:
                        </div>
                        <div className="fs-16 fw-bolder pr-12">
                          {" "}
                          {loadData.itemDetails["Total Units"] || 0}{" "}
                        </div>
                      </div>
                      {loginDetail.canSeeSubtotal && (!window.location.pathname.includes('/my-cart')) && (
                        <div className="flex flex-between wt-100p">
                          <div className="fs-16 fw-bolder pl-12">Subtotal</div>
                          <div className="fs-16 fw-bolder pr-12">
                            {" "}
                            <CurrencyFormat
                              value={loadData.subtotal || 0}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />{" "}
                          </div>
                        </div>
                      )}
                    </>
                  )}
                  {displayCart && (!window.location.pathname.includes('/my-cart')) && (
                    <>
                      <div></div>{" "}
                      <div className="fs-20 fw-bolder c-gray">
                        INVOICE {loadData.invoiceNo}
                      </div>
                    </>
                  )}
                </div>
                {!isSeller && (!window.location.pathname.includes('/my-cart')) &&
                  displayCart &&
                  (loginDetail.canAddMarkup || canAddMarkDownOnly) && (
                    <div className="flex mb-12 mt-20 flex-between">
                      <div className="c-black fw-bolder fs-14 flex flex-between mb-4 flex-middle">
                        <span>MARKUP</span>
                        <div className="flex flex-middle">
                          <span className="c-gray mr-4 ml-12">$</span>
                          <Toggle
                            className="bg-theme hide-check-cross"
                            onChange={(e) => {
                              setDiscountIsPercentage(e.target.checked);
                            }}
                            checked={discountIsPercentage}
                          />
                          <span className="c-gray ml-4">%</span>
                        </div>
                      </div>
                      <FormInput
                        style={{ paddingRight: "10px" }}
                        inputClassName="flex flex-middle ht-35 t-right fs-20 pr-16 bg-blue rounded-pill"
                        className="hideSpinner wt-80"
                        placeholderType="red"
                        placeholder=""
                        value={discountVal || ""}
                        onChange={(e) => {
                          if (e.target.value !== "-" && isNaN(e.target.value)) {
                            return;
                          }
                          if (canAddMarkDownOnly && e.target.value > 0) {
                            e.target.value = `-${e.target.value}`;
                          }
                          setDiscountVal(e.target.value);
                        }}
                      />
                      <div
                        className="flex flex-middle flex-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer ht-35"
                        onClick={(e) => {
                          updateLoad(e, true);
                        }}
                      >
                        <span>SAVE </span>
                      </div>
                    </div>
                  )}
                <div
                  className={`flex flex-middle flex-between t-right fs-20 mb-12 pl-16 pr-16 bg-white fw-bolder ${
                    !displayCart ? "mt-16 pt-12 pb-12 ht-60" : "ht-40"
                  }`}
                >
                  <div className={!displayCart ? "c-black" : "fs-16"}>
                    {" "}
                    Total
                  </div>
                  <div className={!displayCart ? "fs-28" : ""}>
                    <CurrencyFormat
                      value={loadData.grandtotal}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                    />
                  </div>
                </div>
                {(!displayCart || window.location.pathname.includes('/my-cart')) &&
                  (!(
                    isSeller &&
                    (loadData.type === 3 ||
                      (loadData.type === 4 && canEditInvoice))
                  ) && (loginDetail.hierarchy !== 10)  &&
                  (loadData.type !== 4 || canEditInvoice) ? (
                    <div>
                      {pdfIsLoading && (
                        <div className="ht-80 p-relative">
                          {" "}
                          <ActionLoader />{" "}
                        </div>
                      )}
                      <div
                        className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder 
                        ${
                          !paymentModesLoaded 
                            ? backState && backState.includes('/active-load')?"": "bg-op5-green c-not-allowed"
                            : ""
                        }
                        ${
                          loadData.type === 2 || loadData.type === 4
                            ? "bg-op5-green c-not-allowed"
                            : "bg-green"
                        } 
                        ${
                          loadData.sellerFinalisedBy && isSeller
                            ? "c-not-allowed bg-op5-green"
                            : "c-pointer"
                        }
                         `}
                        onClick={
                          (loadData.sellerFinalisedBy && isSeller) ||
                          loadData.type === 4
                            ? null
                            : () => {
                                // setPdfIsLoading(true);
                                // setShowInvoicePdf(true);
                                // uploadPDFFIleBeforeCompletion();
                                handleCompleteOrder();
                              }
                        }
                      >
                        <span> FINISH ORDER </span>
                      </div>

                      {/* {paymentModesLoaded &&
                        ((showInvoicePdf && canEditInvoice) ||
                          loadData.type !== 4) && (
                          <div
                            style={{
                              // visibility: 'hidden',
                              paddingLeft: "100px",
                              marginLeft: "500px",
                            }}
                          >
                            <InvoicePDF
                              itemData={loadData}
                              paymentModes={paymentModes}
                              isPDFForMail={true}
                              setPrintPopup={true}
                              isLoadedForLink={true} //true
                              isHandleClicked={showInvoicePdf}
                              sendPdfFileToLoads={(file) => {
                                setPdfFile(file);
                              }}
                              uploadPDFFIleBeforeCompletion={
                                showInvoicePdf
                                  ? uploadPDFFIleBeforeCompletion
                                  : null
                              }
                            />
                          </div>
                        )} */}
                    </div>
                  ) : (
                    <div
                      className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mt-24 bg-green`}
                      onClick={() => {
                        // getPaymentModes();
                        setPaymentStatus(true);
                      }}
                    >
                      <span> VIEW PAYMENT STATUS </span>
                    </div>
                  ))}

                {displayCart && (!window.location.pathname.includes('/my-cart')) &&
                  ((loadData.type !== 4 || canEditInvoice) &&
                  !(
                    isSeller &&
                    (loadData.type === 3 ||
                      (loadData.type === 4 && canEditInvoice))
                  ) ? (
                    <div>
                      <div
                        className={`btn ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer 
                      ${isLoading ? "bg-op5-green c-not-allowed" : ""}
                      ${loadData.type === 2 ? "bg-op5-green" : "bg-green"} 
                      ${
                        loadData.buyerFinalisedDate
                          ? "c-not-allowed bg-op5-green"
                          : "c-pointer"
                      } `}
                        onClick={
                          loadData.buyerFinalisedDate
                            ? null
                            : () => {
                                if (isCompleteOrderLoading) {
                                  return;
                                }
                                // handleCompleteOrder();
                                setPaymentModesLoaded(true);
                                uploadPDFFIleBeforeCompletion();
                                // setShowInvoicePdf(true);
                              }
                        }
                      >
                        <span>
                          {isCompleteOrderLoading
                            ? "Loading..."
                            : "COMPLETE ORDER"}
                        </span>
                      </div>
                      {/* {paymentModesLoaded &&
                        ((showInvoicePdf && canEditInvoice) ||
                          loadData.type !== 4) && (
                          <div
                            style={{
                              // visibility: 'hidden',
                              paddingLeft: "100px",
                              marginLeft: "500px",
                            }}
                          >
                            <InvoicePDF
                              itemData={loadData}
                              paymentModes={paymentModes}
                              isPDFForMail={true}
                              setPrintPopup={true}
                              isLoadedForLink={true} //true
                              isHandleClicked={showInvoicePdf}
                              sendPdfFileToLoads={(file) => {
                                setPdfFile(file);
                              }}
                              uploadPDFFIleBeforeCompletion={
                                showInvoicePdf
                                  ? uploadPDFFIleBeforeCompletion
                                  : null
                              }
                            />
                          </div>
                        )} */}
                    </div>
                  ) : (
                    <div
                      className={`btn  ht-40 flex flex-middle flex-center c-white pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mt-24 bg-green`}
                      onClick={() => {
                        // getPaymentModes();
                        setPaymentStatus(true);
                      }}
                    >
                      <span> VIEW PAYMENT STATUS </span>
                    </div>
                  ))}
              </div>
            </div>
            {completeOrderErrorMsg && (
              <div className="c-red">{completeOrderErrorMsg}</div>
            )}
            {loadData.signature && (
              <div className="mt-10 fs-12">
                <span> Signature </span>
                <div>
                  <img src={loadData.signature} className="wt-300 op-5" />
                </div>
              </div>
            )}
            {loadData.buyerFinalisedDate && (
              <div className="mt-10 fs-12">
                <span className="c-gray pr-4">Buyer Finalised on:</span>
                {moment(loadData.buyerFinalisedDate).format(
                  "Do MMM, YYYY h:mm a"
                )}
              </div>
            )}
            {loadData.sellerFinalisedDate && (
              <div className="mt-10 fs-12">
                <span className="c-gray pr-4">Seller Finalised on:</span>
                {moment(loadData.sellerFinalisedDate).format(
                  "Do MMM, YYYY h:mm a"
                )}
              </div>
            )}
            {loadData.invoiceSettledDate && (
              <div className="mt-10 fs-12">
                <span className="c-gray pr-4">Invoice Settled on:</span>
                {moment(loadData.invoiceSettledDate).format(
                  "Do MMM, YYYY h:mm a"
                )}
              </div>
            )}
          </div>

          {displayCart && (!window.location.pathname.includes('/my-cart')) &&
          (loadData.type !== 4 || canEditInvoice) &&
          !(
            isSeller &&
            (loadData.type === 3 || (loadData.type === 4 && canEditInvoice))
          ) ? (
            <div
              style={{
                boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",

                padding: "20px",
                borderRadius: "20px",
                margin: "10px",
              }}
              className="wt-100p bg-lt-gray-2 flex flex-column pl-12 pr-12 pt-24 pb-24"
            >
              <div className="c-black fw-bolder mb-8">SIGNATURE AND DATE</div>

              <div className="fw-bolder fs-14 pb-24">
                <div className="flex flex-between">
                  <div>
                    {/* {signatureImageUrl}#####signatureImageUrl
                    {signature}####signature */}
                    {isSignLoading && (
                      <div className="ht-80 p-relative">
                        <ActionLoader />
                      </div>
                    )}
                    {!isSignLoading && !signatureImageUrl && !signature && (
                      <SignatureCanvas
                        backgroundColor="white"
                        penColor="black"
                        canvasProps={{
                          width: 800,
                          height: 350,
                          className: "sigCanvas",
                        }}
                        ref={(ref) => {
                          sigPad = ref;
                        }}
                      />
                    )}

                    {/* <SignatureCanvas backgroundColor="white" penColor='black' canvasProps={{width: 400, height: 250, className: 'sigCanvas'}} ref={(ref) => { sigPad = ref }} /> */}
                    <div className="flex flex-between">
                      {(signature || signatureImageUrl) && (
                        <img
                          src={signature || signatureImageUrl}
                          alt="signature"
                        />
                      )}
                      {/* <img src={signatureImageUrl} alt="signature" /> */}
                    </div>
                    {!(loadData.sellerFinalisedBy && isSeller) && (
                      <div className="flex flex-between mt-12">
                        {/* <Button type="modalSave" className="flex flex-middle mr-20" onClick={clearSign}>Clear</Button> */}
                        {/* <Button type="modalSave" className="flex flex-middle mr-0" onClick={saveSign}>Save</Button> */}
                        {(signature || signatureImageUrl) && (
                          <Button
                            type="modalSave"
                            className="flex flex-middle mr-20"
                            onClick={editSign}
                          >
                            Edit
                          </Button>
                        )}
                        {!signatureImageUrl && !signature && (
                          <Button
                            type="modalSave"
                            className="btn  ht-40 btn-red flex flex-middle mr-20"
                            onClick={clearSign}
                          >
                            Clear
                          </Button>
                        )}
                        {!signatureImageUrl && !signature && (
                          <Button
                            type="modalSave"
                            className="btn  ht-40 flex flex-middle mr-0"
                            onClick={saveSign}
                          >
                            Save
                          </Button>
                        )}
                      </div>
                    )}
                  </div>
                  <div className="fw-bolder fs-14 pr-12 sign-date">
                    <input
                      value={date}
                      onChange={(e) => {
                        console.log("e.target.value", e.target.value);
                        if (
                          (e.target.value !== "/" &&
                            isNaN(
                              (e.target.value || "").split("/").join("")
                            )) ||
                          e.target.value.length > 10
                        ) {
                          return;
                        }
                        setDate(e.target.value);
                      }}
                      className="ml-12 wt-100p ht-65  bg-white fs-10 fw-bolder pl-12 fs-24 "
                      placeholder="Date"
                    />
                  </div>
                </div>
                {/* <input value={signature} onChange={(e)=>{setSignature(e.target.value)}} className="wt-60p ht-65 bg-white  fs-10 fw-bolder pl-12 fs-24 " placeholder="Signature of individual "/> */}
                <div></div>
              </div>
            </div>
          ) : (
            <div></div>
          )}
        </>
      )}

      {/* {isResendInvoice ? (
        <InvoicePDF
          itemData={loadData}
          paymentModes={paymentModes}
          isPDFForMail={true}
          setPrintPopup={true}
          isLoadedForLink={true} //true
          isHandleClicked={showInvoicePdf}
          sendPdfFileToLoads={(file) => {
            confirmResend(file);
          }}
          uploadPDFFIleBeforeCompletion={null}
        />
      ) : null} */}

      {(paymentModes || []).length > 0 && (
        <div>
          <h1 className="mr-24 fs-20 c-gray">PAYMENT SPLIT</h1>
          <Table
            scrollTable={true}
            columns={[
              "Payment Method",
              "Reference Number",
              "Amount",
              "Payment Date",
              "Created Date",
            ].filter((a) => a)}
            items={paymentModes.map((item) => {
              return {
                ...item,
                payForwardDate: loadData.payForwardDate
                  ? moment(loadData.payForwardDate).format("MM-DD-YYYY")
                  : "-",
                formatAmt: ReactDOMServer.renderToStaticMarkup(
                  <CurrencyFormat
                    value={item.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(val) => val}
                  />
                ),
                date: (() => {
                  const dateObj = new Date(item.createdDate);
                  const year = dateObj.getFullYear();
                  const month = `0${dateObj.getMonth() + 1}`.slice(-2);
                  const date = dateObj.getDate();

                  let hours = dateObj.getHours();
                  let minutes = dateObj.getMinutes();
                  const ampm = hours >= 12 ? "pm" : "am";
                  hours = hours % 12;
                  hours = hours ? hours : 12;
                  minutes = minutes < 10 ? "0" + minutes : minutes;

                  return `${month}-${date}-${year} ${hours}:${minutes}${ampm}`;
                })(),
              };
            })}
            keys={[
              "paymentMethod",
              "referenceNumber",
              "formatAmt",
              "payForwardDate",
              "date",
            ].filter((a) => a)}
            widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]}
            // actions={["EDIT", "DELETE"]}
            isLoading={isLoading}
            hideCheckBox={true}
          />
        </div>
      )}

      {paymentStatus && (
        <Modal>
          <div className="flex flex-middle">
            <div className="flex flex-middle mr-12 wt-20p">
              <h1 className="mr-24 fs-20 c-gray">PAYMENT STATUS</h1>
            </div>
            <div
              className="flex flex-middle flex-right wt-80p"
              style={{ justifyContent: "space-between" }}
            >
              <div className="flex">
                <div className="mr-24 fs-20">
                  <span className="c-gray">Grand Total:</span>{" "}
                  <CurrencyFormat
                    value={loadData.grandtotal}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                </div>
                <div className="mr-24 fs-20">
                  <span className="c-gray">Paid:</span>{" "}
                  <CurrencyFormat
                    value={amountReceived}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                </div>
                <div className="mr-24 fs-20">
                  <span className="c-gray">Balance:</span>{" "}
                  <CurrencyFormat
                    value={loadData.grandtotal - amountReceived}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />{" "}
                </div>
              </div>

              <div className="flex">
                {isChangePayForwardDate ? (
                  <>
                    <div className="mr-20">
                      <div
                        className="c-gray fs-12"
                        style={{ marginBottom: 10 }}
                      >
                        {" "}
                        Payment Date{" "}
                      </div>
                      <DatePicker
                        minDate={new Date()}
                        selected={payForwardDate || startDate}
                        onChange={(date) =>
                          setPayForwardDate(new Date(date).setHours(0, 0, 0, 0))
                        }
                        selectsStart
                        className="form-control"
                      />
                    </div>
                    <div className="mr-20 flex" style={{ marginTop: 25 }}>
                      <Button
                        type="modalSave"
                        className="flex flex-middle mr-20"
                        onClick={updateLoad}
                      >
                        {isSaveLoading ? "Loading..." : "SAVE"}
                      </Button>
                      <Button
                        type="modalCancel"
                        className="flex flex-middle bg-red"
                        onClick={() => setIsChangePayForwardDate(false)}
                      >
                        CANCEL
                      </Button>
                    </div>
                  </>
                ) : (
                  <>
                    <div className="mr-20">
                      <div
                        className="c-gray fs-12"
                        style={{ marginBottom: 10 }}
                      >
                        {" "}
                        Selected Payment Date:{" "}
                      </div>
                      {moment(payForwardDate).format("MM/DD/YYYY")}
                    </div>
                    <div className="mr-20" style={{ marginTop: 25 }}>
                      <img
                        src={Pencil}
                        onClick={() => setIsChangePayForwardDate(true)}
                        className="ht-20 wt-20 mr-12"
                      />
                    </div>
                  </>
                )}

                <div className="ml-24 fs-20">
                  {/* <span
                  className="c-black c-pointer fw-bolder"
                  onClick={() => {
                    setPaymentStatus(false);
                  }}
                >
                  {" "}
                  X{" "}
                </span> */}
                  <Button
                    type="modalCancel"
                    className="flex flex-middle bg-red"
                    onClick={() => {
                      setPaymentStatus(false);
                      setIsChangePayForwardDate(false);
                    }}
                  >
                    CLOSE
                  </Button>
                </div>
              </div>
            </div>
          </div>
          {!(
            isSeller &&
            (loadData.type === 3 || (loadData.type === 4 && canEditInvoice))
          ) &&
            (loadData.type !== 4 || canEditInvoice) && (
              <div className="flex flex-middle flex-center">
                <div className="wt-33p mr-20">
                  <div className="c-gray fs-14 mb-4">Payment Method</div>
                  <select
                    name="qty"
                    className="select font-primary fs-14 fw-bolder bg-blue rounded-pill"
                    value={paymentObj.paymentMethod}
                    onChange={(e) => {
                      setPaymentObj({
                        ...paymentObj,
                        paymentMethod: e.target.value,
                      });
                    }}
                  >
                    <option value={""}>Select Payment</option>
                    {["CHECK", "CASH", "Bank Transfer"].map((pay) => {
                      return <option value={pay}>{pay}</option>;
                    })}
                  </select>
                </div>
                <FormInput
                  placeholder="Reference No"
                  className="mr-20 wt-33p bg-white border-bottom-black"
                  value={paymentObj.referenceNumber}
                  onChange={(e) => {
                    setPaymentObj({
                      ...paymentObj,
                      referenceNumber: e.target.value,
                    });
                  }}
                />
                <FormInput
                  placeholder="Amount"
                  type="number"
                  className="mr-20 wt-33p bg-white border-bottom-black hideInputInc"
                  value={paymentObj.amount}
                  onChange={(e) => {
                    setPaymentObj({ ...paymentObj, amount: e.target.value });
                  }}
                />
                <div
                  style={{ marginTop: 20 }}
                  className="flex flex-middle flex-right"
                >
                  <Button
                    type="modalSave"
                    className="flex flex-middle mr-20"
                    onClick={addPaymentStatus}
                  >
                    {isLoading ? "Loading..." : "SAVE"}
                  </Button>
                  <Button
                    type="modalCancel"
                    className="flex flex-middle bg-red"
                    onClick={() => {
                      setPaymentObj({
                        referenceNumber: "",
                        amount: "",
                        paymentMethod: "",
                        loadId: props.match.params.loadId,
                      });
                    }}
                  >
                    CANCEL
                  </Button>
                </div>
              </div>
            )}
          <Table
            scrollTable={true}
            columns={[
              "Payment Method",
              "Reference Number",
              "Amount",
              "Created Date",
            ].filter((a) => a)}
            items={paymentModes.map((item) => {
              return {
                ...item,
                formatAmt: (
                  <CurrencyFormat
                    value={item.amount}
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                  />
                ),
                date: (() => {
                  const dateObj = new Date(item.createdDate);
                  const year = dateObj.getFullYear();
                  const month = `0${dateObj.getMonth() + 1}`.slice(-2);
                  const date = dateObj.getDate();

                  let hours = dateObj.getHours();
                  let minutes = dateObj.getMinutes();
                  const ampm = hours >= 12 ? "pm" : "am";
                  hours = hours % 12;
                  hours = hours ? hours : 12;
                  minutes = minutes < 10 ? "0" + minutes : minutes;

                  return `${month}-${date}-${year} ${hours}:${minutes}${ampm}`;
                })(),
              };
            })}
            keys={[
              "paymentMethod",
              "referenceNumber",
              "formatAmt",
              "date",
            ].filter((a) => a)}
            widthClasses={["flex-1", "flex-1", "flex-1", "flex-1"]}
            actions={
              loadData.type !== 4 || (loadData.type === 4 && canEditInvoice)
                ? ["DELETE"]
                : []
            }
            isLoading={isLoading}
            hideCheckBox={true}
            onDelete={handlePaymentItemDelete}
          />
          {/*<div className="flex flex-middle flex-right">
            <Button className="flex flex-middle" onClick={() => {setPaymentStatus(false)}}>Close</Button>
          </div>*/}
        </Modal>
      )}

      {Object.keys(selectedRow).length > 0 && (
        <div className="ht-60 bg-lt-gray-2 p-fixed wt-100vh l-0 r-0 b-0 t-right pr-20 z-50 pt-12">
          <div className="flex flex-center flex-middle">
            <div className="flex flex-between flex-center">
              <div
                className="bg-green ht-35 pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mr-20"
                onClick={(e) => {
                  const data = {
                    status: 1,
                    loadId: selectedRow.loadId,
                    itemId: selectedRow.itemId,
                    userId: selectedRow.userId,
                  };
                  handleEditItem(data);
                }}
              >
                <img alt="" src={Tick} className="wt-20 ht-15 mr-15" />
                <span className="mr-15"> Approve </span>
              </div>
              <div
                className="bg-red ht-35 pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mr-20"
                onClick={(e) => {
                  const data = {
                    status: 2,
                    loadId: selectedRow.loadId,
                    itemId: selectedRow.itemId,
                    userId: selectedRow.userId,
                  };
                  handleEditItem(data);
                }}
              >
                <img alt="" src={Cross} className="wt-20 ht-15 mr-15" />
                <span className="mr-15"> Reject </span>
              </div>
              <div
                className="bg-gray ht-35 pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mr-20"
                onClick={(e) => {
                  setSelectedItem(selectedRow);
                  setItemToedit(selectedRow);
                  setItemToModify(selectedRow);
                  setItemImages(selectedRow.loadUrls);
                  setImage(selectedRow.loadUrls);
                  setItemParams({
                    tools: "",
                    // oem: selectedRow.oem,
                    qty: selectedRow.quantity,
                    unit: selectedRow.unit,
                    type: selectedRow.type,
                    finalQty: selectedRow.finalQty || selectedRow.quantity,
                    finalUnit: selectedRow.finalUnit || selectedRow.unit,
                    box: selectedRow.box,
                    adjusted: selectedRow.adjustedPrice,
                  });

                  window.scrollTo({
                    behavior: "smooth",
                    top: addItemRef.current.offsetTop - 55,
                  });
                }}
              >
                <img alt="" src={Pencil} className="wt-20 ht-15 mr-15" />
                <span className="mr-15"> Modify </span>
              </div>
            </div>
          </div>
        </div>
      )}
      <div ref={scrollToEnd} />

      <img
        style={{
          position: "fixed",
          bottom: 40,
          right: 200,
        }}
        src={DownArrow}
        className="wt-30 ht-30"
        onClick={() => {
          scrollToEnd.current.scrollIntoView({ behavior: "smooth" });
        }}
      />
      <img
        style={{
          position: "fixed",
          bottom: 40,
          right: 250,
        }}
        src={UpArrow}
        className="wt-30 ht-30"
        onClick={() => {
          scrollToTop.current.scrollIntoView({ behavior: "smooth" });
        }}
      />

      <img
        onClick={() => {
          setIsVideoCallShown(!isVideoCallShown);
        }}
        src={VideoCallIcon}
        className="wt-60 ht-60"
        style={{
          position: "fixed",
          bottom: 25,
          right: 100,
        }}
      />
      {isVideoCallShown && (
        <Call
          channel={props.match.params.loadId}
          onClose={() => {
            setIsVideoCallShown(false);
          }}
        />
      )}
      <Chat channel={props.match.params.loadId} />
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {showActionBtn && showActionsButton()}
      {previewImage && (
        <Modal>
          <div className="pl-16 pr-16">
            <div className="fs-18 fw-bolder t-center mb-20">
              <img
                style={{ width: 250, height: 225 }}
                src={previewImage}
                className="mr-12"
              />
            </div>
            <div className="flex flex-middle flex-center">
              <Button type="modalSave" onClick={() => setPreviewImage("")}>
                Close
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </PageLayout>
  );
};

export default withRouter(LoadsCart);
