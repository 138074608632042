import React, { useState } from 'react'
import { Link } from 'react-router-dom'

import  Toggle from 'react-toggle'
import FormInput from '../FormInput';
import IconPricingInactive from '../../icons/rev_pricing.png'
import BackArrowIcon from '../../icons/rev_carat.png'
import EntityDetailItem from './EntityDetailItem';

import { getLoginDetails } from '../../utils/storage';


const EntityDetail = ({ entity, className, editEntity, match }) => {
    const { canShowPriceModifier } = getLoginDetails();
    const [isPercantage, setIsPercantage] = useState(entity.groupModifierIsPercentage)
    const [modifierInputValue, setModifierInputValue] = useState(entity.groupModifier || '')
    const { companyName, companyLogo, companyType, isPartner, address, city, state, zipcode, firstName, lastName } = entity;

    return (
        <div className={`${className || ""}`}>
            <div className="flex">
                <div className="">
                    <Link to={window.location.pathname.includes('/Franchise')?`/entities/Franchise`:match.params && match.params.searchText ? `/entities/viewGroup/${match.params.searchText}` : `/entities/viewGroup/`} 
                        className="btn ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
                    >
                        <img src={BackArrowIcon} className="wt-20 mr-8 rotate-90" />
                        <span>Back</span>
                    </Link>
                    {/*<div className="decoration-none ml-8 ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-gray c-white border-gray">
                        <img src={IconPricingInactive} className="wt-30 mr-8" />
                        <span>PRICING</span>
                    </div>*/}
                </div>
                <div className="flex flex-middle t-center">
                    {/*<img src={companyLogo} className="wt-200" />*/}
                    <p className="fs-24 fw-bolder pl-32">{companyName}</p>
                </div>
                <div className="wt-100" />
            </div>
            {/*<div className="flex flex-between mr-80 mt-20 mb-48">
                <div className="">
                    <div className="c-table-header fs-12 fw-bolder flex-1 mb-4">ADDRESS</div>
                    <p className="lh-16 mt-4 mb-4 t-initial">{`${entity.address}`} </p>
                    <p className="lh-16 mt-4 mb-4 t-initial">{`${entity.city}`}</p>
                    <p className="lh-16 mt-4 mb-4 t-initial">{`${entity.state}, ${entity.zipcode}`}</p>
                </div>
                <div className="">
                    <div className="c-table-header fs-12 fw-bolder flex-1 mb-4">CONTACT</div>
                    <p className="lh-16 mt-4 mb-4 fw-bolder t-initial">{`${entity.firstName} ${entity.lastName}`} </p>
                    {entity.phone && <p className="lh-16 mt-4 mb-4 t-initial">{`${entity.phone}`}</p>}
                    <p className="lh-16 mt-4 mb-4 t-initial">{`${entity.email}`}</p>
                </div>
                <div className="">
                    <div className="c-table-header fs-12 fw-bolder flex-1 mb-4">TYPE</div>
                    <p className="lh-16 mt-4 mb-4 fw-bolder t-initial">{`${entity.companyType}`} </p>
                </div>
            </div>*/}
            {false && canShowPriceModifier && <div className="flex flex-middle mt-20">
                <p className="c-table-header fs-16 fw-bolder">PRICE MODIFIER</p>
                <div className="flex flex-middle ml-24 mr-16">
                    <span className="c-gray mr-4">%</span>
                    <Toggle className="bg-theme hide-check-cross"
                        onChange={(e)=> {
                            e.target.checked ? setIsPercantage(false) : setIsPercantage(true)
                        }}
                        checked={!isPercantage}
                    />
                    <span className="c-gray ml-4">$</span>
                </div>
                <FormInput inputClassName="flex flex-middle ht-40 t-right fs-20 fw-bolder pr-16" className="hideSpinner wt-150" type="number" step="0.01" value={modifierInputValue} onChange={(e)=> { setModifierInputValue(e.target.value) }} />
                {/* <div className="flex flex-middle ht-40 fs-20 wt-30 fw-bolder bg-white">{isPercantage ? "%" : "$"}</div> */}
                <div className="ml-8 ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-gray c-white border-gray" onClick={() => {
                    editEntity({
                        ...entity,
                        groupModifierIsPercentage: isPercantage,
                        groupModifier: modifierInputValue
                    })
                }}>
                    <span>SAVE CHANGE</span>
                </div>
            </div>}
            {/* <div className="flex flex-wrap   pl-16 pr-16 pt-16 ">
                {/* <div className="wt-25p">
                    <div className="c-table-header fs-12 fw-bolder flex-1 mb-4">Company Name</div>
                    <div className="fw-bolder fs-14">{companyName}</div>
                </div>  
                <EntityDetailItem label="Company Name" value={companyName}/>
                <EntityDetailItem label="Company type" value={companyType}/>
                <EntityDetailItem label="Is Partner" value={isPartner?"YES":"NO"}/>
                <EntityDetailItem label="Address" value={`${address || ''} ${city} ${state} ${zipcode}`}/>
                <EntityDetailItem label="First name" value={firstName}/>
                <EntityDetailItem label="Last name" value={lastName}/>
                <EntityDetailItem label="Company Logo">
                    <img src={companyLogo} className="wt-50" />
                </EntityDetailItem>
                {/* <EntityDetailItem label="Company Name" value={companyName}/>
                <EntityDetailItem label="Company Name" value={companyName}/>
                <EntityDetailItem label="Company Name" value={companyName}/>
                <EntityDetailItem label="Company Name" value={companyName}/> 
            </div> */}
        </div>
    )
}

export default EntityDetail
