import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";

import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Toast from "../../components/Toast";

import RolesAddEdit from "../../components/AddEditItems/RolesAddEdit";

import IconAdd from "../../icons/rev_permissions.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/key-fill.svg";

import { getLoginDetails } from "../../utils/storage";

import IconUsersInactive from "../../icons/pos_user.png";
import IconGroupsActive from "../../icons/rev_permissions.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_ROLES_URL,
  GET_PERMISSIONS_URL,
  ADD_ROLES_URL,
  EDIT_ROLES_URL,
  EDIT_PERMISSIONS_URL,
  DELETE_ROLES_URL,
  postData,
  getData,
  putData,
  deleteData,
} from "../../utils/api";

const roleModel = {
  name: "",
  permissions: [],
  roleId: undefined,
};

export const Permissions = () => {
  const [permissionItems, setPermissionItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const { isSA } = getLoginDetails();
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getPermissions = async () => {
    try {
      const data = await getData(GET_PERMISSIONS_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        companyId: getLoginDetails().companyId,
      });
      let prevType = '';
      const permData = data.list.sort((a, b) => (a.type > b.type) ? 1 : -1).map((permission, index) => {
        let showBorder = false;
        if(!index) {
          prevType = permission.type;
        }

        if(prevType !== permission.type) {
          showBorder = true;
          prevType = permission.type;
        }

        return {
          ...permission,
          showTopBorder: showBorder,
        }
      });
      setPermissionItems(permData);
      setIsLoading(false);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);

  useEffect(() => {
    getPermissions();
  }, [params.offset, params.text, params.limit]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  // const addItem = async (data)=> {
  //     try {
  //         const response = await postData(ADD_ROLES_URL, {...data, companyId: getLoginDetails().companyId});
  //         setIsAddPopupShown(false);
  //         setToastParams({
  //             type: 'SUCCESS',
  //             message: 'Successfully added the Role',
  //             id: new Date().getTime()
  //         })
  //         getRolesAndPermissions();
  //     } catch(e) {
  //         console.log("ERROR");
  //         setToastParams({
  //             type: 'ERROR',
  //             e && e.error ? e.error : 'Oops! Something went wrong. Please try again later',
  //             id: new Date().getTime()
  //         })
  //     }
  // }
  const editItem = async (data)=> {
      try {
          const response = await putData(EDIT_PERMISSIONS_URL, {...data, companyId: getLoginDetails().companyId});
          setIsEditPopupShown(false);
          setToastParams({
              type: 'SUCCESS',
              message: 'Successfully edited Price Modifier',
              id: new Date().getTime()
          })
          getPermissions();
      } catch(e) {
          console.log("ERROR");
          const error = e && e.error ? e.error : 'Oops! Something went wrong. Please try again later';
          setToastParams({
              type: 'ERROR',
              error,
              id: new Date().getTime()
          })
      }
  }
  // const deleteItem = async ()=> {
  //     try {
  //         const response = await deleteData(DELETE_ROLES_URL, {roleId: itemForDelete.roleId});
  //         setIsDeletePopupShown(false);
  //         getRolesAndPermissions();
  //     } catch(e) {
  //         console.log("ERROR");
  //     }
  // }
  // const setEdit = (item)=> {
  //     setIsEditPopupShown(true);
  //     setItemForEdit({
  //         roleId: item.roleId,
  //         name: item.name,
  //         permissions: (item.permissions || []).map((permission)=> {
  //             return {
  //                 id: permission.permissionId,
  //                 value: permission.permissionName
  //             }
  //         })
  //     })
  // }
  // const setDelete = (item)=> {
  //     setIsDeletePopupShown(true);
  //     setItemForDelete(item)
  // }

  const onIsActiveChange = (item, isActive) => {
    // logic here
    editItem({
      permissionId: item.permissionId,
      isAccessible: isActive,
    });
  }

  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Permissions"
      searchPlaceholder="Search Permissions"
      hideSearchBar={true}
    >
      {!isAddPopupShown && !isEditPopupShown && (
        <div className="flex flex-middle mt-8 flex-left">
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW ROLES</h1> */}
          </div>
          <div className={`tabs flex`} style={{}}>
            <Link
              to="/config/roles"
              className={`decoration-none ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-lt-gray-2 c-white fw-bolder'`}
              onClick={() => {}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span>Roles</span>
            </Link>
            {isSA && (
              <Link
                to="/config/permissions"
                className={`decoration-none ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-white c-gray  border-gray`}
                onClick={() => {}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="24"
                  fill="currentColor"
                  class="bi bi-unlock-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                </svg>
                <span>Permissions</span>
              </Link>
            )}
          </div>
          {permissionItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + permissionItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          {/* <div
            className="btn  ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
            }}
          >
            <img src={IconAdd} className="ht-30 wt-30 mr-12" />
            <span>ADD PERMISSION</span>
          </div> */}
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && (
        <Table
          scrollTable={true}
          type="entity"
          columns={["Permission", "Type", "Description", "Is Accessible"]}
          // className="wt-600"
          items={permissionItems.map((permission) => {
            return {
              ...permission,
              type: permission.type.replace(/(^\w|\s\w)/g, (m) =>
                m.toUpperCase()
              ),
              description: permission.description || " - ",
              isActive: permission.isAccessible,
            };
          })}
          keys={["permissionName", "type", "description", "isAccessible"]}
          widthClasses={["wt-200", "wt-200", "flex-1", "wt-200" ]}
          // onEdit={setEdit}
          // onDelete={setDelete}
          onChange={onSearchChange}
          onIsActiveChange={onIsActiveChange}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
              areMoreItemsAvailable: false,
            });
          }}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          limit={params.limit}
          isLoading={isLoading}
          headerBgWhite={true}
          hideCheckBox={true}
          switchIds={[3]}
        />
      )}
      {/* {isAddPopupShown &&
                <RolesAddEdit 
                    item={{...roleModel}}
                    permissions={permissionItems}
                    onClose = {setIsAddPopupShown.bind(null, false)}
                    onBack = {setIsAddPopupShown.bind(null, false)}
                    onSave = {addItem}
                    type="add"
                />
            }
            {isEditPopupShown &&
                <RolesAddEdit 
                    item={{...itemForEdit}}
                    permissions={permissionItems}
                    onClose = {setIsEditPopupShown.bind(null, false)}
                    onBack = {setIsEditPopupShown.bind(null, false)}
                    onSave = {editItem}
                    type="edit"
                    selected={itemForEdit.permissions}
                />
            }
            {isDeletePopupShown &&
                <DeleteConfirmation 
                    type="role"
                    name={itemForDelete.name}
                    onClose={setIsDeletePopupShown.bind(null, false)}
                    onConfirm={deleteItem}
                />
            }
            <Toast 
                type={toastParams.type} 
                message={toastParams.message}
                id={toastParams.id}
            /> */}
    </PageLayout>
  );
};

export default Permissions;
