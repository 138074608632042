import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import Modal from "../../components/Modal";
import FormInput from "../../components/FormInput";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import EntityAddEdit from "../../components/AddEditItems/EntityAddEdit";
import EntityCustomerAddEdit from "../../components/AddEditItems/EntityCustomerAddEdit";
import { connect } from 'react-redux';
import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/people-fill.svg";
import CustomerUserIcon from "../../icons/customer_users.svg";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import { getLoginDetails } from "../../utils/storage";

import {
  GET_ENTITIES_URL,
  GET_FACILITIES_URL,
  ADD_ENTITIES_URL,
  EDIT_ENTITIES_URL,
  DELETE_ENTITIES_URL,
  FILE_UPLOAD_URL,
  POST_INVITE_USER_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
  FACILITY_CITY_LIST,
  GET_CUSTOMERS_REPORT_URL,
  MESSAGE_ENTITIES_URL
} from "../../utils/api";
import { SELF_COMPANY_ID } from "../../actions/constants";

const entityModel = {
  companyName: "",
  companyLogo: "",
  companyType: "",
  isPartner: false,
  address: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  firstName: "",
  lastName: "",
  companyId: undefined,
  isActive: false,
  businessLicense: "",
  ein: "",
  metalLicense: "",
  facilities: [],
};

// const entityModel={
//     "companyName": "First Organisation",
//     "companyLogo": "http://a.b",
//     "companyType": "BUYER",
//     "isPartner": true,
//     "address": "company address",
//     "city": "company city",
//     "state": "company state",
//     "zipcode": "560043",
//     "email": "company email",
//     "phone": "company phone",
//     "firstName": "first name",
//     "lastName": "last name"
// }

export const Entities = (props) => {
  const { hierarchy, canEditGroup, canDeleteGroup, isSA, canCreateUser } = getLoginDetails();
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [facilitiesItems, setFaciltiesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [isInvitePopupShown, setIsInvitePopupShown] = useState(false);
  const [isCustomerPopupShown, setIsCustomerPopupShown] = useState(false);
  const [customerPopupItem, setCustomerPopupItem] = useState(false);
  const [customerSort, setCustomerSort] = useState("asc");
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    companyId: "",
    facilityCity: "",
  });
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text:
      props.match && props.match.params && props.match.params.searchText
        ? props.match.params.searchText
        : "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const [isFranchiseCustomers,setIsFranchiseCustomers] = useState(undefined)
  const [selfCompanyId,setselfCompanyId] = useState(undefined)
  const [isFranchiseCustomersClicked, setIsFranchiseCustomersClicked] = useState(false);
  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {}
  };
  const addFranchise = (item) => {
    // console.log(item);
    setIsFranchiseCustomers(true);
    setselfCompanyId(item.companyId);
    setIsFranchiseCustomersClicked(true)
    const { dispatch } = props;
    dispatch({
      type: SELF_COMPANY_ID,
      data:item.companyId
    });
  }

  useEffect(()=>{
    if(props.selfCompanyId){
      setIsFranchiseCustomers(true);
      setselfCompanyId(props.selfCompanyId);
      setIsFranchiseCustomersClicked(true)
    }
  },[])

  useEffect(()=>{
    if(isFranchiseCustomers){
      getEntities()
    }
  },[isFranchiseCustomers])
  const getEntities = async () => {
    console.log("get entries");
    try {
      setIsLoading(true);
      // setEntitiesItems([]);

      const payload = {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
        customerSort,
      };

      if(hierarchy === 1) {
        payload.isFranchise = window.location.pathname.includes('/Franchise')?true:false;
      }
      payload.isFranchiseCustomers = isFranchiseCustomers;
      payload.selfCompanyId = selfCompanyId;

      const data = await getData(GET_ENTITIES_URL, payload);
      setIsLoading(false);
      setIsFranchiseCustomers(undefined);
      setEntitiesItems(data.list);
      setselfCompanyId(undefined);
      if (hierarchy !== 1 && hierarchy !== 10) {
        setItemForEdit((data.list || []).length > 0 ? data.list[0] : {});
        // setIsEditPopupShown(true);
        setIsCustomerPopupShown(true);
      }
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {}
  };

  const postInviteUser = async () => {
    try {
      if (
        (userData.email || "").length === 0 ||
        (userData.fname || "").length === 0 ||
        (userData.lname || "").length === 0
      ) {
        setToastParams({
          type: "ERROR",
          message: "Fill All Details",
          id: new Date().getTime(),
        });
        return;
      }
      const data = await postData(POST_INVITE_USER_URL, { ...userData });
      resetData();
      setIsLoading(false);
      setIsInvitePopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message:
          data &&
          data.response &&
          data.response.data &&
          data.response.data.message
            ? data.response.data.message
            : "Invited User Successfully!",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Enter Valid Name & Email",
        id: new Date().getTime(),
      });
    }
  };
  useEffect(() => {
    getFacilities();
  }, []);
  useEffect(() => {
    if(props.selfCompanyId.length == 0 || !window.location.pathname.includes("/Franchise")){
       getEntities();
       if(!window.location.pathname.includes("/Franchise")){
        const { dispatch } = props;
        dispatch({
          type: SELF_COMPANY_ID,
          data:''
        });
       }
       
    }
  }, [params.offset, params.text, params.limit, customerSort,(window.location.pathname.includes("/Franchise"))]);

  useEffect(() => {
    props.searchVal && onSearchChange(props.searchVal);
  }, [props.searchVal]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const addItem = async (data, image, metalLicenseImg) => {
    setIsLoading(true);
    let { companyLogo,companyType, metalLicense } = data;
    let response;

    if(companyType === 'FRANCHISE' && !image){
      setToastParams({
        type: "ERROR",
        message: "please upload company logo",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      return null
    }

    if (image) {
      const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      companyLogo = response.imageUrl;
    }
    if (metalLicenseImg) {
      const metalResponse = await uploadFile(
        FILE_UPLOAD_URL,
        metalLicenseImg.file
      );
      metalLicense = metalResponse.imageUrl;
    }
    response = await postData(ADD_ENTITIES_URL, {
      ...data,
      companyLogo,
      metalLicense,
      parentCompanyId:
        props.parentCompanyId || getLoginDetails().parentCompanyId,
      isEmbed: props.isEmbed || false,
    })
      .then((response) => {
        console.log("RESPONSE", response);
        setIsAddPopupShown(false);
        setToastParams({
          type: "SUCCESS",
          message: "Successfully added the Partner",
          id: new Date().getTime(),
        });
        getEntities();
        setIsLoading(false);
        props.setRemoveTabs(false)
      })
      .catch((e) => {
        console.log("INTO CATCH", e);
        setToastParams({
          type: "ERROR",
          message: e && e.error ? e.error : e.response.data.error,
          id: new Date().getTime(),
        });
        setIsLoading(false);
        props.setRemoveTabs(true)
      });
  };
  const editItem = async (data, image, metalLicenseImg) => {
    let { companyLogo, metalLicense } = data;
    setIsLoading(true);
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      console.log("metalLicenseImg", metalLicenseImg);
      if (metalLicenseImg && metalLicenseImg.file) {
        const metalResponse = await uploadFile(
          FILE_UPLOAD_URL,
          metalLicenseImg.file
        );
        metalLicense = metalResponse.imageUrl;
      }
      const response = await putData(EDIT_ENTITIES_URL, {
        ...data,
        companyLogo,
        metalLicense,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the Partner",
        id: new Date().getTime(),
      });
      getEntities();
      setIsLoading(false);
      props.setRemoveTabs(false)
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: e && e.error ? e.error : e.response.data.error,
        id: new Date().getTime(),
      });
      setIsLoading(false);
      props.setRemoveTabs(true)
    }
  };

  const messageItem = async (data) => {
    setIsLoading(true);
    try {
     
      const response = await getData(MESSAGE_ENTITIES_URL, {
        companyId : data.companyId
      
      });
      setToastParams({
        type: "SUCCESS",
        message: "Successfully Sent",
        id: new Date().getTime(),
      });
      getEntities();
      setIsLoading(false);
     
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: e && e.error ? e.error : e.response.data.error,
        id: new Date().getTime(),
      });
      setIsLoading(false);
      props.setRemoveTabs(true)
    }
  };
  const onIsActiveChange = (item, isActive) => {
    editItem({
      ...item,
      isActive,
    });
  };
  const deleteItem = async () => {
    setIsLoading(true);
    try {
      const response = await deleteData(DELETE_ENTITIES_URL, {
        companyId: itemForDelete.companyId,
      });
      setIsDeletePopupShown(false);
      setIsLoading(false);
      getEntities();
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: e && e.error ? e.error : e.response.data.error,
        id: new Date().getTime(),
      });
      setIsDeletePopupShown(false);
      setIsLoading(false);
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    props.setRemoveTabs && props.setRemoveTabs(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };
  const setMessage = (item) => {
  //  console.log(item);
   messageItem(item)
  };
  const resetData = () => {
    setUserData({ fname: "", lname: "", email: "", companyId: "", facilityCity: "" });
  };
   
console.log(props.selfCompanyId);

 
  return (
    <PageLayout
      icon={HeaderIconSvg}
      defaultSearchVal={params.text}
      onSearchChange={onSearchChange}
      heading="Customers"
      searchPlaceholder="Search Customers"
      hideSearchBar={hierarchy !== 1 && hierarchy !== 10}
      hideTop={props.hideTop}
    >
      { window.location.pathname.includes('/Franchise') &&
      <div style={{display:"flex", 
       flexDirection:'row',width: '280px',justifyContent: 'space-between',margin: '10px',borderRadius: '5px',alignItems: 'center', paddingTop: '9px',
       paddingLeft: '8px',paddingRight: '8px'}}>
        <p style={{ cursor:'pointer',fontWeight:'bold' }}  onClick={() => {
           getEntities()
          setIsFranchiseCustomersClicked(false);
          const { dispatch } = props;
          dispatch({
            type: SELF_COMPANY_ID,
            data:''
          });
           }}>{`Franchise`}</p>
        { isFranchiseCustomersClicked &&
        <>
        <p style={{ cursor:'pointer',fontWeight:'bold' }}>{`> Franchise Customer`}</p>
        </>
         }
      </div>
}    
      
      {!isAddPopupShown && !isEditPopupShown && (
        <div className="flex flex-middle flex-between create-group-button">
          <div className="flex flex-between flex-middle wt-250"></div>
          {/* {entitiesItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + entitiesItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )} */}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}

          {(hierarchy !== 1 && hierarchy !== 10) ? (
            <div className="ht-30 wt-30 mr-12 flex flex-middle flex-between pl-8 pr-8 pb-8 pt-8 mb-28 c-white fw-bolder c-pointer"></div>
          ) : (
            <div className="flex">
              {userData.companyId && (
                <div
                  className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 mb-8 c-white fw-bolder c-pointer mr-20"
                  onClick={() => {
                    setIsInvitePopupShown(true);
                  }}
                >
                  <span>Invite User</span>
                </div>
              )}
              {
                canCreateUser ? <div
                className="btn  ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 mb-8 c-white fw-bolder c-pointer"
                onClick={() => {
                  setIsAddPopupShown(true);
                  props.setRemoveTabs(true);
                }}
              >
                <img src={IconAdd} className="ht-20 wt-20 mr-12" />
                <span>Create Customer</span>
              </div> : <div className="ht-40 pb-8 pt-8 mb-8"/>
              }
              
            </div>
          )}
        </div>
      )}
      {
        console.log('isAddPopupShown', isAddPopupShown, isEditPopupShown, isCustomerPopupShown)
      }
     
      {!isAddPopupShown && !isEditPopupShown && !isCustomerPopupShown && (
        <Table
          scrollTable={true}
          type="entity"
          columns={[
            () => {
              return (
                <span>
                  Customer{" "}
                  {customerSort === "asc" ? (
                    <span
                      className={"fs-16 c-pointer"}
                      onClick={() => setCustomerSort("desc")}
                    >
                      ↓
                    </span>
                  ) : (
                    <span
                      className={"fs-16 c-pointer"}
                      onClick={() => setCustomerSort("asc")}
                    >
                      ↑
                    </span>
                  )}
                </span>
              );
            },
            "Address",
            "Email",
            "Type",
            "Facility",
            "Price Adjust",
            (hierarchy === 1 || hierarchy === 10)? "Is Active?" : undefined,
          ]}
          switchIds={[6]}
          // className="wt-600"
          items={entitiesItems.map((entity) => {
            const createdDate = new Date(entity.createdDate);
            const dd = String(createdDate.getDate()).padStart(2, "0");
            const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
            const yyyy = createdDate.getFullYear();
            let hours = createdDate.getHours();
            let minutes = createdDate.getMinutes();
            const ampm = hours >= 12 ? "pm" : "am";
            hours = hours % 12;
            hours = hours ? hours : 12;
            minutes = minutes < 10 ? "0" + minutes : minutes;
            console.log("entity",entity.isFranchise);
            return {
              ...entity,
              companyNameAndDate: `semicolon-separated-inline-${
                entity.companyName
              }; ${entity.createdDate ? `${mm}-${dd}-${yyyy}` : ""}`,
              displayAddress: `${entity.address || ""} ${entity.city} ${
                entity.state
              } ${entity.zipcode}`,
              name: `${entity.firstName || ""} ${entity.lastName}`,
              displayIsPartner: entity.isPartner ? "YES" : "NO",
              displayIsActive: entity.isActive ? "YES" : "NO",
              isFranchise:entity.isFranchise?entity.isFranchise?'true':'false':'false',
              link:
              window.location.pathname.includes('/Franchise')?
              (params.text || "").length > 0
              ? `/entities/Franchise/viewGroupId/${entity.companyId}/${params.text}`
              : `/entities/Franchise/viewGroupId/${entity.companyId}`
              :
                (params.text || "").length > 0
                  ? `/entities/viewGroupId/${entity.companyId}/${params.text}`
                  : `/entities/viewGroupId/${entity.companyId}`
              
            };
          })}
          keys={[
            "companyNameAndDate",
            "displayAddress",
            "email",
            "companyType",
            "facility",
            "groupModifier",
            (hierarchy === 1 || hierarchy === 10) ? "displayIsActive" : undefined,
          ]}
          widthClasses={[
            "flex-1",
            "flex-1",
            "flex-1",
            "flex-1",
            "flex-1",
            "flex-1",
            "wt-90",
          ]}
          actions={[canEditGroup && "EDIT", canDeleteGroup && "DELETE", "VIEW",window.location.pathname.includes('/Franchise') && "ADD_FRANCHISE","MESSAGE"]}
          onEdit={setEdit}
          onDelete={setDelete}
          onMessage={setMessage}
          onAddFranchise={addFranchise}
          onChange={onSearchChange}
          onIsActiveChange={(hierarchy === 1 || hierarchy === 10)? onIsActiveChange : false}
          headerBgWhite={true}
          viewLinkKey="link"
          viewIconImg={CustomerUserIcon}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          isCart={false}
          hideCheckBox={false}
          isSingleSelect={true}
          onSelection={(item) => {
            if (item) {
              setUserData({
                companyId: item.companyId,
                email: item.email,
                fname: item.firstName,
                lname: item.lastName,
                facilityCity: item.facility,
              });
            } else {
              resetData();
            }
          }}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
          limit={params.limit}
          // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
        />
      )}
      {isAddPopupShown && (
        <EntityAddEdit
          isLoading={isLoading}
          item={{ ...entityModel }}
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          type="add"
          shouldShowPriceMod={getLoginDetails().hierarchy === 1 || hierarchy === 10}
          facilities={facilitiesItems}
          setRemoveTabs={props.setRemoveTabs}
        />
      )}
      {isEditPopupShown && (
        <EntityAddEdit
          item={{ ...itemForEdit }}
          isLoading={isLoading}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
          shouldShowPriceMod={getLoginDetails().hierarchy === 1 || hierarchy === 10}
          facilities={facilitiesItems}
          setRemoveTabs={props.setRemoveTabs}
        />
      )}
      {isCustomerPopupShown && (
        <EntityCustomerAddEdit
          item={{ ...itemForEdit }}
          isLoading={isLoading}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
          isDirectDisplay={hierarchy !== 1 && hierarchy !== 10}
          shouldShowPriceMod={getLoginDetails().hierarchy === 1 || hierarchy === 10}
          facilities={facilitiesItems}
          setRemoveTabs={props.setRemoveTabs}
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="Customer"
          name={itemForDelete.companyName}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}

      {isInvitePopupShown && (
        <Modal>
          <div className="pt-10 pb-20 pl-10 pr-10 wt-360">
            <div className="flex flex-middle mr-12 pb-20">
              <h1
                className="mr-24 fs-20 c-black"
                style={{ fontWeight: "bold" }}
              >
                Invite User
              </h1>
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <div className="c-gray mr-4 wt-110">
                {" "}
                FIRST NAME :<span className="c-red">*</span>
              </div>
              <FormInput
                className={"border-bottom-black flex-1"}
                value={userData.fname}
                onChange={(e) => {
                  setUserData({ ...userData, fname: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-middle mb-24 mt-10">
              <div className="c-gray mr-4 wt-110">
                {" "}
                LAST NAME : <span className="c-red">*</span>
              </div>

              <FormInput
                className={"border-bottom-black flex-1"}
                value={userData.lname}
                onChange={(e) => {
                  setUserData({ ...userData, lname: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <div className="c-gray mr-4 wt-110">
                EMAIL :<span className="c-red">*</span>
              </div>

              <FormInput
                className={"border-bottom-black  flex-1"}
                value={userData.email}
                onChange={(e) => {
                  setUserData({ ...userData, email: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <span className="c-gray mr-4"> LOCATION: </span>

              <select
                name="type"
                className="select font-primary fs-14 fw-bolder"
                value={userData.facilityCity}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    facilityCity: e.target.value,
                  })
                }
              >
                <option value={""}>SELECT FACILITY</option>
                {facilitiesItems.map((state) => {
                  return (
                    <option
                      selected={state.name === userData.facilityCity}
                      value={state.name}
                    >
                      {state.name}
                    </option>
                  );
                })}
              </select>
            </div>

            <div className="flex flex-middle flex-right pt-25">
              <Button
                type="modalCancel"
                className="flex flex-middle ht-40 mr-20 btn btn-red"
                onClick={() => {
                  resetData();
                  setIsInvitePopupShown(false);
                }}
              >
                <span>CANCEL</span>
              </Button>
              <Button
                type="modalSave"
                className="flex  ht-40 flex-middle btn"
                onClick={postInviteUser}
              >
                <span>SAVE</span>
              </Button>
            </div>
          </div>
        </Modal>
      )}

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

const mapStateToProps = state => {
	return {
		selfCompanyId: state.CustomerReducer.selfCompanyId
	}
}

export default connect(mapStateToProps)(Entities);
