import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Toast from "../../components/Toast";

import PriceModifierAddEdit from "../../components/AddEditItems/PriceModifierAddEdit";
import GraphDisplay from "../../components/AddEditItems/GraphDisplay";
import moment from "moment";

import IconAdd from "../../icons/rev_add_load.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/black_pricing.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  PRICE_MODIFIER_URL,
  ADD_PRICE_MODIFIER_URL,
  EDIT_PRICE_MODIFIER_URL,
  DELETE_PRICE_MODIFIER_URL,
  PRICE_MODIFIER_GRAPH_URL,
  getData,
  postData,
  putData,
  deleteData,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const priceModifierModel = {
  name: "",
  value: "",
  id: undefined,
};

export const PriceModifiers = (props) => {
  const [priceModifierItems, setPriceModifierItems] = useState([]);
  const [priceModifierGraphItems, setPriceModifierGraphItems] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [hasMore, setHasMore] = useState(true);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [isGraphPopupShown, setIsGraphPopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [itemForGraph, setItemForGraph] = useState(null);
  const [points, setPoints] = useState([]);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });

  const [graphParams, setGraphParams] = useState({
    limit: 10,
    offset: 0,
  });

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getPriceModifers = async () => {
    try {
      setIsLoading(true);
      setPriceModifierItems([]);
      const data = await getData(PRICE_MODIFIER_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        companyId: props.parentCompanyId || getLoginDetails().companyId,
      });
      setIsLoading(false);
      setPriceModifierItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getPriceModifers();
  }, [params.offset, params.text, params.limit]);
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const getPriceModifierGraphs = async (type) => {
    try {
      const data = await getData(PRICE_MODIFIER_GRAPH_URL, {
        modifierType: type,
        count: graphParams.limit,
        offset: graphParams.offset,
        companyId: props.parentCompanyId || getLoginDetails().companyId,
      });
      const pts = [["Date", "Value"]];
      (data.list || []).map((obj) =>
        pts.push([
          moment(obj.date).format("Do, MMM YY, hh:mm A"),
          parseFloat(obj.value),
        ])
      );
      setPoints(pts);
      setPriceModifierGraphItems(data.list);
      setIsGraphPopupShown(true);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const addItem = async (data) => {
    try {
      const response = await postData(ADD_PRICE_MODIFIER_URL, {
        ...data,
        companyId: props.parentCompanyId || getLoginDetails().companyId,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added Price Modifier",
        id: new Date().getTime(),
      });
      getPriceModifers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data) => {
    try {
      const response = await putData(EDIT_PRICE_MODIFIER_URL, {
        ...data,
        companyId: props.parentCompanyId || getLoginDetails().companyId,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Price Modifier",
        id: new Date().getTime(),
      });
      getPriceModifers();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_PRICE_MODIFIER_URL, {
        priceModifierId: itemForDelete.priceModifierId,
      });
      console.log("RESPONSE", response);
      setIsDeletePopupShown(false);
      getPriceModifers();
    } catch (e) {
      console.log("ERROR");
    }
  };
  const setEdit = (item) => {
    setIsEditPopupShown(true);
    setItemForEdit(item);
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };
  const showGraph = (item) => {
    setItemForGraph(item);
    getPriceModifierGraphs(item.type);
  };
  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Pricing"
      searchPlaceholder="Search Pricing"
    >
      {!isAddPopupShown && !isEditPopupShown && (
        <div className="flex flex-middle mb-16 mt-8 flex-between">
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW PRICING</h1> */}
          </div>
          {priceModifierItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + priceModifierItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          <div
            className="flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
            }}
          >
            <img src={IconAdd} className="ht-30 wt-30 mr-12" />
            <span>ADD PRICING</span>
          </div>
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && (
        <Table
          scrollTable={true}
          columns={[
            "Price Modifier",
            "Value",
            "Previous Value",
            "Change (%)",
            "Last Updated",
          ]}
          className=""
          items={priceModifierItems}
          keys={[
            "name",
            "value",
            "previousValue",
            "diff-value-previousValue-",
            "dd-date-updatedDate",
          ]}
          actions={["EDIT", "DELETE", "GRAPH"]}
          // widthClasses={['wt-200', 'wt-100']}
          onEdit={setEdit}
          onDelete={setDelete}
          showGraph={showGraph}
          onChange={onSearchChange}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          limit={params.limit}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
        />
      )}
      {isAddPopupShown && (
        <PriceModifierAddEdit
          item={{ ...priceModifierModel }}
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          type="add"
        />
      )}
      {isGraphPopupShown && (
        <GraphDisplay
          item={{ ...itemForGraph }}
          priceModifierGraphItems={[...points]}
          dataItems={[...priceModifierGraphItems]}
          onClose={setIsGraphPopupShown.bind(null, false)}
          listHeaders={["Value", "Date"]}
          listDataKeys={["value", "date"]}
          showList={true}
          graphType={"LineChart"}
          type="graph"
        />
      )}
      {isEditPopupShown && (
        <PriceModifierAddEdit
          item={{ ...itemForEdit }}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default PriceModifiers;
