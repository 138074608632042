import React, { useState } from "react";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

import ImageUploading from "./ImageUpload";
import IconSearch from "../../icons/pos_find.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import CaretIcon from "../../icons/caret.png";
import PlusIcon from "../../icons/pos_plus.png";

import { MULTIPLE_FILE_UPLOAD_URL, uploadFile } from "../../utils/api";

const MakeAddEdit = ({ item, onClose, onSave, type, onBack }) => {
  const [make, setMake] = useState(item.make);
  const [description, setDescription] = useState(item.description);
  const [videoUrls, setVideoUrls] = useState(item.videoUrls);
  const [documentUrls, setDocumentUrls] = useState(item.documentUrls);
  const [models, setModels] = useState(item.model || []);
  const [image, setImage] = useState(null);

  return (
    <div className="mt-8" style={{ overflow: "scroll" }}>
      <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
        <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
        <span className="fs-12 c-table-header fw-bolder">BACK TO MAKE</span>
      </div>
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div style={{ width: "80%" }}>
          <div className="flex flex-middle mr-12">
            <img src={IconSearch} className="ht-30 wt-30 mr-12" />
            <h1 className="mr-24 fs-20 c-gray">
              {type === "add" ? "ADD" : "EDIT"} MAKE
            </h1>
          </div>
          <div className="flex flex-between flex-middle mb-40 mt-20 ">
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder="Name"
              className="mr-40 wt-25p"
              value={make}
              onChange={(e) => {
                setMake(e.target.value);
              }}
            />
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder="Description"
              className="mr-40 flex-1"
              value={description}
              onChange={(e) => {
                setDescription(e.target.value);
              }}
            />
            {/* <div className="c-gray fs-14">Upload Images</div> */}
          </div>
          <div className="flex flex-between mb-40">
            <div className="flex-1">
              <div className="c-gray fs-14 flex flex-middle mb-12">
                <span className="mr-8">Model</span>
                <img
                  src={PlusIcon}
                  className="wt-25"
                  onClick={() => {
                    setModels([...models, ""]);
                  }}
                />
              </div>
              {(models || []).map((model, index) => {
                return (
                  <div className="flex flex-middle">
                    <FormInput
                      inputClassName="rounded-pill bg-blue"
                      key={index}
                      placeholder={""}
                      className="mr-8 mb-12 flex-1"
                      value={model}
                      onChange={(e) => {
                        const val = e.target.value;
                        const newItems = [...models];
                        newItems[index] = val;
                        setModels(newItems);
                      }}
                    />
                    <span
                      style={{
                        padding: 0,
                        width: "30px",
                        height: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="btn flex ht-40  btn-red c-pointer c-gray fw-bolder fs-14 p-relative t--8"
                      onClick={(e) => {
                        const newItems = [...models];
                        newItems.splice(index, 1);
                        setModels(newItems);
                      }}
                    >
                      X
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex-1 mr-40">
              <div className="c-gray fs-14 flex flex-middle mb-12">
                <span className="mr-8">Video Urls</span>
                <img
                  src={PlusIcon}
                  className="wt-25"
                  onClick={() => {
                    setVideoUrls([...videoUrls, ""]);
                  }}
                />
              </div>
              {(videoUrls || []).map((url, index) => {
                return (
                  <div className="flex flex-middle">
                    <FormInput
                      inputClassName="rounded-pill bg-blue"
                      key={index}
                      placeholder={""}
                      className="mr-8 mb-12 flex-1"
                      value={url}
                      onChange={(e) => {
                        const val = e.target.value;
                        const newItems = [...videoUrls];
                        newItems[index] = val;
                        setVideoUrls(newItems);
                      }}
                    />
                    <span
                      style={{
                        padding: 0,
                        width: "30px",
                        height: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="btn  ht-40 flex  btn-red c-pointer c-gray fw-bolder fs-14 p-relative t--8"
                      onClick={(e) => {
                        const newItems = [...videoUrls];
                        newItems.splice(index, 1);
                        setVideoUrls(newItems);
                      }}
                    >
                      X
                    </span>
                  </div>
                );
              })}
            </div>
            <div className="flex-1">
              <div className="c-gray fs-14 flex flex-middle mb-12">
                <span className="mr-8">Document Urls</span>
                <img
                  src={PlusIcon}
                  className="wt-25"
                  onClick={() => {
                    setDocumentUrls([...documentUrls, ""]);
                  }}
                />
              </div>
              {(documentUrls || []).map((url, index) => {
                return (
                  <div className="flex flex-middle">
                    <FormInput
                      inputClassName="rounded-pill bg-blue"
                      key={index}
                      placeholder={""}
                      className="mr-8 mb-12 flex-1"
                      value={url}
                      onChange={(e) => {
                        const val = e.target.value;
                        const newItems = [...documentUrls];
                        newItems[index] = val;
                        setDocumentUrls(newItems);
                      }}
                    />
                    <span
                      style={{
                        padding: 0,
                        width: "30px",
                        height: "30px",
                        justifyContent: "center",
                        alignItems: "center",
                      }}
                      className="btn ht-40 flex  btn-red c-pointer c-gray fw-bolder fs-14 p-relative t--8"
                      onClick={(e) => {
                        const newItems = [...documentUrls];
                        newItems.splice(index, 1);
                        setDocumentUrls(newItems);
                      }}
                    >
                      X
                    </span>
                  </div>
                );
              })}
            </div>
          </div>
          <div className="flex flex-middle wt-100p">
            <ImageUploading
              onChange={setImage}
              initialImage={item.profileUrl || ""}
              isMultiple={true}
              initialImages={(item.imglUrls || []).map((url) => {
                return {
                  dataURL: url,
                };
              })}
            />
          </div>
          <div className="flex flex-middle flex-right">
            {/* <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button> */}
            <Button
              type="modalCancel"
              className="flex ht-40 flex-middle mr-20  btn btn-red"
              onClick={onClose}
            >
              {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
              <span>CANCEL</span>
            </Button>
            <Button
              type="modalSave"
              className="flex ht-40 flex-middle btn"
              onClick={() => {
                onSave(
                  {
                    make,
                    description,
                    model: models,
                    videoUrls,
                    documentUrls,

                    makeId: item.makeId,
                  },
                  image
                );
              }}
            >
              <img src={Tick} className="wt-20 ht-20 mr-4" />
              {/* <span>{type === 'add'?'Add': 'Edit'} Item</span> */}
              <span>SAVE</span>
            </Button>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MakeAddEdit;
