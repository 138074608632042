import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import FacilityAddEdit from "../../components/AddEditItems/FacilityAddEdit";

import Toast from "../../components/Toast";

import IconAdd from "../../icons/rev_add_group.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/geo.svg";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import ActionLoader from "../../components/ActionLoader";

import {
  GET_FACILITIES_URL,
  ADD_FACILITIES_URL,
  EDIT_FACILITIES_URL,
  DELETE_FACILITIES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import { getLoginDetails } from "../../utils/storage";

const facilityModel = {
  name: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  email: "",
  firstName: "",
  lastName: "",
  phone: "",
  isActive: true,
};

// const entityModel={
//     "companyName": "First Organisation",
//     "companyLogo": "http://a.b",
//     "companyType": "BUYER",
//     "isPartner": true,
//     "address": "company address",
//     "city": "company city",
//     "state": "company state",
//     "zipcode": "560043",
//     "email": "company email",
//     "phone": "company phone",
//     "firstName": "first name",
//     "lastName": "last name"
// }

export const Facilities = (props) => {
  const [facilitiesItems, setFacilitiesItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const getFacilities = async () => {
    try {
      setIsLoading(true);
      // setFacilitiesItems([]);

      const data = await getData(GET_FACILITIES_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        // parentCompanyId: props.parentCompanyId || getLoginDetails().parentCompanyId
      });
      setIsLoading(false);

      setFacilitiesItems((data.list || []).sort((a, b) => a.name > b.name && 1 || -1));
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getFacilities();
  }, [params.offset, params.text, params.limit]);

  // useEffect(()=> {
  //     getEntities();
  // }, [params.offset]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const editItem = async (data, image) => {
    setIsLoading(true);
    let { companyLogo } = data;
    try {
      if (image) {
        const response = await uploadFile(FILE_UPLOAD_URL, image.file);
        companyLogo = response.imageUrl;
      }
      const response = await putData(EDIT_FACILITIES_URL, {
        ...data,
        companyLogo,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited the Facility",
        id: new Date().getTime(),
      });
      setIsLoading(false);
      getFacilities();
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const onIsActiveChange = (item, isCartCreation) => {
    if(isLoading) {
      return;
    }
    editItem({
      name: item.name,
      facilityId: item.facilityId,
      city: item.city,
      isCartCreation,
    });
  };
  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Facility Activation"
      searchPlaceholder="Search Facility"
      hideSearchBar={true}
    >
        <div className="flex flex-middle mb-16 mt-8 flex-between">
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW FACILITIES</h1> */}
          </div>
          {facilitiesItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + facilitiesItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
        </div>
        <Table
          scrollTable={true}
          type="entity"
          columns={[
            "Facility Name",
            'Enable Cart Creation',
            "",
            "",
            ''
          ]}
          // className="wt-600"
          items={facilitiesItems.map((facility) => {
            return {
              ...facility,
              displayAddress: `${facility.address || ""} ${facility.city} ${
                facility.state
              } ${facility.zipcode}`,
              displayName: `${facility.firstName || ""} ${facility.lastName}`,
              isActive: facility.isCartCreation,
            };
          })}
          keys={["name", "isCartCreation", "", "",]}
          widthClasses={[
            "wt-200", "flex-1","flex-1","flex-1",
          ]}
          onChange={onSearchChange}
          onIsActiveChange={onIsActiveChange}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          hideCheckBox={true}
          onCountChange={(value) => {
            setParams({
              ...params,
              offset: 0,
              limit: value,
            });
          }}
          limit={params.limit}
          switchIds={[1]}
          // widthClasses={['wt-200', 'wt-200', 'wt-150', 'wt-300', 'flex-1']}
        />
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Facilities;
