import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";

import BulkUploadConverters from "../../components/AddEditItems/BulkUploadConverters";

import Toast from "../../components/Toast";

import Refresh from "../../icons/refresh.svg";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import uploadIcon from "../../icons/newicons/upload.svg";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import { getLoginDetails } from "../../utils/storage";
import ActionLoader from "../../components/ActionLoader";

import {
  GET_BULK_UPLOAD_URL,
  POST_BULK_UPLOAD_URL,
  PRODUCT_BULK_EDIT_URL,
  getData,
  uploadFile,
  uploadFileWithToken,
  DOWNLOAD_CONVERTER_URL,
} from "../../utils/api";

export const BulkUpload = (props) => {
  const [uploadedFilesList, setUploadedFilesList] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const [isAddPopupShown, setIsAddPopupShown] = useState(false);

  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getUploadedFiles = async () => {
    try {
      const data = await getData(GET_BULK_UPLOAD_URL, {
        count: params.limit,
        offset: params.offset,
        text: params.text,
      });
      setIsLoading(false);
      setUploadedFilesList(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  useEffect(() => {
    getUploadedFiles();
  }, [params.offset, params.text, params.limit]);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };

  const bulkEditUpload = async (data, images) => {
    setIsLoading(true);
    try {
      // if(Object.keys(images).length > 0) {
      console.log("response", data, images);
      const response = await uploadFileWithToken(
        PRODUCT_BULK_EDIT_URL,
        images,
        { title: data }
      );
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully Uploaded File",
        id: new Date().getTime(),
      });
      getUploadedFiles();
      setIsLoading(false);
      // } else {
      //     setToastParams({
      //         type: 'ERROR',
      //         message: 'Please upload a file',
      //         id: new Date().getTime()
      //     })
      // }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  const addItem = async (data, images) => {
    try {
      // if(Object.keys(images).length > 0) {
      console.log("response", data, images);
      const response = await uploadFile(POST_BULK_UPLOAD_URL, images, {
        title: data,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully Uploaded File",
        id: new Date().getTime(),
      });
      getUploadedFiles();
      // } else {
      //     setToastParams({
      //         type: 'ERROR',
      //         message: 'Please upload a file',
      //         id: new Date().getTime()
      //     })
      // }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  const downloadConvertors = async () => {
    try {
      setIsLoading(true);
      const data = await getData(DOWNLOAD_CONVERTER_URL);
      if (data && data.link) {
        const element = document.createElement("a");
        element.setAttribute("href", data.link);
        element.setAttribute("download", true);

        element.click();
      }
      setIsLoading(false);
      console.log("data", data);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };

  return (
    <PageLayout
      onSearchChange={onSearchChange}
      icon={HeaderIconSvg}
      heading="Bulk Upload"
      searchPlaceholder="Search File"
    >
      <div className="flex flex-middle mb-16 mt-8 flex-between">
        <div className="flex flex-between flex-middle"></div>
        {uploadedFilesList.length > 0 &&
          (params.offset >= 10 || params.areMoreItemsAvailable) && (
            <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
              <div className="ht-20 rounded-circleflex flex-center flex-middle pr-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate-90 c-pointer ${
                    params.offset > 0 ? "op-7" : "op-2"
                  }`}
                  onClick={params.offset > 0 ? fetchLess : () => {}}
                />
              </div>
              <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                Showing {params.offset + 1} -{" "}
                {params.offset + uploadedFilesList.length}
              </span>
              <div className="ht-20 rounded-circle flex flex-center flex-middle pl-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate--90 c-pointer ${
                    params.areMoreItemsAvailable ? "op-7" : "op-2"
                  }`}
                  onClick={params.areMoreItemsAvailable ? fetchMore : () => {}}
                />
              </div>
            </div>
          )}
          <div className="flex flex-middle flex-between">
        <div className="flex flex-middle flex-between mr-4">
          <div
            className="pl-5 pr-5 c-white fw-bolder c-pointer"
            onClick={() => {
              getUploadedFiles();
            }}
          >
            <img src={Refresh} className="ht-25 wt-25 mr-12" />
          </div>
          <div
            className="btn  mr-12 ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
            }}
          >
            <span>Bulk Upload</span>
          </div>
          <div
            className="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              downloadConvertors(true);
            }}
          >
            <span>Download Converters</span>
          </div>
        </div>
        </div>
      </div>
      {!isAddPopupShown && (
        <Table
          scrollTable={true}
          scrollTable={true}
          columns={["Title", "Status", "Time"]}
          items={uploadedFilesList.map((make) => {
            return {
              ...make,
              date: (() => {
                const dateObj = new Date(make.time);
                const year = dateObj.getFullYear();
                const month = `0${dateObj.getMonth() + 1}`.slice(-2);
                const date = dateObj.getDate();
                return `${year}-${month}-${date}`;
              })(),
            };
          })}
          keys={["title", "status", "date"]}
          widthClasses={["flex-1", "flex-1", "flex-1"]}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          hideCheckBox={true}
          isLoading={isLoading}
          onCountChange={(value) => {
            setParams({
              ...params,
              offset: 0,
              limit: value,
            });
          }}
          limit={params.limit}
        />
      )}
      {isAddPopupShown && (
        <BulkUploadConverters
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          onEditSave={bulkEditUpload}
          type="add"
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default BulkUpload;
