import React, {useState} from 'react'

import Modal from '../Modal';
import Button from '../Button';

import FormInput from '../FormInput';


const RupeeView = ({rupeeItems, onClose, type}) => {

    return (
        <Modal>
            <div className="wt-500  mt-8">
                <div className="fs-24 fw-bolder mb-40">Price</div>
                <div className="flex flex-left mb-40 flex-wrap">
                    <FormInput disabled placeholder="Market Price" className="mr-40 wt-33p" value={rupeeItems.marketPrice} />
                    <FormInput disabled placeholder="Listed Price" className="mr-40 wt-33p" value={rupeeItems.listedPrice} />
                    <FormInput disabled placeholder="Scheme Price" className="mr-40 wt-33p" value={rupeeItems.schemePice} />
                    <FormInput disabled placeholder="Alt1 Price" className="mr-40 wt-33p" value={rupeeItems.alt1Price} />
                    <FormInput disabled placeholder="Base Price" className="mr-40 wt-33p" value={rupeeItems.basePrice} />
                </div>
                <div className="flex flex-middle flex-right">
                    <Button className="mr-20" type="secondary" onClick={onClose}>Cancel</Button>
                </div>
            </div>
        </Modal>
    )
}

export default RupeeView
