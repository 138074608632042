import React, { useState, useEffect, Fragment } from 'react'
import Toggle from 'react-toggle';
import Modal from '../Modal';
import Button from '../Button';

import FormInput from '../FormInput';
import moment from 'moment';
import ActionLoader from '../ActionLoader';

import Chart from "react-google-charts";


const GraphDisplay = ({item, priceModifierGraphItems, onClose, type, graphType, showList, dataItems, listHeaders, listDataKeys}) => {
    
    const [isGraph, setIsGraph] = useState(true);

    const data = priceModifierGraphItems

    const options = {
                      pointSize: 10,
                      curveType: "function",
                      bar: { groupWidth: '50%' },
                    }

    return (
        // <Modal>

            <div className="ht-200 mt-8 flex-1">
                {/* <div className="fs-24 fw-bolder mb-40"> {item.name} </div> */}
                {showList && <div className="flex flex-right">
                  {/* <div> Is Graph? </div> */}
                  {/* <div>
                    <Toggle 
                      onChange={(e)=> { setIsGraph(e.target.checked) }} 
                      className="bg-theme" 
                      checked={isGraph}
                    />
                  </div> */}
                </div>}
                <div className="flex flex-between flex-middle mb-40">
                    {isGraph && <div style={{
                        width: '100%',
                        height: '200pz',
                        overflow: 'scroll'
                      }}>
                        <Chart 
                          height="100%" 
                          width="100%" 
                          data={data}
                          options={options}
                          chartType={graphType} 
                          loader={<div><ActionLoader /></div>} 
                        />
                    </div>}
                </div>
                {
                  showList && !isGraph &&
                  <div className="wt-700">
                    <div className="flex flex-middle flex-between ht-60 pl-16 pr-16 border-table-header">
                      {(listHeaders || []).map((column, index)=> {
                          return (
                            <span className={'c-table-header fs-12 fw-bolder pl-16 pr-16 flex-1'} key={`header-${(index+1)}`}>{column}</span>
                          )
                        })
                      }
                    </div>

                    {(dataItems || []).map((item, index) => {
                      return <div className="flex flex-middle flex-between ht-60 pl-16 pr-16 border-table-header" key={`table-${(index+1)}`}>

                      {(listDataKeys || []).map((key, index) => {
                        return (
                            <Fragment key={`list-${(index + 1)}`}>{
                              key==='date' ?
                              <span className={'c-table-header fs-12 fw-bolder pl-16 pr-16 flex-1'}>{moment(item[key]).format('Do, MMM YYYY hh:mm A')}</span>
                              :
                              <span className={'c-table-header fs-12 fw-bolder pl-16 pr-16 flex-1'}>{item[key]}</span>
                            }</Fragment>
                        )
                      })}
                      </div>
                    })}
                  </div>
                }
            </div>
        // </Modal>
    )
}

export default GraphDisplay
