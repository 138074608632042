import React, { useState } from "react";
import { NavLink as Link } from "react-router-dom";

// import { ReactComponent as DashbardIcon } from '../../icons/dashboard.svg';
// import { ReactComponent as InvoiceIcon } from '../../icons/invoice.svg';
// import { ReactComponent as EntitiesIcon } from '../../icons/partners.svg';
// import { ReactComponent as PriceModifierIcon } from '../../icons/price.svg';
// import { ReactComponent as RoleIcon } from '../../icons/role.svg';
// import { ReactComponent as CartIcon } from '../../icons/buy.svg';
// import { ReactComponent as CovertersIcon } from '../../icons/car-engine.svg';
// import  Logo from '../../icons/logo.png';

import Logo1 from "../../icons/newicons/topSIdebarLogo.png";
import franchise from "../../icons/newicons/franchise.png";
import Logo from "../../icons/newicons/bottomSidebarLogo.png";
import BG from "../../icons/bg.png";
import Settings from "../../icons/settings.png";

import DashbardIcon from "../../icons/newicons/dashboardIcon.png";

import InvoiceIcon from "../../icons/newicons/invoicesIcon.png";

import CovertersIcon from "../../icons/newicons/convertersIcon.png";

import FacilitiesIcon from "../../icons/newicons/facilitiesIcon.png";

import UsersIcon from "../../icons/newicons/usersIcon.png";
import sportscar from "../../icons/newicons/sports-car.png";

import PermissionsIcon from "../../icons/newicons/permissionsIcon.png";
import CartIcon from "../../icons/newicons/cartsIcon.png";
import PricingIcon from "../../icons/newicons/pricingIcon.png";
import LogoFullWhite from "../../icons/newicons/newLogo.png";

import { getLoginDetails } from "../../utils/storage";
import { GET_CUSTOMERS_REPORT_URL, getData } from "../../utils/api";
import CovertersGradingQueueIconActive from "../../icons/pos_grading_queue.png";
import CovertersGradingQueueIconInActive from "../../icons/rev_grading_queue.png";
import "./Sidebar.css";
import Converters from "../../containers/Converters";
import ActionLoader from "../ActionLoader";

// import  DashbardIcon from '../../icons/rev_dashboard.png';

export const BlankSidebar = () => {
  return (
    <div className="sidebar-main  wt-250 ht-100vh p-fixed t-0 l-0 z-1 sidebar_main">
      <div className="p-absolute t-0 l-0 ht-100p wt-100p bg-black op-7"></div>
      <div className="p-absolute wt-100p pl-16 pr-16 sidebar-container">
        <div className="ht-60 flex flex-middle flex-center c-white fs-18">
          <img src={Logo1} className="wt-150 mt-4 sidebar-logo" />
        </div>
      </div>
      <div className="p-absolute b-40 t-center flex flex-middle flex-center wt-100p l-0">
        <img src={Logo} className="wt-150 t-hide" />
      </div>
    </div>
  );
};

export const Sidebar = () => {
  const pathname = window.location.pathname;
  const {
    hierarchy,
    canAccessDashboard,
    canShowManufactureTips,
    isAdmin,
    canBuildCart,
    isNewUser,
    isSA,
    canInviteUsers,
    canEditPricing,
    minimumSettingsOnly,
    canViewCostSheet,
    canViewTopCats,
    canViewTopLookups,
    canAddConverter,
    canViewDNBReport,
    canViewTransactionReport,
    canViewGlobalModifierHistoryReport,
    isFranchise = false,
  } = getLoginDetails();
  const [isLoading, setIsLoading] = useState(false);
  const [onActiveCart, setOnActiveCart] = useState(false);
  const getReport = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_CUSTOMERS_REPORT_URL, {});
      if (data && data.link && data.link != "") {
        const element = document.createElement("a");
        element.setAttribute("href", data.link);
        element.setAttribute("download", true);
        element.click();
        setIsLoading(false);
      } else {
        setIsLoading(false);
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
    }
  };

  const onActiveCartClicked = () => {
    setOnActiveCart(true);
  }

  if (isNewUser) {
    return <BlankSidebar />;
  }

  return (
    <>
      <div className="sidebar-main wt-250 ht-100vh p-fixed t-0 l-0 z-1" style={{overflow:"hidden"}} >
        <div className="p-absolute wt-100p  sidebar-container" style={{height:'1200px',overflowX:"scroll"}}>
          <div className="ht-60 flex flex-middle flex-center c-white fs-18">
            {hierarchy === 10 || isFranchise ? (
              <img
                src={localStorage.getItem("company URL")?localStorage.getItem("company URL"):Logo1}
                className="wt-200 mt-4 sidebar-logo"
                style={{ height: 60 }}
              />
            ) : (
              <img src={Logo1} className="wt-150 mt-4 sidebar-logo" />
            )}
            {/* <span className="  mr-4 ">CAT</span> 
                    <span>TRADERS</span>  */}
          </div>

          <div className="pt-20 menu-data flex flex-column z-1 z-50" style={{height:'1300px'}}>
            {canAccessDashboard &&
              (canViewCostSheet || canViewTopLookups || canViewTopCats) && (
                <Link
                  to={{
                    pathname: canViewCostSheet
                      ? "/dashboard/costsheetreport"
                      : "/",
                    params: {
                      filterData: window.location.href.includes("load")
                        ? true
                        : false,
                    },
                  }}
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                    pathname.includes("/dashboard")
                      ? "bg-active-gradient c-red active-sidebar"
                      : ""
                  }`}
                  activeClassName="bg-active-gradient active-sidebar"
                >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-binoculars-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                  {/* <img
                src={DashbardIcon}
                className="wt-20 sidebar-icon mr-16 inactive"
              />
              <img
                src={DashbardIcon}
                className="wt-20 sidebar-icon mr-16 active"
              /> */}
                  <span>Reports</span>
                </Link>
              )}
            {/* {pathname.includes("/dashboard") && (
              <div className="ml-12 sub-menu">
                {canViewCostSheet ? (
                  <Link
                    to="/dashboard/costsheetreport"
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                      pathname.includes("/dashboard/costsheetreport")
                        ? "bg-active-gradient c-red active-sidebar"
                        : ""
                    }`}
                    activeClassName="bg-white c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-binoculars-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Cost Sheet</span>
                  </Link>
                ) : null}

                {canViewTransactionReport ? (
                  <Link
                    to="/dashboard/transactionsreport"
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                      pathname.includes("/dashboard/transactionsreport")
                        ? "bg-active-gradient c-red active-sidebar"
                        : ""
                    }`}
                    activeClassName="bg-white c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-binoculars-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Transactions Report</span>
                  </Link>
                ) : null}

                {canViewTopCats ? (
                  <Link
                    to="/dashboard/top-cats"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-lines-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Top Cats</span>
                  </Link>
                ) : null}

                {canViewTopLookups ? (
                  <Link
                    to="/dashboard/top-lookups"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-gear-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Top Lookups</span>
                  </Link>
                ) : null}

                {canViewDNBReport ? (
                  <Link
                    to="/dashboard/didnotbuy"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-gear-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Did Not Buy Report</span>
                  </Link>
                ) : null}

                {canViewGlobalModifierHistoryReport ? (
                  <Link
                    to="/dashboard/globalmodifier"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-gear-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                    </svg>

                    <span>Global Modifier Report</span>
                  </Link>
                ) : null}
              </div>
            )} */}
            <Link
              to={
                hierarchy === 2 || hierarchy === 10
                  ? "/converters/identify"
                  : "/converters/identify"
              }
              className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16 flex flex-middle ht-auto  ${
                pathname.includes("/converters") ? "bg-active-gradient" : ""
              } `}
              activeClassName="bg-active-gradient active-sidebar"
            >
              <svg
                width="19"
                viewBox="0 0.233 25 19.533"
                fill="currentColor"
                className="mr-8"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="25"
                  height="20"
                  viewBox="0.25 0.975 24.25 18.947"
                >
                  <path
                    paint-order="stroke fill markers"
                    fill-rule="evenodd"
                    d="M.25 6.281v-.758c0-.627.509-1.136 1.137-1.136h17.05V2.113c0-1.012 1.227-1.517 1.941-.804l3.789 3.79a1.137 1.137 0 0 1 0 1.607l-3.789 3.79c-.711.71-1.94.211-1.94-.805V7.418H1.386A1.137 1.137 0 0 1 .25 6.28zm23.113 7.2H6.313v-2.274c0-1.01-1.225-1.52-1.941-.804l-3.79 3.79a1.137 1.137 0 0 0 0 1.607l3.79 3.789c.712.711 1.94.211 1.94-.804v-2.273h17.051c.628 0 1.137-.51 1.137-1.137v-.758c0-.628-.509-1.137-1.137-1.137z"
                  />
                </svg>
              </svg>
              {/* <img
                src={CovertersIcon}
                className="wt-20 sidebar-icon mr-16 inactive"
              />
              <img
                src={CovertersIcon}
                className="wt-20 sidebar-icon mr-16 active"
              /> */}

              <span>Converters</span>
            </Link>
            {pathname.includes("/converters") && (
              <div className="ml-12 sub-menu">
                <Link
                  to="/converters/identify"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-binoculars-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                  </svg>
                  {/* <img
                    src={CovertersIdentifyIconActiveRed}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  />
                  <img
                    src={CovertersIdentifyIconInActive}
                    className="wt-25 sidebar-icon mr-8 active"
                  /> */}
                  <span>Identify Converters</span>
                </Link>
                {!minimumSettingsOnly ? (
                  <Link
                    to="/converters/expert-advice"
                    className="sidebar-link bg-transparent fs-15   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-person-lines-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M6 8a3 3 0 1 0 0-6 3 3 0 0 0 0 6zm-5 6s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zM11 3.5a.5.5 0 0 1 .5-.5h4a.5.5 0 0 1 0 1h-4a.5.5 0 0 1-.5-.5zm.5 2.5a.5.5 0 0 0 0 1h4a.5.5 0 0 0 0-1h-4zm2 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2zm0 3a.5.5 0 0 0 0 1h2a.5.5 0 0 0 0-1h-2z" />
                    </svg>
                    {/* <PriceModifierIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                    {/* <img
                    src={ExpertIconRed}
                    className="wt-20 sidebar-icon mr-16 inactive"
                  />
                  <img
                    src={ExpertIcon}
                    className="wt-20 sidebar-icon mr-16 active"
                  /> */}

                    <span>Identification Queue</span>
                  </Link>
                ) : null}
                {canShowManufactureTips && (
                  <Link
                    to="/converters/make-model"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-gear-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                    </svg>
                    {/* <PriceModifierIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                    {/* <img
                      src={MakeIconRed}
                      className="wt-20 sidebar-icon mr-16 inactive"
                    />
                    <img
                      src={MakeIcon}
                      className="wt-20 sidebar-icon mr-16 active"
                    /> */}

                    <span>Manufacturer Tips</span>
                  </Link>
                )}
                {hierarchy === 1 && canAddConverter && (
                  <Link
                    exact={true}
                    to="/converters"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-eye-fill mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M10.5 8a2.5 2.5 0 1 1-5 0 2.5 2.5 0 0 1 5 0z" />
                      <path d="M0 8s3-5.5 8-5.5S16 8 16 8s-3 5.5-8 5.5S0 8 0 8zm8 3.5a3.5 3.5 0 1 0 0-7 3.5 3.5 0 0 0 0 7z" />
                    </svg>

                    <span>Add Converters</span>
                  </Link>
                )}

                
                  {/* <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-binoculars-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M4.5 1A1.5 1.5 0 0 0 3 2.5V3h4v-.5A1.5 1.5 0 0 0 5.5 1h-1zM7 4v1h2V4h4v.882a.5.5 0 0 0 .276.447l.895.447A1.5 1.5 0 0 1 15 7.118V13H9v-1.5a.5.5 0 0 1 .146-.354l.854-.853V9.5a.5.5 0 0 0-.5-.5h-3a.5.5 0 0 0-.5.5v.793l.854.853A.5.5 0 0 1 7 11.5V13H1V7.118a1.5 1.5 0 0 1 .83-1.342l.894-.447A.5.5 0 0 0 3 4.882V4h4zM1 14v.5A1.5 1.5 0 0 0 2.5 16h3A1.5 1.5 0 0 0 7 14.5V14H1zm8 0v.5a1.5 1.5 0 0 0 1.5 1.5h3a1.5 1.5 0 0 0 1.5-1.5V14H9zm4-11H9v-.5A1.5 1.5 0 0 1 10.5 1h1A1.5 1.5 0 0 1 13 2.5V3z" />
                  </svg> */}
                  {/* <img
                    src={CovertersIdentifyIconActiveRed}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  /> */}
                  <Link
                  to="/converters/vehicle"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <div style={{display: 'flex'}}>
                  <img
                    src={sportscar}
                    style={{display: pathname.includes("/vehicle")? 'none': 'block'}}
                    className="wt-25 mr-8 active"
                  />
                  <span>Vehicle Search</span>
                  </div>
                </Link>

                {/* {hierarchy === 1 && isSA && (
                  <Link
                    exact={true}
                    to="/converters/bulk-upload"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16 flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-upload mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                      <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                    </svg>
                    <span>Bulk Upload </span>
                  </Link>
                )} */}
              </div>
            )}
            {(canBuildCart || hierarchy === 10) && (
              <Link
                to="/loads/create-load"
                className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                  (pathname.includes("/loads") || pathname.includes("/my-cart")) ? "bg-active-gradient" : ""
                } `}
                activeClassName="bg-active-gradient active-sidebar"
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="27"
                  fill="currentColor"
                  className="bi bi-cart-fill mr-8"
                  viewBox="0 0 16 16"
                >
                  <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                </svg>
                {/* <CartIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                {/* <img
                  src={CartIcon}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                <img
                  src={CartIcon}
                  className="wt-20 sidebar-icon mr-16 active"
                /> */}

                <span>Carts</span>
              </Link>
            )}
            {(pathname.includes("/loads") || pathname.includes("/my-cart") )&& (
              <div className="ml-12 sub-menu">
                <Link
                  to="/loads/create-load"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cart-plus-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zM9 5.5V7h1.5a.5.5 0 0 1 0 1H9v1.5a.5.5 0 0 1-1 0V8H6.5a.5.5 0 0 1 0-1H8V5.5a.5.5 0 0 1 1 0z" />
                  </svg>
                  {/* <img
                    src={LoadAddIconActiveRed}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  />
                  <img
                    src={LoadAddIconInactive}
                    className="wt-25 sidebar-icon mr-8 active"
                  /> */}
                  <span>Create Cart</span>
                </Link>
                <Link
                  to="/loads/active-load"
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30
                  ${
                    pathname.includes("/loads/load/") ? "bg-active-gradient" : ""
                  } 
                  `}
                  activeClassName="bg-active-gradient c-red active-sidebar"
                  onClick={() => onActiveCartClicked()}
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cart-check-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M.5 1a.5.5 0 0 0 0 1h1.11l.401 1.607 1.498 7.985A.5.5 0 0 0 4 12h1a2 2 0 1 0 0 4 2 2 0 0 0 0-4h7a2 2 0 1 0 0 4 2 2 0 0 0 0-4h1a.5.5 0 0 0 .491-.408l1.5-8A.5.5 0 0 0 14.5 3H2.89l-.405-1.621A.5.5 0 0 0 2 1H.5zM6 14a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm7 0a1 1 0 1 1-2 0 1 1 0 0 1 2 0zm-1.646-7.646l-3 3a.5.5 0 0 1-.708 0l-1.5-1.5a.5.5 0 1 1 .708-.708L8 8.293l2.646-2.647a.5.5 0 0 1 .708.708z" />
                  </svg>
                  <span>Active Carts</span>
                </Link>
                <Link
                  to="/loads/reports"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cart mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  {/* <img
                    src={CartIconRed}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  />
                  <img
                    src={CartIcon}
                    className="wt-25 sidebar-icon mr-8 active"
                  /> */}
                  <span>Load</span>
                </Link>
{ (hierarchy === 10 ) &&
                <Link
                  to="/loads/my-cart"
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30
                  ${
                    pathname.includes("/my-cart/load/") ? "bg-active-gradient" : ""
                  } 
                  `}
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-cart mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM3.102 4l1.313 7h8.17l1.313-7H3.102zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <span>My Cart</span>
                </Link>
}
              </div>
            )}
            {(canBuildCart || hierarchy === 10) && (
              <Link
                to="/invoices/settled"
                className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                  pathname.includes("/invoices") ? "bg-active-gradient" : ""
                }`}
                activeClassName={`${
                  pathname.includes("/invoices") ? "bg-active-gradient" : ""
                } active-sidebar`}
              >
                <svg
                  width="18"
                  viewBox="0.42 0 23.161 28"
                  fill="currentColor"
                  height="28"
                  className="mr-8"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="100%"
                    height="100%"
                    viewBox="0 0.077 23.097 27.923"
                  >
                    <path
                      paint-order="stroke fill markers"
                      fill-rule="evenodd"
                      d="M13.473 7.494V.077H1.443C.643.077 0 .661 0 1.387V26.69C0 27.416.644 28 1.443 28h20.21c.8 0 1.443-.584 1.443-1.309V8.803h-8.18c-.794 0-1.443-.589-1.443-1.309zm9.202-1.69L16.787.459a1.522 1.522 0 0 0-1.022-.382h-.367v6.981h7.698v-.333c0-.343-.15-.676-.42-.921zm-3.428 7.362v5.236c0 .482-.43.872-.962.872H4.812c-.532 0-.963-.39-.963-.872v-5.236c0-.482.431-.873.963-.873h13.473c.531 0 .962.391.962.873zM3.849 4.004c0-.241.216-.436.482-.436h4.811c.266 0 .481.195.481.436v.872c0 .242-.215.437-.48.437H4.33c-.266 0-.482-.195-.482-.437v-.872zm0 3.49c0-.24.216-.436.482-.436h4.811c.266 0 .481.195.481.436v.873c0 .24-.215.436-.48.436H4.33c-.266 0-.482-.195-.482-.436v-.873zm15.398 16.58c0 .24-.215.436-.481.436h-4.812c-.266 0-.481-.196-.481-.437v-.872c0-.241.215-.437.481-.437h4.812c.266 0 .481.196.481.437v.872zm-1.925-10.035H5.774v3.49h11.548v-3.49z"
                    />
                  </svg>
                </svg>
                {/* <InvoiceIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                {/* <img
                  src={InvoiceIcon}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                <img
                  src={InvoiceIcon}
                  className="wt-20 sidebar-icon mr-16 active"
                /> */}

                <span>Invoices</span>
              </Link>
            )}
            {(pathname.includes("/invoices") )&& (
              <div className="ml-12 sub-menu">
                <Link
                  to="/invoices/settled"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-receipt-cutoff mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                    <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198l.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
                  </svg>
                  {/* <img
                    src={}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  />
                  <img
                    src={InvoiceIcon}
                    className="wt-25 sidebar-icon mr-8 active"
                  /> */}
                  <span>settled</span>
                </Link>
                {hierarchy === 1 && (
                  <Link
                    to="/invoices/franchise"
                    className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                    activeClassName="bg-active-gradient c-red active-sidebar"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-receipt-cutoff mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                      <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198l.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
                    </svg>
                    {/* <img
                    src={}
                    className="wt-25 sidebar-icon mr-8 inactive"
                  />
                  <img
                    src={InvoiceIcon}
                    className="wt-25 sidebar-icon mr-8 active"
                  /> */}
                    <span>Franchise</span>
                  </Link>
                )}
                {(hierarchy === 10) && 
                          <Link
                            to={`/invoices/myInvoices`}
                            className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                              pathname.includes("/invoices/Franchise") ? "bg-active-gradient" : ""
                            }`}
                            activeClassName="bg-active-gradient c-red active-sidebar"
                          >
                             <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="16"
                      height="16"
                      fill="currentColor"
                      class="bi bi-receipt-cutoff mr-8"
                      viewBox="0 0 16 16"
                    >
                      <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zM11.5 4a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1zm0 2a.5.5 0 0 0 0 1h1a.5.5 0 0 0 0-1h-1z" />
                      <path d="M2.354.646a.5.5 0 0 0-.801.13l-.5 1A.5.5 0 0 0 1 2v13H.5a.5.5 0 0 0 0 1h15a.5.5 0 0 0 0-1H15V2a.5.5 0 0 0-.053-.224l-.5-1a.5.5 0 0 0-.8-.13L13 1.293l-.646-.647a.5.5 0 0 0-.708 0L11 1.293l-.646-.647a.5.5 0 0 0-.708 0L9 1.293 8.354.646a.5.5 0 0 0-.708 0L7 1.293 6.354.646a.5.5 0 0 0-.708 0L5 1.293 4.354.646a.5.5 0 0 0-.708 0L3 1.293 2.354.646zm-.217 1.198l.51.51a.5.5 0 0 0 .707 0L4 1.707l.646.647a.5.5 0 0 0 .708 0L6 1.707l.646.647a.5.5 0 0 0 .708 0L8 1.707l.646.647a.5.5 0 0 0 .708 0L10 1.707l.646.647a.5.5 0 0 0 .708 0L12 1.707l.646.647a.5.5 0 0 0 .708 0l.509-.51.137.274V15H2V2.118l.137-.274z" />
                             </svg>
                            <span>My Invoices</span>
                          </Link>
}
                {/* <Link
                  to="/invoices/pending"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-receipt mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M1.92.506a.5.5 0 0 1 .434.14L3 1.293l.646-.647a.5.5 0 0 1 .708 0L5 1.293l.646-.647a.5.5 0 0 1 .708 0L7 1.293l.646-.647a.5.5 0 0 1 .708 0L9 1.293l.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .708 0l.646.647.646-.647a.5.5 0 0 1 .801.13l.5 1A.5.5 0 0 1 15 2v12a.5.5 0 0 1-.053.224l-.5 1a.5.5 0 0 1-.8.13L13 14.707l-.646.647a.5.5 0 0 1-.708 0L11 14.707l-.646.647a.5.5 0 0 1-.708 0L9 14.707l-.646.647a.5.5 0 0 1-.708 0L7 14.707l-.646.647a.5.5 0 0 1-.708 0L5 14.707l-.646.647a.5.5 0 0 1-.708 0L3 14.707l-.646.647a.5.5 0 0 1-.801-.13l-.5-1A.5.5 0 0 1 1 14V2a.5.5 0 0 1 .053-.224l.5-1a.5.5 0 0 1 .367-.27zm.217 1.338L2 2.118v11.764l.137.274.51-.51a.5.5 0 0 1 .707 0l.646.647.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.646.646.646-.646a.5.5 0 0 1 .708 0l.509.509.137-.274V2.118l-.137-.274-.51.51a.5.5 0 0 1-.707 0L12 1.707l-.646.647a.5.5 0 0 1-.708 0L10 1.707l-.646.647a.5.5 0 0 1-.708 0L8 1.707l-.646.647a.5.5 0 0 1-.708 0L6 1.707l-.646.647a.5.5 0 0 1-.708 0L4 1.707l-.646.647a.5.5 0 0 1-.708 0l-.509-.51z" />
                    <path d="M3 4.5a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 1 1 0 1h-6a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h6a.5.5 0 0 1 0 1h-6a.5.5 0 0 1-.5-.5zm8-6a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5zm0 2a.5.5 0 0 1 .5-.5h1a.5.5 0 0 1 0 1h-1a.5.5 0 0 1-.5-.5z" />
                  </svg>
                
                  <span>pending</span>
                </Link> */}
              </div>
            )}
            {hierarchy === 1 || hierarchy === 10 || !minimumSettingsOnly ? (
              <>
                <Link
                  to="/entities/viewGroup"
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                    pathname.includes("/entities") ? "bg-active-gradient" : ""
                  }  `}
                  activeClassName="bg-active-gradient active-sidebar"
                >
                  {/* <EntitiesIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                  {/* <img
                src={UsersIcon}
                className="wt-20 sidebar-icon mr-16 inactive"
              />
              <img
                src={UsersIcon}
                className="wt-20 sidebar-icon mr-16 active"
              /> */}
                  <svg
                    width="28"
                    height="19"
                    viewBox="0 0.054 28 18.892"
                    fill="currentColor"
                    className="mr-8"
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="100%"
                      height="100%"
                      viewBox="0 0 27.666 18.667"
                    >
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M4.15 8c1.526 0 2.767-1.196 2.767-2.667 0-1.47-1.241-2.666-2.767-2.666-1.526 0-2.767 1.195-2.767 2.666C1.383 6.804 2.624 8 4.15 8zm19.367 0c1.526 0 2.766-1.196 2.766-2.667 0-1.47-1.24-2.666-2.766-2.666S20.75 3.862 20.75 5.333C20.75 6.804 21.99 8 23.517 8zM24.9 9.333h-2.767c-.76 0-1.448.296-1.95.775 1.743.921 2.98 2.584 3.247 4.559h2.853c.765 0 1.384-.596 1.384-1.334V12c0-1.47-1.241-2.667-2.767-2.667zm-11.067 0c2.676 0 4.842-2.087 4.842-4.666 0-2.58-2.166-4.667-4.842-4.667-2.676 0-4.841 2.087-4.841 4.667 0 2.579 2.165 4.666 4.841 4.666zm3.32 1.334h-.359a7.02 7.02 0 0 1-2.96.666 7.035 7.035 0 0 1-2.962-.666h-.359c-2.75 0-4.98 2.15-4.98 4.8v1.2c0 1.104.93 2 2.075 2h12.45c1.146 0 2.075-.896 2.075-2v-1.2c0-2.65-2.23-4.8-4.98-4.8zm-9.67-.559a2.812 2.812 0 0 0-1.95-.775H2.767C1.24 9.333 0 10.53 0 12v1.333c0 .738.618 1.334 1.383 1.334h2.85c.271-1.975 1.508-3.638 3.25-4.559z"
                      />
                    </svg>
                  </svg>
                  <span>
                    {hierarchy === 1 || hierarchy === 10
                      ? "Customers"
                      : "Profile"}
                  </span>
                </Link>
                {pathname.includes("/entities") && (
                  <div className="ml-12 sub-menu">
                    {/*                 
                <Link
                  to="/entities/viewGroup"
                  className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-people-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                    <path
                      fill-rule="evenodd"
                      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                    />
                    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                  </svg>

                  <span>{hierarchy === 1 ? "Customers" : "View Profile"}</span>
                </Link>
               */}
                    {(hierarchy === 1 || hierarchy === 10) && (
                      <>
                        {hierarchy !== 10 && (
                          <Link
                            to={`/entities/viewGroup`}
                            className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                              pathname.includes("/entities/viewGroup") ||
                              pathname.includes("/entities/users")
                                ? "bg-active-gradient"
                                : ""
                            } `}
                            activeClassName="bg-active-gradient c-red active-sidebar"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-people-fill mr-8"
                              viewBox="0 0 16 16"
                            >
                              <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              <path
                                fill-rule="evenodd"
                                d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                              />
                              <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                            </svg>
                            {/* <EntitiesIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                            {/* <img
                       src={FindIconActiveRed}
                       className="wt-20 sidebar-icon mr-16 inactive"
                     />
                     <img
                       src={FindIconInActive}
                       className="wt-20 sidebar-icon mr-16 active"
                     /> */}
                            <span>Customers and Users</span>
                          </Link>
                        )}
                         

                        {(canInviteUsers || hierarchy === 10) && (
                          <Link
                            to={`/entities/inviteUsers`}
                            className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                            activeClassName="bg-active-gradient c-red active-sidebar"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-plus-fill mr-8"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              <path
                                fill-rule="evenodd"
                                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                              />
                            </svg>
                            {/* <EntitiesIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                            {/* <img
                          src={FindIconActiveRed}
                          className="wt-20 sidebar-icon mr-16 inactive"
                        />
                        <img
                          src={FindIconInActive}
                          className="wt-20 sidebar-icon mr-16 active"
                        /> */}
                            <span>Invite Users</span>
                          </Link>
                        )}
                          {(hierarchy === 1) && (
                          <Link
                            to={`/entities/Franchise`}
                            className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                            activeClassName="bg-active-gradient c-red active-sidebar"
                          >
                            <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="16"
                              height="16"
                              fill="currentColor"
                              class="bi bi-person-plus-fill mr-8"
                              viewBox="0 0 16 16"
                            >
                              <path d="M1 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H1zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                              <path
                                fill-rule="evenodd"
                                d="M13.5 5a.5.5 0 0 1 .5.5V7h1.5a.5.5 0 0 1 0 1H14v1.5a.5.5 0 0 1-1 0V8h-1.5a.5.5 0 0 1 0-1H13V5.5a.5.5 0 0 1 .5-.5z"
                              />
                            </svg>
                            <span>Franchise</span>
                          </Link>
                        )}
                            {/* {(hierarchy === 1) && ( */}
                         
                        {/* )} */}
                      </>
                    )}
                  </div>
                )}
              </>
            ) : null}
            {(hierarchy === 1 || hierarchy === 10) && (
              <>
                {/* {(pathname.includes("/permissions") ||
                  pathname.includes("/roles")) && (
                  <div className="ml-12 sub-menu">
                    <Link
                      to="/roles"
                      className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                      activeClassName="bg-active-gradient c-red active-sidebar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-people-fill mr-8"
                        viewBox="0 0 16 16"
                      >
                        <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
                        <path
                          fill-rule="evenodd"
                          d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
                        />
                        <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
                      </svg>

                      <span>View Roles</span>
                    </Link>
                    <Link
                      to="/permissions"
                      className="sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto"
                      activeClassName="bg-active-gradient c-red active-sidebar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-unlock-fill mr-8"
                        viewBox="0 0 16 16"
                      >
                        <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                      </svg>

                      <span>View Permissions</span>
                    </Link>
                  </div>
                )} */}
                {canEditPricing && (
                  <Link
                    to="/pricing"
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                      pathname.includes("/previewPricing")
                        ? "bg-active-gradient"
                        : ""
                    }`}
                    activeClassName="bg-active-gradient active-sidebar"
                  >
                    {/* <PriceModifierIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                    <svg
                      className="mr-8"
                      width="18"
                      height="24"
                      viewBox="0.5 0 24 24"
                      fill="currentColor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="24"
                        viewBox="1 0.333 23.096 23.096"
                      >
                        <path
                          paint-order="stroke fill markers"
                          fill-rule="evenodd"
                          d="M10.239.333h-7.7C1.694.333 1 1.026 1 1.873v7.699c0 .847.693 1.54 1.54 1.54h7.699c.846 0 1.54-.693 1.54-1.54V1.873c0-.847-.694-1.54-1.54-1.54zm0 6.16h-7.7v-1.54h7.7v1.54zM22.557.332h-7.7c-.846 0-1.539.693-1.539 1.54V21.89c0 .847.693 1.54 1.54 1.54h7.699c.847 0 1.54-.693 1.54-1.54V1.873c0-.847-.693-1.54-1.54-1.54zm0 13.858h-7.7v-1.54h7.7v1.54zm0-4.619h-7.7v-1.54h7.7v1.54zm-12.318 3.08h-7.7c-.846 0-1.539.692-1.539 1.54v7.698c0 .847.693 1.54 1.54 1.54h7.699c.846 0 1.54-.693 1.54-1.54v-7.699c0-.847-.694-1.54-1.54-1.54zm0 6.159h-3.08v3.08H5.62v-3.08H2.54v-1.54h3.08v-3.08h1.54v3.08h3.08v1.54z"
                        />
                      </svg>
                    </svg>
                    {/* <img
                      src={PricingIcon}
                      className="wt-20 sidebar-icon mr-16 inactive"
                    />
                    <img
                      src={PricingIcon}
                      className="wt-20 sidebar-icon mr-16 active"
                    /> */}

                    <span>Pricing</span>
                  </Link>
                )}
              </>
            )}
            {(isSA || hierarchy === 10) && (
              <Link
                to={hierarchy === 10 ? "/config/cart-config" : "/config"}
                className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                  pathname.includes("/config") ? "bg-active-gradient" : ""
                } `}
                activeClassName="bg-active-gradient active-sidebar"
              >
                <img
                  src={Settings}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                {/* <CartIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                {/* <img
                  src={Settings}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                <img
                  src={CartIcon}
                  className="wt-20 sidebar-icon mr-16 active"
                /> */}

                <span>Config</span>
              </Link>
            )}
            {pathname.includes("/config") && (
              <div className="ml-12 sub-menu">
                {hierarchy !== 10 && (
                  <>
                    <Link
                      to="/config/bulk-upload"
                      className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                        pathname.includes("/config") &&
                        pathname.includes("/config/bulk-upload")
                          ? "bg-active-gradient"
                          : ""
                      }`}
                      activeClassName="bg-active-gradient c-red active-sidebar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-upload mr-8"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 1.146a.5.5 0 0 1 .708 0l3 3a.5.5 0 0 1-.708.708L8.5 2.707V11.5a.5.5 0 0 1-1 0V2.707L5.354 4.854a.5.5 0 1 1-.708-.708l3-3z" />
                      </svg>
                      <span>Converters</span>
                    </Link>
                    <Link
                      to="/config/download-customers"
                      className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                        pathname.includes("/download-customers")
                          ? "bg-active-gradient"
                          : ""
                      }`}
                      activeClassName="bg-active-gradient c-red active-sidebar"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="16"
                        height="16"
                        fill="currentColor"
                        class="bi bi-download mr-8"
                        viewBox="0 0 16 16"
                      >
                        <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                        <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                      </svg>
                      <span>Download Customers</span>
                    </Link>
                  </>
                )}

                {hierarchy === 1 && (
                  <Link
                    to={"/config/sageinvoice"}
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                      pathname.includes("/sageinvoice")
                        ? "bg-active-gradient"
                        : ""
                    } `}
                    activeClassName="bg-active-gradient active-sidebar"
                  >
                    <svg
                      width="18"
                      viewBox="0.42 0 23.161 28"
                      fill="currentColor"
                      height="28"
                      className="mr-8"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="100%"
                        height="100%"
                        viewBox="0 0.077 23.097 27.923"
                      >
                        <path
                          paint-order="stroke fill markers"
                          fill-rule="evenodd"
                          d="M13.473 7.494V.077H1.443C.643.077 0 .661 0 1.387V26.69C0 27.416.644 28 1.443 28h20.21c.8 0 1.443-.584 1.443-1.309V8.803h-8.18c-.794 0-1.443-.589-1.443-1.309zm9.202-1.69L16.787.459a1.522 1.522 0 0 0-1.022-.382h-.367v6.981h7.698v-.333c0-.343-.15-.676-.42-.921zm-3.428 7.362v5.236c0 .482-.43.872-.962.872H4.812c-.532 0-.963-.39-.963-.872v-5.236c0-.482.431-.873.963-.873h13.473c.531 0 .962.391.962.873zM3.849 4.004c0-.241.216-.436.482-.436h4.811c.266 0 .481.195.481.436v.872c0 .242-.215.437-.48.437H4.33c-.266 0-.482-.195-.482-.437v-.872zm0 3.49c0-.24.216-.436.482-.436h4.811c.266 0 .481.195.481.436v.873c0 .24-.215.436-.48.436H4.33c-.266 0-.482-.195-.482-.436v-.873zm15.398 16.58c0 .24-.215.436-.481.436h-4.812c-.266 0-.481-.196-.481-.437v-.872c0-.241.215-.437.481-.437h4.812c.266 0 .481.196.481.437v.872zm-1.925-10.035H5.774v3.49h11.548v-3.49z"
                        />
                      </svg>
                    </svg>
                    <span>Sage Invoice</span>
                  </Link>
                )}

                <Link
                  to="/config/cart-config"
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none  pl-16  flex flex-middle ht-30 ${
                    pathname.includes("/cart-config")
                      ? "bg-active-gradient"
                      : ""
                  }`}
                  activeClassName="bg-active-gradient c-red active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="18"
                    height="27"
                    fill="currentColor"
                    className="bi bi-cart-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M0 1.5A.5.5 0 0 1 .5 1H2a.5.5 0 0 1 .485.379L2.89 3H14.5a.5.5 0 0 1 .491.592l-1.5 8A.5.5 0 0 1 13 12H4a.5.5 0 0 1-.491-.408L2.01 3.607 1.61 2H.5a.5.5 0 0 1-.5-.5zM5 12a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm7 0a2 2 0 1 0 0 4 2 2 0 0 0 0-4zm-7 1a1 1 0 1 1 0 2 1 1 0 0 1 0-2zm7 0a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" />
                  </svg>
                  <span>Facility Activation</span>
                </Link>
                <Link
                  to="/config/facilities"
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                    pathname.includes("/facilities") ? "bg-active-gradient" : ""
                  }`}
                  activeClassName="bg-active-gradient active-sidebar"
                >
                  {/* <PriceModifierIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                  {/* <img
                    src={FacilitiesIcon}
                    className="wt-20 sidebar-icon mr-16 inactive"
                  />
                  <img
                    src={FacilitiesIcon}
                    className="wt-20 sidebar-icon mr-16 active"
                  /> */}

                  <svg
                    className="mr-8"
                    width="22"
                    viewBox="0 0 25 29"
                    height="28
                    "
                    fill="currentColor"
                  >
                    <svg xmlns="http://www.w3.org/2000/svg" width="100%">
                      <path
                        paint-order="stroke fill markers"
                        fill-rule="evenodd"
                        d="M10.5 0C4.695 0 0 4.695 0 10.5 0 18.375 10.5 30 10.5 30S21 18.375 21 10.5C21 4.695 16.305 0 10.5 0zm0 14.25c-2.07 0-3.75-1.68-3.75-3.75 0-2.07 1.68-3.75 3.75-3.75 2.07 0 3.75 1.68 3.75 3.75 0 2.07-1.68 3.75-3.75 3.75z"
                      />
                    </svg>
                  </svg>

                  <span>Facilities</span>
                </Link>
                {isAdmin && hierarchy !== 10 && (
                  <Link
                    to="/config/roles"
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                      pathname.includes("/roles") ||
                      pathname.includes("/permissions")
                        ? "bg-active-gradient"
                        : ""
                    }`}
                    activeClassName="bg-active-gradient active-sidebar"
                  >
                    <svg
                      className="mr-8"
                      width="18"
                      height="27"
                      viewBox="0.638 0 23.724 27"
                      fill="currentColor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="27"
                        viewBox="1 0.714 23.096 26.286"
                      >
                        <path
                          paint-order="stroke fill markers"
                          fill-rule="evenodd"
                          d="M21.622 13.857H8.836V8.564c0-2.033 1.635-3.722 3.676-3.743 2.062-.02 3.748 1.648 3.748 3.697v.821c0 .683.552 1.232 1.237 1.232h1.65c.686 0 1.238-.549 1.238-1.232v-.821c0-4.313-3.532-7.82-7.863-7.804-4.33.016-7.81 3.568-7.81 7.88v5.263H3.475A2.47 2.47 0 0 0 1 16.321v8.215A2.47 2.47 0 0 0 3.475 27h18.147a2.47 2.47 0 0 0 2.474-2.464V16.32a2.47 2.47 0 0 0-2.474-2.464zm-7.012 7.804a2.057 2.057 0 0 1-2.062 2.053 2.057 2.057 0 0 1-2.062-2.053v-2.465c0-1.134.923-2.053 2.062-2.053 1.14 0 2.062.919 2.062 2.053v2.465z"
                        />
                      </svg>
                    </svg>

                    <span>Roles & Permissions</span>
                  </Link>
                )}
                {hierarchy === 10 && (
                  <Link
                    to="/config/roles"
                    className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto ${
                      pathname.includes("/roles") ? "bg-active-gradient" : ""
                    }`}
                    activeClassName="bg-active-gradient active-sidebar"
                  >
                    <svg
                      className="mr-8"
                      width="18"
                      height="27"
                      viewBox="0.638 0 23.724 27"
                      fill="currentColor"
                    >
                      <svg
                        xmlns="http://www.w3.org/2000/svg"
                        width="25"
                        height="27"
                        viewBox="1 0.714 23.096 26.286"
                      >
                        <path
                          paint-order="stroke fill markers"
                          fill-rule="evenodd"
                          d="M21.622 13.857H8.836V8.564c0-2.033 1.635-3.722 3.676-3.743 2.062-.02 3.748 1.648 3.748 3.697v.821c0 .683.552 1.232 1.237 1.232h1.65c.686 0 1.238-.549 1.238-1.232v-.821c0-4.313-3.532-7.82-7.863-7.804-4.33.016-7.81 3.568-7.81 7.88v5.263H3.475A2.47 2.47 0 0 0 1 16.321v8.215A2.47 2.47 0 0 0 3.475 27h18.147a2.47 2.47 0 0 0 2.474-2.464V16.32a2.47 2.47 0 0 0-2.474-2.464zm-7.012 7.804a2.057 2.057 0 0 1-2.062 2.053 2.057 2.057 0 0 1-2.062-2.053v-2.465c0-1.134.923-2.053 2.062-2.053 1.14 0 2.062.919 2.062 2.053v2.465z"
                        />
                      </svg>
                    </svg>

                    <span>Roles</span>
                  </Link>
                )}
              </div>
            )}
            {(hierarchy === 10 || hierarchy === 1) && (
              <>
                <Link
                  to={"/settings-config"}
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                    pathname.includes("/settings") ? "bg-active-gradient" : ""
                  } `}
                  activeClassName="bg-active-gradient active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-gear-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                  </svg>

                  {/* <CartIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                  {/* <img
                  src={Settings}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                <img
                  src={CartIcon}
                  className="wt-20 sidebar-icon mr-16 active"
                /> */}

                  <span>Settings</span>
                </Link>
              </>
            )}
            {(hierarchy === 10 || hierarchy === 1) && (
              <>
                <Link
                  to={"/sales"}
                  className={`sidebar-link bg-transparent fs-16   c-white decoration-none pt-16 pb-16 pl-16  flex flex-middle ht-auto  ${
                    pathname.includes("/sales") ? "bg-active-gradient" : ""
                  } `}
                  activeClassName="bg-active-gradient active-sidebar"
                >
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-gear-fill mr-8"
                    viewBox="0 0 16 16"
                  >
                    <path d="M9.405 1.05c-.413-1.4-2.397-1.4-2.81 0l-.1.34a1.464 1.464 0 0 1-2.105.872l-.31-.17c-1.283-.698-2.686.705-1.987 1.987l.169.311c.446.82.023 1.841-.872 2.105l-.34.1c-1.4.413-1.4 2.397 0 2.81l.34.1a1.464 1.464 0 0 1 .872 2.105l-.17.31c-.698 1.283.705 2.686 1.987 1.987l.311-.169a1.464 1.464 0 0 1 2.105.872l.1.34c.413 1.4 2.397 1.4 2.81 0l.1-.34a1.464 1.464 0 0 1 2.105-.872l.31.17c1.283.698 2.686-.705 1.987-1.987l-.169-.311a1.464 1.464 0 0 1 .872-2.105l.34-.1c1.4-.413 1.4-2.397 0-2.81l-.34-.1a1.464 1.464 0 0 1-.872-2.105l.17-.31c.698-1.283-.705-2.686-1.987-1.987l-.311.169a1.464 1.464 0 0 1-2.105-.872l-.1-.34zM8 10.93a2.929 2.929 0 1 1 0-5.86 2.929 2.929 0 0 1 0 5.858z" />
                  </svg>

                  {/* <CartIcon className="wt-20 ht-20 sidebar-icon mr-16" />  */}
                  {/* <img
                  src={Settings}
                  className="wt-20 sidebar-icon mr-16 inactive"
                />
                <img
                  src={CartIcon}
                  className="wt-20 sidebar-icon mr-16 active"
                /> */}

                  <span>Sales History</span>
                </Link>
              </>
            )}
          </div>
        </div>
        <div
          style={{ bottom: 5 }}
          className="p-absolute t-center flex flex-middle flex-center wt-100p l-0"
        >
          <img src={Logo} className="wt-150 t-hide sidebar-logo bottom" />
        </div>
        {isLoading && (
          <div
            style={{ left: "100%", top: "50%" }}
            className="ht-80 p-relative"
          >
            <ActionLoader />
          </div>
        )}
      </div>
    </>
  );
};

export default Sidebar;
