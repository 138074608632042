import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import HeaderIcon from "../../icons/newicons/receipt.svg";
import IconUserAdd from "../../icons/rev_add_user.png";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import PageLayout from "../../components/PageLayout";
import FormInput from "../../components/FormInput";
import { getLoginDetails } from "../../utils/storage";
import Table from "../../components/Table";
import ActionLoader from "../../components/ActionLoader";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";
import moment from "moment";
import SearchIcon from "../../icons/pos_find.png";
import SearchIconWhite from "../../icons/rev_find.png";
import InvoicePDF from "../InvoicePDF";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import "react-datepicker/dist/react-datepicker.css";
import CurrencyFormat from "react-currency-format";

import {
  GET_ALL_LOADS_URL,
  GET_ALL_SAGE_LOADS_URL,
  getData,
  postData,
  putData,
  CREATE_NEW_SESSION,
  deleteData,
  ADD_LOAD_URL,
  GET_USERS_URL,
  GET_ENTITIES_URL,
  GET_FACILITIES_URL,
  DOWNLOAD_INVOICES,
  downloadInvoices,
} from "../../utils/api";
let TIMER = null;
const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};
const Invoices = (props) => {
    const loginDetail = getLoginDetails() || {};
    const {hierarchy} = getLoginDetails();
  //   const isSeller = loginDetail.hierarchy == 2;
  //   const canAcceptLoadRequest = loginDetail.canAcceptLoadRequest;
  const canDeleteInvoice = loginDetail.canDeleteInvoice || false;
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);


  const [loadsList, setLoadsList] = useState([]);
  const [companyName, setCompanyNames] = useState([]);
  const [userItems, setUserItems] = useState([]);
  const [selectedPandingLoad, setSelectedPendingLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [printPopup, setPrintPopup] = useState(null);
  const [date, setDateSort] = useState("asc");
  const [amount, setAmountSort] = useState("asc");
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [loadForDelete, setLoadForDelete] = useState(null);
  const [companyId, setCompanyId] = useState(null);
  const [params, setParams] = useState({
    limit: 40,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const jsonToUrlParams = (json) => {
    return Object.keys(json)
      .map(function (k) {
        if (typeof json[k] != "undefined" && json[k] !== "") {
          return encodeURIComponent(k) + "=" + encodeURIComponent(json[k]);
        }
        return "";
      })
      .filter((item) => {
        return item;
      })
      .join("&");
  };
  //   const [showConfirmationModal, setShowConfimationModal] = useState(false);
  const getLoads = async (type = 3, isPathChange) => {
    console.log("startDate",startDate);
    console.log("endDate",endDate);
       const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
       const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
    try {
      if (isPathChange && params.offset !== 0) {
        setParams({
          ...params,
          offset: 0,
          areMoreItemsAvailable: false, //data.areMoreItemsAvailable,
        });
      } else {
        setLoadsList([]);
        const payload = {
          count: isPathChange ? 10 : params.limit,
          offset: isPathChange ? 0 : params.offset,
          text: isPathChange ? "" : searchValue,
          type,
          companyId:props.match.path.includes("franchise")?companyId?companyId:'':'',
          from:startDt,
          to:endDt,
          facility:facility?facility:undefined,
          buyer:
          Object.keys(selectedBuyerItem || {}).length > 0
            ? selectedBuyerItem.userId
            : undefined,
        customer:
          Object.keys(selectedItem || {}).length > 0
            ? selectedItem.companyId
            : undefined,
        };
        if(type === 4 && props.match.path.includes("myInvoices")){
          payload.isMyCarts = true;
        }
        if(type === 1) {
          payload.status = 1;
        } else {
          payload.date = date;
          payload.amount = amount;
        }
        const data = await getData(type === 1 ? GET_ALL_SAGE_LOADS_URL:GET_ALL_LOADS_URL, payload);
        console.log(companyId);
        // setCompanyId('');
        // setEndDate('');
        // setStartDate('');
        // jsonToUrlParams
        setIsLoading(false);
        setTableLoading(false);
        setLoadsList(data.list);
        setParams({
          ...params,
          areMoreItemsAvailable: data.areMoreItemsAvailable,
        });
      }
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const onSearchChange = (val) => {
    setSearchValue(val);
  };
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  //   const handleRequestAccept = async () => {
  //     setShowConfimationModal(false);
  //     try {
  //       await putData(CREATE_NEW_SESSION, {
  //         loadId: selectedPandingLoad.loadId,
  //       });
  //       setTableLoading(true);
  //       getLoads(1);
  //       getLoads(2);
  //     } catch (e) {
  //       console.log("ERROR", e);
  //       setToastParams({
  //         type: "ERROR",
  //         message: "Something went wrong please try agian later",
  //         id: new Date().getTime(),
  //       });
  //     }
  //   };
  //   const hendelPendingLoadClick = async (item) => {
  //     setShowConfimationModal(true);
  //     setSelectedPendingLoad(item);
  //   };
  const hendelUserSelect = (item) => {
    if(hierarchy === 2 && window.location.pathname.includes('/settled')){
      return
    }
    const url = window.location.pathname.includes('/settled')?'/invoices/settled/':'/invoices/Franchise/'
    props.history.push(`${url}load/${item.loadId}`, {
      settledView: props.match.path,
    });
  };
  const showPrintPopup = (item) => {
    setPrintPopup(item);
  };

  const isFirstRun = useRef(true); 

  const setDeleteLoad = (item) => {
    setIsDeletePopupShown(true);
    setLoadForDelete(item);
  };

  const deleteLoad = async () => {
    setIsLoading(true)
    setIsDeletePopupShown(false);
    setLoadForDelete(null);
    try {
      await deleteData(ADD_LOAD_URL, {
        cartId: loadForDelete.loadId,
        isInvoice: true,
      });
      setTableLoading(true);
      getTabBasedData();
      setIsLoading(false)
      setToastParams({
        type: "SUCCESS",
        message: "Deleted Successfully",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
      setIsLoading(false)
    }
  };
  const getUsers = async () => {
    try {
      setIsLoading(true);
      // setEntitiesItems([]);

      const payload = {
        count: 50,
        offset: params.offset,
        text: params.text,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
        isEmbed: props.isEmbed || false,
        customerSort: 'asc'
      };

      payload.isFranchise = true;
      const data = await getData(GET_ENTITIES_URL, payload);
      setIsLoading(false);
      setCompanyNames(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {}
  };

  useEffect(() => {
    getTabBasedData();
  }, [searchValue, params.offset, params.limit]);

  const getTabBasedData = (isPathChange) => {
    if (props.match.path.includes("settled")) {
      getLoads(4, isPathChange);
    } else if (props.match.path.includes("franchise")) {
      getLoads(10, isPathChange);
    } else if (props.match.path.includes("sageinvoice")) {
      getLoads(1, isPathChange);
    }else if (props.match.path.includes("myInvoices")) {
      getLoads(4, isPathChange);
    }
     else {
      getLoads(3, isPathChange);
    }
  };

  const tableKeys = () => {
    const keys = [
      "loadNameFormulated",
      "date",
      "group",
      !props.match.path.includes("settled") ?!props.match.path.includes("myInvoices")?"assistantFullName":'':'',
      !props.match.path.includes("sageinvoice")?"parentCompanyName":"message",
      "facilityCity",
      "payForwardDate",
      "amountPaid",
    ]; // "calculatedTotal",

    // if (!props.match.path.includes("settled")) {
    //     keys.push("dueDaysVal")
    // }
    // keys.push("statusVal")
    return keys.filter(item => item !== '');
  };

  useEffect(() => {
    getUsers()
  }, []);
  const tableColumns = () => {
    const columns = [
      "Invoice #" ,

      () => {
        return (
          <span>
            Date{" "}
            {/* {date === "asc" ? (
              <span
                className={"fs-16 c-pointer"}
                onClick={() => setDateSort("desc")}
              >
                ↓
              </span>
            ) : (
              <span
                className={"fs-16 c-pointer"}
                onClick={() => setDateSort("asc")}
              >
                ↑
              </span>
            )} */}
          </span>
        );
      },
      "CUSTOMER ",
      "Buyer",
      !props.match.path.includes("sageinvoice")? (!props.match.path.includes("settled") ) ?!props.match.path.includes("myInvoices")?"Franchise":'':'':"Message",
      "Facility",
      "Payment Date",
      () => {
        return (
          <span>
            Amount Paid{" "}
            {/* {amount === "asc" ? (
              <span
                className={"fs-16 c-pointer"}
                onClick={() => setAmountSort("desc")}
              >
                ↓
              </span>
            ) : (
              <span
                className={"fs-16 c-pointer"}
                onClick={() => setAmountSort("asc")}
              >
                ↑
              </span>
            )} */}
          </span>
        );
      },
    ]; // "Total",

    // if (!props.match.path.includes("settled")) {
    //     columns.push("Due days")
    // }
    // columns.push("status")
    console.log("COL", columns);
    return columns.filter(item => item !== '');
  };



  const [facility, setFacility] = useState("");
  const [faacilities, setFaciltiesItems] = useState([]);

  
  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(
        (data.list && data.list.filter((el) => el.isActive)) || []
      );
    } catch (e) {}
  };
  useEffect(() => {
    getFacilities();
    return () => {
      setFaciltiesItems([]);
    };
  }, []);

  const [buyer, setBuyer] = useState("");
  const [buyerItems, setBuyerItems] = useState([]);
  const [showBuyerDropdown, setShowBuyerDropdown] = useState(false);
  const [selectedBuyerItem, setSelectedBuyerItem] = useState({});
  

  const getBuyers = async (text) => {
    try {
      const data = await getData(GET_USERS_URL, {
        count: 500,
        offset: 0,
        text,
        companyId: getLoginDetails().companyId,
        isBuyer: true,
      });
      setBuyerItems(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  const [customer, setCustomer] = useState("");
  const [entitiesItems, setEntitiesItems] = useState([]);
  const [showDropdownDiv, setShowDropdownDiv] = useState(false);
  const [selectedItem, setSelectedItem] = useState({});
  
  const getEntities = async (text, isFranchise = false) => {
    try {
      const data = await getData(GET_ENTITIES_URL, {
        count: 500,
        offset: 0,
        text,
        parentCompanyId: getLoginDetails().parentCompanyId,
        isEmbed: false,
        isFranchise,
        // customerSort: 'asc'
      });
      setEntitiesItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };

  useEffect(()=>{
    resetData()
  },[props.match.path])

  useEffect(() => {
    if(startDate === null && endDate === null && facility === '' && buyer === '' && customer === ''){
    getTabBasedData()
    }
  }, [startDate,endDate,facility,buyer,customer,props.match.path]);

  const resetData =() => {
    setStartDate(null);
    setEndDate(null)
    setCompanyId('');
    setFacility("");
    setBuyer("");
    setCustomer("");
    setEntitiesItems([]);
    setShowDropdownDiv(false);
    setBuyerItems([]);
    setShowBuyerDropdown(false);
    setSelectedItem({});
    setSelectedBuyerItem({});
  }

  const downloadInvoices2 = () => {
    const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
    const data = {
      type:props.match.path.includes("settled")?1:2,
      from:startDt,
      to:endDt,
      facility:facility?facility:undefined,
      buyer:
      Object.keys(selectedBuyerItem || {}).length > 0
        ? selectedBuyerItem.userId
        : undefined,
    customer:
      Object.keys(selectedItem || {}).length > 0
        ? selectedItem.companyId
        : undefined,
      companyId:props.match.path.includes("franchise")?companyId?companyId:'':'',
    }
    downloadInvoices(jsonToUrlParams(data))
      .then((res) => {
        console.log(res);
        const element = document.createElement("a");
        element.setAttribute("href", res.link);
        element.setAttribute("target","_blank");
        element.setAttribute("download", true);
        element.click();
      })
      .catch((err) => {
        console.log(err);
      });
  }

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
      return;
    }
    // getTabBasedData()
  }, [props.match.path]);


  return (
    <PageLayout
      icon={HeaderIconSvg}
      heading="Invoices"
      searchPlaceholder="Search Invoice"
      onSearchChange={onSearchChange}
    >
      {isLoading ? (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-middle flex-between">
            <div className="flex flex-middle">
              {/* <h1 className="mr-24 fs-24 c-gray mb-4"> */}
                {/* {`${
                  props.match.path.includes("settled") ? "SETTLED" : "PENDING"
                } REQUESTS`} */}
                {/* SETTLED INVOICES
              </h1> */}
            </div>
          </div>
         
          {/* {props.match.path.includes("franchise") && */}
          <div  className="costSheetFilters" style={{display:'flex', flexDirection:'row',justifyContent: 'flex-start'}}>
            {window.location.pathname.includes('franchise') &&
            <div >
        <div className="c-gray fs-12"> Franchise </div>

        <div className="ht-40 wt-150 p-relative t-15"  style={{ zIndex: 1 }}>
            <select
              class="form-select rounded-pill"
              name=""
              id=""
              value={companyId}
              onChange={(e) => {
                setCompanyId(e.target.value);
              }}
            >
              <option >Select </option>
            {companyName.filter((v,i,a)=>a.findIndex(t=>(t.companyName===v.companyName))===i).map((parentCompanyName) => (
            <option value={`${parentCompanyName.companyId}`}>{parentCompanyName.companyName}</option>
        ))}
            </select>
            </div>
            </div>
}
            {/* <div  style={{ display:'flex', flexDirection:'row'}}> */}
            
             <div style={{paddingLeft:'30px'}}>
              <div className="c-gray fs-12"> Start Date </div>
              <DatePicker
                selected={startDate?startDate:Date.now()}
                onChange={(date) => setStartDate(date)}
                selectsStart
                startDate={startDate}
                endDate={endDate}
                className="form-control"
              />
             </div>

              <div style={{paddingLeft:'50px'}}>
                <div className="c-gray fs-12"> End Date </div>
                <DatePicker
                  selected={endDate?endDate:Date.now()}
                  onChange={(date) => setEndDate(date)}
                  selectsEnd
                  startDate={startDate}
                  endDate={endDate}
                  minDate={startDate}
                  className="form-control"
                />
              </div>
{ (hierarchy !== 2) && (
  <>
            {window.location.pathname.includes('settled') &&
              <div style={{paddingLeft:'50px'}} className="facility-filter">
            <div className="mb-16"> </div>

            <select
              name="type"
              className="select font-primary fs-14 form-select"
              value={facility}
              onChange={(e) => setFacility(e.target.value)}
            >
              <option value={""}>Select Facility</option>
              {faacilities.map((fac, idx) => (
                <option key={idx} value={fac.name}>
                  {fac.name}
                </option>
              ))}
            </select>
              </div>
            }      
{window.location.pathname.includes('settled') &&

              <div style={{paddingLeft:'50px'}} className="buyer-filter">
              <div className="mb-16"> </div>
              <div className=" p-relative">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={buyer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Buyer Filter"
                onChange={(e) => {
                  setBuyer(e.target.value);
                  if(e.target.value.length ===0){
                    setBuyer('');
                    getBuyers('')
                    setSelectedBuyerItem([])
                    setShowBuyerDropdown(false);

                  }
                  if ((e.target.value || "").length > 2) {
                    getBuyers(e.target.value);
                    setShowBuyerDropdown(true);
                  }
                }}
              />

              {showBuyerDropdown && buyerItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300"
                    style={{ overflow: "scroll" }}
                  >
                    {[...buyerItems].map((item) => {
                      return (
                        <div
                          key={`buyer-${item.companyId}`}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowBuyerDropdown(false);
                            setSelectedBuyerItem(item);
                            setBuyer(`${item.firstName} ${item.lastName}`);
                          }}
                        >
                          <div className="fs-14 mb-4">
                            {item.firstName} {item.lastName}
                          </div>
                        </div>
                      );
                    })}
                  </div>
                </div>
               )}
            </div>
              </div>
}
{window.location.pathname.includes('settled') &&
              <div  className="customer-filter">
            <div className="mb-16"> </div>
            <div className=" p-relative ">
              <img src={SearchIcon} className="wt-20 t-8 p-absolute l-8" />
              <input
                value={customer}
                className=" form-control top_form_input forminput fs-16  ht-35 search pl-32"
                placeholder="Customer Filter"
                onChange={(e) => {
                  setCustomer(e.target.value);
                  if(e.target.value.length === 0){
                    setSelectedItem('');
                    getEntities('');
                    setShowDropdownDiv(false);
                  }
                  if ((e.target.value || "").length > 2) {
                    setCustomer(e.target.value);
                    getEntities(e.target.value);
                    setShowDropdownDiv(true);
                  }
                }}
              />

              {showDropdownDiv && entitiesItems.length > 0 && (
                <div className="p-absolute z-1 border-black">
                  <div
                    className="pl-8 pr-8 bg-lt-gray wt-200 z-1 max-ht-300 "
                    style={{ overflow: "scroll" }}
                  >
                    {[...entitiesItems].map((item) => {
                      return (
                        <div
                          key={item.companyId}
                          className="flex selectListDiv c-pointer"
                          onClick={() => {
                            setShowDropdownDiv(false);
                            setEntitiesItems([]);
                            setSelectedItem(item);
                            setCustomer(item.companyName);
                          }}
                        >
                          <div className="fs-14 mb-4">{item.companyName}</div>
                        </div>
                      );
                    })}
                  </div>
                </div>
              )}
            </div>
              </div>
}
</>
)
      }
            {/* </div> */}
            {/* <span> */}
            <div style={{ display:'flex' }}>
            <div className="search-button ">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              style={{marginTop:"15px", marginLeft:'27px'}}
              onClick={() => {
                getTabBasedData()
              }}
            >
              <img
                src={SearchIconWhite}
                alt="searchicon"
                width="20px"
                height="20px"
              />
            </div>
        </div>
        <div className="search-button">
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center  closeicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={() => {
                resetData()
              }
              }
              style={{marginTop:"15px", marginLeft:'27px'}}
            >
              <span className="fs-16 c-white"> X </span>
            </div>
          </div>
{hierarchy !== 2 &&

          <div class="search-button  ml-10">
          <div
            class="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            // style={{ marginLeft: "10px", marginTop:'13px'}}
            style={{marginTop:"15px", marginLeft:'27px'}}
            onClick={downloadInvoices2}
          >
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="16"
              height="16"
              fill="currentColor"
              class="bi bi-download"
              viewBox="0 0 16 16"
            >
              <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
              <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
            </svg>
            
          </div>
          </div>
}

          </div>
            {/* </span> */}
            </div>

{/* } */}

{loadsList.length === 0 && (
            <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
              {`No ${
                props.match.path.includes("settled") ? "SETTLED" : "PENDING"
              } REQUESTS`}
            </div>
          )}
         

          {loadsList.length > 0 && (
            <Table
              scrollTable={true}
              highlight={true}
              columns={tableColumns()}
              modalColumn={['message']}
              items={loadsList.map((load, index) => {
                  const createdDate = new Date(
                  load.updatedDate || load.createdDate
                );
                const dd = String(createdDate.getDate()).padStart(2, "0");
                const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yyyy = createdDate.getFullYear();
                let hours = createdDate.getHours();
                let minutes = createdDate.getMinutes();
                const ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? "0" + minutes : minutes;
                if (!props.match.path.includes("settled")) {
                  load.dueDaysVal = `${load.dueDays} Days`;
                }

                const calTotal = ReactDOMServer.renderToStaticMarkup(
                  <CurrencyFormat
                    value={
                      Number(load.discount || 0) + Number(load.subtotal || 0)
                    }
                    displayType={"text"}
                    thousandSeparator={true}
                    prefix={"$"}
                    renderText={(val) => val}
                  />
                );
                return {
                  ...load,
                  group: load.companyName,
                  assistantFullName:
                    load.assistantFirstName + " " + load.assistantLastName,
                    loadNameFormulated: `#${load.invoiceNo}`,
                  // loadNameFormulated: `semicolon-separated-inline-${load.firstName} ${load.lastName}; #${load.invoiceNo}`,
                  location: load.city + ", " + load.state,
                  date: `${mm}-${dd}-${yyyy}`, // ${hours}:${minutes}${ampm}
                  statusVal: STATUS_MAPPING[load.status],
                  payForwardDate: load.payForwardDate?moment(load.payForwardDate).format(
                    "MM-DD-YYYY"
                  ):"-",
                  parentCompanyName: `${load.assistantFirstName + " " + load.assistantLastName}`,
                  amountPaid: ReactDOMServer.renderToStaticMarkup(
                    <CurrencyFormat
                      value={Number(load.amountPaid || 0)}
                      displayType={"text"}
                      thousandSeparator={true}
                      prefix={"$"}
                      renderText={(val) => val}
                    />
                  ), // `$${load.amountPaid || 0}`,
                  calculatedTotal: calTotal, //`$${Number(load.discount || 0) + Number(load.subtotal || 0)}`,
                };
              })}
              keys={tableKeys()}
              widthClasses={[
                "wt-140",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "flex-1",
                "wt-100",
              ]}
              actions={(!props.match.path.includes("sageinvoice") && !props.match.path.includes("myInvoices"))?canDeleteInvoice?["DELETE", "PRINT"]:["PRINT"]:false}
              onPrint={showPrintPopup}
              onDelete={setDeleteLoad}
              // isCart={true}
              hideCheckBox={true}
              onItemClick={hendelUserSelect}
              isLoading={tableLoading}
              offset={params.offset}
              areMoreItemsAvailable={params.areMoreItemsAvailable}
              onCountChange={(value) => {
                setParams({
                  ...params,
                  offset: 0,
                  limit: value,
                });
              }}
              fetchMore={fetchMore}
              fetchLess={fetchLess}
              limit={params.limit}
            />
          )}
        </>
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="Invoice"
          name={''} //loadForDelete.loadName
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteLoad}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {Object.keys(printPopup || {}).length > 0 && (
        <Modal style={{}}>
          <InvoicePDF setPrintPopup={setPrintPopup} itemData={printPopup} hidePreview={true}/>
        </Modal>
      )}
    </PageLayout>
  );
};

export default Invoices;
