import React from 'react'

const Button = ({type, className, children, ...props}) => {
    return (
        <button
            className={`${className || ''} button ${type || 'primary'}`}
            {...props}
        >
            {children}
        </button>
    )
}

export default Button
