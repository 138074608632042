/* eslint-disable default-case */
import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import PageLayout from "../../components/PageLayout";
import Table from "../../components/Table";
import Button from "../../components/Button";
import DeleteConfirmation from "../../components/DeleteConfirmation";
import Toast from "../../components/Toast";

import RolesAddEdit from "../../components/AddEditItems/RolesAddEdit";

import IconAdd from "../../icons/rev_permissions.png";
import IconSearch from "../../icons/pos_find.png";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIcon from "../../icons/newicons/people-fill.svg";

import { getLoginDetails } from "../../utils/storage";

import IconUsersInactive from "../../icons/rev_user.png";
import IconGroupsActive from "../../icons/pos_permissions.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

import {
  GET_ROLES_URL,
  GET_PERMISSIONS_URL,
  ADD_ROLES_URL,
  EDIT_ROLES_URL,
  DELETE_ROLES_URL,
  postData,
  getData,
  putData,
  deleteData,
} from "../../utils/api";

const roleModel = {
  name: "",
  permissions: [],
  roleId: undefined,
};

const TYPES = [
  {
    value: "Internal",
    id: 1,
  },
  {
    value: "External",
    id: 2,
  },
  {
    value: "Franchise",
    id: 3,
  },
];

export const Roles = () => {
  const [rolesItems, setRolesItems] = useState([]);
  const [permissionItems, setPermissionItems] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [hasMore, setHasMore] = useState(true);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
    text: "",
  });
  const [isEditPopupShown, setIsEditPopupShown] = useState(false);
  const [isClonePopupShown, setIsClonePopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [itemForEdit, setItemForEdit] = useState(null);
  const [itemForDelete, setItemForDelete] = useState(null);
  const [selectedType, setSelectedType] = useState(null);
  const { isSA, hierarchy } = getLoginDetails();
  // const isSA = false;
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getRolesAndPermissions = async () => {
    try {
      setIsLoading(true);
      setRolesItems([]);
      const payload = {
        count: params.limit,
        offset: params.offset,
        text: params.text,
        companyId: getLoginDetails().companyId,
      };
      if (selectedType) {
        payload.type = selectedType;
      }
      const data = await getData(GET_ROLES_URL, payload);
      setIsLoading(false);
      setRolesItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getPermissions = async () => {
    try {
      const data = await getData(GET_PERMISSIONS_URL, {
        count: 100,
      });
      setIsLoading(false);
      setPermissionItems(
        data.list.map((permission) => {
          return {
            id: permission.permissionId,
            value: permission.permissionName,
            type: (permission.type || "").replace(/(^\w|\s\w)/g, (m) =>
              m.toUpperCase()
            ),
          };
        })
      );
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);

  useEffect(() => {
    getRolesAndPermissions();
  }, [params.offset, params.text, params.limit, selectedType]);
  useEffect(() => {
    getPermissions();
  }, []);

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };
  const onSearchChange = (text) => {
    setParams({
      ...params,
      text,
      offset: 0,
    });
  };
  const addItem = async (data) => {
    try {
      const response = await postData(ADD_ROLES_URL, {
        ...data,
        companyId: getLoginDetails().companyId,
      });
      setIsAddPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Role",
        id: new Date().getTime(),
      });
      getRolesAndPermissions();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data) => {
    try {
      const response = await putData(EDIT_ROLES_URL, {
        ...data,
        companyId: getLoginDetails().companyId,
      });
      setIsEditPopupShown(false);
      setToastParams({
        type: "SUCCESS",
        message: "Changes Applied.",
        id: new Date().getTime(),
      });
      getRolesAndPermissions();
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteItem = async () => {
    try {
      const response = await deleteData(DELETE_ROLES_URL, {
        roleId: itemForDelete.roleId,
      });
      setIsDeletePopupShown(false);
      getRolesAndPermissions();
    } catch (e) {
      console.log("ERROR");
    }
  };
  const setEdit = (item, isClone = false) => {
    if (isClone) {
      setIsClonePopupShown(true);
    } else {
      setIsEditPopupShown(true);
    }
    setItemForEdit({
      roleId: item.roleId,
      type: item.type,
      name: isClone ? "" : item.name,
      isFranchise: item.isFranchise,
      permissions: (item.permissions || []).map((permission) => {
        return permission.permissionId !== "canViewAllFacilityInvoices"
          ? {
              id: permission.permissionId,
              value: permission.permissionName,
              type: (permission.type || "").replace(/(^\w|\s\w)/g, (m) =>
                m.toUpperCase()
              ),
            }
          : {
              id: permission.permissionId,
              value: permission.permissionName,
              facilities: permission.facilities,
              type: (permission.type || "").replace(/(^\w|\s\w)/g, (m) =>
                m.toUpperCase()
              ),
            };
      }),
    });
  };
  const setDelete = (item) => {
    setIsDeletePopupShown(true);
    setItemForDelete(item);
  };

  const formatPermissions = (permissions) => {
    let data = {};
    permissions.map((el) => {
      if (!el || !el.type) {
        el.type = "";
      } else {
        el.type = el.type.replace(/(^\w|\s\w)/g, (m) => m.toUpperCase());
      }
      if (!data[el.type]) {
        data[el.type] = [];
      }
      data[el.type].push(el);
    });
    return data;
  };

  const getAssignedTo = (type) => {
    switch (type) {
      case 1:
        return "Internal";
      case 2:
        return "External";
      case 3:
        return "Franchise";
      default:
        return "-";
    }
  };

  return (
    <PageLayout
      icon={HeaderIconSvg}
      onSearchChange={onSearchChange}
      heading="Roles"
      searchPlaceholder="Search Roles"
      hideSearchBar={true}
    >
      {!isAddPopupShown && !isEditPopupShown && !isClonePopupShown && (
        <div
          className={`flex flex-middle mt-8 ${
            isSA ? "flex-between" : "flex-left"
          }`}
        >
          <div className="flex flex-between flex-middle">
            {/* <img src={IconSearch} className="ht-30 wt-30 mr-12" />
                    <h1 className="mr-24 fs-20 c-gray">VIEW ROLES</h1> */}
          </div>
          <div className={`tabs flex ${isSA ? "p-absolute" : ""}`} style={{}}>
            <Link
              to="/config/roles"
              className={`decoration-none ht-60 flex flex-middle pl-16 pr-16 fw-bolder bg-white c-gray fw-bolder'`}
              onClick={() => {}}
            >
              <svg
                xmlns="http://www.w3.org/2000/svg"
                width="30"
                height="24"
                fill="currentColor"
                class="bi bi-person-fill"
                viewBox="0 0 16 16"
              >
                <path d="M3 14s-1 0-1-1 1-4 6-4 6 3 6 4-1 1-1 1H3zm5-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
              </svg>
              <span>Roles</span>
            </Link>
            {isSA && hierarchy !== 10 && (
              <Link
                to="/config/permissions"
                className={`decoration-none ht-60 flex flex-middle pl-16 pr-16 fw-bolder c-pointer bg-lt-gray-2 c-white border-gray`}
                onClick={() => {}}
              >
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="30"
                  height="24"
                  fill="currentColor"
                  class="bi bi-unlock-fill"
                  viewBox="0 0 16 16"
                >
                  <path d="M11 1a2 2 0 0 0-2 2v4a2 2 0 0 1 2 2v5a2 2 0 0 1-2 2H3a2 2 0 0 1-2-2V9a2 2 0 0 1 2-2h5V3a3 3 0 0 1 6 0v4a.5.5 0 0 1-1 0V3a2 2 0 0 0-2-2z" />
                </svg>
                <span>Permissions</span>
              </Link>
            )}
            {hierarchy === 1 && (
              <div className="mr-40 t-12" style={{ border: 0 }}>
                <select
                  name="type"
                  className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
                  value={selectedType}
                  onChange={(e) => setSelectedType(e.target.value)}
                >
                  <option value={""}>Filter Type</option>
                  {TYPES.map((fac, idx) => (
                    <option key={idx} value={fac.id}>
                      {fac.value}
                    </option>
                  ))}
                </select>
              </div>
            )}
          </div>
          {rolesItems.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={params.offset > 0 ? fetchLess : () => {}}
                  />
                </div>
                <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + rolesItems.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable ? fetchMore : () => {}
                    }
                  />
                </div>
              </div>
            )}
          {/* <Button type="secondary" onClick={()=> {setIsAddPopupShown(true)}}>Add item</Button> */}
          {isSA && (
            <div
              className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer mb-8"
              onClick={() => {
                setIsAddPopupShown(true);
              }}
            >
              <img src={IconAdd} className="ht-20 wt-20 mr-12" />
              <span>Add Role</span>
            </div>
          )}
        </div>
      )}
      {!isAddPopupShown && !isEditPopupShown && !isClonePopupShown && (
        <Table
          scrollTable={true}
          type={hierarchy === 10 ? "rolesgrid" : ""}
          columns={
            hierarchy === 10 ? ["Role", "Permissions"] : ["Role", "Type"]
          }
          // className="wt-600"
          items={rolesItems.map((role, index) => {
            return {
              ...role,
              permissionDisplay: formatPermissions(role.permissions || []),
              assignedTo: getAssignedTo(role.type),
              showTopBorder: hierarchy !== 10 ? false : index ? true : false,
            };
          })}
          keys={
            hierarchy === 10
              ? ["name", "permissionDisplay"]
              : ["name", "assignedTo"]
          }
          actions={
            hierarchy === 10
              ? []
              : isSA
              ? ["EDIT", "CLONE", "DELETE"]
              : ["EDIT", "CLONE"]
          }
          widthClasses={["wt-200", "flex-1"]}
          onEdit={setEdit}
          onClone={(item) => setEdit(item, true)}
          onDelete={setDelete}
          onChange={onSearchChange}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
              areMoreItemsAvailable: false,
            });
          }}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          isLoading={isLoading}
          headerBgWhite={hierarchy === 10 ? true : false}
          hideCheckBox={true}
        />
      )}
      {isAddPopupShown && (
        <RolesAddEdit
          isLoading={isLoading}
          item={{ ...roleModel }}
          permissions={permissionItems}
          onClose={setIsAddPopupShown.bind(null, false)}
          onBack={setIsAddPopupShown.bind(null, false)}
          onSave={addItem}
          types={TYPES}
          type="add"
        />
      )}
      {isEditPopupShown && (
        <RolesAddEdit
          isLoading={isLoading}
          item={{ ...itemForEdit }}
          permissions={permissionItems}
          onClose={setIsEditPopupShown.bind(null, false)}
          onBack={setIsEditPopupShown.bind(null, false)}
          onSave={editItem}
          type="edit"
          types={TYPES}
          selected={itemForEdit.permissions}
        />
      )}

      {isClonePopupShown && (
        <RolesAddEdit
          isLoading={isLoading}
          item={{ ...itemForEdit }}
          permissions={permissionItems}
          onClose={setIsClonePopupShown.bind(null, false)}
          onBack={setIsClonePopupShown.bind(null, false)}
          onSave={addItem}
          type="clone"
          types={TYPES}
          selected={itemForEdit.permissions}
        />
      )}

      {isDeletePopupShown && (
        <DeleteConfirmation
          type="role"
          name={itemForDelete.name}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteItem}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Roles;
