import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import FormInput from "../../components/FormInput";
import Button from "../../components/Button";
import Checkbox from "../../components/Checkbox";
import Toast from "../../components/Toast";

import { SIGNIN_IN_URL, FORGOT_PASSWORD_URL, postData } from "../../utils/api";
import { storeToken, storeLoginDetails } from "../../utils/storage";

import BottomImage from "./bottom.png";
import TopImage from "./top.png";
// import Logo from './Logo.png';

import Logo1 from "../../icons/abcattech.png";
import Logo2 from "../../icons/newicons/bottomSidebarLogo.png";
import BG from "../../icons/bg.png";
import EYE from "../../icons/eye.png";
// import  Logo from '../../icons/hBird.png';

import { getToken } from "../../utils/storage";

const SIGN_IN = "SIGN_IN";

const FORGOT_PASSWORD = "FORGOT_PASSWORD";

const NEW_USER = "NEW_USER";

function getWindowDimensions() {
  const { innerWidth: width, innerHeight: height } = window;
  return {
    width,
    height,
  };
}

export const Signin = (props) => {
  const isInvite = props.match.path === "/invite";
  const {params: { username } } = props.match;
  const [email, setEmail] = useState(username?username:'');
  const [password, setPassword] = useState("");
  const [seePassword, setSeePassword] = useState(false);

  const [authenticationType, setAuthenticationType] = useState("SIGN_IN");

  const [checkRememberMe, setCheckRememberMe] = useState(false);

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const [isMobile, setIsMobile] = useState(false);
  const [windowDimensions, setWindowDimensions] = useState(
    getWindowDimensions()
  );

  useEffect(() => {
    function handleResize() {
      setWindowDimensions(getWindowDimensions());
    }

    window.addEventListener("resize", handleResize);
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  useEffect(() => {
    if (windowDimensions.width < 720) {
      setIsMobile(true);
    } else {
      setIsMobile(false);
    }
  }, [windowDimensions]);

  useEffect(() => {
    if (getToken()) {
      props.history.push("/dashboard");
    }
  }, []);

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      if (authenticationType === SIGN_IN) {
        const data = await postData(SIGNIN_IN_URL, {
          email,
          password,
          isRegistration: isInvite,
        });
        if (!isInvite) {
          storeToken(data.token);
        }
        storeLoginDetails(data);
        localStorage.setItem("company URL",data.companyLogo);
        if (data.isNewUser) {
          props.history.push("new-user");
          window.location.reload();
        } else {
          if(!isMobile) {
            props.history.push(
              data.hierarchy === 2 || data.hierarchy === 5
                ? "/converters/identify"
                : "/converters/identify"
            );
          } else {
            props.history.push("/loads/active-load");
          }
         
          window.location.reload();
        }
      } else if (authenticationType === FORGOT_PASSWORD) {
        const data = await postData(FORGOT_PASSWORD_URL, {
          email,
        });
        // alert("Email Sent");
        setToastParams({
          type: "SUCCESS",
          message: "Email sent successfully",
          id: new Date().getTime(),
        });
      }
    } catch (e) {
      // alert("Wrong Credentials");
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Invalid Credentials",
        id: new Date().getTime(),
      });
    }
  };
  const onForgotPasswordSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const data = await postData(FORGOT_PASSWORD_URL, {
        email,
      });
      alert("Email Sent");
    } catch (e) {
      // alert("Wrong Email");?\
    }
  };

  return (
    <div
      className=""
      style={{
        // background: `linear-gradient( rgba(0, 0, 0, 0.6), rgba(0, 0, 0, 0.6) ), url(${BG})`,
        backgroundImage: `url(${BG})`,
        backgroundSize: "cover",
        backgroundRepeat: "no-repeat",
        width: "100vw",
        height: "100vh",
      }}
    >
      <div>
        <div className="bg-black page-center box-shadow wt-100p ht-550 op-7"></div>
        <form
          onSubmit={onSubmit}
          style={{ padding: "30px 50px", borderRadius: "30px" }}
          className="page-center box-shadow wt-400 bg-white"
        >
          {/* <h1 className="t-center mb-40 mt-40">Login</h1> */}
          <div className="t-center ">
            <img src={Logo1} className="wt-200 mb-24" />
            <img src={Logo2} className="wt-200" />
          </div>
          <div className="" style={{ margin: "auto" }}>
            <div className="mt-20 mb-20">
              <FormInput
                inputClassName="rounded-pill bg-blue"
                inputType="login"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                value={email}
                type="email"
              />
            </div>
            {authenticationType === SIGN_IN && (
              <div className="mb-20 p-relative">
                <FormInput
                  inputClassName="rounded-pill bg-blue"
                  inputType="login"
                  placeholder="Password"
                  type={seePassword ? "text" : "password"}
                  onChange={(e) => {
                    setPassword(e.target.value);
                  }}
                  value={password}
                />
                <img
                  src={EYE}
                  style={{ top: "49px" }}
                  className="wt-25 p-absolute   r-4  c-pointer"
                  onClick={(e) => {
                    setSeePassword(!seePassword);
                  }}
                />
              </div>
            )}
            {authenticationType === SIGN_IN && !isInvite && (
              <div className="mt-32 flex flex-middle">
                <Checkbox
                  isSelected={checkRememberMe}
                  label="Remember me"
                  onClick={() => {
                    setCheckRememberMe(!checkRememberMe);
                  }}
                  labelClassName="c-black fs-12"
                />
                {/* <span className="pl-8">Remember me</span> */}
              </div>
            )}
            {/* <div>
                            <Link className="flex flex-middle fs-12 c-pointer c-white mt-20" to={'/new-user/1'}>
                                <span onClick={()=> {setAuthenticationType(NEW_USER)}}>REGISTER</span>
                            </Link>
                        </div> */}
            <div className=" flex flex-between flex-middle">
              {authenticationType === SIGN_IN && !isInvite && (
                <span
                  className="fs-12 c-pointer c-black"
                  onClick={() => {
                    setAuthenticationType(FORGOT_PASSWORD);
                  }}
                >
                  Forgot Password?
                </span>
              )}
              {authenticationType === FORGOT_PASSWORD && !isInvite && (
                <span
                  className="fs-12 c-pointer c-black"
                  onClick={() => {
                    setAuthenticationType(SIGN_IN);
                  }}
                >
                  Signin
                </span>
              )}
              {authenticationType === SIGN_IN && (
                <Button className="btn">
                  {" "}
                  {isInvite ? "Register" : "Login"}
                </Button>
              )}
              {authenticationType === FORGOT_PASSWORD && (
                <Button className="btn">Send Email</Button>
              )}
            </div>
          </div>
        </form>
      </div>
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </div>
  );
};
export default Signin;
