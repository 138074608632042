/* eslint-disable jsx-a11y/alt-text */
import * as React from "react";

import ChooseImageIcon from "../../icons/newicons/uploadIcon.webp";

import ImageUploading from "react-images-uploading";

import Button from "../Button";
// { ImageUploadingPropsType, ImageListType, ImageType } is type for typescript
const maxNumber = 10;
const maxMbFileSize = 5 * 1024 * 1024; // 5Mb

export default class Example extends React.Component {
  onChange = (imageList) => {
    this.props.onChange(imageList);
  };

  render() {
    return (
      <ImageUploading
        onChange={this.onChange}
        maxNumber={maxNumber}
        multiple={this.props.isMultiple || false}
        maxFileSize={maxMbFileSize}
        acceptType={["jpg", "gif", "png", "jpeg", "csv", "xlsx", "xls"]}
        defaultValue={this.props.initialImages || []}
      >
        {({ imageList, onImageUpload, onImageRemoveAll }) => {
          return (
            // write your building UI
            <div className="flex">
              {imageList.length === 0 && (
                <div
                  className="flex flex-column mb-32"
                  onClick={!this.props.hideAction ? onImageUpload : () => {}}
                >
                  {this.props.initialImage && (
                    <img
                      className="wt-100 mb-16 "
                      src={this.props.initialImage}
                    />
                  )}
                  {/* <Button type="modalSave" >Upload image</Button>  */}
                  {!this.props.initialImage && (
                    <div
                      style={{
                        borderRadius: "20px",
                        borderColor: "#000",
                        justifyContent: "center",
                      }}
                      className="flex flex-middle wt-190 border-gray-dashed pl-16 pr-16 pb-16 pt-16 text-center"
                    >
                      <div>
                        <img
                          style={{ margin: "0 auto" }}
                          className="wt-100"
                          src={this.props.initialImage || ChooseImageIcon}
                        />
                      </div>

                      {/* <img
                        className="wt-100 "
                        src={this.props.initialImage || ChooseImageIcon}
                      />
                       */}
                    </div>
                  )}
                </div>
              )}
              {/* <button onClick={onImageRemoveAll}>Remove all images</button> */}
              {imageList.length > 0 && (
                <div className="flex">
                  {imageList.map((image, index) => (
                    <div key={image.key} className="flex flex-column mr-40">
                      <img
                        src={image.dataURL}
                        className="wt-150 mb-32"
                        onClick={!this.props.hideAction?image.onUpdate:() => {}}
                      />
                      {/* <button onClick={image.onUpdate}>Update</button>
                  <button onClick={image.onRemove}>Remove</button> */}
                      {!this.props.hideAction && this.props.isMultiple && (
                        <div className="">
                          <div className="flex ">
                            <Button
                              className="btn ht-40 btn-red"
                              onClick={image.onRemove}
                            >
                              Remove
                            </Button>
                            {/* <Button type="modalSave" className="ml-20"  onClick={onImageUpload}>Add image</Button> */}
                          </div>
                        </div>
                      )}
                    </div>
                  ))}
                  {!this.props.hideAction && this.props.isMultiple && (
                    <div
                      className="flex flex-middle flex-between ht-150 wt-190 border-gray-dashed pl-16 pr-16 pb-16 pt-16"
                      onClick={onImageUpload}
                    >
                      <img className="wt-100 " src={ChooseImageIcon} />
                      {/* <div className="c-gray fs-16 fw-bolder">CHOOSE IMAGE</div> */}
                    </div>
                  )}
                </div>
              )}
            </div>
          );
        }}
      </ImageUploading>
    );
  }
}
