import React, { useState, useEffect } from "react";
import PageLayout from "../../components/PageLayout";

import FormInput from "../../components/FormInput";
import Toast from "../../components/Toast";
import Button from "../../components/Button";

import { postData, CHANGE_PASSWORD_URL } from "../../utils/api";

export const Settings = (props) => {
  const [password, setPassword] = useState("");
  const [currentPassword, setCurrentPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");

  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const onSubmit = async (e) => {
    e.preventDefault();
    e.stopPropagation();
    try {
      const data = await postData(CHANGE_PASSWORD_URL, {
        currentPassword,
        newPassword: password,
        confirmPassword,
      });
      setToastParams({
        type: "SUCCESS",
        message: "Password changed successfully",
        id: new Date().getTime(),
      });
    } catch (e) {
      setToastParams({
        type: "ERROR",
        message: "Oops! Something went wrong.",
        id: new Date().getTime(),
      });
    }
  };

  return (
    <PageLayout heading="SETTINGS">
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
          width: "35%",
        }}
      >
        <form onSubmit={onSubmit}>
          <FormInput
            placeholder="Current Password"
            onChange={(e) => {
              setCurrentPassword(e.target.value);
            }}
            className="mr-40 "
            value={currentPassword}
            type="password"
            inputClassName="rounded-pill bg-blue"
          />
          <FormInput
            placeholder="Set Password"
            onChange={(e) => {
              setPassword(e.target.value);
            }}
            className="mr-40"
            value={password}
            type="password"
            inputClassName="rounded-pill bg-blue"
          />
          <FormInput
            placeholder="Confirm Password"
            onChange={(e) => {
              setConfirmPassword(e.target.value);
            }}
            className="mr-40 mb-60"
            value={confirmPassword}
            type="password"
            inputClassName="rounded-pill bg-blue"
          />
          <Button className="mt-10 btn ">Change Password</Button>
        </form>
      </div>

      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
    </PageLayout>
  );
};

export default Settings;
