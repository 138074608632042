import React, { useState } from "react";

import Toggle from "react-toggle";

import Modal from "../Modal";
import Button from "../Button";

import FormInput from "../FormInput";
import SelectCheckbox from "../SelectCheckbox";

import ImageUploading from "./ImageUpload";

import CaretIcon from "../../icons/caret.png";

import { STATES_LIST } from "../../utils/api";

// const STATES = [ 'Florida', 'Wisconsin', 'Georgia'];

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (value.length > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};

const EntityAddEdit = ({
  item,
  onClose,
  onSave,
  type,
  selected,
  roles,
  onBack,
  hideBack = false,
  isLoading = false,
}) => {

  //edit state
  const [editNotes, setEditNotes] = useState(undefined);
  const [editSmsNumber, setEditSmsNumber] = useState(undefined);
  const [editIsActive, setEditIsActive] = useState(undefined);
  const [editSelectedRoles, setEditSelectedRoles] = useState(undefined);
  
  const [companyEditName, setEditCompanyName] = useState(undefined);
  const [editCompanyType, setEditCompanyType] = useState(undefined);
  const [editAddress, setEditAddress] = useState(undefined);
  const [editAddress1, setEditAddress1] = useState(undefined);
  const [editCity, setEditCity] = useState(undefined);
  const [editState, setEditState] = useState(undefined);
  const [editZipcode, setEditZipcode] = useState(undefined);
  const [editfacility, setEditFacility] = useState(undefined);
  const [editEmail, setEditEmail] = useState(undefined);
  const [editPhone, setEditPhone] = useState(normalizeInput(undefined));
  const [editDriversLicense, setEditDriversLicense] = useState(undefined);
  const [editFirstName, setEditFirstName] = useState(undefined);
  const [editLastName, setEditLastName] = useState(undefined);
  const [editBusinessLicense, setEditBusinessLicense] = useState(undefined);
  const [editIsPriceModifierPercantage, setEditIsPriceModifierPercantage] = useState(undefined);
  const [editCanSeeMarkup, setEditCanSeeMarkup] = useState(undefined);
  const [editCanBuildCart, setEditCanBuildCart] = useState(undefined);
  const [editCanSeeSubtotal, setEditCanSeeSubtotal] = useState(undefined);
  const [editEin, setEditEin] = useState(undefined);
  const [editPriceMod, setEditPriceMod] = useState(undefined);
  const [editIsPictureMandatory, setEditIsPictureMandatory] = useState(undefined);
  const [editCanSeeUnitPrice, setEditCanSeeUnitPrice] = useState(undefined);
  const [editImage, editSetImage] = useState(undefined);
  const [editModifierInputValue, setEditModifierInputValue] = useState(undefined);
  /////
  const [email, setEmail] = useState(item.email);
  const [companyId, setCompanyId] = useState(item.companyId);
  const [password, setPassword] = useState(item.password);
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [address, setAddress] = useState(item.address);
  const [city, setCity] = useState(item.city);
  const [state, setState] = useState(item.state);
  const [zipcode, setZipcode] = useState(item.zipcode);
  const [phone, setPhone] = useState(normalizeInput(item.phone));
  const [notes, setNotes] = useState(item.notes);
  const [smsNumber, setSmsNumber] = useState(normalizeInput(item.smsNumber));
  const [driversLicense, setDriversLicense] = useState(item.driversLicense);
  const [isActive, setIsActive] = useState(item.isActive);
  const [isAdmin, setIsAdmin] = useState(item.isAdmin);
  console.log("selected", selected);
  const [selectedRoles, setSelectedRoles] = useState(selected || []);

  const [image, setImage] = useState(null);
  const [driversLicenseUrl, setDriversLicenseUrl] = useState(null);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [validations, setValidations] = useState({});
  const validateFields = () => {
    const updatedValidations = { ...validations };
    updatedValidations.email = email;
    updatedValidations.password = type === "add" ? password : true;
    updatedValidations.firstName = firstName;
    updatedValidations.lastName = lastName;
    updatedValidations.address = address;
    updatedValidations.city = city;
    updatedValidations.zipcode = zipcode;
    updatedValidations.state = state;
    updatedValidations.phone = phone;
    updatedValidations.notes = true;
    updatedValidations.smsNumber = true;
    updatedValidations.selectedRoles = selectedRoles.length;
    console.log("selectedRoles.length", selectedRoles);
    setValidations(updatedValidations);
    return updatedValidations;
  };

  return (
    // <Modal>
    <div className="mt-8">
      {!hideBack ? (
        <div className="flex flex-middle c-pointer mb-20" onClick={onBack}>
          <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
          <span className="fs-12 c-table-header fw-bolder">Back to Users</span>
        </div>
      ) : null}

      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="fs-24 fw-bolder mb-40">
          {type === "add" ? "Add" : "Edit"} User
        </div>

        <div className="flex flex-between flex-middle mb-40 flex-wrap">
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `First Name <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setFirstName(e.target.value);
              setEditFirstName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={firstName}
            hasError={hasSubmitted && !validations.firstName}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Last Name <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setLastName(e.target.value);
              setEditLastName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={lastName}
            hasError={hasSubmitted && !validations.lastName}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Phone <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setPhone(normalizeInput(e.target.value));
              setEditPhone(normalizeInput(e.target.value));
            }}
            className="mr-40 wt-33p"
            value={phone}
            hasError={hasSubmitted && !validations.phone}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Email <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setEmail(e.target.value);
              setEditEmail(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={email}
            hasError={hasSubmitted && !validations.email}
          />
          {type === "add" && (
            <FormInput
              inputClassName="rounded-pill bg-blue"
              placeholder={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Password <span style='color:red;'>*</span>`,
                  }}
                ></div>
              }
              onChange={(e) => {
                setPassword(e.target.value);
              }}
              className="mr-40 wt-33p"
              value={password}
              hasError={hasSubmitted && !validations.password}
            />
          )}
        </div>
        <div className="flex flex-between flex-middle mb-40 flex-wrap">
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Address <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setAddress(e.target.value);
              setEditAddress(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={address}
            hasError={hasSubmitted && !validations.address}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `City <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setCity(e.target.value);
              setEditCity(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={city}
            hasError={hasSubmitted && !validations.city}
          />
          <div className=" mr-40 wt-33p">
            <div className="c-gray fs-14 mb-4">
              State <span style={{ color: "red" }}>*</span>
            </div>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
              value={state}
              onChange={(e) =>{
 
               setState(e.target.value)
               setEditState(e.target.value)
              }
              }
            >
              <option value={""}>Select State</option>
              {(STATES_LIST || []).map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </select>
            {hasSubmitted && !validations.state && (
              <div className="fs-10 c-error fw-bold p-absolute">
                Please select valid state
              </div>
            )}
          </div>
          {/*<FormInput   inputClassName="rounded-pill bg-blue" 
                        placeholder="State"
                        onChange={(e)=> {
                            setState(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={state}
                        hasError={hasSubmitted && !validations.state}

                    />*/}
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Zipcode <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }
              setZipcode((e.target.value || "").replace(/[^\d]/g, ""));
              setEditZipcode((e.target.value || "").replace(/[^\d]/g, ""));
            }}
            className="mr-40 wt-33p"
            value={(zipcode || "").replace(/[^\d]/g, "")}
            hasError={hasSubmitted && !validations.zipcode}
          />

          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Notes"
            onChange={(e) => {
              setNotes(e.target.value);
              setEditNotes(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={notes}
            hasError={hasSubmitted && !validations.notes}
          />
          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="SMS Number"
            onChange={(e) => {
              setSmsNumber(normalizeInput(e.target.value));
              setEditSmsNumber(normalizeInput(e.target.value));
            }}
            className="mr-40 wt-33p"
            value={smsNumber}
            hasError={hasSubmitted && !validations.smsNumber}
          />

          <div className="wt-33p mr-40 p-relative t--8 mt-20">
            <div className="fs-14 mb-4 fw-bold">Is Active?</div>
            <Toggle
              className="bg-theme"
              onChange={(e) => {
                setIsActive(e.target.checked);
                setEditIsActive(e.target.checked);
              }}
              checked={isActive}
            />
          </div>
          {/* <div className="wt-33p mr-40 p-relative t--8 mt-20">   
                        <div className="fs-14 mb-4 fw-bold">Is Admin?</div>
                        <Toggle 
                            className="bg-theme"
                            onChange={(e)=> {
                                setIsAdmin(e.target.checked)
                            }}
                            checked={isAdmin || false}
                        />
                    </div> */}
          <div className="wt-33p mr-40 mt-20">
            <div className="c-gray fs-14 mb-4">
              Select Role <span style={{ color: "red" }}>*</span>
            </div>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
              value={
                selectedRoles && selectedRoles[0] && selectedRoles[0]["id"]
                  ? selectedRoles[0]["id"]
                  : ""
              }
              onChange={(e) => {
                setSelectedRoles(
                  roles.find((el) => el.id === e.target.value)
                    ? [roles.find((el) => el.id === e.target.value)]
                    : {}
                );
                setEditSelectedRoles(
                  roles.find((el) => el.id === e.target.value)
                    ? [roles.find((el) => el.id === e.target.value)]
                    : {}
                );
              }}
            >
              <option value={""}>Select Role</option>
              {(roles || []).map((role) => {
                return <option value={role.id}>{role.value}</option>;
              })}
            </select>
            {hasSubmitted && !validations.selectedRoles ? (
              <span
                style={{ color: "#d0021b", fontWeight: "bold", fontSize: 10 }}
              >
                Please select the valid value
              </span>
            ) : null}
            {/* <SelectCheckbox
              selectPlaceholder={"Select Role"}
              selected={selectedRoles}
              items={roles}
              setSelectedItems={setSelectedRoles}
              selectSingle={true}
              hasError={hasSubmitted && !validations.selectedRoles}
            /> */}
          </div>
          <div className="wt-33p mr-40 ">
            <div className="c-gray fs-14 mb-4">Profile Image</div>
            <div className="flex flex-middle">
              <ImageUploading
                onChange={setImage}
                initialImage={item.profileUrl}
              />
            </div>
          </div>
          {/*<div className="mr-40 wt-33p"></div>*/}

          <FormInput
            inputClassName="rounded-pill bg-blue"
            placeholder="Driver License"
            onChange={(e) => {
              setDriversLicense(e.target.value);
              setEditDriversLicense(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={driversLicense}
            // hasError={hasSubmitted && !validations.driversLicense}
          />
          <div className="wt-33p mr-40 ">
            <div className="c-gray fs-14 mb-4">Driver's License</div>
            <div className="flex flex-middle">
              <ImageUploading
                onChange={setDriversLicenseUrl}
                initialImage={item.driversLicenseUrl}
              />
            </div>
          </div>
          <div className="mr-40 wt-33p"></div>
          {/* <div className="wt-33p mr-40"></div> */}
        </div>
        <div className="flex flex-middle flex-right">
          <Button className="mr-20 btn ht-40 btn-red" onClick={onClose}>
            Cancel
          </Button>
          <Button
            className="btn"
            type="modalSave"
            onClick={() => {
              if (isLoading) {
                return;
              }
              setHasSubmitted(true);
              const updatedValidations = validateFields();
              if (
                !updatedValidations.email ||
                !updatedValidations.password ||
                !updatedValidations.firstName ||
                !updatedValidations.lastName ||
                !updatedValidations.address ||
                !updatedValidations.city ||
                !updatedValidations.state ||
                !updatedValidations.zipcode ||
                !updatedValidations.phone ||
                !updatedValidations.notes ||
                !updatedValidations.selectedRoles ||
                !updatedValidations.smsNumber ||
                !updatedValidations.selectedRoles
              ) {
                return;
              }

              onSave(
                {
                  email:type === 'edit'?editEmail:email,
                  password: type === "add" ? password : undefined,
                  firstName: type === 'edit'?editFirstName:firstName,
                  lastName:type === 'edit'?editLastName:lastName,
                  address:type === 'edit'?editAddress:address,
                  city:type === 'edit'?editCity:city,
                  state:type === 'edit'?editState:state,
                  zipcode:type === 'edit'?editZipcode:zipcode,
                  phone:type === 'edit'?editPhone:phone,
                  notes:type === 'edit'?editNotes:notes,
                  smsNumber:type === 'edit'?editSmsNumber:smsNumber,
                  driversLicense:type === 'edit'?editDriversLicense:driversLicense,
                  driversLicenseUrl,
                  roleId:
                    selectedRoles && selectedRoles[0] && selectedRoles[0]["id"]
                      ? selectedRoles[0]["id"]
                      : "",
                  userId: item.userId,
                  profileUrl: item.profileUrl,
                  isActive:type === 'edit'?editIsActive:isActive,
                  companyId,
                  // isAdmin
                },
                image ? image[0] : null,
                driversLicenseUrl ? driversLicenseUrl[0] : null
              );
            }}
          >
            {" "}
            {isLoading ? "Loading..." : "Save"}
            {/* {type === 'add'?'Add': 'Edit'} item */}
          </Button>
        </div>
      </div>
    </div>
    // </Modal>
  );
};

export default EntityAddEdit;
