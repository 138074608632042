import React, { useState } from "react";
import OutsideClickHandler from "react-outside-click-handler";

import Sidebar from "../Sidebar";
import { Link } from "react-router-dom";

import { getLoginDetails } from "../../utils/storage";

import { ReactComponent as LogoutIcon } from "../../icons/logout.svg";
import CaretIcon from "../../icons/caret.png";

import BG from "../../icons/bg.png";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

let TIMER = null;

const PageLayout = ({
  children,
  heading,
  icon,
  searchPlaceholder,
  onSearchChange = () => {},
  hideSearchBar,
  hideLoginDetails,
  noSideBar,
  defaultSearchVal,
  svg,
  hideTop=false
}) => {
  const [isDropdownShown, setIsDropdownShown] = useState(false);
  const [searchValue, setSearchValue] = useState(
    defaultSearchVal ? defaultSearchVal : ""
  );
  const [hideSideBar, setHideSideBar] = useState(false);
  const { firstName, lastName, organisationName, profileUrl } =
    getLoginDetails() || {};
  return (
    <div
      className={`t-pl-72 ${
        hideSideBar ? "sidebarHidden" : "d-pl-250 "
      } ${hideTop?'':`pt-80`} pb-80 ml-20 pr-20 tabs-wrapper `}
    >
      <div className="p-fixed  wt-100vw l-0 t-0 ht-60  z-1 navbar-wrapper d-flex">
        <div
          className="p-fixed d-l-250 t-l-75 ml-20 t-8 fs-28 fw-bolder flex flex-middle align-items-center mt-8"
          onClick={() => setHideSideBar(!hideSideBar)}
        >
          {icon && <img alt="" src={icon} className="wt-30 mr-8 t-hide" />}

          <span className="navbar-heading">{heading}</span>
        </div>
        <div className=" p-absolute ht-60 r-0 t-0 flex flex-middle pr-16 navbar-right">
          {!hideSearchBar && (
            <FormInput
              className="top_form_input mr-32"
              inputType="search"
              value={searchValue}
              onChange={(e) => {
                const val = e.target.value;
                setSearchValue(val);
                if (TIMER) {
                  clearTimeout(TIMER);
                }
                TIMER = setTimeout(() => {
                  onSearchChange(val);
                }, 500);
              }}
              placeholder={searchPlaceholder}
            />
          )}
          {!hideLoginDetails && (
            <div
              className="flex flex-middle pl-4 pr-4"
              onClick={() => {
                setIsDropdownShown((dropdown) => true);
              }}
            >
              <div
                className="rounded-circle"
                style={{
                  overflow: "hidden",
                  width: 50,
                  height: 50,
                  borderRadius: "50%",
                  marginRight: "10px",
                }}
              >
                <img
                  className="w-100"
                  style={{ height: "100%" }}
                  src={
                    profileUrl ||
                    `https://s3.ap-south-1.amazonaws.com/cat-general-bucket/c4ee9b8e-129c-402f-acd1-779caf9342f51591591440282.jpeg`
                  }
                  alt=""
                />
              </div>

              <div className="dropdown admin-dropdown">
                <div
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                  className="mr-12 rounded flex items-center"
                >
                  {firstName} {lastName}
                  <img src={CaretIcon} style={{height: 'intrinsic'}} className="wt-20  op-5 mr-4 c-pointer" />
                </div>

                <ul
                  className="dropdown-menu"
                  aria-labelledby="dropdownMenuLink"
                >
                  <li>
                    <a className="dropdown-item">
                      <Link to="/settings">
                        <img
                          className="wt-20 mr-12 rotate--90"
                          src={CaretIcon}
                        />
                        <span>User Profile</span>
                      </Link>
                    </a>
                  </li>
                  <li>
                    <a className="dropdown-item">
                      <Link to="/logout">
                        <img
                          className="wt-20 mr-12 rotate--90 decoration-none"
                          src={CaretIcon}
                        />
                        <span>Logout</span>
                      </Link>
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          )}
        </div>
      </div>

      {/* {isDropdownShown && (
        <OutsideClickHandler onOutsideClick={() => setIsDropdownShown(false)}>
          <div
            className=" bg-white wt-130 p-fixed box-shadow pt-8 pb-4 pl-8 pr-8"
            style={{ top: 50, right: 20, zIndex: 20 }}
          >
            <Link
              to="/settings"
              className="decoration-none flex flex-middle c-gray fw-14 mb-4"
            >
              <img
                src={CaretIcon}
                className="wt-20 rotate--90 op-5 mr-4 c-pointer"
              />
              <span>USER PROFILE</span>
            </Link>
            <Link
              to="/logout"
              className="decoration-none flex flex-middle c-gray fw-14 mb-4"
            >
              <img
                src={CaretIcon}
                className="wt-20 rotate--90 op-5 mr-4 c-pointer"
              />
              <span>LOGOUT</span>
            </Link>
          </div>
        </OutsideClickHandler>
      )} */}
      {!hideSideBar && !noSideBar && <Sidebar />}
      {children}
    </div>
  );
};

export default PageLayout;
