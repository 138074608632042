import React, { useState ,useEffect} from "react";

import Toggle from "react-toggle";
import Toast from "../../components/Toast";

import Modal from "../Modal";
import Button from "../Button";
import IconAdd from "../../icons/rev_add_user.png";
import FormInput from "../FormInput";
import SelectCheckbox from "../SelectCheckbox";
import UserAddEdit from "../../components/AddEditItems/UserAddEdit";
import CaretIcon from "../../icons/caret.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";

import { getLoginDetails } from "../../utils/storage";

// import ImageUploader from 'react-images-upload';

// import ImageUploading from "react-images-uploading";
import { 
  STATES_LIST ,
  postData,
  uploadFile,
  ADD_USERS_URL,
  FILE_UPLOAD_URL,
  GET_ROLES_URL,
  getData,
} from "../../utils/api";
import ImageUploading from "./ImageUpload";

// const STATES = [ 'Florida', 'Wisconsin', 'Georgia'];
const userModel = {
  email: "",
  companyId: "",
  roleId: "",
  password: "",
  firstName: "",
  lastName: "",
  phone: "",
  address: "",
  city: "",
  state: "",
  zipcode: "",
  profileUrl: "",
  smsNumber: "",
  notes: "",
  isActive: true,
};

const PARTNER_TYPES = [
  {
    id: "YES",
    value: "YES",
  },
  {
    id: "NO",
    value: "NO",
  },
];
const COMPANY_TYPES = [
  /*{
    id: "BUYER",
    value: "Partner Buyer",
  },
  {
    id: "COLLECTOR",
    value: "Partner Collector",
  },
  {
    id: "SI",
    value: "Seller Individual",
  },
  {
    id: "VIEWER",
    value: "VIEWER",
  },*/
  {
    id: "SELLER",
    value: "Partner Seller",
  },
  {
    id: "FRANCHISE",
    value: "Franchise",
  },
];

const COMPANY_TYPES_10 = [
  {
    id: "SELLER",
    value: "Partner Seller",
  },
];

const normalizeInput = (value) => {
  if (!value) return value;
  const currentValue = value.replace(/[^\d]/g, "");
  const cvLength = currentValue.length;

  if (value.length > 0) {
    if (cvLength < 4) return currentValue;
    if (cvLength < 7)
      return `${currentValue.slice(0, 3)}-${currentValue.slice(3)}`;
    return `${currentValue.slice(0, 3)}-${currentValue.slice(
      3,
      6
    )}-${currentValue.slice(6, 10)}`;
  }
};



const EntityAddEdit = ({
  item,
  onClose,
  onSave,
  type,
  onBack,
  shouldShowPriceMod,
  facilities,
  editType,
  setRemoveTabs,
  isFromCart = false,
  isLoading = false,
  history,
  hideCustomers = false,
}) => {
  const {
    hierarchy,
    canShowPriceModifier,
    canEditCompanyName,
    canAdjustCustomerSettings,
    canCreateFranchise,
  } = getLoginDetails();
  const pathname = window.location.pathname;

/////edit state
  const [companyEditName, setEditCompanyName] = useState(undefined);
  const [editCompanyType, setEditCompanyType] = useState(undefined);
  const [editAddress, setEditAddress] = useState(undefined);
  const [editAddress1, setEditAddress1] = useState(undefined);
  const [editCity, setEditCity] = useState(undefined);
  const [editState, setEditState] = useState(undefined);
  const [editZipcode, setEditZipcode] = useState(undefined);
  const [editfacility, setEditFacility] = useState(undefined);
  const [editEmail, setEditEmail] = useState(undefined);
  const [editPhone, setEditPhone] = useState(normalizeInput(undefined));
  const [editDriversLicense, setEditDriversLicense] = useState(undefined);
  const [editFirstName, setEditFirstName] = useState(undefined);
  const [editLastName, setEditLastName] = useState(undefined);
  const [editBusinessLicense, setEditBusinessLicense] = useState(undefined);
  const [editIsPriceModifierPercantage, setEditIsPriceModifierPercantage] = useState(undefined);
  const [editCanSeeMarkup, setEditCanSeeMarkup] = useState(undefined);
  const [editCanBuildCart, setEditCanBuildCart] = useState(undefined);
  const [editCanSeeSubtotal, setEditCanSeeSubtotal] = useState(undefined);
  const [editEin, setEditEin] = useState(undefined);
  const [editPriceMod, setEditPriceMod] = useState(undefined);
  const [editIsPictureMandatory, setEditIsPictureMandatory] = useState(undefined);
  const [editCanSeeUnitPrice, setEditCanSeeUnitPrice] = useState(undefined);
  const [editImage, editSetImage] = useState(undefined);
  const [editModifierInputValue, setEditModifierInputValue] = useState(undefined);
///////
  const [companyName, setCompanyName] = useState(item?.companyName);
  const [companyType, setCompanyType] = useState(item?.companyType);
  const [address, setAddress] = useState(item?.address);
  const [address1, setAddress1] = useState(item?.address1);
  const [city, setCity] = useState(item?.city);
  const [state, setState] = useState(item.state);
  const [zipcode, setZipcode] = useState(item.zipcode);
  const [facility, setFacility] = useState(item.facility);
  const [email, setEmail] = useState(item.email);
  const [phone, setPhone] = useState(normalizeInput(item.phone || ""));
  const [driversLicense, setDriversLicense] = useState(item.driversLicense);
  const [firstName, setFirstName] = useState(item.firstName);
  const [lastName, setLastName] = useState(item.lastName);
  const [isActive, setIsActive] = useState(item.isActive);
  const [isPictureMandatory, setIsPictureMandatory] = useState(
    item.isPictureMandatory || false
  );
  const [canSeeUnitPrice, setCanSeeUnitPrice] = useState(
    item.canSeeUnitPrice || false
  );
  const [canSeeMarkup, setCanSeeMarkup] = useState(item.canSeeMarkup || false);
  const [canSeeSubtotal, setCanSeeSubtotal] = useState(
    item.canSeeSubtotal || false
  );
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);

  const [isPartner, setIsPartner] = useState(item.isPartner);
  const [priceMod, setPriceMod] = useState(item.priceMod);
  const [businessLicense, setBusinessLicense] = useState(item.businessLicense);
  const [ein, setEin] = useState(item.ein);
  const [selectedFacilities, setSelectedFacilities] = useState(
    (item.facilities || []).map((facility) => {
      const referenceFacility = facilities.find((refFacility) => {
        return facility === refFacility.facilityId;
      });
      return {
        id: (referenceFacility || {}).facilityId,
        value: (referenceFacility || {}).name,
      };
    })
  );

  const hideAbcPricing = true;

  const [image, setImage] = useState(null);

  const [metalLicense, setMetalLicense] = useState(item.metalLicense);

  const [hasSubmitted, setHasSubmitted] = useState(false);

  const [canBuildCart, setCanBuildCart] = useState(item.canBuildCart || false);
  const [rolesItems, setRolesItems] = useState([]);

  const [validations, setValidations] = useState({});
  const [isPriceModifierPercantage, setIsPriceModifierPercantage] = useState(
    item.groupModifierIsPercentage
  );
  const [modifierInputValue, setModifierInputValue] = useState(
    item.groupModifier || ""
  );
  const validateFields = () => {
    const updatedValidations = { ...validations };

    updatedValidations.companyName = companyName;
    // updatedValidations.email = email;
    updatedValidations.companyType = companyType;
    updatedValidations.address = address;
    // updatedValidations.address1 = address1;
    // updatedValidations.phone = phone;
    updatedValidations.city = city;
    updatedValidations.state = state;
    updatedValidations.zipcode = zipcode;
    updatedValidations.firstName = firstName;
    updatedValidations.lastName = lastName;
    updatedValidations.facility = companyType === 'FRANCHISE'? '' : facility;

    setValidations(updatedValidations);
    return updatedValidations;
  };
 console.log(hierarchy);
 const [toastParams, setToastParams] = useState({
  message: "",
  type: "",
  id: "",
});
const [toastParams2, setToastParams2] = useState({
  message2: "",
  type2: "",
  id2: "",
});

 const addItem = async (data, image) => {
  let { profileUrl } = data;
  try {
    if (image) {
      const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      profileUrl = response.imageUrl;
    }
    const response = await postData(ADD_USERS_URL, {
      ...data,
      profileUrl,
      companyId: item.companyId,
    });
    setToastParams2({
      type2: "SUCCESS",
      message2: "Successfully added the User",
      id2: new Date().getTime(),
    });
    setIsAddPopupShown(false);

  } catch (e) {
    console.log("ERROR");
    setToastParams({
      type: "ERROR",
      message:
        e && e.error
          ? e.error
          : "Oops! Something went wrong. Please try again later",
      id: new Date().getTime(),
    });
  }
};

useEffect(() => {
  getRolesAndPermissions();
}, []);

const getRolesAndPermissions = async () => {
  try {
    const data = await getData(GET_ROLES_URL, {
      count: 1000,
      offset: 0,
      companyId: item.companyId,
    });
    setRolesItems(
      data.list.map((role, index) => {
        return {
          id: role.roleId,
          value: role.name,
        };
      })
    );
  } catch (e) {
    console.log("ERROR", e);
  }
};
useEffect(()=>{
  setToastParams( {
    message: "",
    type: "",
    id: "",
  })
},[isAddPopupShown])

const onBackClick=() => {
  setIsAddPopupShown(false)
  setToastParams2({
    type2: "",
    message2: "",
    id2: '',
  });

}

  return (
    // <Modal>

    <>
    {isAddPopupShown && (
      <>
      <UserAddEdit
        item={{ ...userModel }}
        onClose={setIsAddPopupShown.bind(null, false)}
        // eslint-disable-next-line no-sequences
        onBack={onBackClick}
        onSave={addItem}
        type="add"
        roles={rolesItems}
        selected={[]}
      />
      { toastParams.message &&
      <Toast
      type={toastParams.type}
      message={toastParams.message}
      id={toastParams.id}
    />
      }
      </>

    )}
    {!isAddPopupShown && (

    
    <div className="mt-8">
      <div
        style={{ marginTop: isFromCart ? 0 : 100 }}
        className="flex flex-middle c-pointer mb-20"
      >
            { toastParams2.message2 &&
      <Toast
      type={toastParams2.type2}
      message={toastParams2.message2}
      id={toastParams2.id2}
    />
      }
        {
          !hideCustomers && <div
          onClick={() => {
            setRemoveTabs(false);
            onBack();
          }}
        >
          <img src={CaretIcon} className="wt-20 rotate-90 op-5" />
          <span className="fs-12 c-table-header fw-bolder">
            Back To Customers
          </span>
        </div>
        }
        
        {(hierarchy === 10 || hierarchy === 1) && (window.location.pathname.includes("settings-config")) && (
          <>
          <div style={{ marginLeft: 20 }}>
            <div
              className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={() => {
                history && history.push("/entities/users", {hideCustomers: true});
              }}
            >
              <span>Go To Users</span>
            </div>
          </div>
          <div
            className="btn ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={() => {
              setIsAddPopupShown(true);
            }}
            style={{ marginLeft: "10px" }}
          >
            <img src={IconAdd} className="ht-20 wt-20 mr-8" />
            <span>Create user</span>
          </div>
          </>
        )}

      </div>
      <div
        style={{
          boxShadow: "0 5px 10px rgb(0, 0, 0, 0.3)",
          backgroundColor: "#fff",
          padding: "20px",
          borderRadius: "20px",
          margin: "10px",
        }}
      >
        <div className="fs-24 fw-bolder mb-40 c-gray">
          {type === "add" ? window.location.pathname.includes("settings-config") ?"SETTINGS":"CREATE CUSTOMER" : "EDIT CUSTOMER"} 
        </div>
        {/* <div className="flex flex-middle flex-center">
                    <ImageUploading onChange={setImage} initialImage={item.companyLogo}/>
                </div> */}

        <div className="flex flex-between flex-middle mb-40 flex-wrap">
          {type === "edit" && !canEditCompanyName ? (
            <div className=" mr-40 wt-33p">
              <div className="c-gray fs-14 mb-4">Company Name</div>
              <div> {companyName} </div>
            </div>
          ) : (
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder={
                <div
                  dangerouslySetInnerHTML={{
                    __html: `Company Name <span style='color:red;'>*</span>`,
                  }}
                ></div>
              }
              onChange={(e) => {
                setCompanyName(e.target.value);
                setEditCompanyName(e.target.value);
              }}
              className="mr-40 wt-33p"
              value={companyName}
              hasError={hasSubmitted && !validations.companyName}
            />
          )}
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Primary Contact First Name <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setFirstName(e.target.value);
              setEditFirstName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={firstName}
            hasError={hasSubmitted && !validations.firstName}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Primary Contact Last Name <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setLastName(e.target.value);
              setEditLastName(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={lastName}
            hasError={hasSubmitted && !validations.lastName}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div dangerouslySetInnerHTML={{ __html: `Company Email` }}></div>
            }
            onChange={(e) => {
              setEmail(e.target.value);
              setEditEmail(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={email}
            // hasError={hasSubmitted && !validations.email}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder="Company Phone"
            onChange={(e) => {
              setPhone(normalizeInput(e.target.value || ""));
              setEditPhone(normalizeInput(e.target.value || ""))
            }}
            className="mr-40 wt-33p"
            value={phone}
            // hasError={hasSubmitted && !validations.phone}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder="Driver License"
            onChange={(e) => {
              setDriversLicense(e.target.value);
              setEditDriversLicense(e.target.value)
            }}
            className="mr-40 wt-33p"
            value={driversLicense}
            // hasError={hasSubmitted && !validations.driversLicense}
          />
        </div>
        <div className="flex flex-between flex-middle mb-40 flex-wrap">
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Company Address 1 <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setAddress(e.target.value);
              setEditAddress(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={address}
            hasError={hasSubmitted && !validations.address}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder="Company Address 2"
            onChange={(e) => {
              setAddress1(e.target.value);
              setEditAddress1(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={address1}
            // hasError={hasSubmitted && !validations.address1}
          />
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Company City <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              setCity(e.target.value);
              setEditCity(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={city}
            hasError={hasSubmitted && !validations.city}
          />
          <div className=" mr-40 wt-33p">
            <div className="c-gray fs-14 mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: `Select Company State <span style='color:red;'>*</span>`,
                }}
              ></div>
            </div>
            <select
              name="type"
              className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
              value={state}
              onChange={(e) => {
                setState(e.target.value)
                setEditState(e.target.value)
              }
            }
            >
              <option value={""}>Select Company State</option>
              {STATES_LIST.map((state) => {
                return <option value={state}>{state}</option>;
              })}
            </select>
            {hasSubmitted && !validations.state && (
              <div className="fs-10 c-error fw-bold p-absolute">
                Please select valid state
              </div>
            )}
          </div>
          {/*<FormInput  inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }} 
                        placeholder="State"
                        onChange={(e)=> {
                            setState(e.target.value);
                        }}
                        className="mr-40 wt-33p"
                        value={state}
                        hasError={hasSubmitted && !validations.state}

                    />*/}
          <FormInput
            inputClassName="bg-blue rounded-pill"
            style={{ paddingLeft: "10px" }}
            placeholder={
              <div
                dangerouslySetInnerHTML={{
                  __html: `Company Zipcode <span style='color:red;'>*</span>`,
                }}
              ></div>
            }
            onChange={(e) => {
              if (isNaN(e.target.value)) {
                return;
              }
              setZipcode(e.target.value);
              setEditZipcode(e.target.value);
            }}
            className="mr-40 wt-33p"
            value={(zipcode || "").replace(/[^\d]/g, "")}
            hasError={hasSubmitted && !validations.zipcode}
          />

          <div className="flex flex-between wt-33p mr-40 p-relative t--8">
            {/* <div>
                            <div className="fs-14 mb-4 fw-bold mt-20 c-gray">Is Partner?</div>
                            <Toggle 
                                className="bg-theme"
                                onChange={(e)=> {
                                    setIsPartner(e.target.checked)
                                }}
                                checked={isPartner}
                            />
                        </div> */}
            {/*<div className="">
                        <div className="fs-14 mb-4 fw-bold mt-20 c-gray">Is Active?</div>
                            <Toggle 
                                className="bg-theme"
                                onChange={(e)=> {
                                    setIsActive(e.target.checked)
                                }}
                                checked={isActive}
                            />
                        </div>*/}
          </div>
          {/* <div className="wt-33p mr-40 p-relative t--8">   
                        
                    </div> */}
          {!hideAbcPricing && shouldShowPriceMod && (
            <div className="wt-33p mr-40 p-relative t--8">
              <div className="fs-14 mb-4 fw-bold mt-20">Is ABC Pricing</div>
              <Toggle
                className="bg-theme"
                onChange={(e) => {
                  setPriceMod(e.target.checked);
                  setEditPriceMod(e.target.checked)
                }}
                checked={priceMod}
              />
            </div>
          )}
          <div className="wt-33p mr-40 p-relative t-12">
            <div className="c-gray fs-14 mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: `Company Type <span style='color:red;'>*</span>`,
                }}
              ></div>
            </div>
            {
             ( pathname.includes("/settings-config") || (type === "edit" && pathname.includes("/viewGroup")))?(
                <span className="c-gray ml-4">{companyType}</span>
              ):(
                <select
                name="type"
                className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
                value={companyType}
                onChange={(e) =>{
                  setEditCompanyType(e.target.value)
                  setCompanyType(e.target.value)
                  }
                }
              >
                <option value={""}>Select Company Type</option>
                {((hierarchy !== 10 && canCreateFranchise) ? COMPANY_TYPES : COMPANY_TYPES_10).map(
                  (type) => {
                    return <option value={type.id}>{type.value}</option>;
                  }
                )}
              </select>
              )
            }
            {hasSubmitted && !validations.companyType && (
              <div className="fs-10 c-error fw-bold p-absolute">
                Please select valid Company Type
              </div>
            )}
            {/* <SelectCheckbox
              isRound={true}
              className="bg-blue rounded-pill"
              id={"companyType-dropdowns"}
              selectPlaceholder={"Company Type"}
              selected={
                companyType
                  ? [
                      {
                        id: companyType,
                        value: COMPANY_TYPES.find((type) => {
                          return type.id === companyType;
                        }).value,
                      },
                    ]
                  : []
              }
              items={COMPANY_TYPES}
              selectSingle={true}
              setSelectedItems={(items) => {
                console.log('ssdv', items)
                hierarchy === 1 && setCompanyType(items[0].id);
              }}
              hasError={hasSubmitted && !validations.companyType}
            /> */}
          </div>
          <div className="wt-33p mr-40 p-relative t-12">
            {/* <SelectCheckbox
              id={"selectedFacilities-dropdowns"}
              selectPlaceholder={"Facilities"}
              selected={selectedFacilities || []}
              items={facilities.map((facility) => {
                return {
                  id: facility.facilityId,
                  value: facility.name,
                };
              })}
              setSelectedItems={(items) => {
                console.log("ITEMS", items);
                setSelectedFacilities(items);
              }}
              hasError={hasSubmitted && !validations.companyType}
            /> */}
            <div className="c-gray fs-14 mb-4">
              <div
                dangerouslySetInnerHTML={{
                  __html: `Select Facility <span style='color:red;'>*</span>`,
                }}
              ></div>
            </div>
            {
              pathname.includes("/settings-config")?(
                <span className="c-gray ml-4">{facility}</span>
              ):(
                <select
                  name="type"
                  placeholder="Facilities"
                  className="select font-primary fs-14 fw-bolder rounded-pill bg-blue"
                  value={facility}
                  onChange={(e) => {
                    setFacility(e.target.value)
                    setEditFacility(e.target.value)
                  }
                }
                >
                  <option value={""}>Select Facility</option>
                  {facilities.map((facility) => {
                    return <option value={facility.name}>{facility.name}</option>;
                  })}
                </select>
              )
            }
            
            {hasSubmitted && !validations.facilities && companyType != 'FRANCHISE' && (
              <div className="fs-10 c-error fw-bold p-absolute">
                Please select valid Facility
              </div>
            )}
          </div>
          {canShowPriceModifier && 
          (pathname.includes("/settings-config") && hierarchy !== 1)?"":
            <div className="wt-33p mr-40 p-relative t--8">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Price Modifier
              </div>
              <div className="flex flex-middle">
                <div className="flex flex-middle mr-16">
                  <span className="c-gray mr-4">%</span>
                  <Toggle

                    className="bg-theme hide-check-cross"
                    onChange={(e) => {
                      setEditIsPriceModifierPercantage(e.target.checked)
                      if(pathname.includes("/settings-config")) {
                        return;
                      }
                      e.target.checked
                        ? setIsPriceModifierPercantage(false)
                        : setIsPriceModifierPercantage(true);
                    }}
                    checked={!isPriceModifierPercantage}
                  />
                  <span className="c-gray ml-4">$</span>
                </div>
                <FormInput
                  inputClassName="bg-blue rounded-pill"
                  style={{ paddingLeft: "10px" }}
                  className="wt-150"
                  type="number"
                  step="0.01"
                  value={modifierInputValue}
                  onChange={(e) => {
                    if(pathname.includes("/settings-config")) {
                      return;
                    }
                    setModifierInputValue(e.target.value);
                    setEditModifierInputValue(e.target.value)
                  }}
                />
                {/* <div className="flex flex-middle fs-18 wt-30  bg-white">{isPriceModifierPercantage ? "%" : "$"}</div> */}
              </div>
            </div>
}

          {!canShowPriceModifier && (
            <div className="mr-40 wt-33p p-relative"></div>
          )}

          <div className="wt-33p mr-40">
            <div className="fs-14 mb-4 fw-bold c-gray"> Company Logo </div>
            <div className="flex flex-middle">
              <ImageUploading
                onChange={setImage}
                initialImage={item.companyLogo}
              />
            </div>
          </div>

          {hasSubmitted && !image && companyType === 'FRANCHISE' && (
              <div className="fs-10 c-error fw-bold p-absolute">
                please add image
              </div>
            )}

          <div className="flex flex-between flex-middle mb-40 flex-wrap wt-100p">
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder="Business License"
              onChange={(e) => {
                setBusinessLicense(e.target.value);
                setEditBusinessLicense(e.target.value);
              }}
              className="mr-40 wt-33p"
              value={businessLicense}
              // hasError={hasSubmitted && !validations.businessLicense}
            />
            <FormInput
              inputClassName="bg-blue rounded-pill"
              style={{ paddingLeft: "10px" }}
              placeholder="EIN"
              onChange={(e) => {
                setEin(e.target.value);
                setEditEin(e.target.value);
              }}
              className="mr-40 wt-33p"
              value={ein}
              // hasError={hasSubmitted && !validations.ein}
            />
            <div className="mr-40 wt-33p">
              <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                Metal Recycler License
              </div>
              <ImageUploading
                onChange={setMetalLicense}
                initialImage={metalLicense}
              />
            </div>
          </div>

          {hierarchy === 1 && canAdjustCustomerSettings && (
            <div className="flex flex-between flex-middle mb-40 flex-wrap wt-100p">
              <div className="mr-10 wt-20p p-relative">
                <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                  Is Picture Mandatory?
                </div>
                <Toggle
                  className="bg-theme"
                  onChange={(e) => {
                    setIsPictureMandatory(e.target.checked);
                    setEditIsPictureMandatory(e.target.checked)
                  }}
                  checked={isPictureMandatory}
                />
              </div>
              <div className="mr-10 wt-20p p-relative">
                <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                  Can See Offer Price?
                </div>
                <Toggle
                  className="bg-theme"
                  onChange={(e) => {
                    setCanSeeUnitPrice(e.target.checked);
                    setEditCanSeeUnitPrice(e.target.checked)
                  }}
                  checked={canSeeUnitPrice}
                />
              </div>
              <div className="mr-10 wt-20p p-relative">
                <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                  Can See Markup?
                </div>
                <Toggle
                  className="bg-theme"
                  onChange={(e) => {
                    setCanSeeMarkup(e.target.checked);
                    setEditCanSeeMarkup(e.target.checked)
                  }}
                  checked={canSeeMarkup}
                />
              </div>
              <div className="mr-10 wt-20p p-relative">
                <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                  Can See Subtotal ?
                </div>
                <Toggle
                  className="bg-theme"
                  onChange={(e) => {
                    setCanSeeSubtotal(e.target.checked);
                    setEditCanSeeSubtotal(e.target.checked)
                  }}
                  checked={canSeeSubtotal}
                />
              </div>
              <div className="mr-10 wt-20p p-relative">
                <div className="fs-14 mb-4 fw-bold mt-20 c-gray">
                  Can Build Cart ?
                </div>
                <Toggle
                  className="bg-theme"
                  onChange={(e) => {
                    setCanBuildCart(e.target.checked);
                    setEditCanBuildCart(e.target.checked)
                  }}
                  checked={canBuildCart}
                />
              </div>
            </div>
          )}
          <div className="wt-33p mr-40 p-relative"></div>
          <div className="wt-33p mr-40 p-relative"></div>
          <div className="wt-33p mr-40 p-relative"></div>
          <div className="wt-33p mr-40 p-relative"></div>
        </div>
        <div className="flex flex-middle flex-right">
          <Button
            className="flex  ht-40 flex-middle mr-20 btn btn-red"
            type="modalCancel"
            onClick={() => {
              setRemoveTabs(false);
              onClose();
            }}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>

          <Button
            type="modalSave"
            className="btn  ht-40"
            onClick={() => {
              if (isLoading) {
                return;
              }
              setHasSubmitted(true);
              const updatedValidations = validateFields();
              if (
                !updatedValidations.companyName ||
               (companyType !== 'FRANCHISE' && !updatedValidations.facility) ||
                !updatedValidations.address ||
                // !updatedValidations.address1 ||
                !updatedValidations.city ||
                !updatedValidations.state ||
                !updatedValidations.zipcode ||
                !updatedValidations.firstName ||
                !updatedValidations.lastName
                // || !updatedValidations.email
              ) {
                window.scrollTo(0, 0);
                setRemoveTabs(true);
              } else {
                setRemoveTabs(false);
              }

              onSave(
                {
                  companyName:type==="edit"?companyEditName:companyName,
                  companyType:!pathname.includes("/viewGroup")?companyType:type==="edit"?editCompanyType:companyType,
                  address:type==="edit"?editAddress:address,
                  address1:type==="edit"?editAddress1:address1,
                  phone:type==="edit"?editPhone:phone,
                  city:type==="edit"?editCity:city,
                  state:type==="edit"?editState:state,
                  zipcode:type === "edit"?editZipcode:zipcode,
                  email:type === "edit"?editEmail:email,
                  firstName:type === "edit"? editFirstName:firstName,
                  lastName:type === "edit"? editLastName:lastName,
                  companyId: item.companyId,
                  companyLogo: item.companyLogo,
                  isPartner:type === "edit"?undefined:isPartner,
                  isActive:type === "edit"?undefined:isActive,
                  isPictureMandatory:type === "edit"?editIsPictureMandatory:isPictureMandatory,
                  canSeeUnitPrice:type === "edit"?editCanSeeUnitPrice:canSeeUnitPrice,
                  canSeeMarkup:type === "edit"?editCanSeeMarkup:canSeeMarkup,
                  canSeeSubtotal:type === "edit"?editCanSeeSubtotal:canSeeSubtotal,
                  businessLicense:type === "edit"?editBusinessLicense:businessLicense,
                  canBuildCart:type === "edit"?editCanBuildCart:canBuildCart,
                  driversLicense:type === "edit"?editDriversLicense:driversLicense,
                  // metalLicense,
                  ein:type === "edit"?editEin:ein,
                  priceMod: type === "edit"?editPriceMod:shouldShowPriceMod ? priceMod : undefined,
                  facility:type === "edit"?editfacility:facility,
                  groupModifierIsPercentage:type === "edit"? editIsPriceModifierPercantage:isPriceModifierPercantage,
                  groupModifier:type === "edit"?editModifierInputValue: modifierInputValue,
                  isFacility:companyType == 'Franchise'?'true':undefined
                },
                image ? image[0] : null,
                metalLicense ? metalLicense[0] : null
              );
            }}
          >
            {/* {type === 'add' ? 'Add' : 'Edit'} item */}
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            <span>{isLoading ? "Loading..." : "SAVE"}</span>
          </Button>
        </div>
      </div>
    </div>
    )}
    </>

    // </Modal>
  );
};

export default EntityAddEdit;
