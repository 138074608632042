import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import HeaderIcon from "../../icons/pos_loads.png";
import IconUserAdd from "../../icons/rev_add_user.png";
import PageLayout from "../../components/PageLayout";
import FormInput from "../../components/FormInput";
import { getLoginDetails } from "../../utils/storage";
import Table from "../../components/Table";
import ActionLoader from "../../components/ActionLoader";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import {
  Document,
  Page,
  // StyleSheet,
  // Text,
  // View
} from "react-pdf";

import moment from "moment";

// import ReactToPdf from "react-to-pdf";

import ReactToPrint from "react-to-print";

import { PDFExport } from "@progress/kendo-react-pdf";

import { drawDOM, exportPDF, saveAs } from "@progress/kendo-drawing";

import CurrencyFormat from "react-currency-format";

import {
  GET_ALL_LOADS_URL,
  getData,
  postData,
  putData,
  uploadFile,
  CREATE_NEW_SESSION,
  UPLOAD_PDF_FILE_URL,
  GET_LOAD_DETAILS_URL,
  GET_PAYMENT_STATUS_URL,
} from "../../utils/api";

import Logo1 from "../../icons/icon-pdf-logo.png";

const InvoicePDF = (props) => {
  //   const isSeller = loginDetail.hierarchy == 2;
  //   const canAcceptLoadRequest = loginDetail.canAcceptLoadRequest;
  let ref = React.createRef();
  let componentRef = React.createRef();
  const isFirstRun = useRef(true);
  const [loadData, setLoadData] = useState(
    props.isPDFForMail ? props.itemData : []
  );
  const [selectedPandingLoad, setSelectedPendingLoad] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [printPopup, setPrintPopup] = useState(null);
  const [paymentModes, setPaymentModes] = useState(
    props.isPDFForMail ? props.paymentModes : []
  );
  const [amountReceived, setAmountReceived] = useState("");
  const [pdfFile, setPdfFile] = useState(null);
  const [params, setParams] = useState({
    limit: 500,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });

  const getPaymentModes = async () => {
    try {
      setIsLoading(true);
      const data = await getData(GET_PAYMENT_STATUS_URL, {
        loadId: props.itemData.loadId,
        count: 100,
        offset: 0,
      });
      setPaymentModes(data.list);
      setAmountReceived(data.amountReceived);
      setIsLoading(false);
      getLoadDetails();
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable Payment Modes.",
        id: new Date().getTime(),
      });
    }
  };

  const getLoadDetails = async () => {
    try {
      setIsLoading(true);
      setTableLoading(true);
      const loadData = await getData(GET_LOAD_DETAILS_URL, {
        loadId: props.itemData.loadId,
        isShowAll: true,
      });
      setLoadData(loadData);
      setIsLoading(false);
      setTableLoading(false);
      if (props.isLoadedForLink) {
        exportPDFWithMethod();
      }
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setTableLoading(false);
      setToastParams({
        type: "ERROR",
        message: "Something went wrong please try agian later",
        id: new Date().getTime(),
      });
    }
  };

  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  useEffect(() => {
    if (props.isPDFForMail) {
      setLoadData(props.itemData);
    } else {
      return;
    }
  }, [props.itemData]);

  useEffect(() => {
    if (props.isPDFForMail) {
      setPaymentModes(props.paymentModes);
    } else {
      return;
    }
  }, [props.paymentModes]);

  useEffect(() => {
    if (props.isPDFForMail && !isFirstRun.current) {
      exportPDFWithMethod();
    }
  }, [loadData]);

  useEffect(() => {
    if (isFirstRun.current) {
      isFirstRun.current = false;
    }

    if (props.isPDFForMail) {
      exportPDFWithMethod();
    } else {
      getPaymentModes();
    }
  }, []);

  // const options = {
  //   orientation: 'landscape',
  //   unit: 'in',
  //   format: [4,2]
  // };

  const start = moment(loadData.buyerFinalisedDate); //todays date
  const end = moment(loadData.invoiceSettledDate); // another date
  let duration = moment.duration(end.diff(start));
  // let hours = end.diff(start, 'hours');
  // let minutes = end.diff(start, 'minutes');
  let days = duration.days();
  let hours = duration.hours();
  let minutes = duration.minutes();

  const floorDays = duration.asDays();

  useEffect(() => {
    if (props.isHandleClicked && props.isLoadedForLink) {
      props.uploadPDFFIleBeforeCompletion();
    }
  }, [props.isHandleClicked]);

  const exportPDFWithMethod = () => {
    let gridElement = document.getElementsByClassName("k-grid")[0];

    const item = document.getElementById("pdfDiv");
    if (
      item &&
      item.style &&
      item.style.display &&
      item.style.display === "none"
    ) {
      item.style.display = "block";
    }

    drawDOM(gridElement, {
      paperSize: "A4",
      forcePageBreak: ".page-break",
      multiPage: true,
      margin: "1cm",
      scale: 0.7,
    })
      .then((group) => exportPDF(group))
      .then((dataUri) => {
        console.log("dataUri", dataUri);
        setPdfFile(dataUri);
        // saveAs({
        //     dataURI: dataUri,
        //     fileName: `cat-${loadData.invoiceNo}.pdf`,
        //     // proxyURL: "https://catapi.abcattech.io//write/v1/image/upload",
        //     // forceProxy: true
        // });

        const dataaaa = dataUri;
        fetch(dataUri)
          .then((res) => res.blob())
          .then((blob) => {
            let byteString;
            let mimeString;
            let ia;

            if (dataaaa.split(",")[0].indexOf("base64") >= 0) {
              byteString = atob(dataaaa.split(",")[1]);
            } else {
              byteString = encodeURI(dataaaa.split(",")[1]);
            }
            // separate out the mime component
            mimeString = dataaaa.split(",")[0].split(":")[1].split(";")[0];

            // write the bytes of the string to a typed array
            ia = new Uint8Array(byteString.length);
            for (var i = 0; i < byteString.length; i++) {
              ia[i] = byteString.charCodeAt(i);
            }

            if (props && props.sendPdfFileToLoads) {
              props.sendPdfFileToLoads(
                new Blob([ia], {
                  type: "application/pdf",
                  name: loadData.invoiceNo,
                })
              );
            }
            const item = document.getElementById("pdfDiv");
            if (item && item.style) {
              item.style.display = "none";
            }
          });
      });
  };

  console.log(localStorage.getItem("company URL"));

  return (
    <div className="pdf-invoice" id="pdfDiv">
      <div className="flex flex-middle flex-between">
        <div className="flex-middle">
          {!props.hidePreview && (
            <h1
              className="mr-24 fs-18 c-gray"
              style={{
                color: "#0d6efd",
                textDecoration: "underline",
                cursor: "pointer",
              }}
            >
              {" "}
              Preview{" "}
            </h1>
          )}
          <h1 className="mr-24 fs-18 c-gray">
            {" "}
            <a
              style={{
                color: "#0d6efd",
                textDecoration: "underline",
                cursor: "pointer",
              }}
              onClick={() => {
                ref.save();
              }}
            >
              Export PDF
            </a>{" "}
          </h1>
          <ReactToPrint
            trigger={() => {
              return (
                <a
                  className="fs-18 c-gray"
                  style={{
                    color: "#0d6efd",
                    textDecoration: "underline",
                    cursor: "pointer",
                  }}
                  href="#"
                >
                  Print
                </a>
              );
            }}
            content={() => componentRef}
          />
        </div>
        <div>
          {/*<button className="k-button" onClick={exportPDFWithMethod}>Export with method</button>*/}
        </div>
        <div>
          <h1
            className="mr-24 fs-24 c-gray mb-4"
            style={{ cursor: "pointer" }}
            onClick={() => {
              props.setPrintPopup({});
            }}
          >
            {" "}
            X{" "}
          </h1>
        </div>
      </div>
      <PDFExport
        fileName={`${loadData.invoiceNo || "Invoice"}.pdf`}
        forcePageBreak=".page-break"
        scale={0.7}
        // keepTogether="div"
        paperSize="A4"
        margin="1cm"
        ref={(component) => (ref = component)}
      >
        <div
          ref={(el) => (componentRef = el)} //ref={ref}
          className="k-grid"
          style={{
            margin: "0 auto",
            padding: "1mm",
          }}
        >
          <div className="flex flex-middle flex-between">
            <div style={{ height: "90px", padding: "11px" }}>
              <img src={localStorage.getItem("company URL") == 'null'?Logo1:localStorage.getItem("company URL")} className="wt-200 mb-24" />
            </div>
            <div>
              <div>{loadData.parentCompanyName} </div>
              {(loadData.facilityCity || loadData.facilityState) && (
                <>
                  <div className="fs-14"> {loadData.facilityAddress || ""}</div>
                  <div className="fs-14">
                    {" "}
                    {loadData.facilityCity || ""}
                    {loadData.facilityCity && loadData.facilityState
                      ? ", "
                      : ""}
                    {loadData.facilityState || ""}{" "}
                    {loadData.facilityZipcode || ""}
                  </div>
                </>
              )}
            </div>
            <div>
              <h2> INVOICE </h2>
              <div className="bg-lt-gray-2 pt-12 pb-12 pl-8 pr-8">
                Date:{" "}
                {moment(
                  loadData.invoiceSettledDate || loadData.buyerFinalisedDate
                ).format("MM/DD/YYYY H:MM:SS A")}
              </div>
              <div className="flex flex-between flex-middle flex-1 pt-12 pb-12 pl-8 pr-8 border-bottom-gray">
                <div>INVOICE # </div>
                <div>
                  {" "}
                  {loadData && loadData.invoiceNo
                    ? loadData.invoiceNo
                    : "-"}{" "}
                </div>
              </div>
              <div className="flex flex-between flex-middle flex-1 pt-12 pb-12 pl-8 pr-8">
                <div className="mr-8"> EMPLOYEE: </div>
                <div>
                  {" "}
                  {loadData.assistantFirstName || ""}{" "}
                  {loadData.assistantLastName || "-"}{" "}
                </div>
              </div>
            </div>
          </div>
          <div>
            <h2> {loadData.companyName} </h2>
          </div>
          <div className="wt-300" style={{ margin: "auto" }}>
            <div className="bg-lt-gray-2 pt-12 pb-12 pl-20 pr-20">
              <div>BUSINESS ADDRESS:</div>
            </div>
            <div className="flex flex-between flex-middle flex-1 pt-12 pb-12 pl-20 pr-20 border-bottom-gray">
              <div>
                {loadData.address}
                {loadData.address && (loadData.city || loadData.state)
                  ? ", "
                  : ""}{" "}
                {loadData.city}
                {loadData.city && (loadData.state || loadData.zipcode)
                  ? ", "
                  : ""}{" "}
                {loadData.state}
                {loadData.state && loadData.zipcode ? ", " : ""}{" "}
                {loadData.zipcode}
              </div>
            </div>
          </div>

          <Table
            scrollTable={false}
            columns={[
              "Item",
              // "Type",
              "Description",
              "Box",
              "Qty",
              // "Unit",
              "Price",
              "Total",
            ].filter((a) => a)}
            items={(loadData.items || []).map((item) => {
              return {
                ...item,
                nameModified: item.oem, //statusName ? `semicolon-separated-inline-${item.oem};text-color-${statusName.color}_${(statusName.name || '').toUpperCase()}` : (item.oem),
                total: item.total
                  ? ReactDOMServer.renderToStaticMarkup(
                      <CurrencyFormat
                        value={item.total}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(val) => val}
                      />
                    )
                  : "-",
                image: `is-photo-${item.url}`,
                totalQty: `semicolon-separated-inline-${item.finalQty};SQ : ${item.quantity}`,
                totalUnit: `semicolon-separated-inline-${item.finalUnit};SU : ${item.unit}`,
                adjustedPrice: item.adjustedPrice
                  ? ReactDOMServer.renderToStaticMarkup(
                      <CurrencyFormat
                        value={item.adjustedPrice}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                        renderText={(val) => val}
                      />
                    )
                  : "-",
              };
            })}
            keys={[
              "nameModified",
              // "type",
              "description",
              "box",
              "finalQty",
              // "totalUnit",
              "adjustedPrice",
              "total",
            ].filter((a) => a)}
            widthClasses={[
              "lh-2 wt-150",
              "lh-2 wt-200",
              "",
              "",
              "",
              "",
              // "",
              // 'wt-100',
              // "wt-100",
              // "wt-100",
              // "wt-100",
              // "wt-100",
              // "wt-100",
              // "wt-100",
            ]}
            isLoading={tableLoading}
            hideCheckBox={true}
            hideTableCounter={true}
            className={"pdf-table"}
          />

          <div className="page-break" />

          <div className="mb-20">
            <div className="flex flex-middle flex-between">
              <div style={{ height: "90px", padding: "11px" }}>
                <img src={localStorage.getItem("company URL") == 'null' ?Logo1:localStorage.getItem("company URL")} className="wt-200 mb-24" />
              </div>
              <div>
                <div>{loadData.parentCompanyName}</div>
                {(loadData.facilityCity || loadData.facilityState) && (
                  <>
                    <div className="fs-14">
                      {" "}
                      {loadData.facilityAddress || ""}
                    </div>
                    <div className="fs-14">
                      {" "}
                      {loadData.facilityCity || ""}
                      {loadData.facilityCity && loadData.facilityState
                        ? ", "
                        : ""}
                      {loadData.facilityState || ""}{" "}
                      {loadData.facilityZipcode || ""}
                    </div>
                  </>
                )}
              </div>
              <div>
                <h2> INVOICE </h2>
                <div className="bg-lt-gray-2 pt-12 pb-12 pl-8 pr-8">
                  Date:{" "}
                  {moment(
                    loadData.invoiceSettledDate || loadData.buyerFinalisedDate
                  ).format("MM/DD/YYYY H:MM:SS A")}
                </div>
                <div className="flex flex-between flex-middle flex-1 pt-12 pb-12 pl-8 pr-8 border-bottom-gray">
                  <div>INVOICE # </div>
                  <div>
                    {" "}
                    {loadData && loadData.invoiceNo
                      ? loadData.invoiceNo
                      : "-"}{" "}
                  </div>
                </div>
                <div className="flex flex-between flex-middle flex-1 pt-12 pb-12 pl-8 pr-8">
                  <div className="mr-8"> EMPLOYEE: </div>
                  <div>
                    {" "}
                    {loadData.assistantFirstName || ""}{" "}
                    {loadData.assistantLastName || "-"}{" "}
                  </div>
                </div>
              </div>
            </div>
            <div className="flex flex-between">
              <div className="wt-70p">
                <div className="mb-8 mt-4 flex flex-between">
                  {" "}
                  <div className="flex-1 t-right mr-12">
                    Pay Forward Date:
                  </div>{" "}
                  <div className="flex-1">
                    {moment(loadData.payForwardDate).format("MM/DD/YYYY")}{" "}
                  </div>
                </div>
                {paymentModes.map((pay, index) => {
                  return (
                    <div
                      key={`method-${pay.paymentId}`}
                      className="mb-20 mt-20"
                    >
                      <div className="mb-8 mt-4 flex flex-between">
                        {" "}
                        <div className="flex-1 t-right mr-12">
                          Method {index + 1}:
                        </div>{" "}
                        <div className="flex-1">{pay.paymentMethod} </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        {" "}
                        <div className="flex-1 t-right mr-12">
                          Payment Date:
                        </div>{" "}
                        <div className="flex-1">
                          {moment(pay.createdDate).format("MM/DD/YYYY")}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        {" "}
                        <div className="flex-1 t-right mr-12">
                          Check number transfer ID:
                        </div>{" "}
                        <div className="flex-1">{pay.referenceNumber}</div>
                      </div>
                    </div>
                  );
                })}

                {Object.keys(loadData.invoioceItemDetails || {}).length > 0 && (
                  <div className="mt-12 mb-20">
                    <div className="mb-8 mt-4 flex flex-between fw-bolder">
                      <div className="flex-1 t-right mr-12">
                        Total Piece Count:
                      </div>
                      <div className="flex-1">
                        {loadData.invoioceItemDetails.totalPieceCount || 0}
                      </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">
                        Converter Quantity:
                      </div>
                      <div className="flex-1">
                        {loadData.invoioceItemDetails.totalUnits || 0}
                      </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Total Wires:</div>
                      <div className="flex-1">
                        {loadData.invoioceItemDetails.totalUnits1 || 0}
                      </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Total Misc.:</div>
                      <div className="flex-1">
                        {loadData.invoioceItemDetails.totalUnits2 || 0}
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Domestic Foil Value:
                        </div>
                        <div className="flex-1">
                          <CurrencyFormat
                            value={
                              loadData.invoioceItemDetails.domesticFoilValue ||
                              0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Domestic Foil LBS:
                        </div>
                        <div className="flex-1">
                          {loadData.invoioceItemDetails.domesticFoilLBS || 0}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Import Foil Value:
                        </div>
                        <div className="flex-1">
                          {" "}
                          <CurrencyFormat
                            value={
                              loadData.invoioceItemDetails.importFoilValue || 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Import Foil LBS:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.importFoilLBS || 0}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Total All Foil Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalAllFoilPieces ||
                            0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total Domestic Foil Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails
                            .totalDomesticFoilPieces || 0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total Import Foil Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalImportFoilPieces ||
                            0}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Diesel Value:
                        </div>
                        <div className="flex-1">
                          {" "}
                          <CurrencyFormat
                            value={
                              loadData.invoioceItemDetails.dieselValue || 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Diesel Unit Count:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.dieselUnitCount ||
                            0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total Diesel Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalDieselPieces ||
                            0}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Selloff / MISC Value:
                        </div>
                        <div className="flex-1">
                          {" "}
                          <CurrencyFormat
                            value={loadData.invoioceItemDetails.miscValue || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Selloff / MISC Unit Count:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.miscUnitCount || 0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total MISC Entries:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalMiscPieces ||
                            0}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Aftermarket Value:
                        </div>
                        <div className="flex-1">
                          {" "}
                          <CurrencyFormat
                            value={loadData.invoioceItemDetails.afmktValue || 0}
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Aftermarket Unit Count:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.afmktUnitCount ||
                            0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total Aftermarket Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalAfmktPieces ||
                            0}{" "}
                        </div>
                      </div>
                    </div>

                    <div className="mb-20 mt-20">
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Ceramic Value:
                        </div>
                        <div className="flex-1">
                          {" "}
                          <CurrencyFormat
                            value={
                              loadData.invoioceItemDetails.ceramicValue || 0
                            }
                            displayType={"text"}
                            thousandSeparator={true}
                            prefix={"$"}
                          />{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between">
                        <div className="flex-1 t-right mr-12">
                          Ceramic Unit Count:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.ceramicUnitCount ||
                            0}{" "}
                        </div>
                      </div>
                      <div className="mb-8 mt-4 flex flex-between fw-bolder">
                        <div className="flex-1 t-right mr-12">
                          Total Ceramic Pieces:
                        </div>
                        <div className="flex-1">
                          {" "}
                          {loadData.invoioceItemDetails.totalCeramicPieces ||
                            0}{" "}
                        </div>
                      </div>
                    </div>

                    {/*<div className="mb-20 mt-20">
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Test Value:</div>
                      <div className="flex-1"> ${loadData.invoioceItemDetails.testValue || 0} </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Test Unit Count:</div>
                      <div className="flex-1"> {loadData.invoioceItemDetails.testUnitCount || 0} </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between fw-bolder">
                      <div className="flex-1 t-right mr-12">Total Test Pieces:</div>
                      <div className="flex-1"> {loadData.invoioceItemDetails.totalTestPieces || 0} </div>
                    </div>
                  </div>

                  <div className="mb-20 mt-20">
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Pulled Value:</div>
                      <div className="flex-1"> ${loadData.invoioceItemDetails.pulledValue || 0} </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between">
                      <div className="flex-1 t-right mr-12">Pulled Unit Count:</div>
                      <div className="flex-1"> {loadData.invoioceItemDetails.pulledUnitCount || 0} </div>
                    </div>
                    <div className="mb-8 mt-4 flex flex-between fw-bolder">
                      <div className="flex-1 t-right mr-12">Total Pulled Pieces:</div>
                      <div className="flex-1"> {loadData.invoioceItemDetails.totalPulledPieces || 0} </div>
                    </div>
                  </div>*/}
                  </div>
                )}
              </div>
              <div className="wt-30p mt-20 mb-20">
                <div className="border-gray pl-12 pr-12">
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fw-bolder">SUB TOTAL:</div>
                    <div className="flex-1 t-right">
                      <CurrencyFormat
                        value={loadData.grandtotal}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1">TAX:</div>
                    <div className="flex-1 t-right">
                      <CurrencyFormat
                        value={0}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fw-bolder">TOTAL:</div>
                    <div className="flex-1 t-right">
                      <CurrencyFormat
                        value={loadData.grandtotal}
                        displayType={"text"}
                        thousandSeparator={true}
                        prefix={"$"}
                      />
                    </div>
                  </div>
                </div>
                <div className="mb-4 mt-20 t-center"> Split </div>
                <div className="border-gray pl-12 pr-12 mb-20">
                  {paymentModes.map((pay, index) => {
                    return (
                      <div
                        className="mb-20 mt-12"
                        key={`split-${pay.paymentId}`}
                      >
                        <div className="mb-8 mt-4 flex">
                          <div className="wt-70p">
                            Payment {index + 1} Amount:
                          </div>
                          <div className="wt-30p t-right">
                            <CurrencyFormat
                              value={pay.amount}
                              displayType={"text"}
                              thousandSeparator={true}
                              prefix={"$"}
                            />
                          </div>
                        </div>
                      </div>
                    );
                  })}
                </div>
                <div className="border-gray pl-4 pr-4 mt-20">
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fs-14"> Start Time:</div>{" "}
                    <div className="flex-1 fs-14 t-right">
                      {moment(loadData.buyerFinalisedDate).format(
                        "MM/DD/YYYY HH:mm"
                      )}
                    </div>
                  </div>
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fs-14"> End Time:</div>{" "}
                    <div className="flex-1 fs-14 t-right">
                      {" "}
                      {moment(loadData.invoiceSettledDate).format(
                        "MM/DD/YYYY HH:mm"
                      )}{" "}
                    </div>
                  </div>
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fs-14"> Completion Time: </div>{" "}
                    <div className="flex-1 fs-14 t-right">
                      {days} days, {hours} hr {minutes} mins
                    </div>
                  </div>
                  <div className="mb-12 mt-12 flex">
                    <div className="flex-1 fs-14"> Cats Per Minute: </div>{" "}
                    <div className="flex-1 fs-14 t-right"> 0.0 P/M</div>
                  </div>
                </div>
              </div>
            </div>
            <br/>
            <div style={{marginTop: 5000}}>
            <div className="mb-8 mt-4" style={{marginTop: 30}}>
                      <div className="flex-1 mr-12" style={{fontSize: 1}}>{`.`}</div>
              </div>
              </div>
            <div style={{marginTop: 5000}}>
              <div className="mb-8 mt-4" style={{marginTop: 30}}>
                <div className="flex-1 mr-12" style={{fontSize: 38}}>DATE: {moment(
                  loadData.invoiceSettledDate || loadData.buyerFinalisedDate
                ).format("MM/DD/YYYY")}</div>
              </div>
              <div className="mb-8 mt-4">
                <div className="flex-1 mr-12" style={{fontSize: 38}}>INVOICE #: {loadData && loadData.invoiceNo
                    ? loadData.invoiceNo
                    : "-"}</div>
              </div>
              <div className="mb-8 mt-4">
                <div className="flex-1 mr-12" style={{fontSize: 38}}>CUSTOMER NAME: {loadData.companyName || "-"}</div>
              </div>
              <div className="mb-8 mt-4">
                <div className="flex-1 mr-12" style={{fontSize: 38}}>BUYER: {loadData.assistantLastName || "-"}</div>
              </div>
            </div>
          </div>
        </div>
      </PDFExport>
    </div>
  );
};

export default InvoicePDF;
