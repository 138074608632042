import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import moment from "moment";

import Call from "./Call";
import Chat from "./Chat";

import PageLayout from "../../components/PageLayout";

import ConverterInfo from "../../components/AddEditItems/ConverterInfo";
import ConverterPricing from "../../components/AddEditItems/ConverterPricing";
import TestAddEdit from "../../components/AddEditItems/TestAddEdit";
import Table from "../../components/Table";
import Toast from "../../components/Toast";
import DeleteConfirmation from "../../components/DeleteConfirmation";

import { getLoginDetails } from "../../utils/storage";
import { useHistory ,useLocation } from 'react-router-dom';

import HeaderIcon from "../../icons/black_pricing.png";

import {
  GET_MAKE_MODEL_URL,
  EDIT_CONVERTERS_URL,
  ADD_TEST_URL,
  EDIT_TEST_URL,
  DELETE_TEST_URL,
  GET_PRODUCT_DETAILS_URL,
  GET_TEST_HISTORY_URL,
  GET_PRICE_HISTORY_URL,
  GET_CONVERTER_TYPES_URL,
  FILE_UPLOAD_URL,
  getData,
  postData,
  putData,
  deleteData,
  uploadFile,
} from "../../utils/api";

import IconUsersInactive from "../../icons/rev_user.png";
import IconInformationInactive from "../../icons/rev_information.png";
import IconPricingInactive from "../../icons/rev_pricing.png";

import IconUsersActive from "../../icons/pos_user.png";
import IconInformationActive from "../../icons/pos_information.png";
import IconPricingActive from "../../icons/pos_pricing.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

const testModel = {
  productId: "",
  subweight: "",
  ptAssay: "",
  pdAssay: "",
  rhAssay: "",
  location: "",
  userName: "",
  notes: "",
  testId: "",
};

const ConverterDetail = (props) => {
  const { canShowTestHistory, canShowPricing, hierarchy, canAccessAssay = false } = getLoginDetails();
  const location = useLocation()

  const [isLoading, setIsLoading] = useState(true);
  const [productDetail, setProductDetail] = useState(null);
  const [makeAndModelList, setMakeAndModelList] = useState(null);
  const [testHistory, setTestHistory] = useState([]);
  const [activeView, setActiveView] = useState(2);
  const [isAddTestPopupShown, setIsAddTestPopupShown] = useState(false);
  const [isEditTestPopupShown, setIsEditTestPopupShown] = useState(false);
  const [isDeletePopupShown, setIsDeletePopupShown] = useState(false);
  const [convoTypes, setConvoTypes] = useState([]);

  const [testItemForEdit, setTestItemForEdit] = useState(null);
  const [testForDelete, setTestForDelete] = useState(null);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  const [tableLoading, setTableLoading] = useState(false);
  const [points, setPoints] = useState([]);
  const [historyGraphItems, setHistoryGraphItems] = useState([]);

  const getProductDetail = async (productId) => {
    try {
      setIsLoading(true);
      setProductDetail(null);
      const data = await getData(GET_PRODUCT_DETAILS_URL, {
        productId: productId,
      });
      setProductDetail(data);
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };
  const getHistoryForGraph = async (productId) => {
    try {
      const data = await getData(GET_PRICE_HISTORY_URL, {
        productId: productId,
      });
      // const pts = {abListPrice: [], abPublishPrice: []};
      const pts = [["Date", "List Price", "Publish Price"]];

      (data.list || []).map((obj, index) => {
        // pts.abListPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abListPrice)])
        // pts.abPublishPrice.push([moment(obj.date).format('Do, MMM YY, hh:mm A'), parseFloat(obj.abPublishPrice)])
        pts.push([
          moment(obj.date).format("Do, MMM YY, hh:mm A"),
          parseFloat(obj.abListPrice),
          parseFloat(obj.abPublishPrice),
        ]);
      });
      setPoints(pts);
      setHistoryGraphItems(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getTestHistory = async () => {
    return;
    try {
      const data = await getData(GET_TEST_HISTORY_URL, {
        productId: props.match.params.id,
      });
      setTestHistory(data.list);
      setTableLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR", e);
    }
  };
  const getMakeAndModel = async () => {
    try {
      const data = await getData(GET_MAKE_MODEL_URL, { count: 100 });
      setIsLoading(false);
      setMakeAndModelList(data.list);
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const getConverterTypes = async () => {
    try {
      const data = await getData(GET_CONVERTER_TYPES_URL, {
        count: 100,
        offset: 0,
      });
      setConvoTypes(data.list || []);
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message: "Unable to get conerter types list.",
        id: new Date().getTime(),
      });
    }
  };
  const editItem = async (data, images) => {
    let urls = data.urls;
    if (images) {
      const promises = (images || []).map((image) => {
        if (image.file) {
          return uploadFile(FILE_UPLOAD_URL, image.file);
        }
        return new Promise((resolve, reject) => {
          resolve({ imageUrl: image.dataURL });
        });
      });
      const updatedUrls = await Promise.all(promises);
      // const response = await uploadFile(FILE_UPLOAD_URL, image.file);
      urls = updatedUrls.map((item) => {
        if ((item.imageUrl || "").length > 0) {
          return item.imageUrl;
        }
      });
    }
    try {
      // setIsLoading(true)
      const response = await putData(EDIT_CONVERTERS_URL, { ...data, urls });
      // setIsEditPopupShown(false);
      // setIsLoading(true);

      setToastParams({
        type: "SUCCESS",
        message: "Successfully edited Conveter",
        id: new Date().getTime(),
      });
      const productId = props.match.params.id;

      getProductDetail(productId);
      getTestHistory(productId);

      setIsLoading(false);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const addTest = async (data) => {
    try {
      const { firstName, lastName, userName } = getLoginDetails();
      const productId = props.match.params.id;
      const response = await postData(ADD_TEST_URL, {
        ...data,
        userName,
        firstName,
        lastName,
        productId,
      });

      setToastParams({
        type: "SUCCESS",
        message: "Successfully Added the test",
        id: new Date().getTime(),
      });
      setIsAddTestPopupShown(false);
      getTestHistory(productId);
    } catch (e) {
      console.log("ERROR");
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const editTest = async (data) => {
    try {
      const { firstName, lastName, userName } = getLoginDetails();
      const productId = props.match.params.id;
      const response = await putData(EDIT_TEST_URL, {
        ...data,
        userName,
        firstName,
        lastName,
        productId,
      });

      setToastParams({
        type: "SUCCESS",
        message: "Successfully Edited the test",
        id: new Date().getTime(),
      });
      setIsEditTestPopupShown(false);
      getTestHistory(productId);
    } catch (e) {
      console.log("ERROR", e);
      setIsLoading(false);
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };
  const deleteTest = async () => {
    try {
      setIsLoading(true);
      const response = await deleteData(DELETE_TEST_URL, {
        testId: testForDelete.testId,
      });
      setIsDeletePopupShown(false);
      getTestHistory();
      setIsLoading(false);
    } catch (e) {
      setIsLoading(false);
      console.log("ERROR");
    }
  };
  const setDeleteTest = (item) => {
    setIsDeletePopupShown(true);
    setTestForDelete(item);
  };
  useEffect(() => {
    const productId = props.match.params.id;
    getProductDetail(productId);
    // getTestHistory(productId);
    // getHistoryForGraph(productId);
    getConverterTypes();
    // getMakeAndModel();
  }, []);
  const heading = location.pathname.includes("converter")?'Converters':'Pricing';
  return (
    <PageLayout
      icon={HeaderIconSvg}
      heading={heading}
      searchPlaceholder="Search Converters"
      hideSearchBar={hierarchy !== 1}
    >
      {!isLoading &&
        !isAddTestPopupShown &&
        !isEditTestPopupShown &&
        productDetail && (
          <>
            <div className="flex flex-between flex-middle">
              <h1 className="mr-24 fs-28 c-black">{productDetail.name}</h1>
              <div className="flex flex-middle">
                {productDetail.price ? (
                  <div className="flex mr-40 flex-bottom">
                    <span className="c-red fs-18 mr-12 fw-bolder">
                      OVERRIDE PRICE
                    </span>
                    <span className="fs-28 fw-bolder">
                      ${(productDetail.price || 0.00).toFixed(2)}
                    </span>
                  </div>
                ) : null}
                {/*<div className="flex mr-40 flex-bottom">
                            <span className="c-red fs-18 mr-12 fw-bolder">MARKET PRICE</span>
                            <span className="fs-28 fw-bolder">${productDetail.marketPrice}</span>
                        </div>*/}
                <div className="flex mr-40 flex-bottom">
                  <span className="c-red fs-18 mr-12 fw-bolder">
                      {
                        hierarchy !== 1 ? 'OFFER PRICE':'CALCULATED PRICE'
                      }
                  </span>
                  <span className="fs-28 fw-bolder">
                    ${hierarchy !== 1 ?productDetail.basePrice:productDetail.offerPrice}
                  </span>
                </div>
                <div
                    className="btn wt-120 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => {
                      props.history.push(props.match.params.type && props.match.params.type === "2"
                      ? `/converters/identify/${props.match.params.parameters}`
                      : "/converters");
                    }}
                  >
                    <span>Back</span>
                  </div>
                {/* <Link
                  to={
                    props.match.params.type && props.match.params.type === "2"
                      ? `/converters/identify/${props.match.params.parameters}`
                      : "/converters"
                  }
                  className="flex flex-middle flex-between bg-gray pl-16 pr-16 pb-8 pt-8 c-white fw-bolder c-pointer"
                  onClick={() => {}}
                >
                  <span>Back</span>
                </Link> */}
              </div>
            </div>

            <div className={`tabs flex`}>
            {/* {false && (
              <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              activeView === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setActiveView(1)}
          >
            {activeView !== 1 && (
                    <img
                      alt=""
                      src={IconPricingInactive}
                      className={`wt-30 mr-8 ${
                        activeView === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
                      }`}
                    />
                  )}
                  {activeView === 1 && (
                    <img
                      alt=""
                      src={IconPricingActive}
                      className={`wt-30 mr-8 ${
                        activeView === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
                      }`}
                    />
                  )}
            <span>PRICING</span>
          </div>
            )
          } */}
          {/* <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              activeView === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => setActiveView(2)}
          >
            {activeView !== 2 && (
                  <img
                    alt=""
                    src={IconInformationInactive}
                    className={`wt-30 mr-8 ${
                      activeView === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
                    }`}
                  />
                )}
                {activeView === 2 && (
                  <img
                    alt=""
                    src={IconInformationActive}
                    className={`wt-30 mr-8 ${
                      activeView === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
                    }`}
                  />
                )}
            <span>INFO</span>
          </div> */}
        </div>

            {activeView === 2 && productDetail && (
              // makeAndModelList &&
              <>
                {" "}
                <ConverterInfo
                  converter={{ ...productDetail }}
                  convoTypes={[...convoTypes]}
                  makeAndModelList={makeAndModelList}
                  onSave={editItem}
                  showAction={false}
                />
                <div className="border-bottom-thick mt-16 mb-8" />
                {false && (
                  <>
                    <div className="flex flex-between mt-24">
                      <div className="c-red fs-18 fw-bolder ">Test History</div>
                      <div
                    className="btn wt-160 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => {
                      setIsAddTestPopupShown(true);
                    }}
                  >
                    <span>Add Test</span>
                  </div>
                    </div>
                    <Table
                      scrollTable={true}
                      columns={[
                        "Date",
                        "Person ",
                        "PT Assay",
                        "PD Assay",
                        "RH Assay",
                      ]}
                      // className="wt-600"
                      items={testHistory.map((test) => {
                        return {
                          ...test,
                          date: (() => {
                            const dateObj = new Date(test.createdDate);
                            const year = dateObj.getFullYear();
                            const month = `0${dateObj.getMonth() + 1}`.slice(
                              -2
                            );
                            const date = dateObj.getDate();
                            return `${year}-${month}-${date}`;
                          })(),
                          user: `${test.firstName} ${test.lastName}`,
                        };
                      })}
                      keys={["date", "user", "ptAssay", "pdAssay", "rhAssay"]}
                      widthClasses={[
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "flex-1",
                        "wt-100",
                      ]}
                      actions={["EDIT", "DELETE"]}
                      isLoading={tableLoading}
                      onEdit={(item) => {
                        setTestItemForEdit(item);
                        setIsEditTestPopupShown(true);
                      }}
                      onDelete={setDeleteTest}
                    />
                  </>
                )}
              </>
            )}
            {activeView === 1 && productDetail && (
              <ConverterPricing
                converter={{ ...productDetail }}
                points={points}
                historyGraphItems={historyGraphItems}
                onSave={editItem}
              />
            )}
          </>
        )}
      {isAddTestPopupShown && (
        <TestAddEdit
          item={{ ...testModel }}
          type="add"
          onSave={addTest}
          onClose={setIsAddTestPopupShown.bind(null, false)}
          onBack={setIsAddTestPopupShown.bind(null, false)}
        />
      )}
      {isEditTestPopupShown && (
        <TestAddEdit
          item={{ ...testItemForEdit }}
          type="edit"
          onSave={editTest}
          onClose={setIsEditTestPopupShown.bind(null, false)}
          onBack={setIsEditTestPopupShown.bind(null, false)}
        />
      )}
      {isDeletePopupShown && (
        <DeleteConfirmation
          type="test"
          name={testForDelete.testId}
          onClose={setIsDeletePopupShown.bind(null, false)}
          onConfirm={deleteTest}
        />
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {/* <Call channel={props.match.params.id}/> */}
      {/* <Chat /> */}
    </PageLayout>
  );
};

export default ConverterDetail;
