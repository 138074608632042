export const getToken = ()=> {
    return localStorage.getItem("cat-token");
}
export const storeToken = (token)=> {
    return localStorage.setItem("cat-token", token);
}
export const deleteToken = ()=> {
    return localStorage.removeItem("cat-token");
}

export const storeLoginDetails = (loginDetails)=> {
    try{
        return localStorage.setItem("login-details", JSON.stringify(loginDetails));
    } catch(e) {

    }
}
export const getLoginDetails = ()=> {
    try {
        return JSON.parse(localStorage.getItem("login-details"));
    } catch(e) {
        return {};
    }
}