import React, { Component } from "react";
import AgoraRTC from "agora-rtc-sdk";
import Draggable from "react-draggable";

import { ReactComponent as Mute } from "../../icons/mute.svg";
import { ReactComponent as Shrink } from "../../icons/shrink.svg";
import { ReactComponent as Fullscreen } from "../../icons/fullscreen.svg";
import { ReactComponent as Switch } from "../../icons/switch.svg";
import { ReactComponent as Close } from "../../icons/close.svg";
import { ReactComponent as Unmute } from "../../icons/unmute.svg";

let client = AgoraRTC.createClient({ mode: "live", codec: "h264" });

const USER_ID = Math.floor(Math.random() * 1000000001);
const APP_ID = "c48934dc430548ffa0e0d12c348bef54";

export default class Call extends Component {
  localStream = AgoraRTC.createStream({
    streamID: USER_ID,
    audio: true,
    video: true,
    screen: false,
  });

  state = {
    remoteStreams: [],
    currentCameraIndex: 0,
    isShrinkMode: false,
    isAudioMuted: false,
  };

  componentDidMount() {
    console.log("DIDMOUNT");
    this.initLocalStream();
    this.initClient();
    setTimeout(() => {
      this.joinChannel();
    }, 2000);
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.channel !== this.props.channel && this.props.channel !== "") {
      // this.joinChannel();
    }
  }
  componentWillUnmount() {
    const me = this;
    console.log("UNMOUNTING");
    me.localStream.close();
    client.leave();
    // client.unpublish(this.localStream, (err)=> {
    //   console.log(err);
    //   client.unsubscribe(this.localStream, (err)=> {
    //   });
    // });
  }

  initLocalStream = () => {
    let me = this;
    me.localStream.init(
      function () {
        console.log("getUserMedia successfully");
        me.localStream.play("agora_local");
      },
      function (err) {
        console.log("getUserMedia failed", err);
      }
    );
  };

  initClient = () => {
    client.init(
      APP_ID,
      function () {
        console.log("AgoraRTC client initialized");
      },
      function (err) {
        console.log("AgoraRTC client init failed", err);
      }
    );
    this.subscribeToClient();
  };

  subscribeToClient = () => {
    let me = this;
    client.on("stream-added", me.onStreamAdded);
    client.on("stream-subscribed", me.onRemoteClientAdded);

    client.on("stream-removed", me.onStreamRemoved);

    client.on("peer-leave", me.onPeerLeave);
  };

  onStreamAdded = (evt) => {
    let me = this;
    let stream = evt.stream;
    console.log("New stream added: " + stream.getId());
    me.setState(
      {
        remoteStreams: {
          ...me.state.remoteStream,
          [stream.getId()]: stream,
        },
      },
      () => {
        // Subscribe after new remoteStreams state set to make sure
        // new stream dom el has been rendered for agora.io sdk to pick up
        client.subscribe(stream, function (err) {
          console.log("Subscribe stream failed", err);
        });
      }
    );
  };

  joinChannel = () => {
    let me = this;
    client.join(
      null,
      me.props.channel,
      USER_ID,
      function (uid) {
        console.log("User " + uid + " join channel successfully");
        client.publish(me.localStream, function (err) {
          console.log("Publish local stream error: " + err);
        });

        client.on("stream-published", function (evt) {
          console.log("Publish local stream successfully");
        });
      },
      function (err) {
        console.log("Join channel failed", err);
      }
    );
  };

  onRemoteClientAdded = (evt) => {
    let me = this;
    let remoteStream = evt.stream;
    me.state.remoteStreams[remoteStream.getId()].play(
      "agora_remote " + remoteStream.getId()
    );
  };

  onStreamRemoved = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log("Remote stream is removed " + stream.getId());
    }
  };

  onPeerLeave = (evt) => {
    let me = this;
    let stream = evt.stream;
    if (stream) {
      let streamId = stream.getId();
      let { remoteStreams } = me.state;

      stream.stop();
      delete remoteStreams[streamId];

      me.setState({ remoteStreams });

      console.log(evt.uid + " leaved from this channel");
    }
  };

  render() {
    const { isShrinkMode, isAudioMuted } = this.state;
    const { isMobile = false } = this.props;
    return (
      <Draggable handle=".handle">
        <div
          style={{
            position: "fixed",
            top: "25%",
            left: "50%",
            marginLeft: -200,
            marginTop: -200,
          }}
        >
          <div className="handle">
            <div
              id="agora_local"
              style={
                (isShrinkMode || isMobile)
                  ? {
                      width: 50,
                      height: 50,
                      top: 190,
                      left: 145,
                      position: "relative",
                      zIndex: 10000,
                    }
                  : {
                      width: "100px",
                      height: "100px",
                      position: "relative",
                      top: 640,
                      left: 540,
                      zIndex: 10000,
                    }
              }
            />
            {Object.keys(this.state.remoteStreams).map((key) => {
              let stream = this.state.remoteStreams[key];
              let streamId = stream.getId();
              return (
                <div
                  key={streamId}
                  className="agora_remote"
                  id={`agora_remote ${streamId}`}
                  style={
                    (isShrinkMode || isMobile)
                      ? {
                          width: 200,
                          height: 200,
                        }
                      : { width: "650px", height: "650px" }
                  }
                />
              );
            })}
          </div>
          <div
            className="flex mt-8"
            style={
              (isShrinkMode || isMobile)
                ? {
                    position: "absolute",
                    top: 90,
                    left: 210,
                    width: "100%",
                    zIndex: 10000,
                    display: "flex",
                    flexFlow: "column",
                  }
                : {
                    position: "absolute",
                    top: 580,
                    width: "100%",
                    zIndex: 1000,
                    display: "flex",
                    flexFlow: "column",
                    left: 670,
                  }
            }
          >
            <Close className="wt-30 ht-30 mb-12" onClick={this.props.onClose} />
            {!isAudioMuted && (
              <Unmute
                className="wt-30 ht-30 mb-12"
                onClick={() => {
                  !isAudioMuted
                    ? this.localStream.muteAudio()
                    : this.localStream.unmuteAudio();
                  this.setState({
                    isAudioMuted: true,
                  });
                }}
              />
            )}
            {isAudioMuted && (
              <Mute
                className="wt-30 ht-30 mb-12"
                onClick={() => {
                  !isAudioMuted
                    ? this.localStream.muteAudio()
                    : this.localStream.unmuteAudio();
                  this.setState({
                    isAudioMuted: false,
                  });
                }}
              />
            )}
            {!isShrinkMode && (
              <Shrink
                className="wt-30 ht-30 mb-12"
                onClick={() => {
                  this.setState({
                    isShrinkMode: !this.state.isShrinkMode,
                  });
                }}
              />
            )}
            {isShrinkMode && (
              <Fullscreen
                className="wt-30 ht-30 mb-12"
                onClick={() => {
                  this.setState({
                    isShrinkMode: !this.state.isShrinkMode,
                  });
                }}
              />
            )}
            <Switch
              className="wt-30 ht-30 "
              onClick={() => {
                AgoraRTC.getDevices((devices) => {
                  const cameras = devices.filter(
                    (device) => device.kind === "videoinput"
                  );
                  if (cameras.length > 1) {
                    this.localStream.switchDevice(
                      "video",
                      cameras[(this.state.currentCameraIndex + 1) % 2].deviceId,
                      console.log,
                      console.log
                    );
                    this.setState({
                      currentCameraIndex: this.state.currentCameraIndex + 1,
                    });
                  }
                });
              }}
            />
          </div>
        </div>
      </Draggable>
    );
  }
}
