import React, { useState } from "react";

import Modal from "../Modal";
import Button from "../Button";
import FormInput from "../FormInput";

import ImageUploading from "./ImageUpload";
import IconSearch from "../../icons/pos_find.png";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import CaretIcon from "../../icons/caret.png";

import GraphDisplay from "./GraphDisplay";
import Toggle from "react-toggle";

import { getLoginDetails } from "../../utils/storage";

import { MULTIPLE_FILE_UPLOAD_URL, uploadFile } from "../../utils/api";

const ConverterInfo = ({
  converter,
  historyGraphItems,
  points,
  onClose,
  onSave,
  type,
  onBack,
}) => {
  const [item, setItem] = useState(converter);
  const [image, setImage] = useState(null);
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  console.log("CONVERTRER PRICING", converter, historyGraphItems, points);
  const { isSA } = getLoginDetails();

  const uploadFiles = async (e) => {
    try {
      const response = await uploadFile(
        MULTIPLE_FILE_UPLOAD_URL,
        e.target.files
      );
      if (response && response.url) {
        item.urls = response.url;
        setItem(item);
      }
      setToastParams({
        type: "SUCCESS",
        message: "Successfully added the Partner",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR");
      setToastParams({
        type: "ERROR",
        message:
          e && e.error
            ? e.error
            : "Oops! Something went wrong. Please try again later",
        id: new Date().getTime(),
      });
    }
  };

  return (
    // <Modal>
    <div className="pl-8 pt-8 pb-16 bg-lt-gray-3">
      <div className="flex ">
        <img
          src={
            converter.urls[0] ||
            "https://s3.ap-south-1.amazonaws.com/cat-general-bucket/c4ee9b8e-129c-402f-acd1-779caf9342f51591591440282.jpeg"
          }
          className="ht-200 mr-12"
        />
        {/*<GraphDisplay
                        item={{...converter, name: converter.serialNo, s1value: 'abListPrice', s2Value: 'abPublishPrice'}}
                        priceModifierGraphItems={[...points]}
                        onClose = {()=> {}}
                        graphType={'Bar'}
                        dataItems={[...historyGraphItems]}
                        listHeaders={['Publish Price', 'List Price', 'Date']}
                        listDataKeys={['abPublishPrice', 'abListPrice', 'date']}
                        showList={true}
                        type="graph"
                    />*/}
        {/* <div className="flex-1">
                        <div className="flex flex-between flex-middle mb-20">
                            <FormInput placeholder="MANUFACTURER" className="mr-40 flex-1" value={item.manufacturer} onChange={(e)=> { setItem({...item, manufacturer: e.target.value}) }} />
                            <FormInput placeholder="DESCRIPTION" className="mr-40 flex-1" value={item.description} onChange={(e)=> { setItem({...item, description: e.target.value}) }} />
                            <FormInput placeholder="OEM" className="mr-40 flex-1" value={item.oem} onChange={(e)=> { setItem({...item, oem: e.target.value}) }} />
                        </div>
                        <div className="flex flex-between flex-middle mb-20">
                            <FormInput placeholder="SUB WEIGHT" className="mr-40 flex-1" value={item.subweight} onChange={(e)=> { setItem({...item, subweight: e.target.value}) }} />
                            <FormInput placeholder="PT ASSAY" className="mr-40 flex-1" value={item.ptAssay} onChange={(e)=> { setItem({...item, ptAssay: e.target.value}) }} />
                            <FormInput placeholder="PD ASSAY" className="mr-40 flex-1" value={item.pdAssay} onChange={(e)=> { setItem({...item, pdAssay: e.target.value}) }} />
                        </div>
                        <div className="flex flex-between flex-middle">
                            <textarea placeholder="Notes" className="mr-40 flex-1" value={item.notes} onChange={(e)=> { setItem({...item, notes: e.target.value}) }} rows="2"/>
                            <Button type="saveButton" className="flex flex-middle mr-40" onClick={()=> { onSave({...item}, image) }}>
                                <img src={Tick} className="wt-20 ht-20 mr-4"/>
                                <span>Save Changes</span>
                            </Button>
                        </div>
                    </div> */}
      </div>
      <div className="border-bottom-thick mt-16 mb-24" />

      <div className="flex flex-between flex-middle mb-20">
        <FormInput
          isReadonly={true}
          placeholderType="red"
          placeholder="HIGH"
          className="mr-40 flex-1"
          value={item.high}
          onChange={(e) => {
            setItem({ ...item, high: e.target.value });
          }}
        />
        <FormInput
          isReadonly={true}
          placeholderType="red"
          placeholder="LOW"
          className="mr-40 flex-1"
          value={item.low}
          onChange={(e) => {
            setItem({ ...item, low: e.target.value });
          }}
        />
        <FormInput
          isReadonly={true}
          placeholderType="red"
          placeholder="AVG"
          className="mr-40 flex-1"
          value={item.average}
          onChange={(e) => {
            setItem({ ...item, average: e.target.value });
          }}
        />
        <div className="mr-40 flex-1">
          <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
            <span>GLOBAL MODIFIER</span>
            <div className="flex flex-middle">
              <span className="c-gray mr-4">$</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setItem({
                    ...item,
                    price2ModifierIsPercentage: e.target.checked,
                  });
                }}
                checked={item.price2ModifierIsPercentage}
              />
              <span className="c-gray ml-4">%</span>
            </div>
          </div>
          <FormInput
            placeholderType="red"
            placeholder=""
            value={item.price2Modifier}
            onChange={(e) => {
              setItem({ ...item, price2Modifier: e.target.value });
            }}
          />
        </div>
      </div>

      <div className="flex flex-between flex-middle mb-20">
        <div className="mr-40 flex-1">
          <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
            <span>SCHEMA DISC</span>
            <div className="flex flex-middle">
              <span className="c-gray mr-4">$</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setItem({
                    ...item,
                    schemeDiscIsPercentage: e.target.checked,
                  });
                }}
                checked={item.schemeDiscIsPercentage}
              />
              <span className="c-gray ml-4">%</span>
            </div>
          </div>
          <FormInput
            placeholderType="red"
            placeholder=""
            value={item.schemeDisc}
            onChange={(e) => {
              setItem({ ...item, schemeDisc: e.target.value });
            }}
          />
        </div>
        <div className="mr-40 flex-1">
          <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
            <span>PT DISC</span>
            <div className="flex flex-middle">
              <span className="c-gray mr-4">$</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setItem({ ...item, ptDiscIsPercentage: e.target.checked });
                }}
                checked={item.ptDiscIsPercentage}
              />
              <span className="c-gray ml-4">%</span>
            </div>
          </div>
          <FormInput
            placeholderType="red"
            placeholder=""
            value={item.ptDisc}
            onChange={(e) => {
              setItem({ ...item, ptDisc: e.target.value });
            }}
          />
        </div>
        <div className="mr-40 flex-1">
          <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
            <span>PD DISC</span>
            <div className="flex flex-middle">
              <span className="c-gray mr-4">$</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setItem({ ...item, pdDiscIsPercentage: e.target.checked });
                }}
                checked={item.pdDiscIsPercentage}
              />
              <span className="c-gray ml-4">%</span>
            </div>
          </div>
          <FormInput
            placeholderType="red"
            placeholder=""
            value={item.pdDisc}
            onChange={(e) => {
              setItem({ ...item, pdDisc: e.target.value });
            }}
          />
        </div>
        <div className="mr-40 flex-1">
          <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
            <span>RH DISC</span>
            <div className="flex flex-middle">
              <span className="c-gray mr-4">$</span>
              <Toggle
                className="bg-theme hide-check-cross"
                onChange={(e) => {
                  setItem({ ...item, rhDiscIsPercentage: e.target.checked });
                }}
                checked={item.rhDiscIsPercentage}
              />
              <span className="c-gray ml-4">%</span>
            </div>
          </div>
          <FormInput
            placeholderType="red"
            placeholder=""
            value={item.rhDisc}
            onChange={(e) => {
              setItem({ ...item, rhDisc: e.target.value });
            }}
          />
        </div>
      </div>

      {isSA && (
        <div className="flex flex-middle mb-20">
          <FormInput
            type="number"
            placeholderType="red"
            placeholder="Price"
            className="mr-40 wt-33p"
            value={item.price}
            onChange={(e) => {
              setItem({ ...item, price: Number(e.target.value) });
            }}
          />
          <div className="mr-40 wt-33p">
            <div className="c-red fw-bolder fs-14 flex flex-between mb-4 flex-middle">
              <span>USE OVERRIDE PRICE ?</span>
              <div className="flex flex-middle">
                <Toggle
                  className="bg-theme"
                  checked={item.isCalculatedPrice}
                  onChange={(e) => {
                    setItem({ ...item, isCalculatedPrice: e.target.checked });
                  }}
                />
              </div>
            </div>
          </div>
        </div>
      )}
      <div className="flex flex-row-reverse pr-16">
      <div
                    className="btn wt-180 ht-40 t-center fs-16 c-white fw-bolder flex flex-middle flex-center c-pointer mr-12"
                    onClick={() => {
                      onSave({ ...item }, image);
                    }}
                  >
                    <span>Save Changes</span>
                  </div>
      </div>
    </div>
    // </Modal>
  );
};

export default React.memo(ConverterInfo);
