import React, { useState, useEffect } from "react";
import "./Dashboard.css";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import CaretIcon from "../../icons/rev_carat.png";
import { downloadGMTable, getData, GET_GLOBAL_MODIFIERS } from "../../utils/api";
import HeaderIcon from "../../icons/newicons/arrow-down-up.svg";
import Table from "../../components/Table";
import SearchIconWhite from "../../icons/rev_find.png";

import PageLayout from "../../components/PageLayout";
import { withRouter, useHistory } from "react-router";
import ActionLoader from "../../components/ActionLoader";
import { getLoginDetails } from "../../utils/storage";

function GlobalModifierReport(props) {
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  useEffect(() => {
    setStartDate(new Date());
    setEndDate(new Date());
    // getEntities();
  }, []);
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });

  useEffect(() => {
        getEntities(); 
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [params.limit,params.offset]);

  useEffect(() => {
    if(startDate == '' && endDate == ''){
      getEntities(); 
    }
// eslint-disable-next-line react-hooks/exhaustive-deps
}, [startDate,endDate]);

  const resetData = () => {
    setStartDate(new Date());
    setEndDate(new Date());
    setEntitiesItems([]);
  };

  const downloadGMData = () => {
    setIsLoading(true);
    const startDt = new Date(startDate).setHours(0, 0, 0, 0);
    const endDt = new Date(endDate).setHours(23, 59, 59, 59);
    downloadGMTable(startDt, endDt)
      .then((res) => {
        const element = document.createElement("a");
        element.setAttribute("href", res.link);
        element.setAttribute("target", "_blank");
        element.setAttribute("download", true);
        element.click();
        setIsLoading(false);
      })
      .catch((err) => {
        console.log(err);
        setIsLoading(false);
      });
  };
  const [showTab, setShowTab] = useState(6);

  const [entitiesItems, setEntitiesItems] = useState([]);
 
  const getEntities = async () => {
    try {
      setIsLoading(true);
      const startDt = new Date(startDate?startDate:Date.now()).setHours(0, 0, 0, 0);
       const endDt = new Date(endDate?endDate:Date.now()).setHours(23, 59, 59, 59);
      const data = await getData(GET_GLOBAL_MODIFIERS, {
        count: params.limit,
        offset: params.offset,
        from:startDt,
        to:endDt
      });
      setIsLoading(false);

      setEntitiesItems(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  return (
    <PageLayout
      icon={HeaderIcon}
      svg={true}
      heading="Global Modifier Report"
      searchPlaceholder="Search"
      inputClassName=" form-control "
    >
        <div className="flex flex-middle mt-8 flex-between groups-tabs ">
        <div className={`tabs flex`}>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 0 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(0)
              props.history.push('/dashboard/costsheetreport')

            }}
          >
            {/*<img src={HeaderIconPage} className="wt-30 mr-8" />*/}
            <span>Cost Sheet</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 1 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() =>{
              setShowTab(1)
              props.history.push('/dashboard/transactionsreport')
            } }
          >
            <span>Transactions</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 2 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(2)
              props.history.push('/dashboard/top-cats')
            }
            }
          >
            <span>Top Cats</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 3 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(3)
              props.history.push('/dashboard/top-lookups')
            }
            }
          >
            <span>Top Lookups</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 4 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(4)
              props.history.push('/dashboard/didnotbuy')
            }
            }
          >
            <span>Do Not Buy</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 5 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(5)
              props.history.push('/dashboard/top-customers')
            }
            }
          >
            <span>Top Customers</span>
          </div>
          <div
            className={`ht-40 flex flex-middle pl-16 pr-16 fw-bolder c-pointer ${
              showTab === 6 ? "bg-white c-gray" : "bg-lt-gray-2 c-white"
            }`}
            onClick={() => {
              setShowTab(6)
              props.history.push('/dashboard/globalmodifier')
            }
            }
          >
            <span>Global Modifier</span>
          </div>
        </div>
      </div>
      <div style={{justifyContent:"flex-start"}} className="costSheetFilters flex">
        <div>
          <div className="c-gray fs-12"> Start Date </div>
          <DatePicker
            selected={startDate}
            onChange={(date) => setStartDate(date)}
            selectsStart
            startDate={startDate}
            endDate={endDate}
          />
        </div>
        <div style={{ marginRight: "10%", marginLeft: "20px" }}>
          <div className="c-gray fs-12"> End Date </div>
          <DatePicker
            selected={endDate}
            onChange={(date) => setEndDate(date)}
            selectsEnd
            startDate={startDate}
            endDate={endDate}
            minDate={startDate}
          />
        </div>
        <div className="search-button">
          <div
            className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center  closeicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={resetData}
          >
            <span className="fs-16 c-white"> X </span>
          </div>
        </div>
        {/* <div
          class="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer"
          onClick={downloadGMData}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            width="16"
            height="16"
            fill="currentColor"
            class="bi bi-download mr-8"
            viewBox="0 0 16 16"
          >
            <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
            <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
          </svg>
          Download Reports
        </div> */}
         <div className="search-button ml-10">
          <div
            style={{marginRight: 10}}
            className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
            onClick={getEntities}
          >
            <img
              src={SearchIconWhite}
              alt="searchicon"
              width="20px"
              height="20px"
            />
          </div>
        </div>
        <div className="search-button ml-10" >
            <div
              className="wt-35 ht-35 rounded-circle  align-center d-flex justify-content-center align-items-center searchicon pl-4 pr-4 pb-8 pt-8 c-white fw-bolder c-pointer"
              onClick={downloadGMData}
            >
            
              <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="18"
                  height="18"
                  fill="currentColor"
                  class="bi bi-download"
                  viewBox="0 0 16 16"
                >
                  <path d="M.5 9.9a.5.5 0 0 1 .5.5v2.5a1 1 0 0 0 1 1h12a1 1 0 0 0 1-1v-2.5a.5.5 0 0 1 1 0v2.5a2 2 0 0 1-2 2H2a2 2 0 0 1-2-2v-2.5a.5.5 0 0 1 .5-.5z" />
                  <path d="M7.646 11.854a.5.5 0 0 0 .708 0l3-3a.5.5 0 0 0-.708-.708L8.5 10.293V1.5a.5.5 0 0 0-1 0v8.793L5.354 8.146a.5.5 0 1 0-.708.708l3 3z" />
                </svg>
            </div>
          </div>
      </div>
        <Table
          scrollTable={true}
          type="entity"
          columns={[
            () => {
              return (
                <span>
                  Market{" "}
                  {/* {customerSort === "asc" ? (
                    <span
                      className={"fs-16 c-pointer"}
                      onClick={() => setCustomerSort("desc")}
                    >
                      ↓
                    </span>
                  ) : (
                    <span
                      className={"fs-16 c-pointer"}
                      onClick={() => setCustomerSort("asc")}
                    >
                      ↑
                    </span>
                  )} */}
                </span>
              );
            },
            "Hedge",
            "Discount",
            "Net",
            "Date",
            "User",
          ]}
          switchIds={[6]}
          // className="wt-600"
          items={
            entitiesItems.map((entity) => {
              const createdDate = moment(entity.created).format("MM/DD/YYYY hh:mm A");

              return {
                ...entity,
               market :`Pt : ${entity.marketPdOz}
                        Pd : ${entity.marketPtOz}\r\nRh : ${entity.marketRhOz}`,
               hedge :`Pt : ${entity.hedgePdOz}\nPd : ${entity.hedgePtOz}\nRh : ${entity.hedgeRhOz}`,
               discount :`Pt : ${entity.modifierPd}\n\nPd : ${entity.modifierPt}\n\nRh : ${entity.modifierRh}`,
               net :`Pt : ${entity.npaPd}\nPd : ${entity.npaPt}\nRh : ${entity.npaRh}`,
               created:`${createdDate}`
              };
            })
          }
          keys={[
            "market",
            "hedge",
            "discount",
            "net",
            "created",
            "username"
          ]}
          widthClasses={[
            "flex-1",
            "flex-1",
            "wt-90",
            "flex-1",
            "flex-1",
            "flex-1",
            "wt-90",
          ]}
          // actions={[canEditGroup && "EDIT", canDeleteGroup && "DELETE", "VIEW"]}
          // onEdit={setEdit}
          // onDelete={setDelete}
          // onChange={onSearchChange}
          // onIsActiveChange={(hierarchy === 1 || hierarchy === 10)? onIsActiveChange : false}
          // headerBgWhite={true}
          // viewLinkKey="link"
          // viewIconImg={CustomerUserIcon}
          offset={params.offset}
          areMoreItemsAvailable={params.areMoreItemsAvailable}
          fetchMore={fetchMore}
          fetchLess={fetchLess}
          // isLoading={isLoading}
          // isCart={false}
          hideCheckBox={true}
          // isSingleSelect={true}
          // onSelection={(item) => {
          //   if (item) {
          //     setUserData({
          //       companyId: item.companyId,
          //       email: item.email,
          //       fname: item.firstName,
          //       lname: item.lastName,
          //       facilityCity: item.facility,
          //     });
          //   } else {
          //     resetData();
          //   }
          // }}
          onCountChange={(value) => {
            setParams({
              ...params,
              limit: value,
              offset: 0,
            });
          }}
          limit={params.limit}
          widthClasses={['wt-200', 'wt-200', 'wt-200', 'wt-200', 'flex-1']}
        />

         {/* <div className="flex-1">
            <div
              style={{ position: "absolute", width: "100%", left: "0" }}
              className=" flex flex-middle flex-center pl-16 pr-16 pagination-wrapper bottom"
            >
              <div className="ht-20 rounded-circle flex flex-center flex-middle pr-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate-90 c-pointer ${
                    params.offset > 0 ? "op-7" : "op-2"
                  }`}
                  onClick={params.offset > 0 ? fetchLess : () => {}}
                />
              </div>
              <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                Showing {params.offset + 1} - {params.offset + entitiesItems.length}
              </span>
              <div className="ht-20 rounded-circle  flex flex-center flex-middle pl-8 pb-4 pt-4">
                <img
                  src={CaretIcon}
                  alt=""
                  className={`wt-20 rotate--90 c-pointer ${
                    params.areMoreItemsAvailable ? "op-7" : "op-2"
                  }`}
                  onClick={params.areMoreItemsAvailable ? fetchMore : () => {}}
                />
              </div>
            </div>
          </div> */}
  
    
  
      <div></div>
      {isLoading && (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      )}
    </PageLayout>
  );
}

export default withRouter(GlobalModifierReport);
