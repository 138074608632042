import React, { useState, useEffect, useRef } from "react";
import ReactDOMServer from "react-dom/server";
import HeaderIcon from "../../icons/newicons/people-fill.svg";
import IconUserAdd from "../../icons/rev_add_user.png";

import PageLayout from "../../components/PageLayout";
import FormInput from "../../components/FormInput";
import { getLoginDetails } from "../../utils/storage";
import Table from "../../components/Table";
import ActionLoader from "../../components/ActionLoader";
import Toast from "../../components/Toast";
import Modal from "../../components/Modal";
import Button from "../../components/Button";
import CaretIcon from "../../icons/rev_carat.png";
import HeaderIconSvg from "../../icons/newicons/arrow-down-up.svg";

// import InvoicePDF from "../InvoicePDF";

import CurrencyFormat from "react-currency-format";

import {
  GET_ALL_LOADS_URL,
  getData,
  postData,
  putData,
  CREATE_NEW_SESSION,
  POST_INVITE_USER_URL,
  GET_INVITE_USER_URL,
  FACILITY_CITY_LIST,
  GET_FACILITIES_URL,
} from "../../utils/api";
let TIMER = null;
const STATUS_MAPPING = {
  1: "NOT_STARTED",
  2: "IN_PROCESS",
  3: "CONVERTED_TO_QUOTE",
  4: "WAITING_BUYER_APPROVAL",
  7: "COMPLETED",
};
const InviteUsers = (props) => {
  // const loginDetail = getLoginDetails() || {};
  //   const isSeller = loginDetail.hierarchy == 2;
  //   const canAcceptLoadRequest = loginDetail.canAcceptLoadRequest;
  const [loadsList, setLoadsList] = useState([]);
  const [selectedPandingLoad, setSelectedPendingLoad] = useState({});
  const [isLoading, setIsLoading] = useState(false);
  const [tableLoading, setTableLoading] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [printPopup, setPrintPopup] = useState(null);
  const [isAddPopupShown, setIsAddPopupShown] = useState(false);
  const [userData, setUserData] = useState({
    fname: "",
    lname: "",
    email: "",
    companyId: "",
    facilityCity: ""
  });
  const [params, setParams] = useState({
    limit: 10,
    offset: 0,
    areMoreItemsAvailable: false,
  });
  const [toastParams, setToastParams] = useState({
    message: "",
    type: "",
    id: "",
  });
  //   const [showConfirmationModal, setShowConfimationModal] = useState(false);
  const getInvitedUsers = async (type = 3, isPathChange) => {
    try {
      setLoadsList([]);
      const data = await getData(GET_INVITE_USER_URL, {
        count: params.limit,
        offset: params.offset,
        text: searchValue,
        // type,
      });
      setIsLoading(false);
      setTableLoading(false);
      setLoadsList(data.list);
      setParams({
        ...params,
        areMoreItemsAvailable: data.areMoreItemsAvailable,
      });
    } catch (e) {
      console.log("ERROR", e);
    }
  };
  const onSearchChange = (val) => {
    setSearchValue(val);
  };
  const fetchMore = () => {
    setParams({
      ...params,
      offset: params.offset + params.limit,
    });
  };
  const fetchLess = () => {
    setParams({
      ...params,
      offset: params.offset - params.limit,
    });
  };

  const postInviteUser = async () => {
    try {
      if (
        (userData.email || "").length === 0 ||
        (userData.fname || "").length === 0 ||
        (userData.lname || "").length === 0
      ) {
        setToastParams({
          type: "ERROR",
          message: "Fill All Details",
          id: new Date().getTime(),
        });
        return;
      }
      const data = await postData(POST_INVITE_USER_URL, { ...userData });
      setLoadsList([]);
      resetData();
      setIsLoading(false);
      setTableLoading(false);
      setIsAddPopupShown(false);
      getInvitedUsers();
      setToastParams({
        type: "SUCCESS",
        message:
          data &&
          data.response &&
          data.response.data &&
          data.response.data.message
            ? data.response.data.message
            : "Invited User Successfully!",
        id: new Date().getTime(),
      });
    } catch (e) {
      console.log("ERROR", e);
      setToastParams({
        type: "ERROR",
        message:
          e && e.response && e.response.data && e.response.data.error
            ? e.response.data.error
            : "Enter Valid Name & Email",
        id: new Date().getTime(),
      });
    }
  };

  const resetData = () => {
    setUserData({ fname: "", lname: "", email: "", companyId: "", facilityCity: "" });
  };

  const isFirstRun = useRef(true);

  useEffect(() => {
    getInvitedUsers();
  }, [searchValue, params.offset, params.limit]);

  const [facilities, setFaciltiesItems] = useState([]);

  const getFacilities = async () => {
    try {
      const data = await getData(GET_FACILITIES_URL, {
        count: 100,
        offset: 0,
        parentCompanyId:
          props.parentCompanyId || getLoginDetails().parentCompanyId,
      });
      setFaciltiesItems(data.list && data.list.filter((el) => el.isActive) || []);
    } catch (e) {}
  };
  useEffect(() => {
    getFacilities();
  }, []);

  return (
    <PageLayout
      icon={HeaderIconSvg}
      heading="Invite Users"
      searchPlaceholder="Search User"
      onSearchChange={onSearchChange}
    >
      {isLoading ? (
        <div className="ht-80 p-relative">
          <ActionLoader />
        </div>
      ) : (
        <>
          <div className="flex flex-middle flex-between">
            <div className="flex flex-middle flex-between wt-100p">
              {/* <div>
                <h1 className="mr-24 fs-24 c-gray mb-4">Invited Users</h1>
              </div> */}
              <div className="ht-20 wt-20 mr-12 flex flex-middle flex-between pl-8 pr-8 pb-8 pt-8 mb-28 c-white fw-bolder c-pointer"></div>
              <div
                className="btn ht-40 flex flex-middle flex-between bg-gray pl-8 pr-8 pb-8 pt-8 mb-8 c-white fw-bolder c-pointer"
                onClick={() => {
                  setIsAddPopupShown(true);
                }}
              >
                <span>Invite User</span>
              </div>
            </div>
          </div>
          {loadsList.length === 0 && (
            <div className="fs-18 flex flex-center wt-100p mt-24 mb-24 c-gray">
              {" "}
              No Users{" "}
            </div>
          )}
          {loadsList.length > 0 &&
            (params.offset >= 10 || params.areMoreItemsAvailable) && (
              <div className="ht-60 flex flex-middle flex-center pl-16 pr-16 pagination-wrapper">
                {/* <div className="bg-gray flex flex-center flex-middle pr-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate-90 c-pointer ${
                      params.offset > 0 ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.offset > 0
                        ? () => {
                            fetchLess(1);
                          }
                        : () => {}
                    }
                  />
                </div> */}
                {/* <span className="fs-14 c-gray mr-20 ml-20 fw-bold">
                  Showing {params.offset + 1} -{" "}
                  {params.offset + loadsList.length}
                </span>
                <div className="bg-gray flex flex-center flex-middle pl-8 pb-4 pt-4">
                  <img
                    src={CaretIcon}
                    alt=""
                    className={`wt-20 rotate--90 c-pointer ${
                      params.areMoreItemsAvailable ? "op-7" : "op-2"
                    }`}
                    onClick={
                      params.areMoreItemsAvailable
                        ? () => {
                            fetchMore(1);
                          }
                        : () => {}
                    }
                  />
                </div> */}
              </div>
            )}
          {loadsList.length > 0 && (
            <Table
              scrollTable={true}
              columns={[
                "First Name",
                "Last Name",
                "Email",
                "Registered?",
                "Created",
              ]}
              items={(loadsList || []).map((load) => {
                const createdDate = new Date(load.created);
                const dd = String(createdDate.getDate()).padStart(2, "0");
                const mm = String(createdDate.getMonth() + 1).padStart(2, "0"); //January is 0!
                const yyyy = createdDate.getFullYear();
                let hours = createdDate.getHours();
                let minutes = createdDate.getMinutes();
                const ampm = hours >= 12 ? "pm" : "am";
                hours = hours % 12;
                hours = hours ? hours : 12;
                minutes = minutes < 10 ? "0" + minutes : minutes;

                return {
                  ...load,
                  createdDateDisplay: load.created ? `${mm}-${dd}-${yyyy}` : "",
                  displayIsRegistered: load.hasUserRegistered ? "YES" : "NO",
                };
              })}
              keys={[
                "fname",
                "lname",
                "email",
                "displayIsRegistered",
                "createdDateDisplay",
              ]}
              widthClasses={["flex-1", "flex-1", "flex-1", "flex-1", "flex-1"]}
              hideCheckBox={true}
              isLoading={tableLoading}
              offset={params.offset}
              areMoreItemsAvailable={params.areMoreItemsAvailable}
              onCountChange={(value) => {
                setParams({
                  ...params,
                  offset: 0,
                  limit: value,
                });
              }}
              fetchMore={fetchMore}
              fetchLess={fetchLess}
              limit={params.limit}
            />
          )}
        </>
      )}
      <Toast
        type={toastParams.type}
        message={toastParams.message}
        id={toastParams.id}
      />
      {isAddPopupShown && (
        <Modal>
          <div className="pt-10 pb-20 pl-10 pr-10 wt-320">
            <div className="flex flex-middle mr-12 pb-20">
              <h1 className="mr-24 fs-20 c-gray">INVITE USER</h1>
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <span className="c-gray mr-4">
                {" "}
                FIRST NAME<span className="c-red">*</span> :{" "}
              </span>
              <FormInput
                className={"border-bottom-black"}
                value={userData.fname}
                onChange={(e) => {
                  setUserData({ ...userData, fname: e.target.value });
                }}
              />
            </div>
            <div className="flex flex-middle mb-24 mt-10">
              <span className="c-gray mr-4">
                {" "}
                LAST NAME<span className="c-red">*</span> :{" "}
              </span>

              <FormInput
                className={"border-bottom-black"}
                value={userData.lname}
                onChange={(e) => {
                  setUserData({ ...userData, lname: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <span className="c-gray mr-4">
                {" "}
                EMAIL<span className="c-red">*</span> :{" "}
              </span>

              <FormInput
                className={"border-bottom-black"}
                value={userData.email}
                onChange={(e) => {
                  setUserData({ ...userData, email: e.target.value });
                }}
              />
            </div>

            <div className="flex flex-middle mb-24 mt-10">
              <span className="c-gray mr-4"> LOCATION: </span>

              {/*<FormInput 
                    className={'border-bottom-gray'}
                    // placeholderType="red" 
                    // placeholder="Name" 
                    value={selectedUser.location} 
                    onChange={(e)=> { 
                      setSelectedUser({...selectedUser, location: e.target.value}) 
                    }} 
                  />*/}

              {/*<div className="c-gray fs-14 mb-4">Company State</div>*/}
              <select
                name="type"
                className="select font-primary fs-14 fw-bolder"
                value={userData.facilityCity}
                onChange={(e) =>
                  setUserData({
                    ...userData,
                    facilityCity: e.target.value,
                  })
                }
              >
                <option value={""}>SELECT FACILITY</option>
                {facilities.map((state) => {
                  return <option value={state.name}>{state.name}</option>;
                })}
              </select>
            </div>

            <div className="flex flex-middle flex-right pt-25">
              <Button
                type="modalCancel"
                className="flex flex-middle mr-20 bg-red"
                onClick={() => {
                  resetData();
                  setIsAddPopupShown(false);
                }}
              >
                <span>CANCEL</span>
              </Button>
              <Button
                type="modalSave"
                className="flex flex-middle"
                onClick={postInviteUser}
              >
                <span>SAVE</span>
              </Button>
            </div>
          </div>
        </Modal>
      )}
    </PageLayout>
  );
};

export default InviteUsers;
