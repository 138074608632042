import React, { useState } from "react";

import Modal from "../Modal";

import Button from "../Button";

import FormInput from "../FormInput";

import ChooseImageIcon from "../../icons/pos_choose_image.png";

import ImageUploading from "./ImageUpload";
import Tick from "../../icons/rev_confirm.png";
import DeleteIcon from "../../icons/rev_delete.png";
import AskExpertIcon from "../../icons/rev_ask_expert.png";

const BulkUploadConverters = ({ onClose, onSave, onBack, onEditSave }) => {
  const [title, setTitle] = useState("");

  const [image, setImage] = useState(null);
  const [editedImage, setEditedImage] = useState(null);

  // _onFilesSelected(event) {
  //   if (event.target.files && event.target.files.length > 0) {
  //     this.props.onFilesSelected(event.target.files);
  //   }
  // }

  return (
    <Modal>
      <div className="mt-8">
        <div className="btn  ht-40 d-flex  align-items-center bg-gray pl-8 pr-8 pb-8 pt-8 c-white fw-bolder c-pointer" style={{width: 230}}>
          <img alt="" src={AskExpertIcon} className="wt-25 mr-12" />
          <span className="mr-12">BULK UPLOAD</span>
        </div>
        <div className="mb-20 mt-20">
          <div className="c-red fw-bolder fs-16 ml-8">TITLE</div>
          <FormInput
            className="pl-16 pt-8"
            inputClassName="rounded-pill bg-blue"
            // placeholder="Title"
            value={title}
            onChange={(e) => {
              setTitle(e.target.value);
            }}
          />
        </div>
        <div className="flex flex-middle flex-between">
          <div className="wt-50p">
            <div className="c-red fw-bolder fs-16 mr-40">UPLOAD FILE</div>
            <div className="bg-white p-relative">
              <div style={{ paddingBottom: "20px" }}>
                <div>
                  <input
                    type="file"
                    name="files[]"
                    multiple
                    // ref={(e) => { this.fileUploadButton = e; }}
                    onChange={(e) => setImage(e.target.files)}
                  />
                  <img className="wt-100 " src={ChooseImageIcon} />
                  {/* <ImageUploading onChange={setImage} /> */}
                </div>
              </div>
            </div>
          </div>
          <div className="wt-50p">
            <div className="c-red fw-bolder fs-16 mr-40">EDIT FILE</div>
            <div className="bg-white p-relative">
              <div style={{ paddingBottom: "20px" }}>
                <div>
                  <input
                    type="file"
                    name="files[]"
                    multiple
                    // ref={(e) => { this.fileUploadButton = e; }}
                    onChange={(e) => {
                      setEditedImage(e.target.files);
                      onEditSave(title, e.target.files[0]);
                    }}
                  />
                  <img className="wt-100 " src={ChooseImageIcon} />
                  {/* <ImageUploading onChange={setImage} /> */}
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="flex flex-middle flex-right">
          <Button
            className="flex  ht-40 flex-middle mr-20 bg-red btn btn-red"
            type="modalCancel"
            style={{padding: '10px 30px'}}
            onClick={onClose}
          >
            {/*<img src={DeleteIcon} className="wt-20 ht-20 mr-4"/>*/}
            <span>CANCEL</span>
          </Button>
          <Button
            type="modalSave"
            className="btn ht-40"
            style={{padding: '10px 30px'}}
            onClick={() => {
              onSave(title, image ? image[0] : null);
            }}
          >
            <img src={Tick} className="wt-20 ht-20 mr-4" />
            <span>SUBMIT</span>
          </Button>
        </div>
      </div>
    </Modal>
  );
};

export default BulkUploadConverters;
