import {SELF_COMPANY_ID} from '../actions/constants'

const initialState = {
    selfCompanyId: '',
    anotherProp: ''
  };
  const CustomerReducer = (state=initialState, action) => {
    switch(action.type) {
      case SELF_COMPANY_ID:
        return {
          ...state,
          selfCompanyId: action.data
        };
      default:
        return state;
    }
  };
  export default CustomerReducer;